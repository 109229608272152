import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import {
  Box,
  Button,
  Typography,
  Modal,
  useTheme,
  ThemeProvider,
  createTheme,
  Tab,
  Tabs,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ConfirmationDialog from "shared/ConfirmationDialog";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import EmployeeLeaves from "../employee-leaves/EmployeeLeaves";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { dashboardStyle, tabStyles } from "shared/utils";

function ManageLeaves() {
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [open, setOpen] = useState(false);
  const [leaveModalOpen, setLeaveModalOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(""); // Step 1
  const [allLeaves, setAllLeaves] = useState([]);
  const [selectedLeaveTypes, setSelectedLeaveTypes] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [sickLeave, setSickLeave] = useState("");
  const [privilegeLeave, setPrivilegeLeave] = useState("");
  const [selectEmployeeIdFromRow, setSelectEmployeeIdFromRow] = useState("");
  const [selectedSickLeave, setSelectedSickLeave] = useState("");
  const [selectedPrivilegeLeave, setSelectedPrivilegeLeave] = useState("");
  const [
    updateLeaveLimitsConfirmationOpen,
    setUpdateLeaveLimitsConfirmationOpen,
  ] = useState(false);
  const [rows, setRows] = useState([]);

  // Add state variables for confirmation dialog
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [fetchedHistory, setFetchedHistory] = useState([]);
  const [fetchedLeave, setFetchedLeave] = useState([]);
  const [createdOn, setCreatedOn] = useState("");
  const currentYear = new Date().getFullYear();
  const [changeYear, setChangeYear] = useState(currentYear);
  const [changeYearNew, setChangeYearNew] = useState(currentYear);

  const [selectedLeaveId, setSelectedLeaveId] = useState(null); // Track the selected leave id
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  // Existing code...

  const fetchYearlyData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}leaves/fetch-leaves`
      );
      setUsers(response.data);

      // Process the data immediately after fetching
      const allLeavesData = response.data.flatMap((user) =>
        user.years.flatMap((year) =>
          year.leaves.map((leave) => ({
            id: leave._id,
            srno: year.leaves.indexOf(leave) + 1,
            employeeId: user.employeeId,
            name: user.name || "",
            leaveType: leave.leaveType,
            startDate: leave.startDate,
            endDate: leave.endDate,
            totalDays: leave.totalDays,
            description: leave.description,
            history: leave.history,
            status: leave.status,
            year: year.year,
          }))
        )
      );

      // Sort the leaves by start date
      allLeavesData.sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      );

      // Update both states
      setAllLeaves(allLeavesData);
      setLeaves(allLeavesData);
      setRows(allLeavesData);
    } catch (error) {
      console.error("Error fetching leave data:", error);
    }
  };

  // Modify the useEffect that processes the users data
  useEffect(() => {
    fetchYearlyData();
  }, []);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL)
      .then((response) => {
        setEmployees(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user emails:", error);
      });
  }, []);

  const handleLeaveTypeChange = (event) => {
    setSelectedLeaveTypes(event.target.value);
  };

  // Update the useEffect hook to include leave types

  useEffect(() => {
    const allLeavesData = users.flatMap((user) =>
      user.years.flatMap((year) =>
        year.leaves.map((leave) => ({
          id: leave._id,
          srno: year.leaves.indexOf(leave) + 1,
          employeeId: user.employeeId,
          name: user.name || "",
          leaveType: leave.leaveType,
          startDate: leave.startDate,
          endDate: leave.endDate,
          totalDays: leave.totalDays,
          description: leave.description,
          // createdFrom: leave.createdFrom,
          history: leave.history, // Ensure history is included here
          status: leave.status,
          year: year.year, // Add the year information
        }))
      )
    );

    // Sort the leaves by start date
    allLeavesData.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

    setRows(allLeavesData);
    // Set all leaves to the state
    // rows.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

    // Update the serial numbers (srno) after sorting
    const updatedRows = rows.map((row, index) => ({
      ...row,
      srno: index + 1,
    }));

    // Set the updated rows to the state
    setLeaves(updatedRows);

    // Set selected status and leave type to show all by default
    console.log("allLeaves:", allLeavesData);
    setAllLeaves(allLeavesData);
    setSelectedStatus("");
  }, [users]);

  //   useEffect(() => {
  //   const allLeavesData = users.flatMap((user) =>
  //     user.years.flatMap((year) =>
  //       year.leaves.map((leave) => ({
  //         id: leave._id,
  //         srno: year.leaves.indexOf(leave) + 1,
  //         employeeId: user.employeeId,
  //         name: user.name || "",
  //         leaveType: leave.leaveType,
  //         startDate: leave.startDate,
  //         endDate: leave.endDate,
  //         totalDays: leave.totalDays,
  //         description: leave.description,
  //         createdFrom: leave.createdFrom,
  //         history: leave.history, // Ensure history is included here
  //         status: leave.status,
  //         year: year.year, // Add the year information
  //       }))
  //     )
  //   );
  //   console.log("allLeaves:", allLeavesData);
  //   setAllLeaves(allLeavesData);
  // }, [users]);

  const handleOpen = (description) => {
    setDescription(description);
    setOpen(true);
  };

  // const handleHistoryOpen = async (leave) => {
  //   try {
  //     setFetchedLeave({ ...leave, history: [] }); // Set a loading state
  //     setHistoryOpen(true);

  //     const history = await fetchLeaveHistory(leave.employeeId, leave.id);
  //     setFetchedLeave({ ...leave, history });
  //     console.log('Leave history:', fetchedLeave);
  //   } catch (error) {
  //     console.error('Error fetching leave history:', error);
  //     // Handle the error appropriately (e.g., show an error message)
  //   }
  // };

  const handleHistoryOpen = async (employeeId, leaveId) => {
    console.log("employeeId:", employeeId, "leaveId:", leaveId);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}leaves/leave-history/${employeeId}/${leaveId}`
      );
      const history = response.data.history;
      setFetchedHistory(history);
      console.log("Leave history:", history);
      if (history.length > 0) {
        setCreatedOn(history[0].createdOn);
        console.log("createdOn:", history[0].createdOn);
      }
      setHistoryOpen(true);
    } catch (error) {
      console.error("Error fetching leave history:", error);
      toast.error("Error fetching leave history");
    }
  };

  const fetchLeaveHistory = async (employeeId, leaveId) => {
    console.log("employeeId:", employeeId, "leaveId:", leaveId);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}leaves/fetchHistory/${employeeId}/${leaveId}/history`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching leave history:", error);
      throw error;
    }
  };

  const handleLeaveModalOpen = () => {
    setLeaveModalOpen(true);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setLeaveModalOpen(false);
    setHistoryOpen(false);
  };

  const handleDeleteConfirmation = (id) => {
    setSelectedLeaveId(id); // Set the selected leave id
    setDeleteConfirmationOpen(true); // Open the confirmation dialog
  };

  const handleConfirmDelete = () => {
    // Perform deletion action using the deleteId
    handleDelete(selectedLeaveId);
    setDeleteConfirmationOpen(false); // Close the confirmation dialog
  };

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}leaves/leave/${id}`)
      .then((result) => {
        toast.success("Leave record deleted successfully");

        // Remove the deleted leave from the users state
        const updatedUsers = users.map((user) => {
          const updatedYears = user.years.map((year) => {
            const updatedLeaves = year.leaves.filter(
              (leave) => leave._id !== id
            );
            return { ...year, leaves: updatedLeaves };
          });
          return { ...user, years: updatedYears };
        });
        setUsers(updatedUsers);

        // Update the rows and allLeaves states
        const updatedRows = rows.filter((row) => row.id !== id);
        const updatedAllLeaves = allLeaves.filter((leave) => leave.id !== id);
        setRows(updatedRows);
        setAllLeaves(updatedAllLeaves);

        setDeleteConfirmationOpen(false);
      })
      .catch((err) => {
        console.error(err);
        setDeleteConfirmationOpen(false); // Close the confirmation dialog even if an error occurs
      });
  };

  // const getLeaves = () => {
  //   const year = parseInt(changeYear, 10); // Convert to number
  //   console.log("changeYear:", year);

  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}leaves/fetchLeaves/${year}`)
  //     .then((response) => {
  //       // const newLeaveData = response.data;
  //       // const currentYearLeaves = newLeaveData.years.find((yearData) => yearData.year === year);
  //       setUsers(response.data);
  //       // console.log("currentYearLeaves:", currentYearLeaves);
  //       console.log("leave data:", users);
  //       console.log("leave data>>>>>>>>:", response.data);
  //       setEmployees(response.data);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // useEffect(() => {
  //   if (changeYear) {
  //     getLeaves();
  //   }
  // }, [changeYear]);

  //   useEffect(() => {
  //     axios
  //       .get(process.env.REACT_APP_API_URL + "leaves" + changeYear)
  //       .then((response) => {
  // // Set users to the array of users
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching user emails:", error);
  //       });
  //   }, []);

  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    color: "black",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    width: "50%",
    height: "auto",
    wordWrap: "break-word",
    borderRadius: "20px",
  };

  // const rows = users.flatMap((user) =>
  //   user.years.flatMap((year) =>
  //     year.leaves.map((leave) => ({
  //       id: leave._id,
  //       srno: year.leaves.indexOf(leave) + 1,
  //       employeeId: user.employeeId,
  //       name: user.name || "",
  //       leaveType: leave.leaveType,
  //       startDate: leave.startDate,
  //       endDate: leave.endDate,
  //       totalDays: leave.totalDays,
  //       description: leave.description,
  //       createdFrom: leave.createdFrom,
  //       history: leave.history, // Ensure history is included here
  //       status: leave.status,
  //       year: year.year, // Add the year information
  //     }))
  //   )
  // );

  // // const tableHeight = rows.length > 10 ? 53.5 * 10 : 64 * rows.length + 100;
  // rows.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

  // rows.forEach((row, index) => {
  //   row.srno = index + 1;
  // });

  const handleRowCheckboxChange = (rowId) => {
    console.log("row");
    const selectedIndex = selectedRowIds.indexOf(rowId);
    let newSelectedRowIds = [];

    if (selectedIndex === -1) {
      newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds, rowId);

      // Get the employee ID from the selected row
      const selectedRow = rows.find((row) => row.id === rowId);
      setSelectEmployeeIdFromRow(selectedRow.employeeId);
    } else {
      newSelectedRowIds = selectedRowIds.filter((id) => id !== rowId);
    }

    setSelectedRowIds(newSelectedRowIds);
  };

  const handleFilterClick = () => {
    let filteredLeaves = [...allLeaves]; // Create a copy of allLeaves

    // Apply year filter
    if (changeYear) {
      filteredLeaves = filteredLeaves.filter(
        (leave) => leave.year === changeYear
      );
    }

    // Apply status filter
    if (selectedStatus) {
      filteredLeaves = filteredLeaves.filter(
        (leave) => leave.status === selectedStatus
      );
    }

    // Apply name filter
    if (selectedName) {
      filteredLeaves = filteredLeaves.filter(
        (leave) => leave.name === selectedName
      );
    }

    // Apply leave type filter
    if (selectedLeaveTypes) {
      filteredLeaves = filteredLeaves.filter(
        (leave) => leave.leaveType === selectedLeaveTypes
      );
    }

    setLeaves(filteredLeaves);
  };

  // Add an effect to reset filters when needed
  useEffect(() => {
    if (
      !selectedStatus &&
      !selectedName &&
      !selectedLeaveTypes &&
      !changeYear
    ) {
      setLeaves(allLeaves);
    }
  }, [selectedStatus, selectedName, selectedLeaveTypes, changeYear, allLeaves]);

  const approveRequest = () => {
    const updatedLeaveRequests = leaves.map((leave) => {
      if (selectedRowIds.includes(leave.id)) {
        return { ...leave, status: "Approved" }; // Update the status to "Approved"
      }
      return leave;
    });

    setLeaves(updatedLeaveRequests);
  };

  const rejectRequest = () => {
    const updatedLeaveRequests = leaves.map((leave) => {
      if (selectedRowIds.includes(leave.id)) {
        return { ...leave, status: "Rejected" }; // Update the status to "Rejected"
      }
      return leave;
    });

    setLeaves(updatedLeaveRequests);
  };

  const handleApprove = async () => {
    try {
      if (selectedRowIds.length > 0) {
        axios.put(process.env.REACT_APP_API_URL + "leaves/approveLeaves", {
          employeeId: selectEmployeeIdFromRow,
          leavesIds: selectedRowIds,
          status: "Approved",
        });
        approveRequest();
        console.log("Employee Id: " + selectEmployeeIdFromRow);
        // Call any other necessary functions or actions here
      } else {
        console.error("No rows selected");
        toast.error("Please select leaves to approve!");
      }
    } catch (error) {
      console.error("Error updating leave status:", error);
      toast.error("An error occurred while approving leaves!");
      // Handle error
    }
    setSelectedRowIds([]);
  };

  const handleReject = async () => {
    try {
      if (selectedRowIds.length > 0) {
        axios.put(process.env.REACT_APP_API_URL + "leaves/approveLeaves", {
          employeeId: selectEmployeeIdFromRow,
          leavesIds: selectedRowIds,
          status: "Rejected",
        });
        rejectRequest();
        console.log("employeeId: " + selectEmployeeIdFromRow);
        // Call any other necessary functions or actions here
      } else {
        console.error("No rows selected");
        toast.error("Please select leaves to reject!");
      }
    } catch (error) {
      console.error("Error updating leave status:", error);
      toast.error("An error occurred while rejecting leaves!");
      // Handle error
    }
    setSelectedRowIds([]);
  };

  // const handleEmailChange = (event) => {
  //   const selectedEmail = event.target.value;
  //   setSelectedEmail(selectedEmail);

  //   // Find the corresponding employee object based on the selected email
  //   const selectedEmployee = employees.find(
  //     (employee) => employee.employeeId === selectedEmail
  //   );

  //   // Update the selected employee ID
  //   if (selectedEmployee) {
  //     setSelectedEmployeeId(selectedEmployee.employeeId);
  //     setSelectedName(selectedEmployee.name);
  //     setSelectedPrivilegeLeave(selectedEmployee.privilegeLeaves);
  //     setSelectedSickLeave(selectedEmployee.sickLeaves);
  //   }
  // };

  const handleEmailChange = (event) => {
    const selectedEmail = event.target.value;
    setSelectedEmail(selectedEmail);

    // Find the corresponding employee object based on the selected email
    const selectedEmployee = employees.find(
      (employee) => employee.employeeId === selectedEmail
    );

    // Update the selected employee ID and fetch leave data
    if (selectedEmployee) {
      setSelectedEmployeeId(selectedEmployee.employeeId);
      setSelectedName(selectedEmployee.name);
      setSelectedPrivilegeLeave(selectedEmployee.privilegeLeaves);
      setSelectedSickLeave(selectedEmployee.sickLeaves);

      // Fetch leave data for the selected employee
      fetchLeaveData(selectedEmployee.employeeId);
    }
  };

  const fetchLeaveData = async (employeeId) => {
    try {
      const leaveResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}leaves/getLeaves/${employeeId}`
      );
      const leaveData = leaveResponse.data.leaveData;

      // Filter leave data for the current year
      const currentYearLeaves = leaveData.years.find(
        (yearData) => yearData.year === changeYearNew
      );

      if (currentYearLeaves) {
        setSelectedSickLeave(currentYearLeaves.sickLeaves);
        setSelectedPrivilegeLeave(currentYearLeaves.privilegeLeaves);
        setRows(renderRows(currentYearLeaves));
      } else {
        // Handle case when no leaves are found for the current year
        setSelectedSickLeave(0);
        setSelectedPrivilegeLeave(0);
        setRows([]);
      }
    } catch (error) {
      console.error("Error fetching leave data:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  // useEffect(() => {
  //   // You can remove the `approveRequest` and `rejectRequest` calls from here
  //     fetchLeaveData();
  // }, [selectedEmail]);

  const columns = [
    {
      field: "checkbox",
      headerName: "",
      width: 40,
      renderCell: (params) => (
        <Checkbox
          checked={
            selectedRowIds.includes(params.row.id) ||
            params.row.status === "Approved" ||
            params.row.status === "Rejected" ||
            params.row.status === "Canceled"
          }
          onChange={() => handleRowCheckboxChange(params.row.id)}
          disabled={
            params.row.status === "Approved" ||
            params.row.status === "Rejected" ||
            params.row.status === "Canceled"
          }
        />
      ),
    },
    {
      field: "employeeId",
      headerName: "Emp Id",
      width: 80,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          EmpId
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: "Emp Name",
      flex: 1.2,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Emp Name
        </Typography>
      ),
    },
    {
      field: "leaveType",
      headerName: "Leave Type",
      flex: 1.2,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Leave Type
        </Typography>
      ),
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Start Date
        </Typography>
      ),
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          End Date
        </Typography>
      ),
    },
    {
      field: "totalDays",
      headerName: "Total Days",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Total Days
        </Typography>
      ),
    },
    {
      field: "message",
      headerName: "Message",
      width: 70,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Message
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => handleOpen(params.row.description)}
            style={{ color: "#04497a" }}
          >
            <RemoveRedEyeIcon style={{ fontSize: "25px" }} />
          </IconButton>
        </div>
      ),
    },
    // {
    //   field: "createdFrom",
    //   headerName: "Created From",
    //   flex: 1.2,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Created From
    //     </Typography>
    //   ),
    // },
    {
      field: "history",
      headerName: "History",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          History
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() =>
              handleHistoryOpen(params.row.employeeId, params.row.id)
            }
            style={{ color: "#04497a" }}
          >
            <RemoveRedEyeIcon style={{ fontSize: "25px" }} />
          </IconButton>
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      align: "center",
      renderHeader: () => (
        <Typography
          fontSize="14px"
          fontWeight="bold"
          sx={{ marginLeft: "15px" }}
        >
          Action
        </Typography>
      ),
      renderCell: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* <Link to={`/updateleaverequest/${params.id}`}>
            <EditIcon
              style={{
                marginLeft: "5px",
                marginRight: "10px",
                color: "#04497a",
                fontSize: "25px",
              }}
            />
          </Link> */}
          <DeleteIcon
            style={{
              cursor: "pointer",
              color: "#04497a",
              fontSize: "25px",
            }}
            onClick={() => handleDeleteConfirmation(params.id)}
          />
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderHeader: () => (
        <Typography
          fontSize="14px"
          fontWeight="bold"
          sx={{ marginLeft: "15px" }}
        >
          Status
        </Typography>
      ),
      renderCell: (params) => (
        <div
          style={{
            color:
              params.value === "Approved"
                ? "#4caf50"
                : params.value === "Pending"
                ? "#ff9800"
                : params.value === "Rejected" // Add styling for "Rejected" status
                ? "#f44336" // Red color
                : params.value === "Canceled" // Add styling for "Canceled" status
                ? "#8b0000" // Dark red color
                : "inherit",
            background:
              params.value === "Approved"
                ? "#c8e6c9"
                : params.value === "Pending"
                ? "#ffe0b2"
                : params.value === "Rejected" // Add background for "Rejected" status
                ? "#ffcdd2" // Light red background
                : params.value === "Canceled" // Add background for "Canceled" status
                ? "#ffb2b2" // Light red background
                : "inherit",
            borderRadius: "4px",
            padding: "8px",
            display: "inline-block",
            width: "85px",
            textAlign: "center",
          }}
        >
          {params.value}
        </div>
      ),
    },
  ];

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const handleLeaveLimit = async (e) => {
    e.preventDefault();
    try {
      // Make a POST request to your backend API to update leave limits
      await axios.post(
        process.env.REACT_APP_API_URL + "leaves/updateLeaveLimit",
        {
          employeeId: selectedEmployeeId,
          name: selectedName,
          sickLeaves: sickLeave,
          privilegeLeaves: privilegeLeave,
          year: changeYear,
        }
      );

      // Show success message
      toast.success("Leave limits updated successfully!");
      // Close the modal
      setUpdateLeaveLimitsConfirmationOpen(false);
      setLeaveModalOpen(false);
    } catch (error) {
      console.error("Error updating leave limits:", error);
      toast.error("An error occurred while updating leave limits!");
    }
  };

  // Function to handle opening the confirmation dialog
  const handleOpenConfirmation = (message) => {
    setConfirmationMessage(message);
    setConfirmationOpen(true);
  };

  // Function to handle closing the confirmation dialog
  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  // Function to handle approval action
  const handleApproveAction = () => {
    handleApprove(selectedRows);
    setSelectedRowIds([]); // Reset selected row IDs after approval
    handleCloseConfirmation(); // Close the confirmation dialog
  };

  // Function to handle rejection action
  const handleRejectAction = () => {
    handleReject(selectedRows);
    setSelectedRowIds([]); // Reset selected row IDs after rejection
    handleCloseConfirmation(); // Close the confirmation dialog
  };

  // Update leave limits dialog box
  const handleOpenUpdateLeaveLimitsConfirmation = () => {
    setUpdateLeaveLimitsConfirmationOpen(true);
  };

  const handleCloseUpdateLeaveLimitsConfirmation = () => {
    setUpdateLeaveLimitsConfirmationOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const years = Array.from({ length: 100 }, (_, index) => 2000 + index);

  const handleYearChange = (e) => {
    const selectedYear = e.target.value;
    setChangeYear(selectedYear);
    setChangeYearNew(selectedYear);
    fetchYearlyData();
    // Store the selected year in localStorage
    localStorage.setItem("selectedYear", selectedYear);
  };

  const styles = tabStyles(theme, selectedTab);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "crimson",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Message
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, postion: "absolute" }}
          >
            {description}
          </Typography>
        </Box>
      </Modal>

      {/* History Modal */}
      <Modal
        open={historyOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "crimson",
            }}
          >
            <CloseIcon />
          </IconButton>
          <div>
            <Typography variant="h6" component="h2">
              History
            </Typography>
            {fetchedHistory.map((historyItem, index) => (
              <div key={index}>
                <Typography>Created On: {historyItem.createdOn}</Typography>
                <Typography>Updated On: {historyItem.updatedOn}</Typography>
                <Typography>Updated By: {historyItem.updatedBy}</Typography>
              </div>
            ))}
          </div>
        </Box>
      </Modal>

      {/* Modal for Update Leave Limit */}
      <Modal
        open={leaveModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "crimson",
            }}
          >
            <CloseIcon />
          </IconButton>
          <FlexBetween>
            <ThemeProvider theme={lightTheme}>
              <FormControl sx={{ minWidth: "120px" }}>
                <InputLabel id="demo-simple-select-label">Name</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedEmail}
                  label="Email"
                  onChange={handleEmailChange} // Update this line
                  sx={{ minWidth: "120px" }}
                >
                  {employees.map((employee) => (
                    <MenuItem key={employee._id} value={employee.employeeId}>
                      {employee.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120, marginLeft: "20px" }}
              >
                <InputLabel id="year-select-label">Year</InputLabel>
                <Select
                  labelId="year-select-label"
                  id="year-select"
                  label="Year"
                  value={changeYear}
                  onChange={handleYearChange}
                  MenuProps={{
                    style: {
                      maxHeight: 600, // Set a maximum height for the dropdown
                    },
                  }}
                >
                  <MenuItem value="">Choose Year</MenuItem>
                  {years.map((year) => (
                    <MenuItem sx={{ fontSize: "18px" }} key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ThemeProvider>
            <div>
              <h3>Employee Id:{selectedEmployeeId}</h3>
              <h3>Privilege Leaves:{selectedPrivilegeLeave}</h3>
              <h3>Sick Leaves:{selectedSickLeave}</h3>
            </div>
          </FlexBetween>
          <form>
            <div className="formbold-input-flex">
              <div>
                <label htmlFor="email" className="formbold-form-label">
                  {" "}
                  Total Sick Leave{" "}
                </label>
                <input
                  type="number"
                  name="sickLeave"
                  id="sickLeave"
                  className="formbold-form-input"
                  onChange={(e) => setSickLeave(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="phone" className="formbold-form-label">
                  {" "}
                  Total Privilege Leave{" "}
                </label>
                <input
                  type="number"
                  name="privLeave"
                  id="privLeave"
                  className="formbold-form-input"
                  onChange={(e) => setPrivilegeLeave(e.target.value)}
                />
              </div>
            </div>
            <button
              type="button"
              onClick={handleOpenUpdateLeaveLimitsConfirmation}
              className="formbold-btn"
            >
              Update Leave Limit
            </button>
          </form>
        </Box>
      </Modal>

      <Box m="1.5rem 2.5rem" height="85%">
        <Box>
          <ThemeProvider theme={lightTheme}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="fullWidth"
              sx={{
                marginBottom: "20px",
                height: "60px",
                color: "white",
                borderBottom: "1px solid #e5e5e5",
              }}
            >
              <Tab
                label=" Manage Employee Leaves"
                icon={
                  <ManageAccountsOutlinedIcon sx={{ marginRight: "4px" }} />
                }
                sx={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  border: "1px solid #e5e5e5",
                  fontWeight: "bold",
                  borderRadius: "15px",
                  backgroundColor: (index) =>
                    selectedTab === index ? "#04497a" : "initial",
                  color: (index) =>
                    selectedTab === index ? "white" : "#04497a",
                  "&.Mui-selected": {
                    backgroundColor: "#04497a",
                    color: "white",
                  },
                }}
              />
              <Tab
                label=" Manage Personal Leaves"
                icon={<GroupsOutlinedIcon sx={{ marginRight: "4px" }} />}
                sx={styles}
              />
              {/* Add more tabs as needed */}
            </Tabs>
          </ThemeProvider>
        </Box>

        {selectedTab === 0 && (
          <Box>
            <FlexBetween sx={{ marginBottom: "10px" }}>
              <Header title={"Manage Leaves"} />
              <FlexBetween>
                <Box>
                  <Button
                    variant="contained"
                    onClick={() => handleLeaveModalOpen()}
                    sx={{
                      backgroundColor: theme.palette.secondary.light,
                      color: theme.palette.background.alt,
                      fontSize: "14px",
                      fontWeight: "bolder",
                      padding: "10px 20px",
                    }}
                  >
                    Update Leave Limits
                  </Button>
                </Box>
              </FlexBetween>
            </FlexBetween>
            <ThemeProvider theme={lightTheme}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "10px", // Add consistent gap between filter elements
                  mb: 2, // Add margin bottom for spacing from the grid
                }}
              >
                <FormControl sx={{ minWidth: "120px", maxWidth: "120px" }}>
                  <InputLabel id="demo-simple-select-label">Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedName}
                    label="Name"
                    onChange={(e) => setSelectedName(e.target.value)}
                    sx={{ width: "120px" }}
                  >
                    <MenuItem value="">Show All</MenuItem>
                    {employees.map((employee) => (
                      <MenuItem key={employee._id} value={employee.name}>
                        {employee.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ minWidth: "120px", maxWidth: "120px" }}>
                  <InputLabel id="status-select-label">Status</InputLabel>
                  <Select
                    labelId="status-select-label"
                    id="status-select"
                    label="Status"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    sx={{ width: "120px" }}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="Approved">Approved</MenuItem>
                    <MenuItem value="Rejected">Rejected</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                  </Select>
                </FormControl>

                <FormControl sx={{ minWidth: "120px", maxWidth: "120px" }}>
                  <InputLabel id="leave-type-select-label">Type</InputLabel>
                  <Select
                    labelId="leave-type-select-label"
                    id="leave-type-select"
                    label="Type"
                    value={selectedLeaveTypes}
                    onChange={handleLeaveTypeChange}
                    sx={{ width: "120px" }}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="Sick Leave">Sick</MenuItem>
                    <MenuItem value="Privilege Leave">Privilege</MenuItem>
                  </Select>
                </FormControl>

                <FormControl sx={{ minWidth: "120px", maxWidth: "120px" }}>
                  <InputLabel id="year-select-label">Year</InputLabel>
                  <Select
                    labelId="year-select-label"
                    id="year-select"
                    label="Year"
                    value={changeYear}
                    onChange={handleYearChange}
                    sx={{ width: "120px" }}
                    MenuProps={{
                      style: {
                        maxHeight: 600,
                      },
                    }}
                  >
                    <MenuItem value="">Choose Year</MenuItem>
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Button
                  variant="contained"
                  onClick={handleFilterClick}
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontSize: "14px",
                    fontWeight: "bolder",
                    padding: "10px 20px",
                    height: "40px",
                    alignSelf: "flex-end", // Align with the select inputs
                    mb: "3px", // Slight adjustment to align perfectly with selects
                  }}
                >
                  Filter
                </Button>
                <FlexBetween
                  sx={{
                    justifyContent: "flex-end",
                    width: "100%",
                    marginLeft: "12px",
                  }}
                >
                  {/* {selectedRowIds.length > 0 && ( */}
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: theme.palette.secondary.light,
                      color: theme.palette.background.alt,
                      fontSize: "14px",
                      fontWeight: "bolder",
                      padding: "10px 10px",
                      marginRight: "10px",
                      width: "92px",
                    }}
                    onClick={() =>
                      handleOpenConfirmation(
                        "Are you sure you want to approve the selected leaves?"
                      )
                    }
                    disabled={selectedRowIds.length === 0}
                  >
                    Approve
                  </Button>
                  {/* )} */}
                  {/* {selectedRowIds.length > 0 && ( */}
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: theme.palette.secondary.light,
                      color: theme.palette.background.alt,
                      fontSize: "14px",
                      fontWeight: "bolder",
                      padding: "10px 10px",
                      width: "92px",
                    }}
                    onClick={() =>
                      handleOpenConfirmation(
                        "Are you sure you want to reject the selected leaves?"
                      )
                    }
                    disabled={selectedRowIds.length === 0}
                  >
                    Reject
                  </Button>
                  {/* )} */}
                </FlexBetween>
              </Box>
            </ThemeProvider>
            <ThemeProvider theme={lightTheme}>
              <DataGrid
                rows={leaves}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 20]}
                pageSizeOptions={[5, 10]}
                sx={dashboardStyle(theme, "400px")}
              />
            </ThemeProvider>
            <ConfirmationDialog
              open={confirmationOpen} // Pass state to control dialog visibility
              handleClose={handleCloseConfirmation} // Function to close the dialog
              handleConfirm={
                confirmationMessage.includes("approve")
                  ? handleApproveAction
                  : handleRejectAction
              } // Conditional action based on message
              message={confirmationMessage} // Confirmation message
            />
            {/* Update Leave Limits Confirmation Dialog */}
            <ConfirmationDialog
              open={updateLeaveLimitsConfirmationOpen}
              handleClose={handleCloseUpdateLeaveLimitsConfirmation}
              handleConfirm={handleLeaveLimit}
              message="Are you sure you want to update leave limits?"
            />
            <ConfirmationDialog
              open={deleteConfirmationOpen}
              handleClose={() => setDeleteConfirmationOpen(false)}
              handleConfirm={handleConfirmDelete} // Pass handleDelete as the confirm action
              message="Are you sure you want to delete this leave record?" // Customize the message
            />
            <ToastContainer />
          </Box>
        )}

        {selectedTab === 1 && (
          <div>
            {/* Content for Tab 2 */}
            <EmployeeLeaves />
          </div>
        )}
      </Box>
    </>
  );
}

export default ManageLeaves;
