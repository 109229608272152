//EMployee - IND
import React, { useEffect, useState } from "react";
import axios from "axios";

import FlexBetween from "shared/FlexBetween";
import {
  Box,
  useTheme,
  Button,
  Modal,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
} from "@mui/material";
import Header from "shared/Header";
import { DataGrid } from "@mui/x-data-grid";
import { indigo } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import { dashboardStyle } from "shared/utils";
import Spinner from "shared/spinner/Spinner";
import { useNavigate } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Document, Packer, Paragraph } from "docx";
import tutorialFile from "../../../assets/tutorial.pdf";

function TimesheetsMonthsTable() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [user, setUser] = useState({});
  const [employeeId, setEmployeeId] = useState("");
  const [timesheets, setTimesheets] = useState([]);
  const [timesheetName, setTimesheetName] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("all"); // Change default to "all"
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [openUpload, setOpenUpload] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [uploadConfirmationOpen, setUploadConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [newMonth, setNewMonth] = useState("");
  const [deleteMonth, setDeleteMonth] = useState("");
  const [selectPeriod, setSelectPeriod] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterPeriod, setFilterPeriod] = useState("all");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //Tutorial
  const [showTutorial, setShowTutorial] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [tutorialContent, setTutorialContent] = useState("");

  // Check localStorage and determine if tutorial should be shown
  useEffect(() => {
    const tutorialSeen = localStorage.getItem("timesheetTutorialSeen");
    if (!tutorialSeen) {
      setShowTutorial(true);
      fetchTutorialContent();
    }
  }, []);

  // Function to fetch and parse the DOCX file
  const fetchTutorialContent = async () => {
    try {
      const response = await axios.get(tutorialFile, {
        responseType: "arraybuffer",
      });

      // Parse the DOCX file
      const arrayBuffer = response.data;
      const doc = new Document(arrayBuffer);

      // Extract text content (simplified approach)
      let content = "";
      doc.sections.forEach((section) => {
        section.children.forEach((paragraph) => {
          if (paragraph instanceof Paragraph) {
            paragraph.children.forEach((run) => {
              content += run.text + " ";
            });
            content += "\n\n";
          }
        });
      });

      setTutorialContent(content);
    } catch (error) {
      console.error("Error loading tutorial:", error);
      setTutorialContent(
        "Unable to load tutorial content. Please try again later."
      );
    }
  };

  // Function to handle closing the tutorial
  const handleCloseTutorial = () => {
    if (dontShowAgain) {
      localStorage.setItem("timesheetTutorialSeen", "true");
    }
    setShowTutorial(false);
  };

  const years = Array.from(
    { length: 10 },
    (_, index) => new Date().getFullYear() - 2 + index
  );

  const monthOptions = [
    { value: "all", label: "Show All" },
    ...months.map((month, index) => ({
      value: (index + 1).toString(), // Change to 1-based indexing
      label: month,
    })),
  ];

  const handleDeleteConfirmation = (id, month) => {
    setDeleteId(id);
    setDeleteMonth(month);
    setConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    handleDelete(deleteId, deleteMonth);
    setConfirmationOpen(false);
  };

  const handleDeleteCancel = () => {
    setConfirmationOpen(false);
  };

  const handleUploadConfirmationOpen = () => {
    setUploadConfirmationOpen(true);
  };

  const handleUploadCancel = () => {
    setUploadConfirmationOpen(false);
  };

  const handleOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleCloseUpload = () => setOpenUpload(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      })
      .then((response) => {
        setUser(response.data);
        // setName(response.data.name);
        setEmployeeId(response.data.employeeId);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        if (error.response && error.response.status === 401) {
          toast.warning(
            "Your account has been disabled. Contact HR for assistance.",
            {
              onClose: () => {
                document.cookie =
                  "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              },
            }
          );
        }
      });
  };

  const fetchTimesheets = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}attendance/fetchTimesheetsDataIND`,
        {
          params: {
            employeeId,
            month: filterMonth,
            year: selectedYear,
          },
          withCredentials: true,
        }
      );

      setTimesheets(response.data);
    } catch (error) {
      console.error("Error fetching timesheets:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (employeeId) {
      fetchTimesheets();
    }
  }, [filterMonth, selectedYear, employeeId]);

  const getMonthFromPeriod = (periodValue) => {
    const period = fortNightlyPeriods.find((p) => p.value === periodValue);
    return period ? parseInt(period.startMonth) - 1 : 0; // Convert to 0-based month
  };

  // Helper function to get month name from period
  // Update the handleCreate function
  const handleCreate = async () => {
    try {
      setIsLoading(true);

      const monthName = months[selectedMonth - 1]; // Adjust index for display
      const timesheetName = `${user.name}_${monthName}_${selectedYear}`;

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}attendance/createTimesheetIND`,
        {
          employeeId,
          timesheetName,
          selectedMonth, // This is already 1-based from the Select component
          selectedYear,
        },
        { withCredentials: true }
      );

      if (response.status === 201) {
        toast.success("Timesheet created successfully");
        fetchTimesheets();
        setUploadConfirmationOpen(false);
        setOpenUpload(false);
      }
    } catch (error) {
      console.error("Error creating timesheet:", error);
      toast.error(error.response?.data?.message || "Error creating timesheet");
      setUploadConfirmationOpen(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getMonthShortName = (monthNumber) => {
    const date = new Date(2000, monthNumber - 1, 1);
    return date.toLocaleString("default", { month: "short" });
  };

  // Add a function to get periods for selected month
  const getPeriodsForMonth = (monthIndex) => {
    return fortNightlyPeriods.filter(
      (period) => parseInt(period.startMonth) === monthIndex + 1
    );
  };

  // useEffect(() => {
  //   handleCreate();
  // }, [selectPeriod]);

  const handleDelete = async (timesheetId, month) => {
    try {
      setIsLoading(true);
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}attendance/deletetimesheetIND`,
        {
          params: {
            employeeId: employeeId,
            timesheetId,
            month: month,
            year: selectedYear,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        toast.success("Timesheet deleted successfully");
        fetchTimesheets();
      }
    } catch (error) {
      console.error("Error deleting timesheet:", error);
      toast.error("Error deleting timesheet");
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to get month name
  const getMonthName = (monthNumber) => {
    const date = new Date(2000, monthNumber - 1, 1);
    return date.toLocaleString("default", { month: "short" });
  };

  const rows = timesheets
    .map((timesheet, index) => ({
      id: timesheet._id || `temp-id-${index}`,
      timesheetId: timesheet._id,
      month: timesheet.month, // This is now 1-based from the database
      daysWorked: timesheet.daysWorked || 0,
      leavesTaken: timesheet.leavesTaken || 0,
      totalHours: timesheet.totalHours || 0,
      status: timesheet.status || "Pending",
      modifiedAt: timesheet.modifiedAt,
    }))
    .sort((a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt))
    .map((row, index) => ({
      ...row,
      index: index + 1,
    }));

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 40,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          #
        </Typography>
      ),
    },
    {
      field: "month",
      headerName: "Month",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Month
        </Typography>
      ),
      renderCell: (params) => (
        <Typography>{months[params.value - 1]}</Typography>
      ), // Adjust for 1-based indexing
    },
    // {
    //   field: "timesheetName",
    //   headerName: "Name",
    //   flex: 1.5,
    //   // headerAlign: 'center',
    //   // align: 'center',
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Name
    //     </Typography>
    //   ),
    // },
    {
      field: "daysWorked",
      headerName: "Days Worked",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Days Worked
        </Typography>
      ),
      renderCell: (params) => <Typography>{params.value || 0}</Typography>,
    },
    // {
    //   field: "leavesTaken",
    //   headerName: "Leaves Taken",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Leaves Taken
    //     </Typography>
    //   ),
    //   renderCell: (params) => <Typography>{params.value || 0}</Typography>,
    // },
    {
      field: "totalHours",
      headerName: "Total Hours",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Total Hours
        </Typography>
      ),
      renderCell: (params) => <Typography>{params.value || 0}</Typography>,
    },
    {
      field: "modifiedAt",
      headerName: "Modified",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Modified
        </Typography>
      ),
      renderCell: (params) => (
        <Typography fontSize={"12px"}>
          {new Date(params.value).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          })}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Status
        </Typography>
      ),
      renderCell: (params) => (
        <div
          style={{
            color:
              params.value === "Approved"
                ? "#388e3c"
                : params.value === "Pending"
                ? "#f57c00"
                : params.value === "Rejected"
                ? "#f44336"
                : params.value === "Submitted"
                ? "#1976d2"
                : params.value === "No Data"
                ? "#616161"
                : "inherit",
            background:
              params.value === "Approved"
                ? "#c8e6c9"
                : params.value === "Pending"
                ? "#ffe0b2"
                : params.value === "Rejected"
                ? "#ffcdd2"
                : params.value === "Submitted"
                ? "#cce5ff"
                : params.value === "No Data"
                ? "#f5f5f5"
                : "inherit",
            borderRadius: "4px",
            padding: "8px",
            display: "inline-block",
            width: "90px",
            textAlign: "center",
            margin: "0 auto", // Add this to center the status box
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Action
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <IconButton
            style={{ cursor: "pointer", color: "#04497a", fontSize: "25px" }}
            onClick={() =>
              navigate(
                `/ind/timesheets/edit/${selectedYear}/${params.row.month}/${params.row.timesheetId}`
              )
            }
          >
            <EditIcon />
          </IconButton>
          <IconButton
            style={{ cursor: "pointer", color: "#04497a", fontSize: "25px" }}
            onClick={() =>
              handleDeleteConfirmation(params.row.id, params.row.month)
            }
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  return (
    <div>
      {/* Tutorial modal */}
{/* Tutorial modal */}
<Modal
  open={showTutorial}
  onClose={() => setShowTutorial(false)}
  aria-labelledby="tutorial-modal-title"
>
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "#ffffff",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
      padding: "24px",
      width: "80%",
      maxWidth: "900px",
      height: "90vh",
      outline: "none",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Box sx={{ position: "relative", mb: 2 }}>
      <Typography
        id="tutorial-modal-title"
        variant="h6"
        sx={{
          fontWeight: 600,
          color: "#04497a",
        }}
      >
        Timesheet Tutorial
      </Typography>
      <IconButton
        onClick={() => setShowTutorial(false)}
        sx={{
          position: "absolute",
          right: -12,
          top: -12,
          color: "#666",
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>

    {/* PDF Viewer using iframe - replaced DOCX viewer */}
    <Box sx={{ flex: 1, mb: 2 }}>
      <iframe
        src={tutorialFile}
        width="100%"
        height="100%"
        title="PDF viewer"
        style={{
          border: "none",
          borderRadius: "4px",
        }}
      />
    </Box>

    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mt: 2, // Added margin top for better spacing
      }}
    >
<FormControlLabel
  control={
    <Checkbox
      checked={dontShowAgain}
      onChange={(e) => setDontShowAgain(e.target.checked)}
      sx={{
        color: "#04497a", // Keep the same color when unchecked
        '&.Mui-checked': {
          color: "#04497a", // Keep the same color when checked
        },
        visibility: 'visible', // Explicitly set visibility
        display: 'block', // Ensure display is not changed
        opacity: 1, // Ensure opacity is full
      }}
    />
  }
  label={
    <Typography 
      sx={{ 
        color: "#333", 
        fontWeight: 500,
        visibility: 'visible', // Explicitly set visibility
        display: 'block', // Ensure display is not changed
        opacity: 1, // Ensure opacity is full
      }}
    >
      Don't show this tutorial again
    </Typography>
  }
  sx={{ 
    visibility: 'visible', 
    display: 'flex',
    opacity: 1,
    marginRight: 2, // Add some margin for better spacing
  }}
/>
      <Button
        variant="contained"
        onClick={handleCloseTutorial}
        sx={{
          backgroundColor: theme.palette.secondary.light,
          color: "#fff",
          fontWeight: 600,
          padding: "8px 16px",
        }}
      >
        CONTINUE
      </Button>
    </Box>
  </Box>
</Modal>

      {/* Create timesheet modal */}
      <Modal
        open={openUpload}
        onClose={handleCloseUpload}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ThemeProvider theme={lightTheme}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#ffffff", // Changed to white for better contrast
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Softer shadow
              padding: "24px",
              width: "400px", // Fixed width for better proportions
              outline: "none",
              borderRadius: "8px",
            }}
          >
            {/* Header Section */}
            <Box sx={{ position: "relative", mb: 3 }}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                sx={{
                  fontWeight: 600,
                  color: "#04497a",
                }}
              >
                Create Timesheet
              </Typography>
              <IconButton
                onClick={handleCloseUpload}
                sx={{
                  position: "absolute",
                  right: -12,
                  top: -12,
                  color: "#666",
                  "&:hover": {
                    color: "crimson",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Form Section */}
            <form>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  mb: 3,
                }}
              >
                <FormControl fullWidth>
                  <InputLabel>Year</InputLabel>
                  <Select
                    value={selectedYear}
                    label="Year"
                    onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>Month</InputLabel>
                  <Select
                    value={selectedMonth}
                    label="Month"
                    onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                  >
                    {months.map((month, index) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* Action Button */}
              <Button
                variant="contained"
                onClick={handleUploadConfirmationOpen}
                fullWidth
                sx={{
                  backgroundColor: theme.palette.secondary.light,
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "12px",
                }}
              >
                Create
              </Button>
            </form>
          </Box>
        </ThemeProvider>
      </Modal>

      {isLoading && <Spinner />}

      <Box m="1.5rem 2.5rem" height="calc(100vh - 275px)">
        <FlexBetween>
          <Header title="Timesheets" />
          <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <ThemeProvider theme={lightTheme}>
              <FormControl sx={{ minWidth: "120px" }}>
                {" "}
                {/* Increased width and added right margin */}
                <InputLabel>Filter Month</InputLabel>
                <Select
                  fullWidth
                  value={filterMonth}
                  label="Filter Month"
                  onChange={(e) => setFilterMonth(e.target.value)}
                  sx={{ height: "46px", mb: 1, maxWidth: "120px" }}
                >
                  <MenuItem value="all">All Months</MenuItem>
                  {months.map((month, index) => (
                    <MenuItem key={index + 1} value={(index + 1).toString()}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: "100px" }}>
                {" "}
                {/* Added right margin */}
                <InputLabel>Year</InputLabel>
                <Select
                  fullWidth
                  value={selectedYear}
                  label="Year"
                  onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                  sx={{ height: "46px", mb: 1 }}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                fullWidth
                onClick={() => setOpenUpload(true)}
                sx={{
                  backgroundColor: theme.palette.secondary.light,
                  color: theme.palette.background.alt,
                  fontSize: "14px",
                  fontWeight: "bolder",
                  padding: "10px 20px",
                  mb: 1,
                }}
              >
                Create
              </Button>
            </ThemeProvider>
          </Box>
        </FlexBetween>
        <ThemeProvider theme={lightTheme}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            pageSizeOptions={[5, 10]}
            getRowId={(row) => row._id || `temp-id-${row.index}`}
            sx={dashboardStyle(theme, "480px")}
          />
        </ThemeProvider>
      </Box>
      <ToastContainer />
      <ConfirmationDialog
        open={confirmationOpen}
        handleClose={handleDeleteCancel}
        handleConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this timesheet?"
      />
      <ConfirmationDialog
        open={uploadConfirmationOpen}
        handleClose={handleUploadCancel}
        handleConfirm={handleCreate}
        message="Are you sure you want to create this timesheet?"
      />
    </div>
  );
}

export default TimesheetsMonthsTable;
