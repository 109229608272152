import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import FlexBetween from "shared/FlexBetween";
import { Box, useTheme, Button, Modal, Typography } from "@mui/material";
import Header from "shared/Header";
import { DataGrid } from "@mui/x-data-grid";
import { indigo } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import { getCalendarPdfUrl } from 'shared/utils';
import ShareIcon from '@mui/icons-material/Share';
import { useNavigate } from "react-router-dom";
import { dashboardStyle } from "shared/utils";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import Spinner from "shared/spinner/Spinner";
import useAWSDetails from "shared/useAWSDetails";


function EmployeeOpeningsPage() {

	const navigate = useNavigate();
	const theme = useTheme();
	const [user, setUser] = useState({});
	const [users, setUsers] = useState([]);
	const [pdfUrl, setPdfUrl] = useState(null);
	const [open, setOpen] = useState(false);
	const [openUpload, setOpenUpload] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [deleteId, setDeleteId] = useState(null);
	const [date, setDate] = useState();
	const [openingsPdf, setOpeningsPdf] = useState(null);
	const [uploadConfirmationOpen, setUploadConfirmationOpen] = useState(false);
	const [openApply, setOpenApply] = useState(false);
	const [email, setEmail] = useState(null)
	const [name, setName] = useState(null)
	const [resume, setResume] = useState(null)
	const [applyId, setApplyId] = useState(null)
	const [openingsFile, setOpeningsFile] = useState(null)
	const [loggedInEmail, setLoggedInEmail] = useState(null)
	const [isLoading, setIsLoading] = useState(false);
	const { fetchedBucketName, fetchedRegion } = useAWSDetails();
	const apiCalledRef = useRef(false);

	// Function to handle opening the confirmation dialog
	// const handleDeleteConfirmation = (id) => {
	// setDeleteId(id);
	// setConfirmationOpen(true);
	// };

	// const [fetchedBucketName, setFetchedBucketName] = useState("");
	// const [fetchedRegion, setFetchedRegion] = useState("");

	// async function fetchAWSDetails() {
	//   try {
	//     const response = await axios.get(`${process.env.REACT_APP_API_URL}getAWSDetails`);
	//     const data = response.data;
	//     const { bucketName, region } = data;

	//     setFetchedBucketName(bucketName);
	//     setFetchedRegion(region);
	//     // Now you can use the bucketName and region in your frontend code
	//     console.log('Bucket Name:', bucketName);
	//     console.log('Region:', region);

	//     // Example usage
	//     // initializeS3Client(bucketName, region);
	//   } catch (error) {
	//     console.error('Error fetching AWS details:', error.message);
	//   }
	// }

	// useEffect(() => {
	// fetchAWSDetails();
	// }, [])

	const handleConfirmDelete = () => {
		// Perform deletion action using the deleteId
		handleDelete(deleteId);
		setConfirmationOpen(false); // Close the confirmation dialog
	};

	// Function to handle canceling the deletion
	const handleDeleteCancel = () => {
		setConfirmationOpen(false); // Close the confirmation dialog
	};

	const handleOpen = (url) => {
		setPdfUrl(url);
		setOpen(true);
	};


	const handleClose = () => setOpen(false);
	const handleCloseUpload = () => {
		setOpenUpload(false)
		setOpenApply(false)
	};

	useEffect(() => {
		if (apiCalledRef.current) return;
		apiCalledRef.current = true;
		axios
			.get(process.env.REACT_APP_API_URL + "profilepage", {
				withCredentials: true,
			})
			.then((response) => {
				setUser(response.data);
				setLoggedInEmail(response.data.email);
			})
			.catch((error) => {
				console.error("Error fetching user data:", error);
				if (error.response && error.response.status === 401) {
					toast.warning("Your account has been disabled. If you think this is an error contact HR. You will be logged out soon.", {
						onClose: () => {
							// Clear the token from cookies
							document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
							// Reload the page after a delay
							setTimeout(() => {
								window.location.reload();
							}, 3000); // 5 seconds delay
						}
					});
				}
			});
	}, []);

	const handleUpload = async (e) => {
		e.preventDefault();

		setIsLoading(true);

		// Check if any required field is empty
		if (!date || !openingsPdf) {
			toast.error("Please fill in all required fields.");
			setIsLoading(false);
			return;
		}

		try {
			const formData = new FormData();
			formData.append("date", date);
			formData.append("file", openingsPdf);

			await axios.post(
				process.env.REACT_APP_API_URL + "currentopenings/uploadpdf",
				formData,
				{
					withCredentials: true,
				}
			);

			setOpenUpload(false);

			// Fetch updated data after successful upload
			axios
				.get(process.env.REACT_APP_API_URL + "currentopenings/getopeningsPdf")
				.then((response) => {
					setUsers(response.data);
					toast.success("Openings updated successfully");
					setUploadConfirmationOpen(false);
				})
				.catch((error) => {
					console.error("Error fetching updated openings data:", error);
					toast.error("Error fetching updated openings data");
				});
		} catch (error) {
			console.error("Error uploading PDF:", error);
			toast.error("Error uploading PDF");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + "currentopenings/getopeningsPdf")
			.then((response) => {
				setUsers(response.data);
				console.log(response.data);
			})
			.catch((error) => {
				console.error("Error fetching calendar data:", error);
			});
	}, []);

	// Transforming fetched data into rows for the DataGrid
	const rows = users.reverse().map((user) => ({
		id: user._id, // or another unique identifier
		date: user.date,
		jobTitle: user.jobTitle,
		jobLocation: user.jobLocation,
		openingsPdf: user.openingsPdf,

	}));

	// Columns configuration for the DataGrid
	const columns = [
		{
			field: "date", headerName: "Date", flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Date
				</Typography>
			),
		},
		{
			field: "jobTitle", headerName: "Job Title", flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Job Title
				</Typography>
			),
		},
		{
			field: "jobLocation", headerName: "Job Location", flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Job Location
				</Typography>
			),
		},
		{
			field: "openingsPdf",
			headerName: "Job Description",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Job Description
				</Typography>
			),
			renderCell: (params) => (
				<IconButton
					onClick={() =>
						handleOpen(
							`https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/openings/${params.row.openingsPdf}`
						)
					}
					sx={{ color: "#04497a" }}
				>
					<RemoveRedEyeIcon />
				</IconButton>
			),
		},
		{
			field: "applications",
			headerName: "Applications",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Applications
				</Typography>
			),
			renderCell: (params) => (
				<IconButton
					onClick={() => navigate(`/viewapplication/${params.row.id}/${loggedInEmail}`)}
					sx={{ color: "#04497a" }}
				>
					<ListAltOutlinedIcon />
				</IconButton>
			),
		},
		// {
		// field: "action",
		// headerName: "Action",
		// flex: 1,
		// renderHeader: () => (
		// <Typography fontSize="14px" fontWeight="bold">
		// Action
		// </Typography>
		// ),
		// renderCell: (params) => (
		// <div>
		// <IconButton
		// style={{ cursor: "pointer", color: "#04497a", fontSize: "25px" }}
		// onClick={handleApplyModal(params.row.id, params.row.date, params.row.openingsPdf)}
		// >
		// <ShareIcon />
		// </IconButton>
		// </div>
		// ),
		// },
	];

	const handleApplyModal = (id, date, openingsPdf) => () => {
		setApplyId(id);
		setDate(date);
		setOpenApply(true);
		setOpeningsFile(openingsPdf);
	};

	const handleDelete = (id) => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}currentopenings/deleteopening/${id}`)
			.then(() => {
				toast.success("Calendar deleted successfully");
				// Filter out the deleted calendar entry from the users state
				setUsers((prevUsers) => prevUsers.filter((user) => user._id !== id));
			})
			.catch((error) => {
				console.error("Error deleting calendar entry:", error);
				toast.error("Error deleting calendar entry");
			});
	};

	const lightTheme = createTheme({
		palette: {
			primary: indigo,
		},
	});

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "white",
		boxShadow: 24,
		padding: "20px", // Adjust padding for inner content spacing
		borderRadius: "8px",
		width: "80%", // Adjust width for the modal
		height: "90%", // Adjust height for the modal
		overflow: "auto",
		display: "flex",
		flexDirection: "column",
	};

	const styleModalBox = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "#e0e0e0",
		boxShadow: 24,
		p: 4,
		width: "80%", // Adjust the width here as per your requirement
		maxWidth: "500px", // Set maximum width if needed
		outline: "none",
		borderRadius: "10px",
	};

	//Upload confirmation dialog box
	// const handleUploadConfirmationOpen = () => {
	// setUploadConfirmationOpen(true);
	// };

	const handleUploadCancel = () => {
		setUploadConfirmationOpen(false); // Close the confirmation dialog
	};

	const handleApply = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		if (!name || !email || !resume || !date) {
			toast.error("Please fill in all required fields.");
			setIsLoading(false);
			return;
		}

		try {
			const formData = new FormData();
			formData.append('name', name);
			formData.append('email', email);
			formData.append('resume', resume); // resume is the file object
			formData.append('date', date);
			formData.append('loggedInEmail', loggedInEmail);

			await axios.post(
				`${process.env.REACT_APP_API_URL}currentopenings/submit-application/${applyId}`,
				formData,
				{
					withCredentials: true,
					headers: {
						'Content-Type': 'multipart/form-data', // Important for handling file uploads
					},
				}
			);
			setOpenApply(false);
			toast.success('Application submitted successfully');
		} catch (error) {
			console.error('Error submitting application:', error);

			// Check if the error is due to an existing email
			if (error.response && error.response.status === 400 && error.response.data.error === 'Email already exists') {
				toast.error('Email id already exists.');
			} else {
				toast.error('An error occurred while submitting the application');
			}
		} finally {
			setIsLoading(false);
		}
	};


	return (
		<div>
			{isLoading && <Spinner />}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: "absolute",
							right: 0,
							top: 0,
							color: "crimson",
						}}
					>
						<CloseIcon />
					</IconButton>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						Holiday Calendar
					</Typography>
					{pdfUrl && (
						<iframe
							src={pdfUrl}
							width="95%"
							height="90%"
							title="PDF viewer"
							style={{ margin: "auto" }}
						/>
					)}
				</Box>
			</Modal>

			<Modal //Upload a Apply
				open={openApply}
				onClose={handleCloseUpload}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={styleModalBox}
				>
					<IconButton
						aria-label="close"
						onClick={handleCloseUpload}
						sx={{
							position: "absolute",
							right: 0,
							top: 0,
							color: "crimson",
						}}
					>
						<CloseIcon />
					</IconButton>
					<div className="upload-section">
						<form onSubmit={handleApply}>
							<div className="formbold-mb-5 w-full">
								<label
									htmlFor="date"
									className="formbold-form-label"
									style={{ color: "black" }}
								>
									{" "}
									Date<span style={{ color: "#c44545", position: "relative", top: "-4px" }}>*</span>
								</label>
								<input
									type="date"
									name="date"
									id="date"
									className="formbold-form-input"
									value={date}
									onChange={(e) => setDate(e.target.value)}
								/>
							</div>

							<div className="formbold-mb-5 w-full">
								<label
									htmlFor="jobTitle"
									className="formbold-form-label"
									style={{ color: "black" }}
								>
									{" "}
									Name<span style={{ color: "#c44545", position: "relative", top: "-4px" }}>*</span>
								</label>
								<input
									type="text"
									name="name"
									id="name"
									className="formbold-form-input"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>

							<div className="formbold-mb-5 w-full">
								<label
									htmlFor="name"
									className="formbold-form-label"
									style={{ color: "black" }}
								>
									{" "}
									Email id<span style={{ color: "#c44545", position: "relative", top: "-4px" }}>*</span>
								</label>
								<input
									type="email"
									name="email"
									id="email"
									className="formbold-form-input"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>

							<div className="formbold-mb-3">
								<label
									htmlFor="address"
									className="formbold-form-label"
									style={{ color: "black" }}
								>
									Resume<span style={{ color: "#c44545", position: "relative", top: "-4px" }}>*</span>
								</label>
								<input
									type="file"
									name="resume"
									id="resume"
									className="formbold-form-input"
									onChange={(e) => setResume(e.target.files[0])}
								/>
							</div>
							<Button
								variant="contained"
								type="button"
								onClick={handleApply}
								sx={{
									backgroundColor: theme.palette.secondary.light,
									color: theme.palette.background.alt,
									fontSize: "14px",
									fontWeight: "bolder",
									padding: "10px 20px",
									margin: "15px 0px",
								}}
							>
								Apply
							</Button>
						</form>
					</div>
				</Box>
			</Modal>

			<Box m="1.5rem 2.5rem" height="calc(100vh - 275px)">
				<FlexBetween sx={{ marginBottom: "10px" }}>
					<Header title={"View Openings"} />
					{/* <Box>
					<Button
						onClick={() => handleOpenUpload()}
						sx={{
							backgroundColor: theme.palette.secondary.light,
							color: theme.palette.background.alt,
							fontSize: "14px",
							fontWeight: "bolder",
							padding: "10px 20px",
						}}
					>
						Upload Opening
					</Button>
				</Box> */}
				</FlexBetween>
				<h3 style={{ textAlign: "center",color: "#c44545", textDecoration: "underline", marginBottom: "20px", fontStyle: "italic" }}>Refer your friends by mailing to hr: srimonti.chatterjee@ivtree.com</h3>
				<ThemeProvider theme={lightTheme}>
					<DataGrid
						rows={rows}
						columns={columns}
						pageSize={10}
						rowsPerPageOptions={[5, 10, 20]}
						pageSizeOptions={[5, 10]}
						sx={dashboardStyle(theme, "495px")}
					/>
				</ThemeProvider>
			</Box>
			<ToastContainer />
			<ConfirmationDialog
				open={confirmationOpen}
				handleClose={handleDeleteCancel}
				handleConfirm={handleConfirmDelete} // Pass handleConfirmDelete here
				message="Are you sure you want to delete this calendar entry?"
			/>
			<ConfirmationDialog
				open={uploadConfirmationOpen}
				handleClose={handleUploadCancel}
				handleConfirm={handleUpload}
				message="Are you sure you want to upload the opening?"
			/>
		</div>
	);
}

export default EmployeeOpeningsPage
