import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import { Box, Button, useTheme, Modal, Typography } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import ConfirmationDialog from "shared/ConfirmationDialog";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { dashboardStyle } from "shared/utils";

function ManageGroupMembers() {
  const { id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [groupMembers, setGroupMembers] = useState([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [deletetionId, setDeletetionId] = useState("");
  const [groupMemberId, setGroupMemberId] = useState("");

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const fetchMembers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}groups/fetchMembers/${id}`
      );
      setGroupMembers(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);
  const handleDelete = (groupId, memberId) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}groups/deleteGroupMember/${deletetionId}/${groupMemberId}`)
      .then((result) => {
        console.log(result.data.message);
        setOpenDeleteConfirmation(false)
        toast.success("Group member deleted successfully");
        fetchMembers(); // Refresh the group members list
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to delete group member");
      });
  };

  const handleOpenDeleteConfirmation = (id, memberId) => {
    setOpenDeleteConfirmation(true);
    setDeletetionId(id);
    setGroupMemberId(memberId);
  };

  const handleCloseConfirmation = () => {
    setOpenDeleteConfirmation(false);
  };

  const rows = groupMembers.map((group) => ({
    id: group._id, // Use group ID as unique identifier
    empId: group.employeeId,
    memberId: group._id, // Include member _id for deletion
    name: group.name,
    email: group.email,
    role: group.role,
    createdOn: group.createdOn,
    updatedOn: group.updatedOn,
  }));

  const columns = [
    {
      field: "empId",
      headerName: "Employee Id",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Employee Id
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Name
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Email
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Action
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton
            style={{ cursor: "pointer", color: "#04497a", fontSize: "25px" }}
            // onClick={() => handleDelete(id, params.row.memberId)} // Pass groupId and memberId
            onClick={() => {
              handleOpenDeleteConfirmation(id, params.row.memberId);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div>
        <FlexBetween sx={{ position: "fixed" }}>
          <IconButton
            onClick={() => navigate(-1)}
            style={{ color: "#04497a", marginTop: "-6px", marginLeft: "10px" }}
          >
            <ArrowBackIcon />
          </IconButton>
        </FlexBetween>
      </div>
      <Box m="1.5rem 2.5rem">
        <FlexBetween sx={{ marginBottom: "5px" }}>
          <Header title="Manage Group" subtitle="Group Description" />
        </FlexBetween>
        <ThemeProvider theme={lightTheme}>
          {/* {selectedGroupData.length ? ( */}
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            pageSizeOptions={[5, 10]}
            sx={dashboardStyle(theme, "480px")}
          />
          {/* ) : (
          <p>Loading...</p>
        )} */}
        </ThemeProvider>
        <ToastContainer />
      </Box>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        handleClose={handleCloseConfirmation}
        handleConfirm={handleDelete}
        message="Are you sure you want to delete this member?"
      />
    </div>
  );
}

export default ManageGroupMembers;
