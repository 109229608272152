// Admin - IND
import React, { useEffect, useState } from "react";
import axios from "axios";
import FlexBetween from "shared/FlexBetween";
import {
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
import Header from "shared/Header";
import { DataGrid } from "@mui/x-data-grid";
import { indigo } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import { dashboardStyle, tabStyles } from "shared/utils";
import Spinner from "shared/spinner/Spinner";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import TimesheetsMonthsTable from "../attendance-main-page/TimesheetsMonthsTable";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";

function TimesheetIND() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const [timesheets, setTimesheets] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("all");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedEmployee, setSelectedEmployee] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const styles = tabStyles(theme, selectedTab);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = Array.from(
    { length: 10 },
    (_, index) => new Date().getFullYear() - 2 + index
  );

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL, {
        withCredentials: true,
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
      toast.error("Failed to fetch employees");
    }
  };

  // const fetchAllTimesheets = async () => {
  //   setIsLoading(true);
  //   try {
  //     const params = {
  //       month: selectedMonth === "all" ? undefined : parseInt(selectedMonth),
  //       year: selectedYear,
  //       employeeId: selectedEmployee === "all" ? undefined : selectedEmployee,
  //     };

  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}attendance/getTimesheetsIND`,
  //       {
  //         params,
  //         withCredentials: true,
  //       }
  //     );

  //     const transformedData = response.data.map((attendance) => {
  //       // Check if attendance and attendance.attendance exist
  //       if (!attendance || !attendance.attendance || !attendance.attendance.length) {
  //         return [];
  //       }

  //       // Find the attendance data for the selected year
  //       const yearData = attendance.attendance.find(
  //         (att) => Number(att.year) === selectedYear
  //       );

  //       // If no year data found, return empty array
  //       if (!yearData || !yearData.months) {
  //         return [];
  //       }

  //       // Filter and map the months
  //       const monthData = yearData.months
  //         .filter((m) => {
  //           if (!m || !m.timesheets) return false;
  //           if (selectedMonth === "all") return true;
  //           return m.month === parseInt(selectedMonth);
  //         })
  //         .map((month) => {
  //           // Ensure timesheets array exists
  //           const timesheets = month.timesheets || [];

  //           // Calculate days worked with null check
  //           const daysWorked = timesheets.filter(
  //             (entry) => entry && entry.workType &&
  //               ![
  //                 "Sick leave",
  //                 "Privilege leave",
  //                 "Public Holiday",
  //                 "Weekend off",
  //               ].includes(entry.workType)
  //           ).length;

  //           // Calculate total hours with null check
  //           const totalHours = timesheets.reduce(
  //             (acc, curr) => acc + (curr && curr.workingHours ? parseFloat(curr.workingHours) : 0),
  //             0
  //           );

  //           return {
  //             _id: month._id || `${attendance.employeeId}-${month.month}-${selectedYear}`,
  //             employeeId: attendance.employeeId,
  //             month: month.month,
  //             timesheetName: `Timesheet-${attendance.employeeId}-${month.month}-${selectedYear}`,
  //             daysWorked,
  //             totalHours,
  //             status: month.status || "Pending",
  //             modifiedAt: month.updatedAt || new Date().toISOString(),
  //           };
  //         });

  //       return monthData;
  //     });

  //     // Filter out empty arrays and flatten the result
  //     const flattenedData = transformedData
  //       .flat()
  //       .filter(Boolean);

  //     setTimesheets(flattenedData);
  //   } catch (error) {
  //     console.error("Error fetching timesheets:", error);
  //     toast.error("Failed to fetch timesheets");
  //     setTimesheets([]); // Set empty array on error
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const fetchAllTimesheets = async () => {
    setIsLoading(true);
    try {
      const params = {
        month: selectedMonth === "all" ? undefined : parseInt(selectedMonth),
        year: selectedYear,
        employeeId: selectedEmployee === "all" ? undefined : selectedEmployee,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}attendance/getTimesheetsIND`,
        {
          params,
          withCredentials: true,
        }
      );

      const transformedData = response.data.map((attendanceDoc) => {
        // Ensure we have the required data
        if (
          !attendanceDoc ||
          !attendanceDoc.attendance ||
          !attendanceDoc.employeeId
        ) {
          return [];
        }

        // Since attendance is an object, not an array
        const yearEntry = attendanceDoc.attendance;

        // Skip if year doesn't match
        if (yearEntry.year !== selectedYear) {
          return [];
        }

        // Process months - ensure months exists and is an array
        if (!yearEntry.months || !Array.isArray(yearEntry.months)) {
          return [];
        }

        return yearEntry.months.map((month) => {
          // Skip if month doesn't match when a specific month is selected
          if (
            selectedMonth !== "all" &&
            month.month !== parseInt(selectedMonth)
          ) {
            return null;
          }

          // Ensure timesheets array exists and is valid
          const timesheets = Array.isArray(month.timesheets)
            ? month.timesheets
            : [];

          // Calculate days worked
          const daysWorked = timesheets.filter(
            (entry) =>
              entry &&
              entry.workType &&
              ![
                "Sick leave",
                "Privilege leave",
                "Public Holiday",
                "Weekend off",
              ].includes(entry.workType)
          ).length;

          // Calculate total hours
          const totalHours = timesheets.reduce(
            (acc, curr) =>
              acc +
              (curr && curr.workingHours ? parseFloat(curr.workingHours) : 0),
            0
          );

          return {
            _id:
              month._id ||
              `${attendanceDoc.employeeId}-${month.month}-${yearEntry.year}`,
            employeeId: attendanceDoc.employeeId,
            month: month.month,
            timesheetName: `Timesheet-${attendanceDoc.employeeId}-${month.month}-${yearEntry.year}`,
            daysWorked,
            totalHours,
            status: month.status || "Pending",
            modifiedAt: month.updatedAt || new Date().toISOString(),
          };
        });
      });

      // Flatten arrays and remove null/undefined values
      const flattenedData = transformedData
        .flat(2) // Flatten nested arrays
        .filter((item) => item && Object.keys(item).length > 0); // Remove empty/null items

      console.log("Transformed Data:", flattenedData); // Debug log
      setTimesheets(flattenedData);
    } catch (error) {
      console.error("Error fetching timesheets:", error);
      toast.error("Failed to fetch timesheets");
      setTimesheets([]); // Set empty array on error
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllTimesheets();
  }, [selectedMonth, selectedYear, selectedEmployee]);

  const downloadTimesheetEntries = async (
    employeeName,
    employeeId,
    timesheetId,
    month,
    year
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}attendance/getTimesheetEntriesIND`,
        {
          params: { employeeId, timesheetId, month, year },
          withCredentials: true,
        }
      );

      const timesheetEntries = response.data.entries;

      if (!timesheetEntries || timesheetEntries.length === 0) {
        toast.warning("No entries found for this timesheet.");
        return;
      }

      const excelData = timesheetEntries.map((entry, index) => ({
        "Sr. No.": index + 1,
        Date: entry.date
          ? new Date(entry.date).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })
          : "N/A",
        "Work Type": entry.workType || "N/A",
        "Working Hours": entry.workingHours || 0,
        "Updated On": entry.updatedAt
          ? new Date(entry.updatedAt).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })
          : "N/A",
        Comments: entry.comments || "N/A",
      }));

      // Approach 1: Using aoa_to_sheet instead of json_to_sheet
      const headers = Object.keys(excelData[0]);
      const data = [
        headers,
        ...excelData.map((obj) => headers.map((header) => obj[header])),
      ];

      const worksheet = XLSX.utils.aoa_to_sheet(data);

      // Set column widths
      const colWidths = [
        { wch: 10 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
      ];
      worksheet["!cols"] = colWidths;

      // Apply center alignment
      for (let i in worksheet) {
        if (i[0] === "!") continue;
        worksheet[i].s = {
          alignment: {
            horizontal: "center",
            vertical: "center",
            wrapText: true,
          },
          font: {
            bold: i.endsWith("1"), // Make headers bold
          },
        };
      }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Timesheet Entries");

      XLSX.writeFile(
        workbook,
        `Timesheet_${employeeName}_${months[month - 1]}_${year}.xlsx`
      );
    } catch (error) {
      console.error("Error downloading timesheet entries:", error);
      toast.error("Failed to download timesheet entries.");
    }
  };

  const rows = timesheets
    .map((timesheet) => ({
      id: timesheet._id,
      timesheetName: timesheet.timesheetName,
      employeeId: timesheet.employeeId,
      employeeName:
        users.find((u) => u.employeeId === timesheet.employeeId)?.name ||
        "Unknown",
      month: timesheet.month,
      daysWorked: timesheet.daysWorked || 0,
      leavesTaken: timesheet.leavesTaken || 0,
      totalHours: timesheet.totalHours || 0,
      status: timesheet.status,
      modifiedAt: timesheet.modifiedAt,
    }))
    .sort((a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt))
    .map((row, index) => ({
      ...row,
      index: index + 1,
    }));

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 40,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          #
        </Typography>
      ),
    },
    {
      field: "employeeName",
      headerName: "Employee Name",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Employee Name
        </Typography>
      ),
    },
    {
      field: "month",
      headerName: "Month",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Month
        </Typography>
      ),
      renderCell: (params) => (
        <Typography>{months[params.value - 1]}</Typography>
      ), // Subtract 1 to get correct month
    },
    // {
    //   field: "timesheetName",
    //   headerName: "Timesheet Name",
    //   flex: 1.5,
    //   renderHeader: () => <Typography fontSize="14px" fontWeight="bold">Timesheet Name</Typography>,
    // },
    {
      field: "daysWorked",
      headerName: "Days Worked",
      flex: 0.7,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Days Worked
        </Typography>
      ),
      renderCell: (params) => <Typography>{params.value || 0}</Typography>,
    },
    {
      field: "totalHours",
      headerName: "Total Hours",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Total Hours
        </Typography>
      ),
      renderCell: (params) => (
        <Typography>{params.value.toFixed(2)}</Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Status
        </Typography>
      ),
      renderCell: (params) => (
        <div
          style={{
            color:
              params.value === "Approved"
                ? "#388e3c"
                : params.value === "Pending"
                ? "#f57c00"
                : params.value === "Rejected"
                ? "#f44336"
                : params.value === "Submitted"
                ? "#1976d2"
                : "#616161",
            background:
              params.value === "Approved"
                ? "#c8e6c9"
                : params.value === "Pending"
                ? "#ffe0b2"
                : params.value === "Rejected"
                ? "#ffcdd2"
                : params.value === "Submitted"
                ? "#cce5ff"
                : "#f5f5f5",
            borderRadius: "4px",
            padding: "8px",
            display: "inline-block",
            width: "90px",
            textAlign: "center",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "modifiedAt",
      headerName: "Modified",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Modified
        </Typography>
      ),
      renderCell: (params) => (
        <Typography fontSize="12px">
          {new Date(params.value).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          })}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Actions
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <IconButton
            style={{ cursor: "pointer", color: "#04497a" }}
            onClick={() =>
              navigate(
                `/admin/timesheets/view/${params.row.employeeId}/${selectedYear}/${params.row.month}/${params.row.id}`
              )
            }
          >
            <RemoveRedEyeIcon />
          </IconButton>
          <Tooltip title="Download Timesheet Entries">
            <IconButton
              style={{ cursor: "pointer", color: "#04497a" }}
              onClick={() =>
                downloadTimesheetEntries(
                  params.row.employeeName,
                  params.row.employeeId,
                  params.row.id,
                  params.row.month,
                  selectedYear
                )
              }
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      {isLoading && <Spinner />}
      <Box m="1.5rem 2.5rem" height="85%">
        <Box>
          <ThemeProvider theme={lightTheme}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="fullWidth"
              sx={{
                marginBottom: "20px",
                height: "60px",
                color: "white",
                borderBottom: "1px solid #e5e5e5",
              }}
            >
              <Tab
                label=" Manage Employee Timesheet"
                icon={<GroupsOutlinedIcon sx={{ marginRight: "4px" }} />}
                sx={styles}
              />
              <Tab
                label=" Manage Personal Timesheet"
                icon={
                  <ManageAccountsOutlinedIcon sx={{ marginRight: "4px" }} />
                }
                sx={styles}
              />
              {/* Add more tabs as needed */}
            </Tabs>
          </ThemeProvider>
        </Box>

        {selectedTab === 0 && (
          <>
            <FlexBetween sx={{ marginBottom: "10px" }}>
              <Header title="Timesheets" />
              <ThemeProvider theme={lightTheme}>
                <Box
                  sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
                >
                  <FormControl sx={{ minWidth: "150px" }}>
                    {" "}
                    {/* Changed width */}
                    <InputLabel>Employee</InputLabel>
                    <Select
                      value={selectedEmployee}
                      label="Employee"
                      onChange={(e) => setSelectedEmployee(e.target.value)}
                      sx={{ height: "40px" }}
                    >
                      <MenuItem value="all">All Employees</MenuItem>
                      {users.map(
                        (user) =>
                          user.country === "India" && (
                            <MenuItem
                              key={user.employeeId}
                              value={user.employeeId}
                            >
                              {user.name}
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ minWidth: "120px" }}>
                    {" "}
                    {/* Changed width */}
                    <InputLabel>Month</InputLabel>
                    <Select
                      value={selectedMonth}
                      label="Month"
                      onChange={(e) => setSelectedMonth(e.target.value)}
                      sx={{ height: "40px" }}
                    >
                      <MenuItem value="all">All Months</MenuItem>
                      {months.map((month, index) => (
                        <MenuItem key={index} value={(index + 1).toString()}>
                          {month}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ minWidth: "100px" }}>
                    {" "}
                    {/* Changed width */}
                    <InputLabel>Year</InputLabel>
                    <Select
                      value={selectedYear}
                      label="Year"
                      onChange={(e) =>
                        setSelectedYear(parseInt(e.target.value))
                      }
                      sx={{ height: "40px" }}
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </ThemeProvider>
            </FlexBetween>

            <ThemeProvider theme={lightTheme}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={20}
                pageSizeOptions={[5, 10]}
                sx={dashboardStyle(theme, "480px")}
              />
            </ThemeProvider>
            <ToastContainer />
            <ConfirmationDialog
              open={confirmationOpen}
              handleClose={() => setConfirmationOpen(false)}
              handleConfirm={() => {
                // Add confirmation logic if needed
                setConfirmationOpen(false);
              }}
              message="Are you sure you want to perform this action?"
            />
          </>
        )}

        {selectedTab === 1 && <TimesheetsMonthsTable />}
      </Box>
    </div>
  );
}

export default TimesheetIND;
