import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Modal,
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { toast, ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import { dashboardStyle, tabStyles } from "shared/utils";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationDialog from "shared/ConfirmationDialog";
import axios from "axios";
import Spinner from "shared/spinner/Spinner";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";

const CommentModal = ({
  open,
  handleClose,
  comments = [],
  onSave,
  readOnly,
  isLoading,
  currentUser,
}) => {
  const [newComment, setNewComment] = useState("");

  const handleSave = () => {
    onSave(newComment);
    setNewComment("");
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="comment-modal"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
          p: 4,
          width: "500px",
          maxWidth: "90%",
          maxHeight: "90vh",
          overflow: "auto",
          // color: "#333",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Typography
            variant="h6"
            sx={{ 
              fontWeight: "600",
              color: "#04497a",
              fontSize: "1.25rem"
            }}
          >
            {readOnly ? "Comments History" : "Add Comment"}
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{ 
              color: "#666",
              '&:hover': {
                color: "crimson",
                backgroundColor: "rgba(220,20,60,0.1)"
              }
            }}
            disabled={isLoading}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Comments History Section */}
        <Box 
          sx={{ 
            mb: 3, 
            maxHeight: "300px", 
            overflow: "auto",
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#ccc',
              borderRadius: '4px',
            },
          }}
        >
          {comments && comments.length > 0 ? (
            comments.map((comment, index) => (
              <Box 
                key={index} 
                sx={{ 
                  mb: 2,
                  animation: 'fadeIn 0.3s ease-in',
                  '@keyframes fadeIn': {
                    from: { opacity: 0 },
                    to: { opacity: 1 }
                  }
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      color: "#04497a",
                      fontWeight: 600
                    }}
                  >
                    {comment.authorName}
                  </Typography>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      color: "#666",
                      fontSize: "0.75rem"
                    }}
                  >
                    {new Date(comment.createdAt).toLocaleString()}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#333",
                    backgroundColor: comment.author === currentUser ? "#e3f2fd" : "#f5f5f5",
                    padding: "12px 16px",
                    borderRadius: "12px",
                    marginLeft: comment.author === currentUser ? "auto" : "0",
                    marginRight: comment.author === currentUser ? "0" : "auto",
                    maxWidth: "80%",
                    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                    fontSize: "0.9rem",
                    lineHeight: "1.5"
                  }}
                >
                  {comment.text}
                </Typography>
                {index < comments.length - 1 && (
                  <Divider sx={{ mt: 2, backgroundColor: "#eee" }} />
                )}
              </Box>
            ))
          ) : (
            <Typography 
              variant="body2" 
              sx={{ 
                color: "#666",
                textAlign: "center",
                fontStyle: "italic"
              }}
            >
              No comments yet
            </Typography>
          )}
        </Box>

        {/* Add New Comment Section */}
        {!readOnly && (
          <>
            <Divider sx={{ mb: 3, backgroundColor: "#eee" }} />
            <TextField
              fullWidth
              multiline
              rows={3}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              disabled={isLoading}
              placeholder="Add your comment..."
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#fff",
                  '& fieldset': {
                    borderColor: '#ddd',
                  },
                  '&:hover fieldset': {
                    borderColor: '#04497a',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#04497a',
                  }
                },
                "& .MuiOutlinedInput-input": {
                  color: "#333",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#999",
                  opacity: 1
                }
              }}
            />
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isLoading || !newComment.trim()}
              sx={{
                backgroundColor: "#04497a",
                padding: "8px 24px",
                borderRadius: "8px",
                textTransform: "none",
                fontWeight: 600,
                "&:hover": { 
                  backgroundColor: "#033c65" 
                },
                "&:disabled": {
                  backgroundColor: "#ccc",
                  color: "white"
                },
                transition: "all 0.2s ease"
              }}
            >
              {isLoading ? "Adding..." : "Add Comment"}
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

function ViewTimesheetIND() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { employeeId, yearFromUrl, monthFromUrl, id } = useParams(); // timesheet ID from URL
  const [isLoading, setIsLoading] = useState(false);
  // const [employeeId, setEmployeeId] = useState("");
  const [timesheetEntries, setTimesheetEntries] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Change to 1-based indexing
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteEntryId, setDeleteEntryId] = useState(null);

  // Form states
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedWorkType, setSelectedWorkType] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [workingHours, setWorkingHours] = useState();
  const [editMode, setEditMode] = useState(false);
  const [editEntryId, setEditEntryId] = useState(null);
  const [user, setUser] = useState({});
  const [approveConfirmationOpen, setApproveConfirmationOpen] = useState(false);
  // Dialog states
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(""); // 'approve' or 'reject'
  const [statusMessage, setStatusMessage] = useState("");
  const [timesheetStatus, setTimesheetStatus] = useState("");

  // New state for final confirmation dialog
  const [finalConfirmationOpen, setFinalConfirmationOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [isViewMode, setIsViewMode] = useState(false);
  const [commentModalOpen, setCommentModalOpen] = useState(false);

  const fetchEmployeeId = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}profilepage`,
        { withCredentials: true }
      );
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching employee ID:", error);
    }
  };

  useEffect(() => {
    fetchEmployeeId();
    console.log("UseEffect triggered with:", {
      id,
      employeeId,
      monthFromUrl,
    });

    if (id && employeeId && monthFromUrl) {
      console.log("Fetching timesheet entries...");
      fetchTimesheetEntries();
    } else {
      console.log("Missing required parameters:", {
        hasId: !!id,
        hasEmployeeId: !!employeeId,
        hasMonth: !!monthFromUrl,
      });
    }
  }, [id, employeeId, monthFromUrl]);

  // When fetching timesheet entries:
  const fetchTimesheetEntries = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}attendance/fetchTimesheetEntriesIND`,
        {
          params: {
            employeeId,
            timesheetId: id,
            month: monthFromUrl,
            year: yearFromUrl,
          },
          withCredentials: true,
        }
      );
  
      console.log("Raw response data:", response.data); // Debug log
  
      if (response.data && response.data.entries) {
        const formattedEntries = response.data.entries.map((entry, index) => ({
          ...entry,
          id: entry._id || `temp-${index}`,
          comments: Array.isArray(entry.comments) ? entry.comments : []
        }));
  
        console.log("Formatted entries:", formattedEntries); // Debug log
        setTimesheetEntries(formattedEntries);
        setTimesheetStatus(response.data.status);
      }
    } catch (error) {
      console.error("Error fetching timesheet entries:", error);
      toast.error("Error fetching timesheet entries");
    } finally {
      setIsLoading(false);
    }
  };

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  useEffect(() => {
    if (
      selectedWorkType === "Privilege Leave" ||
      selectedWorkType === "Sick Leave"
    ) {
      setWorkingHours("0");
    }
  }, [selectedWorkType]);
  // Modify formatAndSortRows function to remove input row
  // Modify the formatAndSortRows function to ensure IDs are always present
  const formatAndSortRows = (entries) => {
    return entries
      .map((entry, index) => ({
        id: entry._id || `temp-${index}`,
        _id: entry._id || `temp-${index}`, // Keep original _id for reference
        index: index + 1,
        date: entry.date
          ? new Date(entry.date).toISOString().split("T")[0]
          : "",
        day: entry.date
          ? new Date(entry.date).toLocaleDateString("en-US", {
              weekday: "long",
            })
          : "",
        workType: entry.workType || "Work from Office",
        workingHours: entry.workingHours || "0",
        comments: Array.isArray(entry.comments) ? entry.comments : [], // Ensure comments is always an array
        updatedAt: entry.updatedAt || entry.createdAt,
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  // Modify columns array to remove input cell renderers
  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 40,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => (
        <Typography fontSize="14px">
          {params.value ? new Date(params.value).toLocaleDateString() : ""}
        </Typography>
      ),
    },
    {
      field: "day",
      headerName: "Day",
      flex: 0.8,
    },
    {
      field: "workType",
      headerName: "Work Type",
      flex: 1.2,
    },
    {
      field: "workingHours",
      headerName: "Working Hours",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography
          fontSize="14px"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {params.value || "0"}
        </Typography>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Updated On",
      flex: 1,
      renderCell: (params) => (
        <Typography fontSize="14px">
          {params.value
            ? new Date(params.value).toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
              })
            : ""}
        </Typography>
      ),
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 0.8,
      headerAlign: "center",
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 1,
            justifyContent: "center",
          }}
        >
          <IconButton
            sx={{ color: "#04497a" }}
            onClick={() => handleCommentIconClick(params.row, false)}
          >
            <EditIcon />
          </IconButton>
    
          <IconButton
            sx={{
              color: params.row.comments?.length > 0 ? "#04497a" : "grey",
            }}
            onClick={() => handleCommentIconClick(params.row, true)}
            disabled={!(params.row.comments?.length > 0)}
          >
            <InsertCommentOutlinedIcon />
          </IconButton>
        </Box>
      ),
    }
  ];

  // Then in your component:
  const allRows = formatAndSortRows(timesheetEntries);

  const currentDate = new Date();
  const nextDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );

  const handleStatusUpdate = async () => {
    try {
      const status = dialogType === "approve" ? "Approved" : "Rejected";
  
      setIsLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}attendance/updateTimesheetStatusIND`,
        {
          employeeId,
          timesheetId: id,
          month: monthFromUrl,
          year: yearFromUrl,
          status,
          message: statusMessage,
          author: user.employeeId,    // Add this
          authorName: user.name,      // Add this
        },
        { withCredentials: true }
      );
  
      if (response.data) {
        // Update local state to reflect the changes
        setTimesheetEntries(prev => 
          prev.map(entry => ({
            ...entry,
            comments: [
              ...(entry.comments || []),
              {
                text: statusMessage,
                author: user.employeeId,
                authorName: user.name,
                createdAt: new Date()
              }
            ]
          }))
        );
        
        setTimesheetStatus(status);
        toast.success(`Timesheet ${status.toLowerCase()} successfully`);
        handleCloseDialog();
        navigate(-1);
      }
    } catch (error) {
      console.error("Error updating timesheet status:", error);
      toast.error("Error updating timesheet status");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDialog = (type) => {
    setDialogType(type);
    setStatusMessage("");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogType("");
    setStatusMessage("");
  };

  // New method to open final confirmation dialog
  const handleOpenFinalConfirmation = () => {
    setFinalConfirmationOpen(true);
  };

  // New method to close final confirmation dialog
  const handleCloseFinalConfirmation = () => {
    setFinalConfirmationOpen(false);
  };

  const handleCommentIconClick = (entry, viewMode) => {
    setSelectedEntry({
      ...entry,
      id: entry._id || entry.id,
    });
    setIsViewMode(viewMode);
    setCommentModalOpen(true);
  };

  const handleSaveComment = async (newComment) => {
    try {
      setIsLoading(true);
      const currentTime = new Date().toISOString();
  
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}attendance/updateCommentIND`,
        {
          employeeId,
          timesheetId: id,
          entryId: selectedEntry.id,
          author: user.employeeId,
          authorName: name,
          comment: newComment,
          updatedAt: currentTime  // Add this line
        },
        { withCredentials: true }
      );
  
      if (response.data.success) {
        // Update only the specific entry's comments and updatedAt
        setTimesheetEntries((prev) =>
          prev.map((entry) =>
            entry._id === selectedEntry.id
              ? {
                  ...entry,
                  updatedAt: currentTime,  // Update the timestamp
                  comments: [
                    ...(entry.comments || []),
                    {
                      text: newComment,
                      author: user.employeeId,
                      authorName: name,
                      createdAt: new Date(),
                    },
                  ],
                }
              : entry
          )
        );
  
        toast.success("Comment saved successfully");
        setCommentModalOpen(false);
      } else {
        throw new Error(response.data.message || "Error saving comment");
      }
    } catch (error) {
      console.error("Error saving comment:", error);
      toast.error(error.message || "Error saving comment");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <FlexBetween sx={{ position: "fixed" }}>
          <IconButton
            onClick={() => navigate(-1)}
            style={{ color: "#04497a", marginTop: "18px", marginLeft: "10px" }}
          >
            <ArrowBackIcon />
          </IconButton>
        </FlexBetween>
      </div>
      <Box m="1.5rem 2.5rem" height="calc(100vh - 275px)">
        <Box>
          <FlexBetween sx={{ marginBottom: "0.5rem", justifyContent: "start" }}>
            <Header
              title="Timesheet"
              // subtitle={timesheetStatus}
              sx={{ alignSelf: "flex-start" }}
            />
            <Box sx={{ marginLeft: "auto" }}>
              <Button
                variant="contained"
                onClick={() => {
                  setDialogType("approve");
                  setDialogOpen(true);
                }}
                sx={{
                  backgroundColor:
                    timesheetStatus === "Approved" ||
                    timesheetStatus === "Pending"
                      ? "#d3d3d3" // Gray background when disabled
                      : "#04497a", // Original color when enabled
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: "8px 10px",
                  "&:disabled": {
                    backgroundColor: "#d3d3d3",
                    color: "#888888",
                  },
                }}
                disabled={
                  timesheetStatus === "Approved" ||
                  timesheetStatus === "Pending"
                }
              >
                Approve
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setDialogType("reject");
                  setDialogOpen(true);
                }}
                sx={{
                  backgroundColor:
                    timesheetStatus === "Approved" ||
                    timesheetStatus === "Pending"
                      ? "#d3d3d3" // Gray background when disabled
                      : theme.palette.secondary.light, // Original color when enabled
                  color:
                    timesheetStatus === "Approved" ||
                    timesheetStatus === "Pending"
                      ? "#888888" // Gray text when disabled
                      : theme.palette.background.alt,
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: "8px 10px",
                  marginLeft: "10px",
                  "&:disabled": {
                    backgroundColor: "#d3d3d3",
                    color: "#888888",
                  },
                }}
                disabled={
                  timesheetStatus === "Approved" ||
                  timesheetStatus === "Pending"
                }
              >
                Reject
              </Button>
            </Box>
          </FlexBetween>
          <ThemeProvider theme={lightTheme}>
            <DataGrid
              rows={allRows}
              columns={columns}
              pageSize={31}
              disableSelectionOnClick
              pageSizeOptions={[5, 10]}
              sx={{
                ...dashboardStyle(theme, "480px"),
                // height: "480px",
                "& .MuiDataGrid-row": {
                  maxHeight: "40px !important",
                  minHeight: "40px !important",
                },
                "& .MuiDataGrid-cell": {
                  maxHeight: "40px !important",
                  minHeight: "40px !important",
                  cursor: "default !important",
                  "&:focus": {
                    outline: "none",
                  },
                  "&:focus-within": {
                    outline: "none",
                  },
                },
                "& .MuiDataGrid-cell--editing": {
                  backgroundColor: "transparent !important",
                  outline: "none !important",
                },
                "& .MuiDataGrid-virtualScroller": {
                  minHeight: "auto !important",
                  overflowY: "auto !important",
                },
                "& .MuiDataGrid-virtualScrollerContent": {
                  minHeight: "auto !important",
                },
                "& .MuiDataGrid-virtualScrollerRenderZone": {
                  position: "relative",
                  minHeight: "auto !important",
                },
              }}
            />
          </ThemeProvider>
          {/* Add this before the closing tag of your component */}
          <CommentModal
            open={commentModalOpen}
            handleClose={() => setCommentModalOpen(false)}
            comments={selectedEntry?.comments || []}
            onSave={handleSaveComment}
            readOnly={isViewMode}
            isLoading={isLoading}
            currentUser={user.employeeId}
          />
          {/* Status Update Dialog */}
          <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            maxWidth="sm"
            fullWidth
            sx={{
              "& .MuiDialog-paper": {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.background.text,
              },
            }}
          >
            <ThemeProvider theme={lightTheme}>
              <DialogTitle sx={{ color: "#04497a", fontSize: "18px" }}>
                {dialogType === "approve"
                  ? "Approve Timesheet"
                  : "Reject Timesheet"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ fontSize: "14px" }}>
                  {dialogType === "approve"
                    ? "Please enter your approval message:"
                    : "Please enter the reason for rejection:"}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  multiline
                  rows={4}
                  value={statusMessage}
                  onChange={(e) => setStatusMessage(e.target.value)}
                  placeholder={
                    dialogType === "approve"
                      ? "Enter approval message..."
                      : "Enter rejection reason..."
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleOpenFinalConfirmation}
                  color="primary"
                  variant="contained"
                  disabled={!statusMessage.trim()}
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontSize: "12px",
                    fontWeight: "bolder",
                    padding: "10px 20px",
                  }}
                >
                  {dialogType === "approve" ? "Approve" : "Reject"}
                </Button>
              </DialogActions>
            </ThemeProvider>
          </Dialog>

          {isLoading && <Spinner />}
          <ToastContainer />
          <ConfirmationDialog
            open={finalConfirmationOpen}
            handleClose={handleCloseFinalConfirmation}
            handleConfirm={handleStatusUpdate}
            message={
              dialogType === "approve"
                ? "Are you sure you want to approve this timesheet?"
                : "Are you sure you want to reject this timesheet?"
            }
          />
        </Box>
      </Box>
    </>
  );
}

export default ViewTimesheetIND;
