import React, { useState, useEffect } from "react";
import FlexBetween from "shared/FlexBetween";
import { Box, useTheme, Button, Typography, TextField } from "@mui/material";
import Header from "shared/Header";
import { DataGrid } from "@mui/x-data-grid";
import { indigo } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import { dashboardStyle } from "shared/utils";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import ConfirmationDialog from "shared/ConfirmationDialog";
import { getCalendarPdfUrl } from "shared/utils";
import { useParams } from "react-router-dom";
import axios from "axios";

function EmployeeHolidayCalendar() {
  const theme = useTheme();
  const [uploadVisible, setUploadVisible] = useState(false);
  const [user, setUser] = useState({});
  const [calendarPDF, setCalendarPDF] = useState();
  const [selectedYear, setSelectedYear] = useState(2024);
  const [pdf, setPdf] = useState("");
  const [calendarName, setCalendarName] = useState("");
  const [fetchedHolidays, setFetchedHolidays] = useState([]);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [isCalendarCreated, setIsCalendarCreated] = useState(false);

  //   useEffect(() => {
  //     axios
  //       .get(process.env.REACT_APP_API_URL + "profilepage", {
  //         withCredentials: true,
  //       })
  //       .then((response) => {
  //         setUser(response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching user data:", error);
  //         if (error.response && error.response.status === 401) {
  //           toast.warning(
  //             "Your account has been disabled. If you think this is an error contact HR. You will be logged out soon.",
  //             {
  //               onClose: () => {
  //                 // Clear the token from cookies
  //                 document.cookie =
  //                   "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //                 // Reload the page after a delay
  //                 setTimeout(() => {
  //                   window.location.reload();
  //                 }, 3000); // 5 seconds delay
  //               },
  //             }
  //           );
  //         }
  //       });
  //   }, []);

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const currentYear = new Date().getFullYear(); // Get current year
  console.log("currentYear", currentYear);

  const years = Array.from({ length: 11 }, (_, index) => 2024 + index);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "profilepage",
        {
          withCredentials: true,
        }
      );
      setUser(response.data);
      setCalendarName(response.data.calendarName);
      console.log("calendarName", calendarName);
    } catch (error) {
      console.error("Error fetching user data:", error);
      if (error.response && error.response.status === 401) {
        toast.warning(
          "Your account has been disabled. If you think this is an error contact HR. You will be logged out soon.",
          {
            onClose: () => {
              // Clear the token from cookies
              document.cookie =
                "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              // Reload the page after a delay
              setTimeout(() => {
                window.location.reload();
              }, 3000); // 5 seconds delay
            },
          }
        );
      }
    }
  };

  useEffect(() => {
    if (currentYear && calendarName) {
      fetchCalendar();
    }
  }, [currentYear, calendarName]);

  const fetchCalendar = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}holidayCalendars/fetchCalendarbyName/${currentYear}/${calendarName}`
      );
      setFetchedHolidays(response.data.holidays);
      setIsCalendarCreated(true);
      console.log("fetchedHolidays", response.data.holidays);
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    }
  };

  const rows = fetchedHolidays.map((holiday, index) => {
	const holidayDate = new Date(holiday.date);
	return {
	  id: index + 1,
	  date: holiday.date,
	  holiday: holiday.holiday,
	  day: holidayDate.toLocaleString('en-US', { weekday: 'long' })
	};
  });
  
  const columns = [
	{
	  field: "id",
	  headerName: "Sr no.",
	  flex: 0.2,
    align: "center",
	  renderHeader: () => (
		<Typography fontSize="14px" fontWeight="bold">
		  Sr no.
		</Typography>
	  ),
	},
	{
	  field: "date",
	  headerName: "Date",
	  flex: 0.5,
	  renderHeader: () => (
		<Typography fontSize="14px" fontWeight="bold">
		  Date
		</Typography>
	  ),
	  valueFormatter: (params) => {
		const date = new Date(params.value);
		return date.toLocaleString('en-US', { month: 'short', day: '2-digit' });
	  }
	},
	{
	  field: "day",
	  headerName: "Day",
	  flex: 0.8,
	  renderHeader: () => (
		<Typography fontSize="14px" fontWeight="bold">
		  Day
		</Typography>
	  )
	},
	{
	  field: "holiday",
	  headerName: "Holiday",
	  flex: 1,
	  renderHeader: () => (
		<Typography fontSize="14px" fontWeight="bold">
		  Holiday
		</Typography>
	  ),
	},
  ];

  return (
    <div>
      <Box m="2rem 2.5rem" height="100%">
        <FlexBetween style={{ marginBottom: "0.5rem" }}>
          <Header title={`Holiday Calendar ${currentYear}`} />
        </FlexBetween>
        {isCalendarCreated ? (
          <ThemeProvider theme={lightTheme}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={20}
              // rowsPerPageOptions={[5, 10, 20]}
              pageSizeOptions={[5, 10]}
              sx={dashboardStyle(theme, "480px")}
              disableSelectionOnClick
            />
          </ThemeProvider>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              backgroundColor: "#f0f0f0",
              borderRadius: "15px",
            }}
          >
            <p style={{ fontSize: "18px", color: "#666" }}>
              Holiday calendar for {currentYear} has not been uploaded yet.
            </p>
          </div>
        )}

        {/* <p
            style={{
              fontSize: "14px",
              textDecoration: "underline",
              color: "maroon",
			  marginTop: "1rem",
			  textAlign: "center"
            }}
          >
            <i>
              Note: Employees / Consultants working on Customer project must
              follow corresponding Holiday calendar provided by the customer.
            </i>
          </p> */}
      </Box>
      <ToastContainer />
    </div>
  );
}

export default EmployeeHolidayCalendar;
