import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Button,
  Tab,
  Tabs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Modal,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast, ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import { dashboardStyle, tabStyles } from "shared/utils";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationDialog from "shared/ConfirmationDialog";
import axios from "axios";
import Spinner from "shared/spinner/Spinner";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";

const CommentModal = ({ open, handleClose, comment, readOnly, isLoading }) => {
  const [newComment, setNewComment] = useState("");
  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });
  // Reset the comment when the modal opens or comment changes
  useEffect(() => {
    setNewComment(comment || "");
  }, [comment, open]);

  const handleSave = () => {
    onSave(newComment);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="comment-modal"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ThemeProvider theme={lightTheme}>
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            width: "400px",
            maxWidth: "90%",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#04497a" }}
            >
              {readOnly ? "View Comment" : "Add/Edit Comment"}
            </Typography>
            <IconButton onClick={handleClose} disabled={isLoading}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            disabled={readOnly || isLoading}
            placeholder="Enter your comment here..."
            sx={{ mb: 2 }}
          />
          {!readOnly && (
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isLoading}
              sx={{
                backgroundColor: "#04497a",
                "&:hover": { backgroundColor: "#04497a" },
              }}
            >
              {isLoading ? "Saving..." : "Save"}
            </Button>
          )}
        </Box>
      </ThemeProvider>
    </Modal>
  );
};

function EditTimesheetUS() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { employeeId, monthFromUrl, id } = useParams(); // timesheet ID from URL
  const [isLoading, setIsLoading] = useState(false);
  // const [employeeId, setEmployeeId] = useState("");
  const [timesheetEntries, setTimesheetEntries] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteEntryId, setDeleteEntryId] = useState(null);

  // Form states
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedWorkType, setSelectedWorkType] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [workingHours, setWorkingHours] = useState();
  const [editMode, setEditMode] = useState(false);
  const [editEntryId, setEditEntryId] = useState(null);
  const [user, setUser] = useState({});
  const [approveConfirmationOpen, setApproveConfirmationOpen] = useState(false);
  // Dialog states
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(""); // 'approve' or 'reject'
  const [statusMessage, setStatusMessage] = useState("");
  const [timesheetStatus, setTimesheetStatus] = useState("");

  // New state for final confirmation dialog
  const [finalConfirmationOpen, setFinalConfirmationOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [isViewMode, setIsViewMode] = useState(false);
  const [commentModalOpen, setCommentModalOpen] = useState(false);

  // Modify useEffect to initialize entries if none exist
  useEffect(() => {
    const initializeTimesheet = async () => {
      if (!id || !employeeId || !monthFromUrl) return;

      try {
        setIsLoading(true);

        // First, fetch existing entries
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}timesheetUS/getTimesheetEntries`,
          {
            params: {
              employeeId,
              timesheetId: id,
              month: monthFromUrl,
              year: selectedYear,
            },
            withCredentials: true,
          }
        );

        // Set the initial entries in state immediately with temporary IDs
        setTimesheetEntries(response.data.entries || []);

        // setTimesheetEntries(entries);
      } catch (error) {
        console.error("Error initializing timesheet:", error);
        toast.error("Error initializing timesheet");
      } finally {
        setIsLoading(false);
      }
    };

    initializeTimesheet();
  }, [id, employeeId, monthFromUrl, selectedYear]);

  useEffect(() => {
    fetchEmployeeId();
    if (id && employeeId && monthFromUrl) {
      fetchTimesheetEntries();
    }
  }, [id, employeeId, monthFromUrl]);

  const fetchEmployeeId = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}profilepage`,
        { withCredentials: true }
      );
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching employee ID:", error);
    }
  };

  // When fetching timesheet entries:
  const fetchTimesheetEntries = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}timesheetUS/getTimesheetEntries`,
        {
          params: {
            employeeId,
            timesheetId: id,
            month: monthFromUrl,
            year: selectedYear,
          },
          withCredentials: true,
        }
      );

      // Ensure each entry has an _id
      const entries = response.data.entries.map((entry, index) => ({
        ...entry,
        _id: entry._id || `temp-${index}`, // Fallback ID if _id is missing
      }));

      setTimesheetEntries(entries);
      setTimesheetStatus(response.data.status);
    } catch (error) {
      console.error("Error fetching timesheet entries:", error);
      // toast.error("Error fetching timesheet entries");
    } finally {
      setIsLoading(false);
    }
  };

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  useEffect(() => {
    if (
      selectedWorkType === "Privilege Leave" ||
      selectedWorkType === "Sick Leave"
    ) {
      setWorkingHours("0");
    }
  }, [selectedWorkType]);

  const handleCommentIconClick = (entry, viewMode) => {
    console.log("Selected entry for comment:", entry); // Add this for debugging
    setSelectedEntry({
      ...entry,
      id: entry._id || entry.id, // Ensure we have the correct ID
    });
    setIsViewMode(viewMode);
    setCommentModalOpen(true);
  };
  // Modify formatAndSortRows function to remove input row
  // Modify the formatAndSortRows function to ensure IDs are always present
  const formatAndSortRows = (entries) => {
    return entries
      .map((entry, index) => ({
        id: entry._id || index.toString(), // Use index as fallback id for DataGrid
        ...entry,
        index: index + 1,
        date: entry.date
          ? new Date(entry.date).toISOString().split("T")[0]
          : "",
        day: entry.date
          ? new Date(entry.date).toLocaleDateString("en-US", {
              weekday: "long",
            })
          : "",
        workType: entry.workType || "Work from Office",
        workingHours: entry.workingHours || 0,
        overtimeHours: entry.overtimeHours || 0,  // Add this line
        status: entry.status || "Pending",
        comments: entry.comments || [],
        updatedAt: entry.updatedAt,
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  // Modify columns array to remove input cell renderers
  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 40,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "day",
      headerName: "Day",
      flex: 0.8,
    },
    {
      field: "workType",
      headerName: "Work Type",
      flex: 1.2,
    },
    {
      field: "workingHours",
      headerName: "Working Hours",
      flex: 0.8,
      type: "number",
      headerAlign: "center", // Center align the header
      align: "center", // Center align the content
      renderHeader: () => (
        <Typography
          fontSize="14px"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          Working Hours
        </Typography>
      ),
      renderCell: (params) => (
        <Typography
          fontSize={"12px"}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {params.value || 0}
        </Typography>
      ),
    },
    {
      field: "overtimeHours",
      headerName: "Overtime hrs",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography
          fontSize={"12px"}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {params.value || 0}
        </Typography>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Updated On",
      flex: 1,
      renderCell: (params) => (
        <Typography fontSize={"12px"}>
          {new Date(params.value).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          })}
        </Typography>
      ),
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 0.8,
      headerAlign: "center",
      // align: "center",
      renderCell: (params) => (
        <Box sx={{ width: "100%", gap: 1, display: "flex", justifyContent: "center" }}>
            <IconButton
              sx={{ color: "#04497a" }}
              onClick={() => handleCommentIconClick(params.row, true)}
              disabled={!params.row.comments?.length}
            >
              <InsertCommentOutlinedIcon />
            </IconButton>
        </Box>
      ),
    },
  ];

  // Then in your component:
  const allRows = formatAndSortRows(timesheetEntries);

  const currentDate = new Date();
  const nextDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );

  const handleStatusUpdate = async () => {
    try {
      const status = dialogType === "approve" ? "Approved" : "Rejected";

      setIsLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}timesheetUS/updateTimesheetStatus`,
        {
          employeeId,
          timesheetId: id,
          month: monthFromUrl,
          year: selectedYear,
          status,
          message: statusMessage,
        },
        { withCredentials: true }
      );

      if (response.data) {
        toast.success(`Timesheet ${status.toLowerCase()} successfully`);
        handleCloseDialog();
        navigate(-1);
      }
    } catch (error) {
      console.error("Error updating timesheet status:", error);
      toast.error("Error updating timesheet status");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDialog = (type) => {
    setDialogType(type);
    setStatusMessage("");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogType("");
    setStatusMessage("");
  };

  // New method to open final confirmation dialog
  const handleOpenFinalConfirmation = () => {
    setFinalConfirmationOpen(true);
  };

  // New method to close final confirmation dialog
  const handleCloseFinalConfirmation = () => {
    setFinalConfirmationOpen(false);
  };

  return (
    <>
      <div>
        <FlexBetween sx={{ position: "fixed" }}>
          <IconButton
            onClick={() => navigate(-1)}
            style={{ color: "#04497a", marginTop: "18px", marginLeft: "10px" }}
          >
            <ArrowBackIcon />
          </IconButton>
        </FlexBetween>
      </div>
      <Box m="1.5rem 2.5rem" height="calc(100vh - 275px)">
        <Box>
          <FlexBetween sx={{ marginBottom: "0.5rem", justifyContent: "start" }}>
            <Header
              title="Timesheet"
              // subtitle={timesheetStatus}
              sx={{ alignSelf: "flex-start" }}
            />
            <Box sx={{ marginLeft: "auto" }}>
              <Button
                // variant="contained"
                onClick={() => {
                  setDialogType("approve");
                  setDialogOpen(true);
                }}
                sx={{
                  backgroundColor:
                    timesheetStatus === "Approved" ||
                    timesheetStatus === "Pending"
                      ? "#d3d3d3" // Gray background when disabled
                      : "#04497a", // Original color when enabled
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: "7px 10px",
                  "&:disabled": {
                    backgroundColor: "#d3d3d3",
                    color: "#888888",
                  },
                }}
                disabled={
                  timesheetStatus === "Approved" ||
                  timesheetStatus === "Pending"
                }
              >
                Approve
              </Button>
              <Button
                // variant="contained"
                onClick={() => {
                  setDialogType("reject");
                  setDialogOpen(true);
                }}
                sx={{
                  backgroundColor:
                    timesheetStatus === "Approved" ||
                    timesheetStatus === "Pending"
                      ? "#d3d3d3" // Gray background when disabled
                      : "#04497a", // Original color when enabled
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "bold",
                  padding: "7px 10px",
                  marginLeft: "10px",
                  "&:disabled": {
                    backgroundColor: "#d3d3d3",
                    color: "#888888",
                  },
                }}
                disabled={
                  timesheetStatus === "Approved" ||
                  timesheetStatus === "Pending"
                }
              >
                Reject
              </Button>
            </Box>
          </FlexBetween>
          <ThemeProvider theme={lightTheme}>
            <DataGrid
              rows={allRows}
              columns={columns}
              pageSize={20}
              disableSelectionOnClick
              pageSizeOptions={[5, 10]}
              sx={{
                ...dashboardStyle(theme, "480px"),
                // height: "480px",
                "& .MuiDataGrid-row": {
                  maxHeight: "40px !important",
                  minHeight: "40px !important",
                },
                "& .MuiDataGrid-cell": {
                  maxHeight: "40px !important",
                  minHeight: "40px !important",
                  cursor: "default !important",
                  "&:focus": {
                    outline: "none",
                  },
                  "&:focus-within": {
                    outline: "none",
                  },
                },
                "& .MuiDataGrid-cell--editing": {
                  backgroundColor: "transparent !important",
                  outline: "none !important",
                },
                "& .MuiDataGrid-virtualScroller": {
                  minHeight: "auto !important",
                  overflowY: "auto !important",
                },
                "& .MuiDataGrid-virtualScrollerContent": {
                  minHeight: "auto !important",
                },
                "& .MuiDataGrid-virtualScrollerRenderZone": {
                  position: "relative",
                  minHeight: "auto !important",
                },
              }}
            />
          </ThemeProvider>
          <CommentModal
            open={commentModalOpen}
            handleClose={() => setCommentModalOpen(false)}
            comment={selectedEntry?.comments} // Make sure you're passing comments, not comment
            readOnly={isViewMode}
            isLoading={isLoading}
          />
          {/* Status Update Dialog */}
          <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            maxWidth="sm"
            fullWidth
            sx={{
              "& .MuiDialog-paper": {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.background.text,
              },
            }}
          >
            <ThemeProvider theme={lightTheme}>
              <DialogTitle sx={{ color: "#04497a", fontSize: "18px" }}>
                {dialogType === "approve"
                  ? "Approve Timesheet"
                  : "Reject Timesheet"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ fontSize: "14px" }}>
                  {dialogType === "approve"
                    ? "Please enter your approval message:"
                    : "Please enter the reason for rejection:"}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  multiline
                  rows={4}
                  value={statusMessage}
                  onChange={(e) => setStatusMessage(e.target.value)}
                  placeholder={
                    dialogType === "approve"
                      ? "Enter approval message..."
                      : "Enter rejection reason..."
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleOpenFinalConfirmation}
                  color="primary"
                  variant="contained"
                  disabled={!statusMessage.trim()}
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontSize: "12px",
                    fontWeight: "bolder",
                    padding: "10px 20px",
                  }}
                >
                  {dialogType === "approve" ? "Approve" : "Reject"}
                </Button>
              </DialogActions>
            </ThemeProvider>
          </Dialog>

          {isLoading && <Spinner />}
          <ToastContainer />
          <ConfirmationDialog
            open={finalConfirmationOpen}
            handleClose={handleCloseFinalConfirmation}
            handleConfirm={handleStatusUpdate}
            message={
              dialogType === "approve"
                ? "Are you sure you want to approve this timesheet?"
                : "Are you sure you want to reject this timesheet?"
            }
          />
        </Box>
      </Box>
    </>
  );
}

export default EditTimesheetUS;
