import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import {
  Box,
  useTheme,
  Button,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import PublishIcon from "@mui/icons-material/Publish";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import DownloadIcon from "@mui/icons-material/Download";
import { getCalendarPdfUrl, dashboardStyle } from "shared/utils";
import { Packer, Document, Paragraph } from "docx"; // Import docx library
import * as XLSX from "xlsx";
import useAWSDetails from "shared/useAWSDetails";

function DocumentsAdmin() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [openDescription, setOpenDescription] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [excelUrl, setExcelUrl] = useState("");
  const [wordUrl, setWordUrl] = useState("");
  const [excelData, setExcelData] = useState([]);
  // const [fetchedBucketName, setFetchedBucketName] = useState("");
  // const [fetchedRegion, setFetchedRegion] = useState("");
  const { fetchedBucketName, fetchedRegion } = useAWSDetails();

  //   async function fetchAWSDetails() {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API_URL}getAWSDetails`);
  //       const data = response.data;
  //       const { bucketName, region } = data;

  //       setFetchedBucketName(bucketName);
  //       setFetchedRegion(region);
  //       // Now you can use the bucketName and region in your frontend code
  //       console.log('Bucket Name:', bucketName);
  //       console.log('Region:', region);

  //       // Example usage
  //       // initializeS3Client(bucketName, region);
  //     } catch (error) {
  //       console.error('Error fetching AWS details:', error.message);
  //     }
  //   }

  // useEffect(() => {
  //   fetchAWSDetails();
  // }, [])

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL) // Update this endpoint to the correct one
      .then((result) => setUsers(result.data))
      .catch((err) => console.log(err));
  }, []);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedName(selectedValue === "" ? "" : selectedValue);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL)
      .then((response) => {
        setEmployees(response.data); // Set users to the array of users
      })
      .catch((error) => {
        console.error("Error fetching user emails:", error);
      });
  }, []);

  const fetchDocuments = async () => {
    axios
      .get(process.env.REACT_APP_API_URL + "itAndDocuments/getDocuments")
      .then((response) => {
        setDocuments(response.data); // Set documents to the array of documents
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
      });
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  useEffect(() => {
    const rows = documents
      .flatMap((document, index) =>
        document.documents.flatMap((innerDocument, innerIndex) => ({
          id: `${index}-${innerIndex}`,
          newId: innerDocument._id,
          employeeId: document.employeeId,
          name: document.name,
          uploadedBy: innerDocument.uploadedBy,
          documentType: innerDocument.documentType,
          document: innerDocument.document,
          description: innerDocument.description,
          createdOn: innerDocument.createdOn,
          updatedOn: innerDocument.updatedOn,
        }))
      )
      .filter((row) => {
        if (selectedName === "") {
          return true; // If no name is selected, show all rows
        }
        return row.name === selectedName; // Filter by name
      });

    setSelectedDocuments(rows);
  }, [documents, selectedName]);

  const handleConfirmDelete = async (documentId) => {
    setSelectedDocumentId(documentId);
    console.log(documentId);
    setConfirmationOpen(true);
    console.log(selectedDocumentId);
  };

  const handleDeleteDocument = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}itAndDocuments/deletedocument/${selectedDocumentId}`
      );
      toast.success("Document deleted successfully");
      fetchDocuments();
    } catch (error) {
      console.error("Error deleting document:", error);
      toast.error("Error deleting document");
    }
    setConfirmationOpen(false);
  };

  const handleDeleteCancel = () => {
    setConfirmationOpen(false); // Close the confirmation dialog
  };

  const handleDownloadDocument = async (documentUrl, documentType, employeeId) => {
    try {
      const response = await axios.get(documentUrl, {
        responseType: "blob", // Binary data response
      });
  
      // Get the file extension from the URL
      const fileExtension = getFileExtension(documentUrl);
      
      // Create the appropriate MIME type based on file extension
      let mimeType;
      switch (fileExtension) {
        case 'pdf':
          mimeType = 'application/pdf';
          break;
        case 'xlsx':
          mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
        case 'xls':
          mimeType = 'application/vnd.ms-excel';
          break;
        case 'docx':
          mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case 'doc':
          mimeType = 'application/msword';
          break;
        default:
          mimeType = 'application/octet-stream'; // Default binary data
      }
  
      const blob = new Blob([response.data], { type: mimeType });
      const fileUrl = URL.createObjectURL(blob);
  
      // Use the correct file extension in the filename
      const fileName = `${documentType}_${employeeId}.${fileExtension}`;
  
      // Create a temporary anchor element
      const anchor = document.createElement("a");
      anchor.href = fileUrl;
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      
      // Clean up the object URL
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error("Error downloading document:", error);
      toast.error("Error downloading document");
    }
  };

  const getFileExtension = (filename) =>
    filename.split(".").pop().toLowerCase();
  const handleOpen = (url) => {
    const fileExtension = getFileExtension(url);
    if (fileExtension === "pdf") {
      setPdfUrl(url);
      setOpen(true);
      setExcelData([]);
    } else if (fileExtension === "xlsx") {
      fetch(url)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          const workbook = XLSX.read(buffer, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          setExcelData(data); // Assuming you have a component to display Excel data in a modal
          setPdfUrl(null);
          setOpen(true);
        })
        .catch((error) => {
          console.error("Error reading Excel file:", error);
        });
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      setWordUrl(url); // Assuming you have a component to display Word documents in a modal
      setPdfUrl(null);
      setOpen(true);
    } else {
      setOpen(false);
      console.log("Unsupported file type:", fileExtension);
    }
  };

  const handleOpenDescription = (description) => {
    setDescription(description);
    setOpenDescription(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDescription(false);
  };

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const columns = [
    {
      field: "employeeId",
      headerName: "Emp ID",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Emp ID
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Name
        </Typography>
      ),
    },
    {
      field: "uploadedBy",
      headerName: "Uploaded by",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Uploaded by
        </Typography>
      ),
    },
    {
      field: "documentType",
      headerName: "Document Type",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Document Type
        </Typography>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Description
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => handleOpenDescription(params.row.description)}
            sx={{ color: "#04497a" }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: "view",
      headerName: "Document",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Document
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() =>
              handleOpen(
                `https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/employees/${
                  params.row.employeeId
                }/it-and-documents/${params.row.document}`
              )
            }
            sx={{ color: "#04497a" }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              handleDownloadDocument(
                `https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/employees/${
                  params.row.employeeId
                }/it-and-documents/${params.row.document}`,
                params.row.documentType,
                params.row.employeeId
              )
            }
            sx={{ color: "#04497a" }}
          >
            <DownloadIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: "createdOn",
      headerName: "Created On",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Created On
        </Typography>
      ),
    },
    // {
    //   field: "updatedOn",
    //   headerName: "Updated On",
    //   flex: 1,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Updated On
    //     </Typography>
    //   ),
    // },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Action
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <DeleteIcon
            style={{ cursor: "pointer", color: "#04497a", fontSize: "25px" }}
            onClick={() => handleConfirmDelete(params.row.newId)}
          />
        </div>
      ),
    },

    // Add more columns as needed
  ];

  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    color: "black",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    width: "50%",
    height: "auto",
    wordWrap: "break-word",
    borderRadius: "20px",
  };

  const styleNew = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    color: "black",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    width: "90%", // Adjust width for PDFs and Word documents
    height: "90%", // Adjust height for PDFs and Word documents
    wordWrap: "break-word",
    borderRadius: "20px",
  };

  return (
    <div>
      <Box m="2rem 2.5rem" height="calc(100vh - 275px)">
        <ThemeProvider theme={lightTheme}>
          <Modal
            open={openDescription}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  color: "crimson",
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Message
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, postion: "absolute" }}
              >
                {description}
              </Typography>
            </Box>
          </Modal>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleNew}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  color: "crimson",
                }}
              >
                <CloseIcon />
              </IconButton>
              {pdfUrl ? (
                <iframe
                  src={pdfUrl}
                  width="95%"
                  height="95%"
                  title="PDF viewer"
                  style={{
                    margin: "auto",
                    display: "block",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                />
              ) : excelData.length > 0 ? (
                <table className="excel-table">
                  <thead>
                    <tr>
                      {excelData[0].map((col, index) => (
                        <th key={index}>{col}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {excelData.slice(1).map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : wordUrl ? (
                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${wordUrl}`}
                  width="95%"
                  height="95%"
                  title="Word viewer"
                  style={{
                    margin: "auto",
                    display: "block",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                />
              ) : (
                <Typography variant="h6" align="center">
                  No file to display
                </Typography>
              )}
            </Box>
          </Modal>
        </ThemeProvider>
        <FlexBetween>
          <Header title="IT & Documents" subtitle="Manage Documents" />
        </FlexBetween>
        <ThemeProvider theme={lightTheme}>
          <FlexBetween sx={{ justifyContent: "space-between", mb: "10px" }}>
          <FormControl sx={{ minWidth: "150px", maxWidth: "150px" }}>
              <InputLabel id="demo-simple-select-label">Name</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedName}
                label="Name"
                onChange={handleChange}
              >
                <MenuItem value="">Show All</MenuItem>
                {/* {employees.map((employee) => (
                  <MenuItem key={employee._id} value={employee.name}>
                    {employee.name}
                  </MenuItem>
                ))} */}

                {employees.map(
                  (user) =>
                    user.country === "India" && (
                      <MenuItem key={user._id} value={user.name}>
                        {user.name}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              onClick={() => {
                navigate("/adddocuments");
              }}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bolder",
                padding: "10px 20px",
              }}
            >
              <PublishIcon sx={{ mr: "10px" }} /> Upload Document
            </Button>
          </FlexBetween>
        </ThemeProvider>
        <ThemeProvider theme={lightTheme}>
          <DataGrid
            rows={selectedDocuments}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            pageSizeOptions={[5, 10]}
            sx={dashboardStyle(theme, "425px")}
          />
        </ThemeProvider>
      </Box>
      <ConfirmationDialog
        open={confirmationOpen}
        handleClose={handleDeleteCancel}
        handleConfirm={handleDeleteDocument} // Call handleDeleteDocument on confirmation
        message="Are you sure you want to delete this Document"
      />
      <ToastContainer />
    </div>
  );
}

export default DocumentsAdmin;
