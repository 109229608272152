import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import {
	Box,
	Button,
	Typography,
	useTheme,
	ThemeProvider,
	createTheme,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "shared/ConfirmationDialog";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import { dashboardStyle } from "shared/utils";

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

function TimeSheetTable() {
	const theme = useTheme();
	const navigate = useNavigate();
	const [users, setUsers] = useState([]);
	// const [open, setOpen] = useState(false);
	const [employeeId, setEmployeeId] = useState("");
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState("");
	const [attendanceData, setAttendanceData] = useState([]);
	const { fetchedEmployeeId, year, month } = useParams();
	const [selectedRowIds, setSelectedRowIds] = useState([]);
	const [selectedTimesheetId, setSelectedTimesheetId] = useState(null);
	const [statusConfirmationOpen, setStatusConfirmationOpen] = useState(false);
	const [monthStatus, setMonthStatus] = useState('');
	const apiCalledRef = useRef(false);

	useEffect(() => {
		if (apiCalledRef.current) return;
		apiCalledRef.current = true;
		axios
			.get(process.env.REACT_APP_API_URL + "profilepage", {
				withCredentials: true,
			})
			.then((response) => {
				setEmployeeId(response.data.employeeId);
				console.log(response.data.employeeId);
			})
			.catch(error => {
				console.error("Error fetching user data:", error);
				if (error.response && error.response.status === 401) {
					toast.warning("Your account has been disabled. If this was a mistake contact HR. You will be logged out.", {
						onClose: () => {
							// Clear the token from cookies
							document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
							// Reload the page after a delay
							setTimeout(() => {
								window.location.reload();
							}, 3000); // 3 seconds delay
						}
					});
				}
			});
	}, []);

	const rows = attendanceData
		.map((timesheet) => ({
			id: timesheet._id,
			date: timesheet.date,
			workType: timesheet.workType,
			startTime: timesheet.startTime,
			endTime: timesheet.endTime,
			workingHours: timesheet.workingHours,
			status: timesheet.status,
			createdOn: timesheet.createdOn,
			updatedOn: timesheet.updatedOn,
		}))
		.sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date;

	const fetchTimesheets = async () => {
		const monthNumber = parseInt(month);
		await axios
			.get(
				`${process.env.REACT_APP_API_URL}attendance/api/attendance/${fetchedEmployeeId}/${year}/${monthNumber}`
			)
			.then((response) => {
				setAttendanceData(response.data.attendance);
			})
			.catch((error) => {
				console.error("Error fetching attendance data:", error);
			});
	};

	useEffect(() => {
		fetchTimesheets();
	}, [fetchedEmployeeId, year, month]);

	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	// const handleMonthChange = (event) => {
	// setSelectedMonth(event.target.value);
	// };

	const handleRowCheckboxChange = (rowId) => {
		console.log("row");
		const selectedIndex = selectedRowIds.indexOf(rowId);
		let newSelectedRowIds = [];

		if (selectedIndex === -1) {
			newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds, rowId);
		} else {
			newSelectedRowIds = selectedRowIds.filter((id) => id !== rowId);
		}

		setSelectedRowIds(newSelectedRowIds);
	};

	const handleHeaderCheckboxChange = (event) => {
		if (event.target.checked) {
			const allRowIds = rows.map((row) => row.id);
			setSelectedRowIds(allRowIds);
		} else {
			setSelectedRowIds([]);
		}
	};

	useEffect(() => {
		const fetchMonthStatus = async () => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_API_URL}attendance/attendance/monthStatus/${fetchedEmployeeId}/${year}/${month}`);
				setMonthStatus(response.data.status);
				console.log("status:" + response.data.status); // Log the fetched status
			} catch (error) {
				console.error('Error fetching month status:', error);
			}
		};

		fetchMonthStatus();
	}, [fetchedEmployeeId, year, month]);

	const columns = [
		{
			field: "checkbox",
			headerName: (
				<Checkbox
					checked={selectedRowIds.length === rows.length}
					indeterminate={
						selectedRowIds.length > 0 && selectedRowIds.length < rows.length
					}
					onChange={handleHeaderCheckboxChange}
				/>
			),
			flex: 1,
			renderCell: (params) => (
				<Checkbox
					checked={
						selectedRowIds.includes(params.row.id) ||
						params.row.status === "Approved" ||
						params.row.status === "Submitted"
					}
					onChange={() => handleRowCheckboxChange(params.row.id)}
					disabled={
						params.row.status === "Approved" ||
						params.row.status === "Submitted"
					}
				/>
			),
		},
		{
			field: "date",
			headerName: "Date",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Date
				</Typography>
			),
		},
		{
			field: "workType",
			headerName: "Work Type",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					WorkType
				</Typography>
			),
		},
		{
			field: "startTime",
			headerName: "Start Time",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Start Time
				</Typography>
			),
		},
		{
			field: "endTime",
			headerName: "End Time",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					End Time
				</Typography>
			),
		},
		{
			field: "workingHours",
			headerName: "Working Hours",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Working Hours
				</Typography>
			),
		},
		{
			field: "status",
			headerName: "Status",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Status
				</Typography>
			),
			renderCell: (params) => (
				<div
					style={{
						color:
							params.value === "Approved"
								? "#4caf50"
								: params.value === "Pending"
									? "#ff9800"
									: params.value === "Submitted"
										? "#007bff"
										: "inherit",
						background:
							params.value === "Approved"
								? "#c8e6c9"
								: params.value === "Pending"
									? "#ffe0b2"
									: params.value === "Submitted"
										? "#cce5ff"
										: "inherit",
						borderRadius: "4px",
						padding: "8px",
						display: "inline-block",
						width: "90px",
						textAlign: "center",
					}}
				>
					{params.value}
				</div>
			),
		},
		{
			field: "action",
			headerName: "Action",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Action
				</Typography>
			),
			renderCell: (params) => (
				<div>
					{typeof params.value === "object" ? (
						<span>Error</span>
					) : (
						<>
							{params.row.status === "Pending" ? ( // Condition to check status
								<>
									<Link to={`/updatetimesheet/${params.id}/${year}/${month}`}>
										<EditIcon
											style={{
												marginLeft: "5px",
												marginRight: "10px",
												color: "#04497a",
												fontSize: "25px",
											}}
										/>
									</Link>
									<DeleteIcon
										style={{
											cursor: "pointer",
											color: "#04497a",
											fontSize: "25px",
										}}
										onClick={() => handleDeleteConfirmation(params.id)}
									/>
								</>
							) : (
								<>
									<EditIcon
										style={{
											marginLeft: "5px",
											marginRight: "10px",
											color: "rgba(0,0,0,0.6)",
											fontSize: "25px",
										}}
									/>
									<DeleteIcon
										style={{
											cursor: "pointer",
											color: "rgba(0,0,0,0.6)",
											fontSize: "25px",
										}}
									/>
								</>
							)}
						</>
					)}
				</div>
			),
		},
	];

	const lightTheme = createTheme({
		palette: {
			primary: indigo,
		},
	});

	// const years = Array.from({ length: 11 }, (_, index) => 2024 + index);

	//Submiting timesheet (Changing timesheet status to "Submitted")
	const handleSubmitStatus = async () => {
		try {
			// Assuming selectedRowIds contain the IDs of the timesheets you want to submit
			for (const timesheetId of selectedRowIds) {
				await axios.put(
					`${process.env.REACT_APP_API_URL}attendance/api/attendance/updateStatus/${timesheetId}`,
					{ status: "Submitted", employeeId: fetchedEmployeeId, year, month }
				);
			}
			setStatusConfirmationOpen(false);
			toast.success("Timesheet(s) submitted successfully");
			fetchTimesheets();
			// Optionally, you can refresh the data after submission
			// For example, you can refetch the attendance data using useEffect
		} catch (error) {
			console.error("Error submitting timesheet:", error);
			toast.error("Failed to submit timesheet(s)");
		}
	};

	const handleDeleteConfirmation = (id) => {
		setSelectedTimesheetId(id);
		setConfirmationOpen(true);
	};

	const handleDeleteCancel = () => {
		setConfirmationOpen(false);
	};

	const handleDeleteConfirm = () => {
		handleDelete(selectedTimesheetId);
		setConfirmationOpen(false);
	};
	// const handleStatusConfirmation = (id) => {
	// setConfirmationOpen(true);
	// };

	const handleDelete = (id) => {
		axios
			.delete(
				process.env.REACT_APP_API_URL + "attendance/deleteTimesheet/" + id
			)
			.then((result) => {
				toast.success("Time sheet deleted successfully");
				console.log(result);
				setUsers((prevUsers) => prevUsers.filter((user) => user._id !== id));
				setAttendanceData((prevData) =>
					prevData.filter((timesheet) => timesheet._id !== id)
				);
			})
			.catch((err) => {
				console.error("Error deleting timesheet:", err);
				toast.error("Failed to delete timesheet");
			});
	};

	return (
		<div>
			<div>
				<FlexBetween sx={{ position: "fixed" }}>
					<IconButton
						onClick={() => navigate(-1)}
						style={{ color: "#04497a", marginTop: "0px", marginLeft: "10px" }}
					>
						<ArrowBackIcon />
					</IconButton>
				</FlexBetween>
			</div>

			<Box m="1.5rem 2.5rem" height="100%">
				<FlexBetween sx={{ marginBottom: "1rem" }}>
					<Header
						title={`${year} ${months[parseInt(month, 10) - 1]} Timesheet`}
					/>
					<FlexBetween sx={{ gap: "0.5rem" }}>
						<ThemeProvider theme={lightTheme}>
							<Box>
								{/* Employee Button */}
								<Button
									variant="contained"
									onClick={() => {
										navigate(`/addtimesheet/${year}/${month}`);
									}}
									sx={{
										backgroundColor: theme.palette.secondary.light,
										color: theme.palette.background.alt,
										fontSize: "14px",
										fontWeight: "bolder",
										padding: "10px 20px",
									}}
									disabled={monthStatus !== "Pending"}
								>
									Insert Timesheet
								</Button>
							</Box>

							<Box>
								{/* Employee Button */}
								<Button
									variant="contained"
									onClick={() => setStatusConfirmationOpen(true)}
									sx={{
										backgroundColor: theme.palette.secondary.light,
										color: theme.palette.background.alt,
										fontSize: "14px",
										fontWeight: "bolder",
										padding: "10px 20px",
									}}
									disabled={selectedRowIds.length === 0}
								>
									Submit
								</Button>
							</Box>
						</ThemeProvider>
					</FlexBetween>
				</FlexBetween>

				<ThemeProvider theme={lightTheme}>
					{/* {attendanceData.length ? ( */}
					<DataGrid
						rows={rows}
						columns={columns}
						pageSize={31}
						rowsPerPageOptions={[5, 10, 20]}
						pageSizeOptions={[5, 10]}
						rowHeight={40}
						headerHeight={50}
						sx={{
							color: theme.palette.background.text,
							// color: "theme.palette.background.text", // remove this line
							backgroundColor: theme.palette.primary.main,
							borderRadius: "15px",
							boxShadow: "13px 22px 19px 0px rgba(0,0,0,0.2)",
							border: "1px solid #DBE2EF",
							fontWeight: "light",
							fontSize: "13px",
							'& .MuiTablePagination-root': {
								color: theme.palette.background.text,
							},
							height: "470px",
						}}
					/>
					{/* ) : (
						<p>Loading...</p>
					)} */}
				</ThemeProvider>
				<ToastContainer />
				<ConfirmationDialog
					open={confirmationOpen}
					handleClose={handleDeleteCancel}
					handleConfirm={handleDeleteConfirm}
					message="Are you sure you want to delete this time sheet?"
				/>
				<ConfirmationDialog
					open={statusConfirmationOpen} // Pass state to control dialog visibility
					handleClose={() => setStatusConfirmationOpen(false)} // Function to close the dialog
					handleConfirm={handleSubmitStatus} // Function to handle confirmation action (approve)
					message="Are you sure you want to submit the timesheets?" // Confirmation message
				/>
			</Box>
		</div>
	);
}

export default TimeSheetTable;
