import React, { useEffect, useState, useRef } from "react";
import "./updateTimesheet.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import FlexBetween from "shared/FlexBetween";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ConfirmationDialog from "shared/ConfirmationDialog";

function UpdateTimesheet() {
	const navigate = useNavigate();
	const [date, setDate] = useState("");
	const [workType, setWorkType] = useState("");
	const [startTime, setStartTime] = useState("");
	const [endTime, setEndTime] = useState("");
	const [loggedInUserEmployeeId, setLoggedInUserEmployeeId] = useState(null);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const apiCalledRef = useRef(false);

	const { id, month, year } = useParams();

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}attendance/attendances/${year}/${month}/${id}`, {
				withCredentials: true,
			})
			.then((response) => {
				setDate(response.data.attendance.date);
				setWorkType(response.data.attendance.workType);
				setStartTime(response.data.attendance.startTime);
				setEndTime(response.data.attendance.endTime);
			})
			.catch((error) => {
				console.error("Error fetching user data:", error);
			});
	}, []);

	useEffect(() => {
		if (apiCalledRef.current) return;
		apiCalledRef.current = true;
		axios
			.get(process.env.REACT_APP_API_URL + "profilepage", {
				withCredentials: true,
			})
			.then((response) => {
				setLoggedInUserEmployeeId(response.data.employeeId);
			})
			.catch((error) => {
				console.error("Error fetching user data:", error);
				if (error.response && error.response.status === 401) {
					toast.warning("Your account has been disabled. If you think this is an error contact HR. You will be logged out soon.", {
						onClose: () => {
							// Clear the token from cookies
							document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
							// Reload the page after a delay
							setTimeout(() => {
								window.location.reload();
							}, 3000); // 5 seconds delay
						}
					});
				}
			});
	}, [loggedInUserEmployeeId]);

	const handleSubmit = (e) => {

		// Parse the start time and end time strings into Date objects
		const startTimeDate = new Date(`1970-01-01T${startTime}`);
		const endTimeDate = new Date(`1970-01-01T${endTime}`);

		// Calculate the difference in milliseconds
		const timeDifference = endTimeDate - startTimeDate;

		// Convert time difference to hours
		const totalWorkingHours = timeDifference / (1000 * 60 * 60); // Calculate total working hours
		const hours = Math.floor(totalWorkingHours); // Extract whole hours
		const minutes = Math.round((totalWorkingHours % 1) * 60); // Extract remaining minutes and round them

		// Format the result as hours and minutes
		const formattedTotalWorkingHours = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;

		axios
			.put(`${process.env.REACT_APP_API_URL}attendance/updateAttendances/${year}/${month}/${id}`, {
				date,
				workType,
				startTime,
				endTime,
				workingHours: formattedTotalWorkingHours,
				status: "Pending",
			})
			.then((response) => {
				console.log(response.data);
				navigate(`/timesheettable/${loggedInUserEmployeeId}/${year}/${month}`); // Redirect to the attendance table
			})
			.catch((error) => {
				console.error("Error updating timesheet:", error);
			});
	};


	return (
		<div>
			<div >

				<FlexBetween sx={{ position: "fixed" }}>
					<IconButton
						onClick={() => navigate(-1)}
						style={{ color: "#04497a", marginTop: "10px", marginLeft: "10px" }}
					>
						<ArrowBackIcon />
					</IconButton>
				</FlexBetween>
			</div>

			<div className="formbold-main-wrapper" style={{ color: "black" }}>
				<div className="formbold-form-wrapper">
					<form >
						<div className="formbold-form-title">
							<h3 className="header">Update Time Sheet</h3>
							{/* <h2>Date: {date}</h2> */}
						</div>
						{/* <div>
						<label htmlFor="phone" className="formbold-form-label">
							Employee ID
						</label>
						<input
							type="number"
							name="id"
							id="id"
							className="formbold-form-input"
							onChange={(e) => setEmployeeId(e.target.value)}
						/>
					</div> */}

						<div className="formbold-mb-5 w-full">
							<label htmlFor="date" className="formbold-form-label">
								Date
							</label>
							<input
								type="date"
								name="date"
								id="date"
								className="formbold-form-input"
								value={date}
								onChange={(e) => setDate(e.target.value)}
							/>
						</div>

						<div className="formbold-mb-3">
							<label className="formbold-form-label">Work Type</label>

							<select
								className="formbold-form-input"
								name="worktype"
								id="worktype"
								value={workType}
								onChange={(e) => setWorkType(e.target.value)}
							>
								<option selected>Choose...</option>
								<option value="Work from office">Work from office</option>
								<option value="Work from Home">Work from Home</option>
								<option value="Privilege Leave">Privilege Leave</option>
								<option value="Sick Leave">Sick Leave</option>
								<option value="Declared Holiday">Declared Holiday</option>
							</select>
						</div>

						<div className="formbold-input-flex">
							<div className="formbold-mb-3">
								<label htmlFor="address" className="formbold-form-label">
									Start Time
								</label>
								<input
									type="time"
									name="start-time"
									id="start-time"
									className="formbold-form-input"
									value={startTime}
									onChange={(e) => setStartTime(e.target.value)}
								/>
								<span className="note">(24 hour format)</span>
							</div>
							<div className="formbold-mb-3">
								<label htmlFor="address" className="formbold-form-label">
									End Time
								</label>
								<input
									type="time"
									name="end-time"
									id="end-time"
									value={endTime}
									className="formbold-form-input"
									disabled={
										workType === "Sick Leave" ||
										workType === "Privilege Leave" ||
										workType === "Declared Holiday"
									}
									onChange={(e) => setEndTime(e.target.value)}
								/>
								<span className="note">(24 hour format)</span>
							</div>
						</div>
						<button type="button" onClick={() => setConfirmationOpen(true)} className="formbold-btn">
							Update Time Sheet
						</button>
					</form>
				</div>
			</div>
			<ConfirmationDialog
				open={confirmationOpen}
				handleClose={() => setConfirmationOpen(false)}
				handleConfirm={handleSubmit}
				message="Are you sure you want to update this timesheet?"
			/>
		</div>
	);
}

export default UpdateTimesheet;
