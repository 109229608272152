import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import FlexBetween from "shared/FlexBetween";
import { Box } from "@mui/material";
import Header from "shared/Header";
import { indigo } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + 'logs/api/logs');
        setLogs(response.data);
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    };

    fetchLogs();
  }, []);


  const rows = logs.map((log, index) => ({
    id: log._id, // or another unique identifier
    srno: index + 1,
    timestamp: log.timestamp,
    level: log.level,
    message: log.message,

  }));

  const columns = [
    { field: 'srno', headerName: 'Sr. No.', width: 50 },
    { field: 'timestamp', headerName: 'Timestamp', width: 200 },
    { field: 'level', headerName: 'Level', width: 100 },
    { field: 'message', headerName: 'Message', flex: 1 },
  ];


  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween m="1 rem">

        <Header title="View Logs" />
      </FlexBetween>
      <div style={{ height: 400, width: '100%' }}>
        <ThemeProvider theme={lightTheme}>
          <DataGrid
            rows={rows}
            columns={columns}
            pagination
            pageSize={pageSize}
            rowCount={rowCount}
            paginationMode="server"
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50]}
            sx={{
              height: '80vh',
            }}
            // getRowId={getRowId}
            onPageChange={(page) => fetchLogs(page * pageSize, (page + 1) * pageSize)}
          />
        </ThemeProvider>
      </div>
    </Box>
  );
};

export default Logs;