// color design tokens export
export const tokensDark = {
  grey: {
    // Shades of grey
    0: "#ffffff", // manually adjusted - White
    10: "#f6f6f6", // manually adjusted - Light Grey
    // 50: "#f0f0f0", // manually adjusted - Light Grey //Previous Background color
    50: "#f0f2f5", // manually adjusted - Light Grey //Current Background color
    100: "#e0e0e0", // Grey
    200: "#c2c2c2", // Dark Grey
    300: "#a3a3a3", // Darker Grey
    400: "#858585", // Even Darker Grey
    500: "#666666", // Quite Dark Grey
    600: "#525252", // Dark Grey (Background)
    700: "#3d3d3d", // Dark Grey
    800: "#292929", // Dark Grey
    900: "#141414", // Dark Grey
    1000: "#000000", // manually adjusted - Black
  },
  primary: {
    100: "#d3d4de", // Light Blue
    200: "#a6a9be", // Medium Light Blue
    300: "#7a7f9d", // Medium Blue
    400: "#4d547d", // selected text color - Dark Blue
    500: "#21295c", // Dashboard color (Dark Mode) - Very Dark Blue
    600: "#191F45", // manually adjusted - #161617 background color (Dark mode) - Very Dark Blue
    700: "#141937", // Darker Blue
    800: "#0d1025", // Even Darker Blue
    900: "#070812", // Very Dark Blue
  },
  secondary: {
    50: "#124076", // manually adjusted - Dark Yellow
    100: "#fff6e0", // Very Light Yellow
    200: "#ffedc2", // Light Yellow
    300: "#ffe3a3", // Selected tab color (Dark mode) - Medium Yellow
    400: "#ffda85", // Medium Yellow
    500: "#ffd166", // Dark Yellow
    600: "#8cc63b", // ivtree greeen
    700: "#04497a", // ivtree blue
    800: "#04497a", // ivtree blue
    900: "#332a14", // Very Very Dark Yellow
  },
};

// function that reverses the color palette
function reverseTokens(tokensDark) {
  const reversedTokens = {};
  Object.entries(tokensDark).forEach(([key, val]) => {
    const keys = Object.keys(val);
    const values = Object.values(val);
    const length = keys.length;
    const reversedObj = {};
    for (let i = 0; i < length; i++) {
      reversedObj[keys[i]] = values[length - i - 1];
    }
    reversedTokens[key] = reversedObj;
  });
  return reversedTokens;
}
export const tokensLight = reverseTokens(tokensDark);

// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "light"
        ? {
          // palette values for dark mode
          primary: {
            ...tokensDark.primary,
            main: tokensDark.primary[400], //Selected text color
            light: tokensDark.primary[400],
          },
          secondary: {
            ...tokensDark.secondary,
            main: tokensDark.secondary[300], //selected tab
          },
          neutral: {
            ...tokensDark.grey,
            main: tokensDark.grey[500],
          },
          background: {
            default: tokensDark.primary[600],
            alt: tokensDark.primary[500],
            text: tokensDark.grey[0]
          },
        }
        : {
          // palette values for light mode
          primary: {
            ...tokensLight.primary,
            main: tokensDark.grey[0],
            light: tokensDark.grey[100],
          },
          secondary: {
            ...tokensLight.secondary,
            main: tokensDark.secondary[600],
            light: tokensDark.secondary[700],
          },
          neutral: {
            ...tokensLight.grey,
            main: tokensDark.grey[500],
          },
          background: {
            default: tokensDark.grey[50],
            alt: tokensDark.grey[0],
            text: tokensDark.grey[1000]
          },
        }),
    },
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};
