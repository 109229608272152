import React, { useState, useEffect } from "react";
import FlexBetween from "shared/FlexBetween";
import { Box, useTheme, Button, Typography, TextField } from "@mui/material";
import Header from "shared/Header";
import { DataGrid } from "@mui/x-data-grid";
import { indigo } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import { dashboardStyle } from "shared/utils";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import ConfirmationDialog from "shared/ConfirmationDialog";
import { getCalendarPdfUrl } from "shared/utils";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function CreateHolidayCalendar() {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const [holidays, setHolidays] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [holidayName, setHolidayName] = useState("");
  const [uploadConfirmationOpen, setUploadConfirmationOpen] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState({});
  const [year, setYear] = useState();
  const [calendarName, setCalendarName] = useState();
  const [existingHolidays, setExistingHolidays] = useState([]);

  useEffect(() => {
    if (id) {
      fetchSingleCalendar();
    }
  }, [id]);

  const fetchSingleCalendar = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}holidayCalendars/fetchCalendar/${id}`
      );
      setSelectedCalendar(response.data);
      setCalendarName(response.data.calendarName);
      setYear(response.data.year);

      // Map the holidays to include id property
      const formattedHolidays = response.data.holidays.map(
        (holiday, index) => ({
          ...holiday,
          id: holiday._id, // Map MongoDB _id to id for DataGrid
          index: index + 1,
        })
      );

      setExistingHolidays(formattedHolidays);
      setHolidays(formattedHolidays);
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    }
  };

  // Modify handleAddHoliday to check for duplicate dates
  const handleAddHoliday = () => {
    if (!selectedDate || !holidayName.trim()) {
      toast.error("Please select both date and holiday name");
      return;
    }

    // Check for duplicate date
    if (holidays.some((h) => h.date === selectedDate)) {
      toast.error("A holiday already exists for this date");
      return;
    }

    const newHoliday = {
      id: Date.now(),
      date: selectedDate,
      holiday: holidayName.trim(),
    };

    setHolidays((prevHolidays) => {
      const updatedHolidays = [...prevHolidays, newHoliday];
      return updatedHolidays.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
    });

    setSelectedDate("");
    setHolidayName("");
    toast.success("Holiday added successfully");
  };

  // Add an input row to the existing holidays
  const allRows = [
    {
      id: "input-row",
      date: "",
      holiday: "",
      isInputRow: true,
    },
    ...holidays,
  ];

  const columns = [
    {
      field: "index",
      headerName: "Sr no.",
      flex: 0.3,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          #
        </Typography>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Date
        </Typography>
      ),
      renderCell: (params) => {
        if (params.row.isInputRow) {
          return (
            <ThemeProvider theme={lightTheme}>
              <TextField
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                size="medium"
                fullWidth
                sx={{
                  backgroundColor: "white",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                  },
                }}
              />
            </ThemeProvider>
          );
        }
        return params.row.date;
      },
    },
    // Modify the TextField in the "holiday" column's renderCell
    {
      field: "holiday",
      headerName: "Holiday",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Holiday
        </Typography>
      ),
      renderCell: (params) => {
        if (params.row.isInputRow) {
          return (
            <ThemeProvider theme={lightTheme}>
              <TextField
                value={holidayName}
                onChange={(e) => setHolidayName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.stopPropagation(); // Prevent default grid behavior
                  }
                }}
                size="medium"
                placeholder="Enter holiday name"
                fullWidth
                sx={{
                  backgroundColor: "white",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                  },
                }}
              />
            </ThemeProvider>
          );
        }
        return params.row.holiday;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Actions
        </Typography>
      ),
      renderCell: (params) => {
        if (params.row.isInputRow) {
          return (
            <ThemeProvider theme={lightTheme}>
              <IconButton
                variant="contained"
                onClick={handleAddHoliday}
                sx={{ color: "#04497a", marginLeft: "10px" }}
              >
                <AddIcon />
              </IconButton>
            </ThemeProvider>
          );
        }
        return (
          <IconButton
            variant="outlined"
            size="small"
            onClick={() => {
              setHolidays((prevHolidays) =>
                prevHolidays.filter((h) => h.id !== params.row.id)
              );
              toast.success("Holiday deleted successfully");
            }}
            sx={{ color: "#04497a", marginLeft: "10px" }}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const handleUploadCancel = () => {
    setUploadConfirmationOpen(false); // Close the confirmation dialog
  };

  // Modify handleCreateHoliday to only send new holidays
  const handleCreateHoliday = async () => {
    try {
      // Send the entire current holidays array instead of filtering for new ones
      const holidaysToSend = holidays.map(({ id, index, ...rest }) => rest);
  
      const response = await axios.put( // Changed to PUT request
        `${process.env.REACT_APP_API_URL}holidayCalendars/updateHolidayCalendar/${year}/${calendarName}`,
        holidaysToSend
      );
      
      if (response.status === 200) {
        toast.success("Calendar updated successfully");
        setUploadConfirmationOpen(false);
        fetchSingleCalendar();
      }
    } catch (error) {
      toast.error("Failed to update holidays");
    }
  };

  // const handleCreateHoliday = async () => {
  //   try {
  //     // Filter out existing holidays and prepare new ones for backend
  //     const newHolidays = holidays
  //       .filter(holiday => !existingHolidays.some(eh => eh.id === holiday.id))
  //       .map(({ id, ...rest }) => rest); // Remove the id property before sending

  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}holidayCalendars/createHolidayCalendar/${year}/${calendarName}`,
  //       newHolidays
  //     );
  //     if (response.status === 200) {
  //       toast.success("Holidays added successfully");
  //       fetchSingleCalendar(); // Refresh the data
  //     }
  //   } catch (error) {
  //     toast.error("Failed to add holidays");
  //   }
  // };

  const handleUploadConfirmationOpen = () => {
    setUploadConfirmationOpen(true);
  };

  return (
    <Box m="1.5rem 2.5rem" height="calc(100vh - 275px)">
        <FlexBetween sx={{ marginBottom: "0.5rem", justifyContent: "start" }}>
        <IconButton
          onClick={() => navigate(-1)}
          style={{ color: "#04497a" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Header title={selectedCalendar.calendarName} sx={{ alignSelf: "flex-start" }} />
        <Box sx={{ marginLeft: 'auto' }}> 
          <Button
            variant="contained"
            onClick={handleUploadConfirmationOpen}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bolder",
              padding: "10px 20px",
              alignSelf: "flex-end",
            }}
          >
            Save
          </Button>
        </Box>
      </FlexBetween>

      <ThemeProvider theme={lightTheme}>
        <DataGrid
          rows={allRows}
          columns={columns}
          pageSize={20}
          // rowsPerPageOptions={[5, 10, 20]}
          pageSizeOptions={[5, 10]}
          sx={dashboardStyle(theme, "480px")}
          disableSelectionOnClick
        />
      </ThemeProvider>
      <ConfirmationDialog
        open={uploadConfirmationOpen}
        handleClose={handleUploadCancel}
        handleConfirm={handleCreateHoliday}
        message="Are you sure you want to create this holiday calendar?"
      />
      <ToastContainer />
    </Box>
  );
}

export default CreateHolidayCalendar;
