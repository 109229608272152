import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import { Box, Button, useTheme, Modal, Typography } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import ConfirmationDialog from "shared/ConfirmationDialog";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { dashboardStyle } from "shared/utils";

function CreateGroups() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  // const [selectedUserId, setSelectedUserId] = useState(null);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [openUpload, setOpenUpload] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [empRole, setEmpRole] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [selectedGroupData, setSelectedGroupData] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editGroupData, setEditGroupData] = useState([]);
  const [editGroupName, setEditGroupName] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [updateOpenConfirmation, setUpdateOpenConfirmation] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [deletetionId, setDeletetionId] = useState("");

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL)
      .then((response) => {
        setUsers(response.data)
        setEmployees(response.data); // Set users to the array of users
        setEmpRole(response.data.role);
        setEmployeeId(response.data.employeeId);
      })
      .catch((error) => {
        console.error("Error fetching user emails:", error);
      });
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(process.env.REACT_APP_API_URL) // Update this endpoint to the correct one
  //     .then((result) => )
  //     .catch((err) => console.log(err));
  // }, []);

  const fetchGroups = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "groups/getgroups")
      .then((result) => {
        setGroups(result.data);
        console.log(groups);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const handleDelete = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}groups/deleteGroup/${deletetionId}`)
      .then((result) => {
        console.log(result.data.message);
        toast.success("Group deleted successfully");
        setOpenDeleteConfirmation(false);
        // Optionally, you can display a success message or update the state to remove the deleted group
        fetchGroups(); // Refresh the group list
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to delete group");
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const groupMembers = selectedEmployee.map((id) => {
        const employee = employees.find((emp) => emp.employeeId === id);
        return {
          employeeId: employee.employeeId,
          name: employee.name,
          email: employee.email,
          role: employee.role,
        };
      });

      const response = await axios.post(
        process.env.REACT_APP_API_URL + "groups/creategroups",
        {
          groupname: groupName,
          description: description,
          groupMembers: groupMembers, // Send the array of selectedEmployee IDs to the backend
        }
      );

      console.log(response.data);
      toast.success("Group created successfully");
      setOpenConfirmation(false);
      // Optionally, you can reset the form fields here
      fetchGroups();
      setGroupName("");
      setDescription("");
      setOpenUpload(false);
      setSelectedEmployee([]);
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === "Group name already exists"
      ) {
        toast.error("Group name already exists");
      } else {
        toast.error("Error creating group");
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const groupMembers = selectedEmployee.map((id) => {
        const employee = employees.find((emp) => emp.employeeId === id);
        return {
          employeeId: employee.employeeId,
          name: employee.name,
          email: employee.email,
          role: employee.role,
        };
      });

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}groups/updateGroup/${groupId}`,
        {
          groupname: editGroupName,
          description: editDescription,
          groupMembers: groupMembers,
        }
      );

      console.log(response.data);
      toast.success("Group updated successfully");
      setUpdateOpenConfirmation(false);
      setOpenEdit(false);
      fetchGroups();
    } catch (error) {
      console.error("Error updating group:", error);
      toast.error("Error updating group");
    }
  };

  const fetchGroupData = async (id) => {
    console.log(id);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}groups/fetchGroups/${id}`
      );
      const groupData = response.data;
      setEditGroupName(groupData.groupname);
      setEditDescription(groupData.description);
      const employeeIds = groupData.members.map((member) => member.employeeId);
      setSelectedEmployee(employeeIds);
      setGroupId(id);
      console.log("groupid: " + groupId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (openEdit && groupId) {
      fetchGroupData(groupId);
    }
  }, [openEdit, groupId]);

  const handleEditGroup = (id) => {
    setGroupId(id);
    setOpenEdit(true);
  };



  const rows = groups.map((group, index) => ({
    id: group._id,
    srno: index + 1, // Use index as a unique identifier
    groupname: group.groupname,
    description: group.description,
    groupMembers: group.members,
    createdOn: group.createdOn,
    updatedOn: group.updatedOn,
    // Add other properties as needed
  }));

  const columns = [
    {
      field: "srno",
      headerName: "Sr no",
      flex: 0.5,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Sr no.
        </Typography>
      ),
    },
    {
      field: "groupname",
      headerName: "Group Name",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Group Name
        </Typography>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Description
        </Typography>
      ),
    },
    {
      field: "members",
      headerName: "View Members",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          View Members
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => navigate(`/managegroupmembers/${params.row.id}`)}
            sx={{ color: "#04497a" }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: "createdOn",
      headerName: "Created On",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Created On
        </Typography>
      ),
    },
    {
      field: "updatedOn",
      headerName: "Updated On",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Updated On
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Action
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton>
            <EditIcon
              style={{
                color: "#04497a",
                fontSize: "25px",
              }}
              onClick={() => handleEditGroup(params.row.id)}
            />
          </IconButton>
          <IconButton
            style={{ cursor: "pointer", color: "#04497a", fontSize: "25px" }}
            onClick={() => {
              handleOpenDeleteConfirmation(params.row.id);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
    setOpenEdit(false);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleOpenUpdateConfirmation = () => {
    setUpdateOpenConfirmation(true);
  };

  const handleOpenDeleteConfirmation = (id) => {
    setOpenDeleteConfirmation(true);
    setDeletetionId(id);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setUpdateOpenConfirmation(false);
    setOpenDeleteConfirmation(false);
  };

  // const handleConfirmCreateGroup = () => {
  //   // Add your create group logic here...
  //   // Close the confirmation dialog
  // };

  return (
    <div>
      <Modal
        open={openUpload}
        onClose={handleCloseUpload}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#e0e0e0",
            boxShadow: 24,
            p: 4,
            width: "80%", // Adjust the width here as per your requirement
            maxWidth: "500px", // Set maximum width if needed
            outline: "none",
            borderRadius: "10px",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseUpload}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "crimson",
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="upload-section">
            <form>
              <div className="formbold-mb-3">
                <div>
                  <label
                    htmlFor="firstname"
                    style={{ color: "black" }}
                    className="formbold-form-label"
                  >
                    Group name
                  </label>
                  <input
                    type="text"
                    name="groupName"
                    id="groupName"
                    className="formbold-form-input"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="message"
                    style={{ color: "black" }}
                    className="formbold-form-label"
                  >
                    {" "}
                    Description{" "}
                  </label>
                  <textarea
                    rows="6"
                    name="message"
                    id="message"
                    placeholder="Group Description"
                    className="formbold-form-input"
                    value={editGroupData.description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>

                <ThemeProvider theme={lightTheme}>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Select Employee
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={selectedEmployee.map(
                        (id) =>
                          employees.find((emp) => emp.employeeId === id)
                            ?.name || ""
                      )} // Display employee name
                      onChange={(e) => {
                        const selectedNames = e.target.value;
                        const selectedIds = selectedNames.map(
                          (name) =>
                            employees.find((emp) => emp.name === name)
                              ?.employeeId || ""
                        );
                        setSelectedEmployee(selectedIds);
                      }}
                      input={<OutlinedInput label="Select Employee" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {employees
                        .filter((employee) => {
                          // Filter employees based on their roles
                          return (
                            employee.role === "admin" ||
                            employee.role === "hr" ||
                            employee.role === "manager" ||
                            employee.role === "superadmin"
                          );
                        })
                        .map((employee) => (
                          <MenuItem
                            key={employee.employeeId}
                            value={employee.name}
                          >
                            <Checkbox
                              checked={selectedEmployee.includes(
                                employee.employeeId,
                                employee.name
                              )}
                            />
                            <ListItemText primary={employee.name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </ThemeProvider>
              </div>
              <Button
                variant="contained"
                type="button"
                onClick={handleOpenConfirmation}
                sx={{
                  backgroundColor: theme.palette.secondary.light,
                  color: theme.palette.background.alt,
                  fontSize: "14px",
                  fontWeight: "bolder",
                  padding: "10px 20px",
                  margin: "15px 0px",
                }}
              >
                Create Group
              </Button>
            </form>
          </div>
        </Box>
      </Modal>

      {/* Update Modal */}
      <Modal
        open={openEdit}
        onClose={handleCloseUpload}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#e0e0e0",
            boxShadow: 24,
            p: 4,
            width: "80%", // Adjust the width here as per your requirement
            maxWidth: "500px", // Set maximum width if needed
            outline: "none",
            borderRadius: "10px",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseUpload}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "crimson",
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="upload-section">
            <form>
              <div className="formbold-mb-3">
                <div>
                  <label
                    htmlFor="firstname"
                    style={{ color: "black" }}
                    className="formbold-form-label"
                  >
                    Group name
                  </label>
                  <input
                    type="text"
                    name="groupName"
                    id="groupName"
                    className="formbold-form-input"
                    value={editGroupName}
                    onChange={(e) => setEditGroupName(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="message"
                    style={{ color: "black" }}
                    className="formbold-form-label"
                  >
                    {" "}
                    Description{" "}
                  </label>
                  <textarea
                    rows="6"
                    name="message"
                    id="message"
                    placeholder="Group Description"
                    className="formbold-form-input"
                    value={editDescription}
                    onChange={(e) => setEditDescription(e.target.value)}
                  ></textarea>
                </div>

                <ThemeProvider theme={lightTheme}>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Select Employee
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={selectedEmployee.map(
                        (id) =>
                          employees.find((emp) => emp.employeeId === id)
                            ?.name || ""
                      )} // Display employee name
                      onChange={(e) => {
                        const selectedNames = e.target.value;
                        const selectedIds = selectedNames.map(
                          (name) =>
                            employees.find((emp) => emp.name === name)
                              ?.employeeId || ""
                        );
                        setSelectedEmployee(selectedIds);
                      }}
                      input={<OutlinedInput label="Select Employee" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {employees
                        .filter((employee) => {
                          // Filter employees based on their roles
                          return (
                            employee.role === "admin" ||
                            employee.role === "hr" ||
                            employee.role === "manager"
                          );
                        })
                        .map((employee) => (
                          <MenuItem
                            key={employee.employeeId}
                            value={employee.name}
                          >
                            <Checkbox
                              checked={selectedEmployee.includes(
                                employee.employeeId,
                                employee.name
                              )}
                            />
                            <ListItemText primary={employee.name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </ThemeProvider>
              </div>
              <Button
                variant="contained"
                type="button"
                onClick={handleOpenUpdateConfirmation}
                sx={{
                  backgroundColor: theme.palette.secondary.light,
                  color: theme.palette.background.alt,
                  fontSize: "14px",
                  fontWeight: "bolder",
                  padding: "10px 20px",
                  margin: "15px 0px",
                }}
              >
                Update Group
              </Button>
            </form>
          </div>
        </Box>
      </Modal>

      <FlexBetween sx={{ marginBottom: "5px" }}>
        <Header title="Manage Group" />
        <Box>
          <Button
            variant="contained"
            onClick={() => handleOpenUpload()}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bolder",
              padding: "10px 20px",
            }}
          >
            <GroupAddOutlinedIcon sx={{ mr: "10px" }} />
            Create Group
          </Button>
        </Box>
      </FlexBetween>

      <ThemeProvider theme={lightTheme}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          pageSizeOptions={[5, 10]}
          sx={dashboardStyle(theme, "425px")}
        />
      </ThemeProvider>

      <ToastContainer />
      <ConfirmationDialog
        open={openConfirmation}
        handleClose={handleCloseConfirmation}
        handleConfirm={handleSubmit}
        message="Are you sure you want to create this group?"
      />

      <ConfirmationDialog
        open={updateOpenConfirmation}
        handleClose={handleCloseConfirmation}
        handleConfirm={handleUpdate}
        message="Are you sure you want to update this group?"
      />

      <ConfirmationDialog
        open={openDeleteConfirmation}
        handleClose={handleCloseConfirmation}
        handleConfirm={handleDelete}
        message="Are you sure you want to delete this group?"
      />
    </div>
  );
}

export default CreateGroups;
