import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import Header from "shared/Header";
import {
  Box,
  Typography,
  IconButton,
  createTheme,
  ThemeProvider,
  Modal,
  Button,
  useTheme,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FlexBetween from "shared/FlexBetween";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationDialog from "shared/ConfirmationDialog";
import DownloadIcon from "@mui/icons-material/Download";
import { getCalendarPdfUrl, dashboardStyle } from "shared/utils";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Spinner from "shared/spinner/Spinner";
import useAWSDetails from "shared/useAWSDetails";

const monthsOfYear = [
  { id: 1, month: "January" },
  { id: 2, month: "February" },
  { id: 3, month: "March" },
  { id: 4, month: "April" },
  { id: 5, month: "May" },
  { id: 6, month: "June" },
  { id: 7, month: "July" },
  { id: 8, month: "August" },
  { id: 9, month: "September" },
  { id: 10, month: "October" },
  { id: 11, month: "November" },
  { id: 12, month: "December" },
];

// const allMonths = [
//   { id: 1, month: "January" },
//   { id: 2, month: "February" },
//   { id: 3, month: "March" },
//   { id: 4, month: "April" },
//   { id: 5, month: "May" },
//   { id: 6, month: "June" },
//   { id: 7, month: "July" },
//   { id: 8, month: "August" },
//   { id: 9, month: "September" },
//   { id: 10, month: "October" },
//   { id: 11, month: "November" },
//   { id: 12, month: "December" },
// ];

function UploadAttendanceTracker() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState(2024);
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(" ");
  const [employeeId, setEmployeeId] = useState(""); // Main employee ID
  const [selectedEmail, setSelectedEmail] = useState("");
  const [name, setName] = useState("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null); // Employee ID for Admin page
  const [loggedInUser, setLoggedInUser] = useState({});
  const [loggedInUserEmployeeId, setLoggedInUserEmployeeId] = useState(null); // Employee ID of logged in user
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  // const [months, setMonths] = useState([]);
  const [timesheetFile, setTimesheetFile] = useState(null);
  const [attendanceData, setAttendanceData] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [monthId, setMonthId] = useState(null);
  const [downloadTimesheetOpen, setDownloadTimesheetOpen] = useState(false);
  const currentYear = new Date().getFullYear();
  const [changeYear, setChangeYear] = useState(currentYear);
  const [fileUrl, setFileUrl] = useState("");
  const [userName, setUserName] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [attendanceTrackers, setAttendanceTrackers] = useState([]);
  const [uploadYear, setUploadYear] = useState(currentYear); // Add this line
  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [note, setNote] = useState("");
  const [employeeData, setEmployeeData] = useState([]);
  const allowedRoles = ["superadmin", "admin", "hr", "manager"];
  // const [fetchedBucketName, setFetchedBucketName] = useState("");
  // const [fetchedRegion, setFetchedRegion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({ year: null, month: null, attendanceTrackerId: null });
  const { fetchedBucketName, fetchedRegion} = useAWSDetails();
  const [uploadedBy, setUploadedBy] = useState("");

  // async function fetchAWSDetails() {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}getAWSDetails`
  //     );
  //     const data = response.data;
  //     const { bucketName, region } = data;

  //     setFetchedBucketName(bucketName);
  //     setFetchedRegion(region);
  //     // Now you can use the bucketName and region in your frontend code
  //     console.log("Bucket Name:", bucketName);
  //     console.log("Region:", region);

  //     // Example usage
  //     // initializeS3Client(bucketName, region);
  //   } catch (error) {
  //     console.error("Error fetching AWS details:", error.message);
  //   }
  // }

  // useEffect(() => {
  //   fetchAWSDetails();
  // }, []);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      })
      .then((response) => {
        setLoggedInUser(response.data);
        setLoggedInUserEmployeeId(response.data.employeeId);
        setUserName(response.data.name);
        setUploadedBy(response.data.name);
        console.log("empId:" + loggedInUserEmployeeId);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  const fetchAttendanceTrackers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}attendance/attendance-trackers/${changeYear}?fields=months.month,months.attendanceTrackers`
      );
      setAttendanceTrackers(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching attendance trackers:", error);
    }
  };

  useEffect(() => {
    console.log("attendanceData: ", attendanceTrackers);
    fetchAttendanceTrackers();
  }, [changeYear]);

  useEffect(() => {
    if (
      loggedInUser.role === "employee" ||
      loggedInUser.role === "consultant"
    ) {
      setEmployeeId(loggedInUserEmployeeId);
      setUserName(loggedInUser.name);
    } else {
      setEmployeeId(selectedEmployeeId);
      setUserName(selectedName);
    }
  }, [employeeId, loggedInUserEmployeeId, selectedEmployeeId]);

  // const rows = monthsOfYear.map((month) => ({
  //   id: month.id,
  //   month: month.month,
  // }));

  // const getDataForMonth = (monthId) => {
  //   const monthData = attendanceTrackers.find((data) => data.month === monthId);
  //   return monthData ? monthData.uploadedBy : "No Data";
  // };

  const getDataForMonth = (monthId) => {
    const monthData = attendanceTrackers.find((data) =>
      data.months.some((month) => month.month === monthId)
    );
    if (monthData) {
      const attendanceTracker = monthData.months.find(
        (month) => month.month === monthId
      ).attendanceTrackers[0];
      if (attendanceTracker) {
        return {
          uploadedBy: attendanceTracker.uploadedBy,
          status: attendanceTracker.status,
          updatedOn: attendanceTracker.updatedOn,
          attendanceTrackerId: attendanceTracker._id,
        };
      }
    }
    return { uploadedBy: "No Data", status: "No Data", updatedOn: "No Data" };
  };

  const rows = monthsOfYear.map((month) => {
    const { uploadedBy, status, updatedOn, attendanceTrackerId } = getDataForMonth(month.id);
    return {
      id: month.id,
      month: month.month,
      uploadedBy,
      status,
      updatedOn,
      attendanceTrackerId,
    };
  });

  

  useEffect(() => {
    // Load the value of changeYear from localStorage
    const storedYear = localStorage.getItem("selectedYear");
    if (storedYear) {
      setChangeYear(parseInt(storedYear));
    }
  }, []);

  const handleYearChange = (e) => {
    const selectedYear = e.target.value;
    setChangeYear(selectedYear);
    // Store the selected year in localStorage
    localStorage.setItem("selectedYear", selectedYear);
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}attendance/attendance/${employeeId}/${changeYear}`
  //     );
  //     setAttendanceData(response.data);
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error("Error fetching months data:", error);
  //     setAttendanceData([]); // Clear attendance data in case of an error
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [changeYear, employeeId]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL)
      .then((response) => {
        setUsers(response.data); // Set users to the array of users
        setEmployees(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user emails:", error);
      });
  }, [selectedEmail]);

  const isValidFileName = (fileName, month, year) => {
    const expectedFormat = `Attendance-Tracker-${month}-${year}.xlsx`;
    console.log(month);
    return fileName === expectedFormat;
  };

  const handleTimesheetUpload = async () => {
    console.log("employeeid: " + loggedInUserEmployeeId);
    const selectedMonthString = monthsOfYear.find(
      (month) => month.id === selectedMonth
    )?.month;

    setIsLoading(true);

    try {
      if (!timesheetFile || !selectedMonthString) {
        throw new Error("Please select a file and month");
      }

      const isValid = isValidFileName(
        timesheetFile.name,
        selectedMonthString,
        uploadYear
      );

      console.log("employeeData: ", employeeData);

      if (!isValid) {
        throw new Error(
          `File name should be in the format "Attendance-Tracker-${selectedMonthString}-${selectedYear}.xlsx"`
        );
      }

      console.log(selectedMonth);
      const formData = new FormData();
      formData.append("file", timesheetFile);
      formData.append("selectedMonth", selectedMonth);
      formData.append("selectedYear", uploadYear);
      formData.append("userName", uploadedBy);
      formData.append("employeeData", JSON.stringify(employeeData)); // Send as JSON string
      formData.append("note", note || "");
      console.log("note: " + note);
      console.log(userName);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}attendance/upload-attendance-tracker/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Attendance tracker uploaded successfully");
        setModalOpen(false);
        fetchAttendanceTrackers();
        setSelectedMonth("");
        setSelectedEmployees("");
        setNote("");
      } else {
        throw new Error("Error uploading attendance tracker");
      }
    } catch (error) {
      console.error("Error uploading attendance tracker:", error);
      toast.error(error.message || "Error uploading attendance tracker");
    } finally {
      setIsLoading(false);
    }
  };

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const years = Array.from({ length: 100 }, (_, index) => 2000 + index);

  const handleDelete = async (year, month, attendanceTrackerId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}attendance/delete-attendance-tracker/${year}/${month}/${attendanceTrackerId}`
      );

      if (response.status === 200) {
        toast.success('Attendance tracker deleted successfully');
        fetchAttendanceTrackers(); // Refresh the data
      } else {
        toast.error('Error deleting attendance tracker');
      }
    } catch (error) {
      console.error('Error deleting attendance tracker:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
      toast.error('Error deleting attendance tracker');
    }
  };

  const downloadFile = async (fileUrl, fileName) => {
    try {
      const response = await axios.get(fileUrl, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set the filename as it is on S3
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleOpenDownloadConfirmation = (monthId) => {
    const selectedMonthString = monthsOfYear.find(
      (month) => month.id === monthId
    )?.month;
    console.log("Month1: " + selectedMonthString);
    console.log("Month1: " + monthId);
    setFileUrl(
      `https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/attendance_tracker/${changeYear}/${monthId}/Attendance-Tracker-${selectedMonthString}-${changeYear}.xlsx`
      // https://ivtree-connect.s3.ap-south-1.amazonaws.com/${getCalendarPdfUrl()}/attendance_tracker/2024/6/Attendance+Tracker-June+2024.xlsx
    );

    console.log("fileUrl in handleOpenDownloadConfirmation:", fileUrl);
    setDownloadTimesheetOpen(true);
  };

  const handleConfirmDownload = () => {
    console.log("fileUrl in handleConfirmDownload:", fileUrl);
    const fileName = fileUrl.split("/").pop();
    console.log("fileName in handleConfirmDownload:", fileName);
    downloadFile(fileUrl, fileName);
    setDownloadTimesheetOpen(false);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
    setDownloadTimesheetOpen(false);
  };

  //   const isCurrentOrPreviousMonth = (month, year) => {
  //     const currentDate = new Date();
  //     const currentMonth = currentDate.getMonth() + 1;
  //     const currentYear = currentDate.getFullYear();
  //     const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
  //     const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;

  //     return (
  //       (month === currentMonth && year === currentYear) ||
  //       (month === previousMonth && year === previousYear)
  //     );
  //   };

  const columns = [
    {
      field: "id",
      headerName: "Sr. No.",
      flex: 0.5,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Sr.no.
        </Typography>
      ),
    },
    {
      field: "month",
      headerName: "Month",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Month
        </Typography>
      ),
    },
    {
      field: "uploadedBy",
      headerName: "Uploaded By",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Uploaded By
        </Typography>
      ),
    },
    {
      field: "updatedOn",
      headerName: "Updated On",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Updated On
        </Typography>
      ),
    },
    // {
    //   field: "view",
    //   headerName: "View",
    //   width: 100,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       View
    //     </Typography>
    //   ),
    //   renderCell: (params) => (
    //     <div>
    //       {
    //         <Link
    //           to={`/timesheettable/${employeeId}/${changeYear}/${params.row.id}`}
    //         >
    //           <VisibilityIcon
    //             style={{
    //               marginLeft: "5px",
    //               marginRight: "10px",
    //               color: "#04497a",
    //               fontSize: "25px",
    //             }}
    //           />
    //         </Link>
    //       }
    //     </div>
    //   ),
    // },
    // {
    //   field: "upload",
    //   headerName: "Upload",
    //   width: 100,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Upload
    //     </Typography>
    //   ),
    //   renderCell: (params) => (
    //     <FileUploadIcon
    //       style={{
    //         marginLeft: "5px",
    //         marginRight: "10px",
    //         color: "#04497a",
    //         fontSize: "25px",
    //       }}
    //       onClick={() => handleModalOpen(params.row.id)}
    //     />
    //   ),
    // },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Status
        </Typography>
      ),
      renderCell: (params) => (
        <div
          style={{
            color:
              params.value === "Uploaded"
                ? "#388e3c" // Green for "Approved"
                : params.value === "No Data"
                  ? "#616161" // Grey for "No Data"
                  : "inherit",
            background:
              params.value === "Uploaded"
                ? "#c8e6c9" // Light green background for "Approved"
                : params.value === "No Data"
                  ? "#f5f5f5" // Light grey background for "No Data"
                  : "inherit",
            borderRadius: "4px",
            padding: "8px",
            display: "inline-block",
            width: "90px",
            textAlign: "center",
          }}
        >
          {params.value}
        </div>
      ),
    },

    {
      field: "download",
      headerName: "Download",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Download
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton disabled={params.row.status !== "Uploaded"}>
            <DownloadIcon
              style={{
                cursor:
                  params.row.status === "Uploaded" ? "pointer" : "not-allowed",
                color:
                  params.row.status === "Uploaded"
                    ? "#04497a"
                    : "rgba(0,0,0,0.6)",
                fontSize: "25px",
              }}
              onClick={() =>
                params.row.status === "Uploaded" &&
                handleOpenDownloadConfirmation(params.row.id)
              }
            />
          </IconButton>
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <div>
          {allowedRoles.includes(loggedInUser.role) && params.row.status === 'Uploaded' && (
            <DeleteIcon
              style={{
                cursor: 'pointer',
                color: '#04497a',
                fontSize: '25px',
              }}
              onClick={() => handleDeleteConfirmation(changeYear, params.row.id, params.row.attendanceTrackerId)}
            />
          )}
        </div>
      ),
    },
  ];
  const style2 = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    color: "black",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    width: "30%",
    height: "auto",
    wordWrap: "break-word",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  };

  const handleModalOpen = (monthNumber) => {
    setModalOpen(true);
    setSelectedMonth(monthNumber);
    console.log("SelectedMonth" + monthNumber);
  };

  const handleClose = () => {
    setOpen(false);
    setModalOpen(false);
    setConfirmationOpen(false);
    setDownloadTimesheetOpen(false);
  };

  const handleDeleteConfirmation = (year, month, attendanceTrackerId) => {
    setConfirmationOpen(true);
    setDeleteInfo({ year, month, attendanceTrackerId });
  };

  const handleConfirmDelete = () => {
    const { year, month, attendanceTrackerId } = deleteInfo;
    handleDelete(year, month, attendanceTrackerId);
    setConfirmationOpen(false);
  };

  const handleChange = (event) => {
    // Get the value of the select element, which should be an array
    const selectedValues = event.target.value;

    // Check if the value is an array and not null or undefined
    if (Array.isArray(selectedValues)) {
      setSelectedNames(selectedValues);

      // Assuming selectedValues is an array of emails
      setEmployeeData(selectedValues);

      console.log("employeeData: ", selectedValues);

      // Find the employee objects with the selected names
      const newSelectedEmployees = employees.filter((employee) =>
        selectedValues.includes(employee.email)
      );

      // Update the selectedEmployees state with the new values
      setSelectedEmployees(newSelectedEmployees);
      console.log(newSelectedEmployees);
    } else {
      console.error("Expected an array but got:", selectedValues);
    }
  };

  // useEffect(() => {
  //   handleChange(event);
  // }, [selectedNames])

  return (
    <Box>
      {isLoading && <Spinner />}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "crimson",
            }}
          >
            <CloseIcon />
          </IconButton>
          <form>
            <ThemeProvider theme={lightTheme}>
              <FlexBetween
                sx={{ flexDirection: "row", justifyContent: "flex-start" }}
              >
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="upload-year-select-label">
                    Year
                    <span
                      style={{
                        color: "#c44545",
                        position: "relative",
                        top: "-4px",
                      }}
                    >
                      *
                    </span>
                  </InputLabel>
                  <Select
                    labelId="upload-year-select-label"
                    id="upload-year-select"
                    label="Year"
                    value={uploadYear}
                    onChange={(e) => setUploadYear(e.target.value)}
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="upload-month-select-label">
                    Month
                    <span
                      style={{
                        color: "#c44545",
                        position: "relative",
                        top: "-4px",
                      }}
                    >
                      *
                    </span>
                  </InputLabel>
                  <Select
                    labelId="upload-month-select-label"
                    id="upload-month-select"
                    label="Month"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                  >
                    {monthsOfYear.map((month) => (
                      <MenuItem key={month.id} value={month.id}>
                        {month.month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FlexBetween>
            </ThemeProvider>
            <ThemeProvider theme={lightTheme}>
              <FlexBetween
                sx={{ justifyContent: "flex-start", marginBottom: "20px" }}
              >
                <FormControl sx={{ width: "270px", marginTop: "15px" }}>
                  <InputLabel id="demo-simple-select-label">Email</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedNames}
                    label="Email"
                    onChange={handleChange}
                    multiple
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {employees.map((employee) => (
                      <MenuItem key={employee._id} value={employee.email}>
                        <Checkbox
                          checked={selectedNames.indexOf(employee.email) > -1}
                        />
                        <ListItemText primary={employee.email} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FlexBetween>
              <label
                htmlFor="message"
                className="formbold-form-label"
                style={{ color: "black" }}
              >
                {" "}
                Note{" "}
              </label>
              <textarea
                rows="6"
                name="message"
                id="message"
                placeholder="Write note here"
                className="formbold-form-input"
                // value={description}
                style={{
                  width: "270px",
                }}
                onChange={(e) => setNote(e.target.value)}
              ></textarea>
            </ThemeProvider>

            <div className="formbold-input-flex">
              <div>
                <label htmlFor="Upload" className="formbold-form-label">
                  Upload file
                  <span
                    style={{
                      color: "#c44545",
                      position: "relative",
                      top: "-4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="formbold-form-input"
                  style={{ width: "300px" }}
                  onChange={(e) => setTimesheetFile(e.target.files[0])}
                />
              </div>
            </div>
            <button
              type="button"
              onClick={handleTimesheetUpload}
              className="formbold-btn"
            >
              Upload
            </button>
          </form>
        </Box>
      </Modal>

      <FlexBetween>
        <Header title={`Attendance Tracker`} />

        <ThemeProvider theme={lightTheme}>
          <FlexBetween
            sx={{ flexDirection: "row", justifyContent: "flex-start" }}
          >
            {allowedRoles.includes(loggedInUser.role) && (
              <Box>
                <Button
                  variant="contained"
                  onClick={() => setModalOpen(true)}
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontSize: "14px",
                    fontWeight: "bolder",
                    padding: "10px 20px",
                  }}
                >
                  Upload Attendance Tracker
                </Button>
              </Box>
            )}
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 120, marginLeft: "20px" }}
            >
              <InputLabel id="year-select-label">Year</InputLabel>
              <Select
                labelId="year-select-label"
                id="year-select"
                label="Year"
                value={changeYear}
                onChange={handleYearChange}
                MenuProps={{
                  style: {
                    maxHeight: 600, // Set a maximum height for the dropdown
                  },
                }}
              >
                <MenuItem value="">Choose Year</MenuItem>
                {years.map((year) => (
                  <MenuItem sx={{ fontSize: "18px" }} key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FlexBetween>
        </ThemeProvider>
      </FlexBetween>

      <ThemeProvider theme={lightTheme}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={12}
          rowsPerPageOptions={[5, 10, 20]}
          // checkboxSelection
          sx={dashboardStyle(theme, "595px")}
        />
      </ThemeProvider>
      <ToastContainer />
      <ConfirmationDialog
        open={confirmationOpen}
        handleClose={handleClose}
        handleConfirm={handleConfirmDelete} // Pass handleConfirmDelete here
        message="Are you sure you want to delete the attendane tracker?"
      />
      <ConfirmationDialog
        open={downloadTimesheetOpen} // Pass state to control dialog visibility
        handleClose={handleCloseConfirmation} // Function to close the dialog
        handleConfirm={handleConfirmDownload} // Function to handle confirmation action (download)
        message="Are you sure you want to download the attendane tracker?" // Confirmation message
      />
    </Box>
  );
}

export default UploadAttendanceTracker;
