import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "./shared/layout";
import EmployeeTable from "components/user-managment/manage-employees/EmployeeTable";
import ProfilePage from "components/user-profile/profile page/ProfilePage";
import Login from "components/login/Login";
import CreateUser from "components/user-managment/create-user/CreateUser";
import UpdateUser from "components/user-managment/update-user/UpdateUser";
import ResetPassword from "components/user-managment/reset-password/ResetPassword";
import PrivateRoute from "./PrivateRoute";
import AttendanceTable from "components/attendance-management/employee-attendance-page/AttendanceTable";
import TimeSheet from "components/attendance-management/create-time-sheet/TimeSheet";
import UpdateTimesheet from "components/attendance-management/update-timesheet/UpdateTimesheet";
import ManageAttendance from "components/attendance-management/admin-attendance-page/ManageAttendance";
import EmployeeLeaves from "components/leave-management/employee-leaves/EmployeeLeaves";
import LeaveRequest from "components/leave-management/create-leave-request/LeaveRequest";
import UpdateLeave from "components/leave-management/update-leave/UpdateLeave";
import ManageLeaves from "components/leave-management/admin-leave-page/ManageLeaves";
import ManagePayslips from "components/payslips/admin-payslips-management/ManagePayslips";
import UploadPayslip from "components/payslips/add-payslip/UploadPayslip";
import PayslipTable from "components/payslips/employee-payslips/PayslipTable";
import Home from "components/home/Home";
import HolidayCalendar from "components/holiday-calendar/admin-holidayCalendar/HolidayCalendar";
import CreateHolidayCalendar from "components/holiday-calendar/admin-holidayCalendar/CreateHolidayCalendar";
import EmployeeHolidayCalendar from "./components/holiday-calendar/employee-holidayCalendar/EmployeeHolidayCalendar";
import EmployeeDetails from "components/emplyoee-details/EmployeeDetails";
import DocumentsAdmin from "components/it-and-documents/documents-admin/DocumentsAdmin";
import DocumentsEmployee from "components/it-and-documents/documents-employee/DocumentsEmployee";
import AddDocument from "components/it-and-documents/add-documents/AddDocument";
import EmployeeAddDocument from "components/it-and-documents/employee-add-document/EmployeeAddDocument";
import EmployeeOpeningsPage from "components/current-openings/employee-current-openings/EmployeeOpeningsPage";
import ManageOpenings from "components/current-openings/admin-current-openings/ManageOpenings";
import DatabaseSettings from "shared/databaseSettings/DatabaseSettings";
import EditProfile from "components/user-profile/profile page/EditProfile";
import TimeSheetTable from "components/attendance-management/employee-attendance-page/TimeSheetTable";
import TimesheetsMonthsTable from "components/attendance-management/attendance-main-page/TimesheetsMonthsTable";
import Logs from "components/logs-tab/Logs";
import ManageApplications from "components/current-openings/applications/ManageApplications";
import ViewApplications from "components/current-openings/applications/ViewApplications";
import ManageGroupMembers from "components/user-managment/create-groups/ManageGroupMembers";
import EpfPortal from "components/epf-portal/EpfPortal";
import ForgotPassword from "components/login/ForgotPassword";
import VerifyOtp from "components/login/VerifyOtp";
import NewPassword from "components/login/NewPassword";
import UploadTimesheetTable from "components/upload-Attendance-Tracker/UploadAttendanceTracker";
import UploadAttendanceTracker from "components/upload-Attendance-Tracker/UploadAttendanceTracker";
import BackupAndRestore from "shared/databaseSettings/backup-and-restore/BackupAndRestore";
import AdminOrHRRoute from './AdminOrHRRoute';
import SessionHandler from "shared/SessionHandler";
import { ToastContainer } from "react-toastify";
import TimesheetMainPageUS from "components/attendance-management/timesheet-US/TimesheetMainPageUS";
import YearlyTimesheetUS from "components/attendance-management/timesheet-US/EditTimesheetUS";
import { Edit } from "@mui/icons-material";
import EditTimesheetUS from "components/attendance-management/timesheet-US/EditTimesheetUS";
import TimesheetsAdminUS from "components/attendance-management/timesheet-US-admin/TimesheetsAdminUS";
import ViewTimesheetAdminUS from "components/attendance-management/timesheet-US-admin/ViewTimesheetAdminUS";
import TimesheetIND from "components/attendance-management/timesheet-admin/TimesheetIND";
import ViewTimesheetIND from "components/attendance-management/timesheet-admin/ViewTimesheetIND";
// import TimesheetBackup from "components/attendance-management/timesheet-US/TimesheetBackup";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SessionHandler />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            theme="light"
          />
          <Routes>
            {/* Redirect from root to /dashboard if authenticated */}
            <Route path="/" element={<Navigate to="/dashboard" />} />

            {/* Protected Routes */}
            <Route element={<PrivateRoute />}>
              {/* Unrestricted for authenticated users */}
              <Route element={<Layout />}>
                <Route path="/dashboard" element={<Home />} />
                <Route path="/profilepage" element={<ProfilePage />} />
                <Route path="/updateuser/:id" element={<UpdateUser />} />
                <Route path="/editprofile/:id" element={<EditProfile />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/attendancetable" element={<AttendanceTable />} />
                <Route path="/timesheettable/:fetchedEmployeeId/:year/:month" element={<TimeSheetTable />} />
                <Route path="/ind/timesheets/edit/:yearFromUrl/:monthFromUrl/:id" element={<TimeSheet />} />
                <Route path="/timesheetmonthstable" element={<TimesheetsMonthsTable />} />
                <Route path="/updatetimesheet/:id/:year/:month" element={<UpdateTimesheet />} />
                <Route path="/uploadattendancetracker" element={<UploadAttendanceTracker />} />
                {/* <Route path="/timesheettable-US" element={<TimesheetMainPageUS />} />
                <Route path="/edittimesheet-US" element={<YearlyTimesheetUS />} /> */}
                <Route path="/us/timesheets" element={<TimesheetMainPageUS />} />
                <Route path="/us/timesheets/edit/:yearFromUrl/:monthFromUrl/:id" element={<EditTimesheetUS />} />
                {/* <Route path="/us/timesheetstable" element={<TimesheetBackup />} /> */}

                <Route path="/employeeLeaves" element={<EmployeeLeaves />} />
                <Route path="/addleaverequest" element={<LeaveRequest />} />
                <Route path="/updateleaverequest/:id" element={<UpdateLeave />} />
                <Route path="/paysliptable" element={<PayslipTable />} />
                <Route path="/employeeholidayCalendar" element={<EmployeeHolidayCalendar />} />
                <Route path="/documentsemployee" element={<DocumentsEmployee />} />
                <Route path="/adddocuments" element={<AddDocument />} />
                <Route path="/employeeadddocuments" element={<EmployeeAddDocument />} />
                <Route path="/employeeopenings" element={<EmployeeOpeningsPage />} />
                <Route path="/viewapplication/:id/:appliedby" element={<ViewApplications />} />
                <Route path="/linktoepfportal" element={<EpfPortal />} />

                {/* Restricted for Admin or HR users */}
                <Route element={<PrivateRoute isProtected />}>
                  <Route path="/employee" element={<EmployeeTable />} />
                  <Route path="/createuser" element={<CreateUser />} />
                  <Route path="/manageattendance/:fetchedEmployeeId/:year/:month" element={<ManageAttendance />} />
                  <Route path="/us/timesheetsadmin" element={<TimesheetsAdminUS />} />
                  <Route path="/us/timesheetsadmin/view/:employeeId/:monthFromUrl/:id" element={<ViewTimesheetAdminUS />} />

                  <Route path="/manageleaves" element={<ManageLeaves />} />
                  <Route path="/managepayslips" element={<ManagePayslips />} />
                  <Route path="/uploadpayslips" element={<UploadPayslip />} />
                  <Route path="/holidaycalendar" element={<HolidayCalendar />} />
                  <Route path="/createholidaycalendar/:id" element={<CreateHolidayCalendar />} />
                  <Route path="/employeedetails" element={<EmployeeDetails />} />
                  <Route path="/documentsadmin" element={<DocumentsAdmin />} />
                  <Route path="/manageopenings" element={<ManageOpenings />} />
                  <Route path="/databasesettings" element={<DatabaseSettings />} />
                  <Route path="/backupandrestore" element={<BackupAndRestore />} />
                  <Route path="/logstab" element={<Logs />} />
                  <Route path="/manageapplications/:id" element={<ManageApplications />} />
                  <Route path="/managegroupmembers/:id" element={<ManageGroupMembers />} />

                  <Route path="/admin/timesheets" element={<TimesheetIND />} />
                  <Route path="/admin/timesheets/view/:employeeId/:yearFromUrl/:monthFromUrl/:id" element={<ViewTimesheetIND />} />
                </Route>
              </Route>

            </Route>

            {/* Authentication Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/verify-otp" element={<VerifyOtp />} />
            <Route path="/reset-password" element={<NewPassword />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}



export default App;
