import React, { useEffect, useState, useRef } from "react";
import "../add-documents/addDocument.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material";

import FlexBetween from "shared/FlexBetween";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ConfirmationDialog from "shared/ConfirmationDialog";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "shared/spinner/Spinner";

function EmployeeAddDocument() {
  const navigate = useNavigate();
  const [employeeId, setEmployeeId] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [file, setFile] = useState(null);
  const theme = useTheme();
  const [employees, setEmployees] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [description, setDescription] = useState("");
  const [role, setRole] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false); // State variable for confirmation dialog
  const [isLoading, setIsLoading] = useState(false); // State variable for loading spinner

  const { id } = useParams();

  const apiCalledRef = useRef(false);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL)
      .then((response) => {
        setEmployees(response.data); // Set users to the array of users
      })
      .catch((error) => {
        console.error("Error fetching user emails:", error);
      });
  }, []);

  useEffect(() => {
    if (apiCalledRef.current) return;
    apiCalledRef.current = true;
    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      })
      .then((response) => {
        setEmployees(response.data);
        console.log("Employees: " + employees);
        setEmployeeId(response.data.employeeId);
        setName(response.data.name);
        setRole(response.data.role);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [employeeId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    // Check if any required field is empty (except description)
    if (!employeeId || !name || !documentType || !file || !role) {
      toast.error("Please fill in all required fields.");
      setOpenConfirmation(false);
      setIsLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("employeeId", employeeId);
      formData.append("name", name);
      formData.append("documentType", documentType);
      formData.append("file", file);

      // Append description only if it's not empty
      if (description) {
        formData.append("description", description);
      }

      formData.append("uploadedBy", `${name}(${role})`);

      const response = await axios.put(
        process.env.REACT_APP_API_URL + "itAndDocuments/uploadSingleDoc/" + id,
        formData
      );

      setOpenConfirmation(false);
      navigate("/documentsemployee");
      console.log(response);
    } catch (error) {
      console.error("Error uploading document:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //Confirmation box
  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  return (
    <div>
      {isLoading && <Spinner />}
      <FlexBetween sx={{ position: "fixed" }}>
        <IconButton
          onClick={() => navigate(-1)}
          style={{ color: "#04497a", marginTop: "10px", marginLeft: "10px" }}
        >
          <ArrowBackIcon />
        </IconButton>
      </FlexBetween>
      <div
        className="formbold-main-wrapper"
        style={{ color: theme.palette.background.text }}
      >
        <div className="formbold-form-wrapper">
          <div className="formbold-form-title">
            <h3 className="header">Upload Documents</h3>
          </div>
          <div className="formbold-input-flex">
            <div className="formbold-mb-3">
              <label className="formbold-form-label">Employee ID:</label>
              <h3>{employeeId}</h3>
            </div>
            <div className="formbold-mb-3">
              <label className="formbold-form-label">Full name:</label>
              <h3>{name}</h3>
            </div>
          </div>
          {status === "uploaded" && (
            <div
              className="formbold-mb-3"
              style={{
                backgroundColor: "#c8e6c9",
                color: "#4caf50",
                borderRadius: "5px",
                border: "4px solid rgba(76, 175, 80, 0.5)",
                padding: "10px 10px",
                display: "inline-block",
                width: "100%",
                textAlign: "center",
                height: "auto",
                fontSize: "20px",
                fontWeight: "bolder",
              }}
            >
              {statusMessage}
            </div>
          )}
          <form>
            <div className="formbold-input-flex">
              <div className="formbold-mb-3">
                <label className="formbold-form-label">
                  Document Type
                  <span
                    style={{
                      color: "#c44545",
                      position: "relative",
                      top: "-4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="input"
                  name="documentType"
                  id="documentType"
                  className="formbold-form-input"
                  value={documentType}
                  onChange={(e) => setDocumentType(e.target.value)}
                />
              </div>
              <div className="formbold-mb-3">
                <label className="formbold-form-label">
                  Upload Document
                  <span
                    style={{
                      color: "#c44545",
                      position: "relative",
                      top: "-4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="file"
                  name="file" // Ensure that the name attribute is set to "file"
                  id="document" // Update id if needed
                  className="formbold-form-input"
                  accept=".pdf, .doc, .docx, .xls, .xlsx"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
            </div>
            <div>
              <label htmlFor="message" className="formbold-form-label">
                {" "}
                Description{" "}
              </label>
              <textarea
                rows="6"
                name="message"
                id="message"
                placeholder="Type your message"
                className="formbold-form-input"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <button
              type="button"
              onClick={handleOpenConfirmation}
              className="formbold-btn"
            >
              Upload
            </button>
          </form>
        </div>
      </div>
      <ConfirmationDialog
        open={openConfirmation}
        handleClose={handleCloseConfirmation}
        handleConfirm={handleSubmit}
        message="Are you sure you want to upload this document?"
      />
      <ToastContainer />
    </div>
  );
}
export default EmployeeAddDocument;
