import React, { useEffect, useState } from "react";
import axios from "axios";

import FlexBetween from "shared/FlexBetween";
import {
  Box,
  useTheme,
  Button,
  Modal,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Tooltip,
} from "@mui/material";
import Header from "shared/Header";
import { DataGrid } from "@mui/x-data-grid";
import { indigo } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import { dashboardStyle } from "shared/utils";
import Spinner from "shared/spinner/Spinner";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";

function TimesheetMainPageUS() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [user, setUser] = useState({});
  const [employeeId, setEmployeeId] = useState("");
  const [timesheets, setTimesheets] = useState([]);
  const [timesheetName, setTimesheetName] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("all"); // Change default to "all"
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [openUpload, setOpenUpload] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [uploadConfirmationOpen, setUploadConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [newMonth, setNewMonth] = useState("");
  const [deleteMonth, setDeleteMonth] = useState("");
  const [selectPeriod, setSelectPeriod] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterPeriod, setFilterPeriod] = useState("all");
  const [users, setUsers] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("all");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const fortNightlyPeriods = [
    {
      value: "jan1",
      label: "9 Jan - 24 Jan",
      startDate: "09",
      endDate: "24",
      startMonth: "01",
    },
    {
      value: "jan2",
      label: "25 Jan - 8 Feb",
      startDate: "25",
      endDate: "08",
      startMonth: "01",
    },
    {
      value: "feb1",
      label: "9 Feb - 24 Feb",
      startDate: "09",
      endDate: "24",
      startMonth: "02",
    },
    {
      value: "feb2",
      label: "25 Feb - 8 Mar",
      startDate: "25",
      endDate: "08",
      startMonth: "02",
    },
    {
      value: "mar1",
      label: "9 Mar - 24 Mar",
      startDate: "09",
      endDate: "24",
      startMonth: "03",
    },
    {
      value: "mar2",
      label: "25 Mar - 8 Apr",
      startDate: "25",
      endDate: "08",
      startMonth: "03",
    },
    {
      value: "apr1",
      label: "9 Apr - 24 Apr",
      startDate: "09",
      endDate: "24",
      startMonth: "04",
    },
    {
      value: "apr2",
      label: "25 Apr - 8 May",
      startDate: "25",
      endDate: "08",
      startMonth: "04",
    },
    {
      value: "may1",
      label: "9 May - 24 May",
      startDate: "09",
      endDate: "24",
      startMonth: "05",
    },
    {
      value: "may2",
      label: "25 May - 8 Jun",
      startDate: "25",
      endDate: "08",
      startMonth: "05",
    },
    {
      value: "jun1",
      label: "9 Jun - 24 Jun",
      startDate: "09",
      endDate: "24",
      startMonth: "06",
    },
    {
      value: "jun2",
      label: "25 Jun - 8 Jul",
      startDate: "25",
      endDate: "08",
      startMonth: "06",
    },
    {
      value: "jul1",
      label: "9 Jul - 24 Jul",
      startDate: "09",
      endDate: "24",
      startMonth: "07",
    },
    {
      value: "jul2",
      label: "25 Jul - 8 Aug",
      startDate: "25",
      endDate: "08",
      startMonth: "07",
    },
    {
      value: "aug1",
      label: "9 Aug - 24 Aug",
      startDate: "09",
      endDate: "24",
      startMonth: "08",
    },
    {
      value: "aug2",
      label: "25 Aug - 8 Sep",
      startDate: "25",
      endDate: "08",
      startMonth: "08",
    },
    {
      value: "sep1",
      label: "9 Sep - 24 Sep",
      startDate: "09",
      endDate: "24",
      startMonth: "09",
    },
    {
      value: "sep2",
      label: "25 Sep - 8 Oct",
      startDate: "25",
      endDate: "08",
      startMonth: "09",
    },
    {
      value: "oct1",
      label: "9 Oct - 24 Oct",
      startDate: "09",
      endDate: "24",
      startMonth: "10",
    },
    {
      value: "oct2",
      label: "25 Oct - 8 Nov",
      startDate: "25",
      endDate: "08",
      startMonth: "10",
    },
    {
      value: "nov1",
      label: "9 Nov - 24 Nov",
      startDate: "09",
      endDate: "24",
      startMonth: "11",
    },
    {
      value: "nov2",
      label: "25 Nov - 8 Dec",
      startDate: "25",
      endDate: "08",
      startMonth: "11",
    },
    {
      value: "dec1",
      label: "9 Dec - 24 Dec",
      startDate: "09",
      endDate: "24",
      startMonth: "12",
    },
    {
      value: "dec2",
      label: "25 Dec - 8 Jan",
      startDate: "25",
      endDate: "08",
      startMonth: "12",
    },
  ];

  // Function to get current period
  const getCurrentPeriod = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1; // JavaScript months are 0-based
    const monthStr = month.toString().padStart(2, "0");

    // Find the period that contains today's date
    return (
      fortNightlyPeriods.find((period) => {
        const periodMonth = parseInt(period.startMonth);
        const startDate = parseInt(period.startDate);
        const endDate = parseInt(period.endDate);

        if (periodMonth === month) {
          if (startDate <= day && day <= 24) return true; // First period
          if (startDate === 25 && day >= 25) return true; // Second period
        } else if (periodMonth < month && day <= 8) {
          return period.startDate === "25"; // Previous month's second period
        }
        return false;
      })?.value || "jan1"
    ); // Default to jan1 if no period found
  };

  // Set current period on component mount
  useEffect(() => {
    setSelectPeriod(getCurrentPeriod());
  }, []);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL, {
        withCredentials: true,
      });
      setUsers(response.data);
      setName(response.data.name);
    } catch (error) {
      console.error("Error fetching employee name:", error);
    }
  };

  // useEffect(() => {
  //   const currentDate = new Date();
  //   const currentMonth = currentDate.getMonth();
  //   setNewMonth(currentMonth);
  //   setSelectPeriod(getCurrentPeriod());

  //   // Set filter to current month/period
  //   setFilterMonth(currentMonth.toString());
  //   setFilterPeriod(getCurrentPeriod());
  // }, []);

  const monthOptions = [
    { value: "all", label: "Show All" },
    ...months.map((month, index) => ({
      value: index.toString(),
      label: month,
    })),
  ];

  const years = Array.from(
    { length: 10 },
    (_, index) => new Date().getFullYear() - 2 + index
  );

  const handleDeleteConfirmation = (id, month) => {
    setDeleteId(id);
    setDeleteMonth(month);
    setConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    handleDelete(deleteId, deleteMonth);
    setConfirmationOpen(false);
  };

  const handleDeleteCancel = () => {
    setConfirmationOpen(false);
  };

  const handleUploadConfirmationOpen = () => {
    setUploadConfirmationOpen(true);
  };

  const handleUploadCancel = () => {
    setUploadConfirmationOpen(false);
  };

  const handleOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleCloseUpload = () => setOpenUpload(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      })
      .then((response) => {
        setUser(response.data);
        // setName(response.data.name);
        setEmployeeId(response.data.employeeId);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        if (error.response && error.response.status === 401) {
          toast.warning(
            "Your account has been disabled. Contact HR for assistance.",
            {
              onClose: () => {
                document.cookie =
                  "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              },
            }
          );
        }
      });
  };

  // Update the fetchAllTimesheets function to include employeeId
  const fetchAllTimesheets = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}timesheetUS/fetchTimesheetsUS`,
        {
          params: {
            employeeId: selectedEmployee,
            period: filterPeriod,
            month: filterMonth,
            year: selectedYear,
          },
          withCredentials: true,
        }
      );
      setTimesheets(response.data);
    } catch (error) {
      console.error("Error fetching timesheets:", error);
      toast.error("Failed to fetch timesheets");
    } finally {
      setIsLoading(false);
    }
  };

  // Add useEffect dependency for selectedEmployee
  useEffect(() => {
    fetchAllTimesheets();
  }, [selectedEmployee, filterPeriod, filterMonth, selectedYear]);

  const getMonthFromPeriod = (periodValue) => {
    const period = fortNightlyPeriods.find((p) => p.value === periodValue);
    return period ? parseInt(period.startMonth) - 1 : 0; // Convert to 0-based month
  };

  const getMonthShortName = (monthNumber) => {
    const date = new Date(2000, monthNumber - 1, 1);
    return date.toLocaleString("default", { month: "short" });
  };

  // Add a function to get periods for selected month
  const getPeriodsForMonth = (monthIndex) => {
    return fortNightlyPeriods.filter(
      (period) => parseInt(period.startMonth) === monthIndex + 1
    );
  };

  const generateTimesheetName = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}timesheetUS/getTimesheetCount`,
        {
          params: {
            employeeId: employeeId,
            period: newMonth,
            year: selectedYear,
          },
          withCredentials: true,
        }
      );

      const currentCount = response.data.count;
      const nextNumber = currentCount + 1;

      // Get the selected period details
      const selectedPeriod = fortNightlyPeriods.find(
        (p) => p.value === newMonth
      );

      // Format dates
      const startDate = selectedPeriod.startDate;
      const endDate = selectedPeriod.endDate;
      const startMonth = selectedPeriod.startMonth;

      // Calculate end month (if period spans across months)
      const endMonth = parseInt(startMonth) + (endDate < startDate ? 1 : 0);

      // Create name in format: EmployeeName_25Jan-8Feb_2025_1
      return `${user.name}_${startDate}${getMonthName(
        startMonth
      )}-${endDate}${getMonthName(endMonth)}_${selectedYear}_${nextNumber}`;
    } catch (error) {
      console.error("Error getting timesheet count:", error);
      return `${user.name}_${selectPeriod}`;
    }
  };

  const downloadTimesheetEntries = async (
    employeeName,
    employeeId,
    timesheetId,
    period,
    month,
    year
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}timesheetUS/getTimesheetEntries`,
        {
          params: {
            employeeId,
            timesheetId,
            month: period,
            year,
          },
          withCredentials: true,
        }
      );

      const timesheetEntries = response.data.entries;

      if (!timesheetEntries || timesheetEntries.length === 0) {
        toast.warning("No entries found for this timesheet.");
        return;
      }

      // Transform entries into Excel-friendly format
      const excelData = timesheetEntries.map((entry, index) => ({
        "Sr. No.": index + 1,
        Date: entry.date ? new Date(entry.date).toLocaleDateString() : "N/A",
        "Work Type": entry.workType || "N/A",
        "Working Hours": entry.workingHours || 0,
        "Overtime Hours": entry.workingHours || 0,
        Comments: entry.comments || "N/A",
        "Updated On": entry.updatedAt
          ? new Date(entry.updatedAt).toLocaleString()
          : "N/A",
      }));

      // Create worksheet
      const worksheet = XLSX.utils.json_to_sheet(excelData);

      // Adjust column widths
      const colWidths = [
        { wch: 10 }, // Sr. No.
        { wch: 15 }, // Date
        { wch: 20 }, // Work Type
        { wch: 15 }, // Working Hours
        { wch: 15 }, // Working Hours
        { wch: 20 }, // Created At
        { wch: 20 }, // Updated At
      ];
      worksheet["!cols"] = colWidths;

      // Create workbook and add worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Timesheet Entries");

      // Generate Excel file
      XLSX.writeFile(
        workbook,
        `Timesheet_${employeeName}_${period}_${year}.xlsx`
      );
    } catch (error) {
      console.error("Error downloading timesheet entries:", error);
      toast.error("Failed to download timesheet entries.");
    }
  };

  // Helper function to get month name
  const getMonthName = (monthNumber) => {
    const date = new Date(2000, monthNumber - 1, 1);
    return date.toLocaleString("default", { month: "short" });
  };
  // Update the rows mapping to include employee name
  const rows = timesheets
    .map((timesheet) => ({
      id: timesheet._id,
      timesheetName: timesheet.timesheetName,
      employeeId: timesheet.employeeId,
      employeeName:
        users.find((u) => u.employeeId === timesheet.employeeId)?.name ||
        "Unknown",
      period: timesheet.period,
      month: timesheet.month,
      daysWorked: timesheet.daysWorked || 0,
      leavesTaken: timesheet.leavesTaken || 0,
      totalHours: timesheet.totalHours || 0,
      status: timesheet.status,
      modifiedAt: timesheet.modifiedAt,
      entries: timesheet.entries || [],
    }))
    .sort((a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt))
    .map((row, index) => ({
      ...row,
      index: index + 1,
    }));

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 40,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          #
        </Typography>
      ),
    },
    {
      field: "employeeName",
      headerName: "Employee Name",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Employee Name
        </Typography>
      ),
    },
    {
      field: "month",
      headerName: "Month",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Month
        </Typography>
      ),
      renderCell: (params) => <Typography>{months[params.value]}</Typography>,
    },
    {
      field: "period",
      headerName: "Period",
      flex: 1.1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Period
        </Typography>
      ),
      renderCell: (params) => {
        const period = fortNightlyPeriods.find((p) => p.value === params.value);
        return <Typography>{period ? period.label : params.value}</Typography>;
      },
    },
    {
      field: "timesheetName",
      headerName: "Timesheet Name",
      flex: 1.5,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Timesheet Name
        </Typography>
      ),
    },
    {
      field: "daysWorked",
      headerName: "Days Worked",
      flex: 0.7,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Days Worked
        </Typography>
      ),
      renderCell: (params) => <Typography>{params.value || 0}</Typography>,
    },
    {
      field: "totalHours",
      headerName: "Total Hours",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Total Hours
        </Typography>
      ),
      renderCell: (params) => (
        <Typography>{params.value.toFixed(2)}</Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Status
        </Typography>
      ),
      renderCell: (params) => (
        <div
          style={{
            color:
              params.value === "Approved"
                ? "#388e3c"
                : params.value === "Pending"
                ? "#f57c00"
                : params.value === "Rejected"
                ? "#f44336"
                : params.value === "Submitted"
                ? "#1976d2"
                : params.value === "No Data"
                ? "#616161"
                : "inherit",
            background:
              params.value === "Approved"
                ? "#c8e6c9"
                : params.value === "Pending"
                ? "#ffe0b2"
                : params.value === "Rejected"
                ? "#ffcdd2"
                : params.value === "Submitted"
                ? "#cce5ff"
                : params.value === "No Data"
                ? "#f5f5f5"
                : "inherit",
            borderRadius: "4px",
            padding: "8px",
            display: "inline-block",
            width: "90px",
            textAlign: "center",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "modifiedAt",
      headerName: "Modified",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Modified
        </Typography>
      ),
      renderCell: (params) => (
        <Typography fontSize={"12px"}>
          {new Date(params.value).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          })}
        </Typography>
      ),
    },
    {
      field: "view",
      headerName: "View",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          View
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <IconButton
            style={{ cursor: "pointer", color: "#04497a" }}
            onClick={() =>
              navigate(
                `/us/timesheetsadmin/view/${params.row.employeeId}/${params.row.period}/${params.row.id}`
              )
            }
            // disabled={params.row.status === "Pending"}
          >
            <RemoveRedEyeIcon />
          </IconButton>
          <Tooltip title="Download Timesheet Entries">
            <IconButton
              style={{ cursor: "pointer", color: "#04497a", fontSize: "25px" }}
              onClick={() =>
                downloadTimesheetEntries(
                  params.row.employeeName,
                  params.row.employeeId,
                  params.row.id,
                  params.row.period,
                  params.row.month,
                  selectedYear
                )
              }
              // disabled={params.row.status === "Pending"}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  return (
    <div>
      {isLoading && <Spinner />}

      <Box m="1.5rem 2.5rem" height="calc(100vh - 275px)">
        <FlexBetween sx={{ marginBottom: "10px" }}>
          <Header
            title="Timesheets"
            // subtitle={getMonthFromPeriod(selectPeriod)}
          />
          <ThemeProvider theme={lightTheme}>
            <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              <FormControl sx={{ minWidth: "150px" }}>
                <InputLabel>Employee</InputLabel>
                <Select
                  value={selectedEmployee}
                  label="Employee"
                  onChange={(e) => setSelectedEmployee(e.target.value)}
                  sx={{ height: "40px" }}
                >
                  <MenuItem value="all">All Employees</MenuItem>
                  {users.map(
                    (user) =>
                      user.country === "US" && (
                        <MenuItem key={user.employeeId} value={user.employeeId}>
                          {user.name}
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: "120px" }}>
                <InputLabel>Filter Month</InputLabel>
                <Select
                  value={filterMonth}
                  label="Filter Month"
                  onChange={(e) => setFilterMonth(e.target.value)}
                  sx={{ height: "40px" }}
                >
                  <MenuItem value="all">All Months</MenuItem>
                  {months.map((month, index) => (
                    <MenuItem key={index} value={index.toString()}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: "120px" }}>
                <InputLabel>Filter Period</InputLabel>
                <Select
                  value={filterPeriod}
                  label="Filter Period"
                  onChange={(e) => setFilterPeriod(e.target.value)}
                  sx={{ height: "40px" }}
                >
                  <MenuItem value="all">All Periods</MenuItem>
                  {fortNightlyPeriods.map((period) => (
                    <MenuItem key={period.value} value={period.value}>
                      {period.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: "100px" }}>
                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                <Select
                  value={selectedYear}
                  label="Year"
                  onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                  sx={{ height: "40px" }}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </ThemeProvider>
        </FlexBetween>
        <ThemeProvider theme={lightTheme}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            pageSizeOptions={[5, 10]}
            sx={dashboardStyle(theme, "480px")}
          />
        </ThemeProvider>
      </Box>
      <ToastContainer />
      <ConfirmationDialog
        open={confirmationOpen}
        handleClose={handleDeleteCancel}
        handleConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this timesheet?"
      />
      {/* <ConfirmationDialog
        open={uploadConfirmationOpen}
        handleClose={handleUploadCancel}
        handleConfirm={handleCreate}
        message="Are you sure you want to create this timesheet?"
      /> */}
    </div>
  );
}

export default TimesheetMainPageUS;
