import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { indigo } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, useTheme, Button, Modal, Typography } from "@mui/material";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CloseIcon from "@mui/icons-material/Close";
import { getCalendarPdfUrl } from "shared/utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Checkbox from "@mui/material/Checkbox";
import {dashboardStyle}  from "shared/utils";
import useAWSDetails from "shared/useAWSDetails";

function ManageApplications() {
  const { id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [open, setOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectEmployeeIdFromRow, setSelectEmployeeIdFromRow] = useState("");
  const [status, setStatus] = useState("");
  const { fetchedBucketName, fetchedRegion} = useAWSDetails();

  
  // Assuming you're using fetch to make API calls
  // const [fetchedBucketName, setFetchedBucketName] = useState("");
  // const [fetchedRegion, setFetchedRegion] = useState("");
//   async function fetchAWSDetails() {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_URL}getAWSDetails`);
//       const data = response.data;
//       const { bucketName, region } = data;
      
//       setFetchedBucketName(bucketName);
//       setFetchedRegion(region);
//       // Now you can use the bucketName and region in your frontend code
//       console.log('Bucket Name:', bucketName);
//       console.log('Region:', region);
  
//       // Example usage
//       // initializeS3Client(bucketName, region);
//     } catch (error) {
//       console.error('Error fetching AWS details:', error.message);
//     }
//   }

// useEffect(() => {
//   fetchAWSDetails();
// }, [])

  const handleRowCheckboxChange = (rowId) => {
    console.log("row");
    const selectedIndex = selectedRowIds.indexOf(rowId);
    let newSelectedRowIds = [];

    if (selectedIndex === -1) {
      newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds, rowId);

      // Get the employee ID from the selected row
      const selectedRow = rows.find((row) => row.id === rowId);
      setSelectEmployeeIdFromRow(selectedRow.employeeId);
    } else {
      newSelectedRowIds = selectedRowIds.filter((id) => id !== rowId);
    }

    setSelectedRowIds(newSelectedRowIds);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "currentopenings/getopeningsPdf")
      .then((response) => {
        setUsers(response.data);
        console.log("applications1: " + response.data);
      })
      .catch((error) => {
        console.error("Error fetching calendar data:", error);
      });
  }, []);


    const fetchApplications = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}currentopenings/fetchapplications/${id}`
        );
        setApplications(response.data);
      } catch (error) {
        console.error("Error fetching applications:", error);
      }
    };


  useEffect(() => {
    fetchApplications();
  }, []);

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    boxShadow: 24,
    padding: "20px", // Adjust padding for inner content spacing
    borderRadius: "8px",
    width: "80%", // Adjust width for the modal
    height: "90%", // Adjust height for the modal
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  };

  const rows = applications.map((application) => ({
    id: application._id, // or another unique identifier
    date: application.date,
    email: application.email,
    resume: application.resume,
    name: application.name, // Add the name field
    appliedBy: application.appliedBy,
    status: application.status,
  }));

  // Columns configuration for the DataGrid
  const columns = [
    {
      field: "checkbox",
      headerName: "",
      flex: 1,
      renderCell: (params) => (
        <Checkbox
          checked={
            selectedRowIds.includes(params.row.id) ||
            params.row.status === "Hired" ||
            params.row.status === "Rejected"
          }
          onChange={() => handleRowCheckboxChange(params.row.id)}
          disabled={
            params.row.status === "Hired" || params.row.status === "Rejected"
          }
        />
      ),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Date
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Name
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Email
        </Typography>
      ),
    },
    {
      field: "appliedBy",
      headerName: "Applied By",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Applied by
        </Typography>
      ),
    },
    {
      field: "resume",
      headerName: "Resume",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Resume
        </Typography>
      ),
      renderCell: (params) => (
        <IconButton
          onClick={() =>
            handleOpen(
              `https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/openings/applications/resumes/${
                params.row.resume
              }`
            )
          }
          sx={{ color: "#04497a" }}
        >
          <RemoveRedEyeIcon />
        </IconButton>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Status
        </Typography>
      ),
      renderCell: (params) => (
        <div
          style={{
            color:
              params.value === "Hired"
                ? "#4caf50"
                : params.value === "Pending"
                ? "#ff9800"
                : params.value === "Rejected" // Add styling for "Rejected" status
                ? "#f44336" // Red color
                : "inherit",
            background:
              params.value === "Hired"
                ? "#c8e6c9"
                : params.value === "Rejected" // Add background for "Rejected" status
                ? "#ffcdd2" // Light red background
                : params.value === "Rejected"
                ? "#cce5ff"
                : "inherit",
            borderRadius: "4px",
            padding: "8px",
            display: "inline-block",
            width: "90px",
            textAlign: "center",
          }}
        >
          {params.value}
        </div>
      ),
    },
  ];

  const handleClose = () => setOpen(false);

  const handleOpen = (url) => {
    setPdfUrl(url);
    setOpen(true);
  };

  //Submiting timesheet (Changing timesheet status to "Submitted")
  const handleSubmitStatus = async (status) => {
    try {
      const updatedApplications = await Promise.all(
        selectedRowIds.map(async (rowId) => {
          const response = await axios.put(
            `${process.env.REACT_APP_API_URL}currentopenings/updateStatus/${rowId}`,
            { status }
          );
          return response.data;
        })
      );

      // Update the applications state with the updated applications
      setApplications((prevApplications) =>
        prevApplications.map(
          (application) =>
            updatedApplications.find(
              (updatedApp) => updatedApp._id === application._id
            ) || application
        )
      );

      // Clear the selected row IDs
      setSelectedRowIds([]);
    } catch (error) {
      console.error("Error updating application status:", error);
    }
  };

  return (
    <div>
      <div>
        <FlexBetween sx={{ position: "fixed" }}>
          <IconButton
            onClick={() => navigate(-1)}
            style={{ color: "#04497a", marginTop: "-6px", marginLeft: "10px" }}
          >
            <ArrowBackIcon />
          </IconButton>
        </FlexBetween>
      </div>

      <Box m="1.5rem 2.5rem">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                color: "crimson",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Holiday Calendar
            </Typography>
            {pdfUrl && (
              <iframe
                src={pdfUrl}
                width="95%"
                height="90%"
                title="PDF viewer"
                style={{ margin: "auto" }}
              />
            )}
          </Box>
        </Modal>

        <FlexBetween sx={{ marginBottom: "10px" }}>
          <Header title={"Manage Applications"} />
          <FlexBetween sx={{ justifyContent: "flex-end", width: "350px" }}>
            {/* {selectedRowIds.length > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontSize: "14px",
                    fontWeight: "bolder",
                    padding: "10px 10px",
                    marginRight: "10px",
                  }}
                  onClick={() =>setStatus("Hired")}
                >
                  Hired
                </Button>
              )}
              {selectedRowIds.length > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontSize: "14px",
                    fontWeight: "bolder",
                    padding: "10px 10px",
                  }}
                  onClick={() =>setStatus("Reject")}
                >
                  Reject
                </Button>
              )} */}

            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bolder",
                padding: "10px 10px",
                marginRight: "10px",
              }}
              onClick={() => handleSubmitStatus("Hired")}
            >
              Hired
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bolder",
                padding: "10px 10px",
              }}
              onClick={() => handleSubmitStatus("Rejected")}
            >
              Reject
            </Button>
          </FlexBetween>
        </FlexBetween>
        <div>
          <ThemeProvider theme={lightTheme}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 20]}
                pageSizeOptions={[5, 10]}
                sx={dashboardStyle(theme, "485px")}
              />
          </ThemeProvider>
        </div>
      </Box>
    </div>
  );
}

export default ManageApplications;
