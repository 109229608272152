import React, { useEffect, useState } from 'react';
import "./resetPassword.css";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import FlexBetween from "shared/FlexBetween";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function ResetPassword() {
    const [prevPassword, setPrevPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loggedUserEmail, setLoggedUserEmail] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_URL + "profilepage", { withCredentials: true })
            .then((response) => {
                setLoggedUserEmail(response.data.email);
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    }, []);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            toast.error("New password and confirm password do not match");
            return;
        }
        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + "resetPassword", {
                email: loggedUserEmail,
                prevPassword: prevPassword,
                newPassword: newPassword
            });
            navigate("/login");
            toast.success(response.data.message);
        } catch (error) {
            toast.error(error.response.data.error);
        }
    };


    return (
        <div>


            <FlexBetween sx={{}}>
                <IconButton onClick={() => navigate(-1)} style={{ color: "#04497a", position: "absolute", marginTop: "50px", marginLeft: "10px" }}>
                    <ArrowBackIcon />
                </IconButton>
            </FlexBetween>
            <div>


                <div style={{ marginTop: "50px" }}>
                    <form className="form_container" onSubmit={handleResetPassword} style={{ width: "40%" }}>
                        <div className="title_container" >
                            <p className="title">Reset Password</p>
                        </div>
                        <br />
                        <h3 style={{ textAlign: "center", color: "black" }}>{loggedUserEmail}</h3>
                        <div className="input_container">
                            {/* <label className="input_label" htmlFor="prev_password_field">Previous Password</label> */}
                            <input placeholder="Previous Password" name="prevPassword" type="password" className="input_field" id="prev_password_field" value={prevPassword} onChange={(e) => setPrevPassword(e.target.value)} />
                        </div>
                        <div className="input_container">
                            {/* <label className="input_label" htmlFor="new_password_field">New Password</label> */}
                            <input placeholder="New Password" name="newPassword" type="password" className="input_field" id="new_password_field" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                        </div>
                        <div className="input_container">
                            {/* <label className="input_label" htmlFor="new_password_field">Confirm Password</label> */}
                            <input placeholder="New Password" name="newPassword" type="password" className="input_field" id="new_password_field" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                        </div>
                        <button type="submit" className="sign-in_btn">
                            <span>Reset Password</span>
                        </button>
                    </form>
                    <ToastContainer />
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
