import React, { useEffect, useState } from "react";
import "../create-user/createUser.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import FlexBetween from "shared/FlexBetween";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ConfirmationDialog from "shared/ConfirmationDialog";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "shared/spinner/Spinner";

function UpdateUser() {
  const theme = useTheme();

  const [employeeId, setEmployeeId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [file, setFile] = useState();

  const [department, setDepartment] = useState("");
  const [workLocation, setWorkLocation] = useState("");
  const [role, setRole] = useState("");
  const [loggedUserRole, setLoggedUserRole] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [groups, setGroups] = useState([]);
  const [uploadConfirmationOpen, setUploadConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [hiringManager, setHiringManager] = useState("");
  const [mailDistributionList, setMailDistributionList] = useState([]);
  const navigate = useNavigate();

  const [emailError, setEmailError] = useState("");
  const [numberError, setNumberError] = useState("");

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "getUser/" + id) // Update this endpoint to the correct one
      .then((result) => {
        console.log(result);
        setEmployeeId(result.data.employeeId);
        setName(result.data.name);
        setEmail(result.data.email);
        setNumber(result.data.number);
        setBirthDate(result.data.birthDate);
        setGender(result.data.gender);
        setAddress(result.data.address);
        setDepartment(result.data.department);
        setWorkLocation(result.data.workLocation);
        setRole(result.data.role);
        setSelectedGroup(result.data.groupname);
        setFile(result.data.profilepic);
        setSelectedCalendar(result.data.calendarName);
        setSelectedCountry(result.data.country);
        setHiringManager(result.data.hiringManager);
        setMailDistributionList(
          Array.isArray(result.data.mailDistributionList)
            ? result.data.mailDistributionList.join(", ")
            : result.data.mailDistributionList || ""
        );
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "groups/getgroups")
      .then((result) => {
        setGroups(result.data);
      });
  }, []);

  useEffect(() => {
    fetchCalendars();
  }, []);

  const fetchCalendars = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}holidayCalendars/calendar`
      );
      setCalendars(response.data);
      console.log("calendars", response.data);
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      })
      .then((response) => {
        setLoggedUserRole(response.data.role);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  const Update = (e) => {
    e.preventDefault();

    // Reset validation errors
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setUploadConfirmationOpen(false);
      setEmailError("Please enter a valid email address");
      toast.error("Please enter a valid email address");
      return;
    }

    // Phone number validation
    const phoneRegex = /^\d{10}$/; // Assuming 10-digit format
    if (!phoneRegex.test(number)) {
      setUploadConfirmationOpen(false);
      setNumberError("Please enter a valid 10-digit phone number");
      toast.error("Please enter a valid 10-digit phone number");
      return;
    }

    const emailArray =
      typeof mailDistributionList === "string"
        ? mailDistributionList.split(",").map((email) => email.trim())
        : Array.isArray(mailDistributionList)
        ? mailDistributionList
        : [];

    setIsLoading(true);
    // Create FormData object
    const formData = new FormData();
    formData.append("employeeId", employeeId);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("number", number);
    formData.append("birthDate", birthDate);
    formData.append("gender", gender);
    formData.append("address", address);
    formData.append("department", department);
    formData.append("workLocation", workLocation);
    formData.append("role", role);
    formData.append("calendarName", selectedCalendar);
    formData.append("groupname", selectedGroup);
    formData.append("country", selectedCountry);
    formData.append("file", file); // Append profile picture file
    formData.append("hiringManager", hiringManager);
    formData.append("mailDistributionList", JSON.stringify(emailArray));

    axios
      .put(process.env.REACT_APP_API_URL + "updateUser/" + id, formData)
      .then((result) => {
        console.log(result);
        toast.info(
          "Employee account updated successfully. You will be redirected to the employee page in 5 seconds.",
          {
            autoClose: 5000,
            onClose: () => navigate("/employee"),
          }
        );
        setUploadConfirmationOpen(false);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.error(err.response.data.message);
        } else {
          console.log(err);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleConfirmationSubmit = () => {
    setUploadConfirmationOpen(true);
  };

  const handleUploadCancel = () => {
    // Close the confirmation dialog
    setUploadConfirmationOpen(false);
  };
  return (
    <div>
      <FlexBetween sx={{ position: "fixed" }}>
        <IconButton
          onClick={() => navigate(-1)}
          style={{ color: "#04497a", marginTop: "10px", marginLeft: "10px" }}
        >
          <ArrowBackIcon />
        </IconButton>
      </FlexBetween>

      {isLoading && <Spinner />}
      <div
        className="main-wrapper"
        style={{ color: theme.palette.background.text }}
      >
        <div className="form-wrapper">
          <form>
            <div className="formbold-form-title">
              <h3 className="header">Update User</h3>
            </div>

            <div className="grid-2-cols">
              {/* Personal Information Section */}
              <div className="form-section">
                <h4>Personal Information</h4>

                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Employee ID</label>
                  <input
                    type="text"
                    name="employeeId"
                    className="formbold-form-input"
                    value={employeeId}
                    disabled
                    onChange={(e) => setEmployeeId(e.target.value)}
                  />
                </div>

                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Full Name</label>
                  <input
                    type="text"
                    name="name"
                    className="formbold-form-input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "15px",
                  }}
                >
                  <div className="formbold-mb-3">
                    <label className="formbold-form-label">Date of Birth</label>
                    <input
                      type="date"
                      name="date"
                      className="formbold-form-input"
                      value={birthDate}
                      onChange={(e) => setBirthDate(e.target.value)}
                    />
                  </div>

                  <div className="formbold-mb-3">
                    <label className="formbold-form-label">Gender</label>
                    <select
                      className="formbold-form-input"
                      name="gender"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="">Choose...</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="others">Others</option>
                    </select>
                  </div>
                </div>

                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Address</label>
                  <input
                    type="text"
                    name="address"
                    className="formbold-form-input"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>

              {/* Contact Information Section */}
              <div className="form-section">
                <h4>Contact Information</h4>

                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="formbold-form-input"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError("");
                    }}
                  />
                  {emailError && (
                    <div
                      className="text-danger"
                      style={{ fontSize: "12px", marginTop: "5px", color: "red" }}
                    >
                      {emailError}
                    </div>
                  )}
                </div>

                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Phone Number</label>
                  <input
                    type="number"
                    name="phone"
                    className="formbold-form-input"
                    value={number}
                    onChange={(e) => {
                      setNumber(e.target.value);
                      setNumberError("");
                    }}
                  />
                  {numberError && (
                    <div
                      className="text-danger"
                      style={{ fontSize: "12px", marginTop: "5px", color: "red" }}
                    >
                      {numberError}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Professional Information Section */}
            <div className="form-section">
              <h4>Professional Information</h4>

              <div className="grid-3-cols">
                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Department</label>
                  <select
                    className="formbold-form-input"
                    name="department"
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                  >
                    <option value="">Choose...</option>
                    <option value="Engineering">Engineering</option>
                    <option value="HRBP/Admin">HRBP/Admin</option>
                    <option value="Human Resource">Human Resource</option>
                    <option value="Business">Business</option>
                  </select>
                </div>

                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Country</label>
                  <select
                    className="formbold-form-input"
                    name="country"
                    value={selectedCountry}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                  >
                    <option value="">Choose...</option>
                    <option value="India">India</option>
                    <option value="US">US</option>
                  </select>
                </div>

                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Work Location</label>
                  {selectedCountry === "India" ? (
                    <select
                      className="formbold-form-input"
                      name="workLocation"
                      value={workLocation}
                      onChange={(e) => setWorkLocation(e.target.value)}
                      disabled={!selectedCountry}
                    >
                      <option value="">Choose...</option>
                      <option value="Banglore">Bangalore</option>
                      <option value="Pune">Pune</option>
                      <option value="Kolkata">Kolkata</option>
                      <option value="Remote">Remote</option>
                    </select>
                  ) : (
                    <input
                      type="text"
                      name="workLocation"
                      className="formbold-form-input"
                      value={workLocation}
                      onChange={(e) => setWorkLocation(e.target.value)}
                      disabled={!selectedCountry}
                    />
                  )}
                </div>
              </div>

              <div className="formbold-mb-3">
                <label className="formbold-form-label">Role</label>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                    gap: "15px",
                    marginTop: "10px",
                  }}
                >
                  {[
                    { value: "admin", label: "Admin" },
                    { value: "hr", label: "HR" },
                    { value: "manager", label: "Manager" },
                    { value: "employee", label: "Employee" },
                    { value: "consultant", label: "Consultant" },
                  ].map((roleOption) => (
                    <label
                      key={roleOption.value}
                      className={`role-radio-label ${
                        role === roleOption.value ? "selected" : ""
                      }`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "12px 20px",
                        border: `1px solid ${
                          role === roleOption.value ? "#04497a" : "#dde3ec"
                        }`,
                        borderRadius: "8px",
                        cursor:
                          loggedUserRole === "employee" ||
                          (loggedUserRole === "consultant" &&
                            roleOption.value === "consultant")
                            ? "not-allowed"
                            : "pointer",
                        backgroundColor:
                          role === roleOption.value ? "#04497a" : "#ffffff",
                        color:
                          role === roleOption.value ? "#ffffff" : "#536387",
                        transition: "all 0.3s ease",
                        opacity:
                          loggedUserRole === "employee" ||
                          (loggedUserRole === "consultant" &&
                            roleOption.value === "consultant")
                            ? 0.6
                            : 1,
                      }}
                    >
                      <input
                        type="radio"
                        name="role"
                        value={roleOption.value}
                        checked={role === roleOption.value}
                        onChange={(e) => setRole(e.target.value)}
                        disabled={
                          loggedUserRole === "employee" ||
                          (loggedUserRole === "consultant" &&
                            roleOption.value === "consultant")
                        }
                        style={{ marginRight: "10px" }}
                      />
                      {roleOption.label}
                    </label>
                  ))}
                </div>
              </div>

              <div className="grid-2-cols">
                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Group</label>
                  <select
                    className="formbold-form-input"
                    name="group"
                    value={selectedGroup}
                    onChange={(e) => setSelectedGroup(e.target.value)}
                  >
                    <option value="">{`Current Group - ${selectedGroup}`}</option>
                    {groups.map((group) => (
                      <option key={group._id} value={group.groupname}>
                        {group.groupname}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Calendar</label>
                  <select
                    className="formbold-form-input"
                    name="calendar"
                    value={selectedCalendar}
                    onChange={(e) => setSelectedCalendar(e.target.value)}
                  >
                    <option value="">Choose...</option>
                    {calendars.map((calendar) => (
                      <option key={calendar._id} value={calendar.calendarName}>
                        {calendar.calendarName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="grid-2-cols">
                <div className="formbold-mb-3">
                  <label className="formbold-form-label">
                    Hiring Manager Email
                  </label>
                  <input
                    type="email"
                    name="hiringManager"
                    className="formbold-form-input"
                    value={hiringManager}
                    onChange={(e) => setHiringManager(e.target.value)}
                  />
                </div>

                <div className="formbold-mb-3">
                  <label className="formbold-form-label">
                    Mail Distribution List
                    <span
                      style={{
                        fontSize: "10px",
                        marginLeft: "5px",
                        color: "gray",
                      }}
                    >
                      (Enter emails separated by comma for timesheet)
                    </span>
                  </label>
                  <input
                    type="email"
                    name="mailDistributionList"
                    className="formbold-form-input"
                    value={mailDistributionList}
                    onChange={(e) => setMailDistributionList(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div style={{ textAlign: "center" }}>
              <button
                type="button"
                onClick={handleConfirmationSubmit}
                className="formbold-btn"
              >
                Update User
              </button>
            </div>
          </form>
        </div>
      </div>

      <ConfirmationDialog
        open={uploadConfirmationOpen}
        handleClose={handleUploadCancel}
        handleConfirm={Update}
        message="Are you sure you want to update this user?"
      />
      <ToastContainer />
    </div>
  );
}

export default UpdateUser;
