import React from 'react'
import FlexBetween from "shared/FlexBetween";
import { Box, useTheme, Button } from "@mui/material";
import Header from "shared/Header";

function EpfPortal() {
	return (
		<div>
			<Box m="2rem 2.5rem" height="100%">
				<FlexBetween>
					<Header
						title={"EPF Portal"}
					/>
				</FlexBetween>

				<div>
					<iframe src="https://www.epfindia.gov.in/" frameborder="0"></iframe>
				</div>
			</Box> 
		</div>
	)
}

export default EpfPortal
