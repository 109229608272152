import { useTheme } from "@mui/material";

export const getCalendarPdfUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return "connect_development";
  } else {
    return "connect";
  }
};

export const dashboardStyle = (theme, height) => ({
  paddingLeft: "15px",
  color: theme.palette.background.text,
  backgroundColor: theme.palette.primary.main,
  borderRadius: "15px",
  boxShadow: "13px 22px 19px 0px rgba(0,0,0,0.2)",
  border: "1px solid #DBE2EF",
  fontWeight: "light",
  fontSize: "13px",
  height: height || "405px",
  width: '100%', // Ensure the DataGrid takes up full width
  '& .MuiTablePagination-root': {
    color: theme.palette.background.text,
  },
  '.MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '&.MuiDataGrid-root': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    color: "#04497a",
    marginLeft: '-2px',
  },
  '& .MuiDataGrid-cell': {
    padding: '0', // Remove cell padding to eliminate space
  },
});


// DataGrid specific selectors
//  '& .MuiDataGrid-root': {
//   border: 'none',
// },
// '& .MuiDataGrid-cell': {
//   color: theme.palette.text.primary,
// },
// '& .MuiDataGrid-columnHeaders': {
//   backgroundColor: theme.palette.background.paper,
//   color: theme.palette.text.secondary,
//   borderBottom: '1px solid #DBE2EF',
// },
// '& .MuiDataGrid-columnsContainer': {
//   backgroundColor: theme.palette.background.paper,
// },
// '& .MuiDataGrid-columnHeader': {
//   backgroundColor: theme.palette.background.paper,
//   color: theme.palette.text.secondary,
// },
// '& .MuiDataGrid-columnHeaderTitle': {
//   fontWeight: 'bold',
// },
// '& .MuiDataGrid-columnSeparator': {
//   display: 'none',
// },
// '& .MuiDataGrid-footerContainer': {
//   backgroundColor: theme.palette.background.paper,
//   color: theme.palette.text.secondary,
// },
// '& .MuiDataGrid-toolbarContainer': {
//   backgroundColor: theme.palette.background.paper,
//   color: theme.palette.text.secondary,
// },
// '& .MuiDataGrid-row': {
//   '&:hover': {
//     backgroundColor: theme.palette.action.hover,
//   },
// },
// '& .MuiDataGrid-row.Mui-selected': {
//   backgroundColor: theme.palette.action.selected,
// },
// '& .MuiDataGrid-row.Mui-selected:hover': {
//   backgroundColor: theme.palette.action.selectedHover,
// },
// '& .MuiDataGrid-overlay': {
//   backgroundColor: theme.palette.background.default,
// },
// '& .MuiDataGrid-virtualScroller': {
//   backgroundColor: theme.palette.background.default,
// },

export const tabStyles = (theme, selectedTab) => ({
  // '& .MuiTab-root': {
  //   flexDirection: 'row',
  //   justifyContent: 'flex-start',
  //   border: '1px solid #e5e5e5',
  //   fontWeight: 'bold',
  //   borderRadius: '15px',
  //   backgroundColor: (index) => (selectedTab === index ? '#04497a' : 'initial'),
  //   color: (index) => (selectedTab === index ? 'white' : '#04497a'),
  //   '&.Mui-selected': {
  //     backgroundColor: '#04497a',
  //     color: 'white',
  //   }
  // },

  flexDirection: 'row',
  justifyContent: 'flex-start',
  border: '1px solid #e5e5e5',
  fontWeight: 'bold',
  borderRadius: '15px',
  backgroundColor: (index) => (selectedTab === index ? '#04497a' : 'initial'),
  color: (index) => (selectedTab === index ? 'white' : '#04497a'),
  '&.Mui-selected': {
    backgroundColor: '#04497a',
    color: 'white',
  }
});