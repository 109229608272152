import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import {
  Box,
  Button,
  useTheme,
  Tab,
  Tabs,
  Typography,
  Avatar,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import ConfirmationDialog from "shared/ConfirmationDialog";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import CreateGroups from "../create-groups/CreateGroups";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { getCalendarPdfUrl } from "shared/utils";
import { dashboardStyle, tabStyles } from "shared/utils";
import Spinner from "shared/spinner/Spinner";
import useAWSDetails from "shared/useAWSDetails";

function EmployeeTable() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [switchConfirmationOpen, setSwitchConfirmationOpen] = useState(false);
  const [switchConfirmationData, setSwitchConfirmationData] = useState({
    userId: null,
    checked: false,
  });
  // const [fetchedBucketName, setFetchedBucketName] = useState("");
  // const [fetchedRegion, setFetchedRegion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { fetchedBucketName, fetchedRegion } = useAWSDetails();

  //   async function fetchAWSDetails() {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API_URL}getAWSDetails`);
  //       const data = response.data;
  //       const { bucketName, region } = data;

  //       setFetchedBucketName(bucketName);
  //       setFetchedRegion(region);
  //       // Now you can use the bucketName and region in your frontend code
  //       console.log('Bucket Name:', bucketName);
  //       console.log('Region:', region);

  //       // Example usage
  //       // initializeS3Client(bucketName, region);
  //     } catch (error) {
  //       console.error('Error fetching AWS details:', error.message);
  //     }
  //   }

  // useEffect(() => {
  //   fetchAWSDetails();
  // }, [])

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL) // Update this endpoint to the correct one
      .then((result) => setUsers(result.data))
      .catch((err) => console.log(err));
  }, []);

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSwitchChange = (id, checked) => {
    const updatedUsers = users.map((user) => {
      if (user._id === id) {
        user.active = checked;
        const message = checked ? "User activated" : "User deactivated";
        checked ? toast.success(message) : toast.error(message);
      }
      return user;
    });

    setUsers(updatedUsers);

    axios
      .put(`${process.env.REACT_APP_API_URL}updateUser/${id}`, {
        active: checked,
      })
      .then((result) => {
        console.log(result);
        setSwitchConfirmationOpen(false);
      })
      .catch((err) => console.log(err));
  };

  const handleSwitchConfirmation = (userId, checked) => {
    setSwitchConfirmationData({ userId, checked });
    setSwitchConfirmationOpen(true);
  };

  const handleDeleteConfirmation = (id) => {
    setSelectedUserId(id);
    setConfirmationOpen(true);
  };

  const handleDelete = (id) => {
    setIsLoading(true);
    axios
      .delete(process.env.REACT_APP_API_URL + "deleteUser/" + id)
      .then((result) => {
        setUsers((prevUsers) => prevUsers.filter((user) => user._id !== id));
        setConfirmationOpen(false);
        toast.success("User deleted successfully");
        console.log(result);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const rows = users
    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
    .map((user) => ({
      id: user._id,
      emp_id: user.employeeId,
      profilepic: user.profilepic,
      name: user.name,
      email: user.email,
      number: user.number,
      role: user.role,
      workLocation: user.workLocation,
      department: user.department,
      createdOn: user.createdOn,
      updatedOn: user.updatedOn,
      action: null,
      status: user.active,
    }));

  const columns = [
    {
      field: "emp_id",
      headerName: "Emp Id",
      flex: 0.5,
      renderHeader: () => (
        <Typography
          fontSize="14px" fontWeight="bold"
        >
          Emp Id
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: "Employee",
      flex: 2,
      renderHeader: () => (
        <Typography
          fontSize="14px" fontWeight="bold"
        >
          Employee
        </Typography>
      ),
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            src={`https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/employees/${params.row.emp_id
              }/profile-picture/${params.row.profilepic}`}
            sx={{ width: "32px", height: "32px", marginRight: "10px" }}
          />
          <div className="text">
            <p
              style={{ fontWeight: "bold", fontSize: "14px", color: "#222836" }}
            >
              {params.value}
            </p>
            <p>{params.row.email}</p>
          </div>
        </div>
      ),
    },
    {
      field: "number",
      headerName: "Phone number",
      flex: 1,
      renderHeader: () => (
        <Typography
          fontSize="14px" fontWeight="bold"
        >
          Phone number
        </Typography>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderHeader: () => (
        <Typography
          fontSize="14px" fontWeight="bold"
        >
          Role
        </Typography>
      ),
      renderCell: (params) => (
        <div style={{ alignItems: "center" }}>
          <p style={{ fontWeight: "bold", fontSize: "14px", color: "#222836" }}>
            {params.value}
          </p>
          <p>{params.row.department}</p>
        </div>
      ),
    },
    {
      field: "workLocation",
      headerName: "Work Location",
      flex: 1,
      renderHeader: () => (
        <Typography
          fontSize="14px" fontWeight="bold"
        >
          Work Location
        </Typography>
      ),
    },
    {
      field: "updatedOn",
      headerName: "Updated On",
      flex: 1,
      renderHeader: () => (
        <Typography
          fontSize="14px" fontWeight="bold"
        >
          Updated On
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderHeader: () => (
        <Typography
          fontSize="14px" fontWeight="bold"
        >
          Action
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <Link to={`/updateuser/${params.id}`}>
            <EditIcon
              style={{
                marginLeft: "5px",
                marginRight: "10px",
                color: "#04497a",
                fontSize: "25px",
              }}
            />
          </Link>
          <DeleteIcon
            style={{ cursor: "pointer", color: "#04497a", fontSize: "25px" }}
            onClick={() => handleDeleteConfirmation(params.id)}
          />
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderHeader: () => (
        <Typography
          fontSize="14px" fontWeight="bold"
        >
          Status
        </Typography>
      ),
      renderCell: (params) => (
        <div style={{ display: "flex", alignContent: "center" }}>
          <FormControlLabel
            control={
              <Switch
                checked={params.row.status} // Use the status field from the row
                onChange={() =>
                  handleSwitchConfirmation(params.id, !params.row.status)
                }
                sx={{
                  "& .MuiSwitch-thumb": {
                    color: "#04497a",
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "#222831",
                  },
                  margin: "auto",
                }}
              />
            }
          />
        </div>
      ),
    },
  ];

  const styles = tabStyles(theme, selectedTab);

  return (
    <div>
      <Box m=".5rem 2.5rem" height="calc(100vh - 275px)">
        {/* Loading Overlay */}
        {isLoading && <Spinner />}
        <Box>
          <ThemeProvider theme={lightTheme}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="fullWidth"
              sx={{ marginBottom: "20px", height: "60px", color: "white", borderBottom: "1px solid #e5e5e5" }}
            >
              <Tab
                label="Manage Employees"
                icon={<ManageAccountsOutlinedIcon sx={{ marginRight: '4px' }} />}
                sx={styles}
              />
              <Tab
                label="Manage Groups"
                icon={<GroupsOutlinedIcon sx={{ marginRight: '4px' }} />}
                sx={styles}
              />


              {/* Add more tabs as needed */}
            </Tabs>
          </ThemeProvider>
        </Box>

        {selectedTab === 0 && (
          <div>
            <FlexBetween sx={{ marginBottom: "20px" }}>
              <Header
                title="Manage Employees"
                subtitle="Add, Delete or Manage Employee Accounts"
              />
              <Box>
                <Button
                  variant="contained"
                  onClick={() => navigate("/createuser")}
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontSize: "14px",
                    fontWeight: "bolder",
                    padding: "10px 20px",
                  }}
                >
                  <PersonAddAltIcon sx={{ mr: "10px" }} />
                  Add Employee
                </Button>
              </Box>
            </FlexBetween>
            <ThemeProvider theme={lightTheme}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 20]}
                pageSizeOptions={[5, 10]}
                rowHeight={60}
                sx={dashboardStyle(theme, "405px")}
              />
            </ThemeProvider>

            <ToastContainer />
            <ConfirmationDialog
              open={confirmationOpen}
              handleClose={() => setConfirmationOpen(false)}
              handleConfirm={() => handleDelete(selectedUserId)}
              message="Are you sure you want to delete this user?"
            />
            <ConfirmationDialog
              open={switchConfirmationOpen}
              handleClose={() => setSwitchConfirmationOpen(false)}
              handleConfirm={() =>
                handleSwitchChange(
                  switchConfirmationData.userId,
                  switchConfirmationData.checked
                )
              }
              message={`Are you sure you want to ${switchConfirmationData.checked ? "activate" : "deactivate"
                } this user?`}
            />
          </div>
        )}

        {selectedTab === 1 && (
          <div>
            {/* Content for Tab 2 */}
            <CreateGroups />
          </div>
        )}
      </Box>
    </div>
  );
}

export default EmployeeTable;
