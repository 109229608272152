import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const useAWSDetails = () => {
  const [fetchedBucketName, setFetchedBucketName] = useState('');
  const [fetchedRegion, setFetchedRegion] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiAWSCalledRef = useRef(false);

  useEffect(() => {
    if (apiAWSCalledRef.current) {
      return;
    }
    apiAWSCalledRef.current = true;

    async function fetchAWSDetails() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}getAWSDetails`
        );
        const data = response.data;
        const { bucketName, region } = data;

        setFetchedBucketName(bucketName);
        setFetchedRegion(region);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching AWS details:", error.message);
        setError(error.message);
        setLoading(false);
      }
    }

    fetchAWSDetails();
  }, []);

  return { fetchedBucketName, fetchedRegion, loading, error };
};

export default useAWSDetails;