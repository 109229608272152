import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import { Box, useTheme, Typography, Modal } from "@mui/material";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from '@mui/icons-material/Download';
import { getCalendarPdfUrl, dashboardStyle } from 'shared/utils';
import useAWSDetails from "shared/useAWSDetails";

function PayslipTable() {
  const theme = useTheme();
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [employeeId, setEmployeeId] = useState("");
  const [fileName, setFileName] = useState("");
  const [open, setOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  // const [fetchedBucketName, setFetchedBucketName] = useState("");
  // const [fetchedRegion, setFetchedRegion] = useState("");
  const [role, setRole] = useState("")
  const { fetchedBucketName, fetchedRegion } = useAWSDetails();

  //   async function fetchAWSDetails() {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API_URL}getAWSDetails`);
  //       const data = response.data;
  //       const { bucketName, region } = data;

  //       setFetchedBucketName(bucketName);
  //       setFetchedRegion(region);
  //       // Now you can use the bucketName and region in your frontend code
  //       console.log('Bucket Name:', bucketName);
  //       console.log('Region:', region);

  //       // Example usage
  //       // initializeS3Client(bucketName, region);
  //     } catch (error) {
  //       console.error('Error fetching AWS details:', error.message);
  //     }
  //   }

  // useEffect(() => {
  //   fetchAWSDetails();
  // }, [])

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", { withCredentials: true })
      .then((response) => {
        setUser(response.data);
        setEmployeeId(response.data.employeeId);
        setRole(response.data.role)
        // Fetch attendance data based on employee ID

        axios
          .get(process.env.REACT_APP_API_URL + `payslips/getpayslips/${response.data.employeeId}`)
          .then((response) => {
            setUsers(response.data.payslips);
            setFileName(response.data.payslips.filename)
          })
          .catch((error) => {
            console.error("Error fetching payslips data:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        if (error.response && error.response.status === 401) {
          toast.warning("Your account has been disabled. If you think this is an error contact HR. You will be logged out soon.", {
            onClose: () => {
              // Clear the token from cookies
              document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              // Reload the page after a delay
              setTimeout(() => {
                window.location.reload();
              }, 3000); // 5 seconds delay
            }
          });
        }
      });
  }, []);

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const rows = users.reverse().map((user) => ({
    id: user._id, // or another unique identifier
    year: user.year,
    month: user.month,
    filename: user.filename,
    createdOn: user.createdOn,
    updatedOn: user.updatedOn,
    download: null,
  }));

  const handleOpen = (url) => {
    setPdfUrl(url);
    setOpen(true);
  };

  const downloadPdf = async (filename, pdfUrl) => {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename; // Set the file name to the original filename
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };


  const rowsWithSerialNumber = rows.map((row, index) => ({ ...row, id: index + 1 }));
  const handleClose = () => setOpen(false);

  const columns = [
    {
      field: "id", headerName: "Sr. No.", width: 90,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Sr. No.
        </Typography>
      ),
    },
    {
      field: "year", headerName: "Year", flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Year
        </Typography>
      ),
    },
    {
      field: "month", headerName: "Month", flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Month
        </Typography>
      ),
    },
    // { field: "createdOn", headerName: "Created On", flex:  1,
    // renderHeader: () => (
    //   <Typography fontSize="14px" fontWeight="bold">
    //     Created On
    //   </Typography>
    // ), },
    {
      field: "updatedOn", headerName: "Updated On", flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Updated On
        </Typography>
      ),
    },
    {
      field: "view",
      headerName: "View",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          View
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() =>
              handleOpen(
                `https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/employees/${employeeId}/payslips/${params.row.filename}`

              )
            }
            sx={{ color: "#04497a" }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: "download",
      headerName: "Download",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Download
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => downloadPdf(params.row.filename, `https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/employees/${employeeId}/payslips/${params.row.filename}`)}
          >
            <DownloadIcon
              style={{
                margin: "auto",
                color: "#04497a",
                fontSize: "25px",
                cursor: "pointer",
              }}
            />
          </IconButton>
        </div>
      ),
    },
  ];

  const tableHeight = users.length > 10 ? 55 * 10 : 64 * users.length + 100;
  const totalColumnWidth = columns.reduce((total, column) => total + column.width, 0);
  const maxWidth = totalColumnWidth + 48; // Add some extra width for padding and scrollbar

  useEffect(() => {

  }, [user])

  return (
    <>
      <ThemeProvider theme={lightTheme} >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              boxShadow: 24,
              padding: "20px", // Adjust padding for inner content spacing
              borderRadius: "8px",
              width: "50%", // Adjust width for the modal
              height: "90%", // Adjust height for the modal
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              View Payslip
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "black"
              }}
            >
              <CloseIcon />
            </IconButton>
            <iframe src={pdfUrl} width="95%" height="90%" title="PDF viewer" style={{ margin: "auto" }} />
          </Box>
        </Modal>
      </ThemeProvider>
      <FlexBetween />
      <Box m={role === "employee" || role === "consultant" || role === "manager" ? "2rem 2.5rem" : "0"}>
        <FlexBetween>
          <Header
            title={user.name + "'s Payslips"}
            subtitle={"Employee Id: " + employeeId}
          />
        </FlexBetween>
        <ThemeProvider theme={lightTheme}>
          <DataGrid
            rows={rowsWithSerialNumber}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 20]}
            pageSizeOptions={[5, 10]}
            sx={dashboardStyle(theme, "480px")}
          />
        </ThemeProvider>
      </Box>

    </>
  );
}

export default PayslipTable;
