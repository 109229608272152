import React, { useEffect, useState } from "react";
import axios from "axios";

import FlexBetween from "shared/FlexBetween";
import { Box, useTheme, Button, Modal, Typography, Tooltip } from "@mui/material";
import Header from "shared/Header";
import { DataGrid } from "@mui/x-data-grid";
import { indigo } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import { getCalendarPdfUrl } from "shared/utils";
import ShareIcon from "@mui/icons-material/Share";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase, faUndo } from "@fortawesome/free-solid-svg-icons";
import { dashboardStyle } from "shared/utils";

function BackupAndRestore() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openApply, setOpenApply] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [date, setDate] = useState();
  const [openingsPdf, setOpeningsPdf] = useState(null);
  const [uploadConfirmationOpen, setUploadConfirmationOpen] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [name, setName] = useState(null);
  const [resume, setResume] = useState(null);
  const [applyId, setApplyId] = useState(null);
  const [openingsFile, setOpeningsFile] = useState(null);
  const [email, setEmail] = useState(null);
  const [loggedInEmail, setLoggedInEmail] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState("");

  const [selectBackup, setSelectBackup] = useState("");
  const [backupConfirmationOpen, setBackupConfirmationOpen] = useState(false);
  const [restoreConfirmationOpen, setRestoreConfirmationOpen] = useState(false);
  const [restoring, setRestoring] = useState(false); // State variable for restoration process
  const [backups, setBackups] = useState([]);
  const [backupName, setBackupName] = useState("")


  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      })
      .then((response) => {
        setLoggedInUser(response.data.name)
        console.log("loggedInEmail: " + response.data.email);
      })
      .catch((err) => console.error(err));
  }, []);

  // Function to handle opening the confirmation dialog
  const handleDeleteConfirmation = (id) => {
    setDeleteId(id);
    setConfirmationOpen(true);
  };

  const handleBackup = () => {
    console.log("LoggedInUser: " + loggedInUser);

    axios
      .get(process.env.REACT_APP_API_URL + `backupdb/backup?loggedInUser=${encodeURIComponent(loggedInUser)}`)
      .then((response) => {
        toast.success("Database backed up successfully");
        fetchBackups();
        console.log("Backup initiated");
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleConfirmDelete = () => {
    // Perform deletion action using the deleteId
    handleDelete(deleteId);
    setConfirmationOpen(false); // Close the confirmation dialog
  };

  // Function to handle canceling the deletion
  const handleDeleteCancel = () => {
    setConfirmationOpen(false); // Close the confirmation dialog
  };

  // const [year, setYear] = useState();

  const handleOpen = (url) => {
    setPdfUrl(url);
    setOpen(true);
  };

  const handleOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleClose = () => setOpen(false);

  const handleCloseUpload = () => {
    setOpenUpload(false);
    setOpenApply(false);
  };

  useEffect(() => {
    fetchBackups();
  }, []);

  const fetchBackups = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}backupdb/fetch-backups`
      );
      setBackups(response.data.Contents);


    } catch (error) {
      console.error("Error fetching backups:", error);
    }
  };

  const handleRestore = () => {
    setRestoring(true); // Set restoring to true when restoration begins
    axios
      .get(process.env.REACT_APP_API_URL + "restoredb/restore", {
        params: { backupName: selectBackup }, // Send the selected backup name as a query parameter
      })
      .then((response) => {
        toast.success("Database restored successfully");
        console.log("Restore initiated");
        fetchBackups();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setRestoring(false); // Set restoring to false when restoration completes
      });
  };

  // Transforming fetched data into rows for the DataGrid
  const rows = backups.reverse().map((backup, index) => ({
    id: index,
    name: backup.Key,
    LastModified: backup.LastModified,
    Size: backup.Size,

    // Add other fields as needed
  }));

  const renderStatusCell = (params) => (
    <Tooltip title={params.value}>
      <div
        style={{
          color:
            params.value === "Pass"
              ? "#388e3c"
              : params.value === "Fail"
                ? "#f44336"
                : "inherit",
          background:
            params.value === "Pass"
              ? "#c8e6c9"
              : params.value === "Fail"
                ? "#ffcdd2"
                : "inherit",
          borderRadius: "4px",
          padding: "8px",
          display: "inline-block",
          width: "90px",
          textAlign: "center",
        }}
      >
        {params.value}
      </div>
    </Tooltip>
  );

  // Columns configuration for the DataGrid
  const columns = [
    {
      field: "name",
      headerName: "Backup Name",
      flex: 1.5,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Backup Name
        </Typography>
      ),
      renderCell: (params) => {
        const fileName = params.value.split('/').pop(); // Slices the key to get the file name
        return (
          <Tooltip title={fileName}>
            <span>{fileName}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "LastModified",
      headerName: "Last Modified",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Last Modified
        </Typography>
      ),
    },
    {
      field: "Size",
      headerName: "File Size",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          File Size
        </Typography>
      ),
      renderCell: (params) => (
        <Tooltip title={`${(params.value / 1024).toFixed(2)} MB`}>
          <span>{(params.value / 1024).toFixed(2)} KB</span>
        </Tooltip>
      ),
    },
    // {
    //   field: "createdBy",
    //   headerName: "Backup Created By",
    //   width: 170,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Backup Created By
    //     </Typography>
    //   ),
    //   renderCell: (params) => (
    //     <Tooltip title={params.value}>
    //       <span>{params.value}</span>
    //     </Tooltip>
    //   ),
    // },
    // {
    //   field: "backupInitialized",
    //   headerName: "Backup Initialized",
    //   width: 170,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Backup Initialized
    //     </Typography>
    //   ),
    //   renderCell: renderStatusCell,
    // },
    // {
    //   field: "backupFileNameGenerated",
    //   headerName: "Backup FileName Generated",
    //   width: 170,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Backup FileName Generated
    //     </Typography>
    //   ),
    //   renderCell: renderStatusCell,
    // },
    // {
    //   field: "backupDirectoryCreated",
    //   headerName: "Backup Directory Created",
    //   width: 170,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Backup Directory Created
    //     </Typography>
    //   ),
    //   renderCell: renderStatusCell,
    // },
    // {
    //   field: "backupFolderCreated",
    //   headerName: "Backup Folder Created",
    //   width: 170,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Backup Folder Created
    //     </Typography>
    //   ),
    //   renderCell: renderStatusCell,
    // },
    // {
    //   field: "backupCompressed",
    //   headerName: "Backup Compressed",
    //   width: 170,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Backup Compressed
    //     </Typography>
    //   ),
    //   renderCell: renderStatusCell,
    // },
    // {
    //   field: "backupUploadedToS3",
    //   headerName: "Backup Uploaded To S3",
    //   width: 170,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Backup Uploaded To S3
    //     </Typography>
    //   ),
    //   renderCell: renderStatusCell,
    // },
    // {
    //   field: "backupFolderDeleted",
    //   headerName: "Backup Folder Deleted",
    //   width: 170,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Backup Folder Deleted
    //     </Typography>
    //   ),
    //   renderCell: renderStatusCell,
    // },
    // {
    //   field: "restore",
    //   headerName: "Restore",
    //   width: 170,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Restore
    //     </Typography>
    //   ),
    //   renderCell: (params) => {
    //     const allPass = params.row.backupInitialized === "Pass" &&
    //       params.row.backupFileNameGenerated === "Pass" &&
    //       params.row.backupDirectoryCreated === "Pass" &&
    //       params.row.backupFolderCreated === "Pass" &&
    //       params.row.backupCompressed === "Pass" &&
    //       params.row.backupUploadedToS3 === "Pass" &&
    //       params.row.backupFolderDeleted === "Pass";

    //     return (
    //       <Tooltip title={allPass ? "Restore Backup" : "Cannot restore, not all steps are Pass"}>
    //         <div>
    //           <FontAwesomeIcon
    //             icon={faUndo}
    //             style={{
    //               fontSize: "20px",
    //               color: allPass ? "#04497a" : "#cccccc", // Gray if not all Pass
    //               cursor: allPass ? "pointer" : "not-allowed", // Pointer if all Pass, not-allowed otherwise
    //             }}
    //             onClick={allPass ? () => handleRestoreConfirmation(params.row.name) : null}
    //           />
    //         </div>
    //       </Tooltip>
    //     );
    //   },
    // },
  ];



  // const handleDelete = (id) => {
  //   axios
  //     .delete(
  //       `${process.env.REACT_APP_API_URL}currentopenings/deleteopening/${id}`
  //     )
  //     .then(() => {
  //       toast.success("Calendar deleted successfully");
  //       // Filter out the deleted calendar entry from the users state
  //       setUsers((prevUsers) => prevUsers.filter((user) => user._id !== id));
  //     })
  //     .catch((error) => {
  //       console.error("Error deleting calendar entry:", error);
  //       toast.error("Error deleting calendar entry");
  //     });
  // };

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    boxShadow: 24,
    padding: "20px", // Adjust padding for inner content spacing
    borderRadius: "8px",
    width: "80%", // Adjust width for the modal
    height: "90%", // Adjust height for the modal
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  };

  const styleModalBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#e0e0e0",
    boxShadow: 24,
    p: 4,
    width: "80%", // Adjust the width here as per your requirement
    maxWidth: "500px", // Set maximum width if needed
    outline: "none",
    borderRadius: "10px",
  };

  //Upload confirmation dialog box
  const handleUploadConfirmationOpen = () => {
    setUploadConfirmationOpen(true);
  };

  const handleUploadCancel = () => {
    setUploadConfirmationOpen(false); // Close the confirmation dialog
  };

  const handleBackupConfirmation = () => {
    setBackupConfirmationOpen(true);
  };

  const handleRestoreConfirmation = (backupName) => {
    // e.preventDefault();
    setRestoreConfirmationOpen(true);
    setSelectBackup(backupName)
  };

  const handleConfirmBackup = () => {
    handleBackup();
    setBackupConfirmationOpen(false);
  };

  const handleConfirmRestore = () => {
    handleRestore();
    setRestoreConfirmationOpen(false);
  };

  return (
    <div>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "crimson",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Backup
          </Typography>
          {pdfUrl && (
            <iframe
              src={pdfUrl}
              width="95%"
              height="90%"
              title="PDF viewer"
              style={{ margin: "auto" }}
            />
          )}
        </Box>
      </Modal> */}

      <Modal //Upload a opening
        open={openApply}
        onClose={handleCloseUpload}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalBox}></Box>
      </Modal>

      <Box m="1.5rem 2.5rem" height="calc(100vh - 275px)">
        <FlexBetween sx={{ marginBottom: "10px" }}>
          <Header title={"Backup Files"} />
          {/* <Box>
            <Button
              onClick={handleBackupConfirmation}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bolder",
                padding: "10px 20px",
              }}
            >
              Backup
            </Button>
          </Box> */}
        </FlexBetween>
        <ThemeProvider theme={lightTheme}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 20]}
            pageSizeOptions={[5, 10]}
            sx={dashboardStyle(theme, "500px")}
          />
        </ThemeProvider>
      </Box>
      <ToastContainer />
      <ConfirmationDialog
        open={backupConfirmationOpen}
        handleClose={() => setBackupConfirmationOpen(false)}
        handleConfirm={handleConfirmBackup}
        message="Are you sure you want to create database backup?"
      />
      <ConfirmationDialog
        open={restoreConfirmationOpen}
        handleClose={() => setRestoreConfirmationOpen(false)}
        handleConfirm={handleConfirmRestore}
        message="Are you sure you want to restore backup?"
      />
    </div>
  );
}

export default BackupAndRestore;
