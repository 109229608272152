import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import {
  Box,
  Button,
  Typography,
  Modal,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { dashboardStyle } from "shared/utils";

function EmployeeLeaves() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [fetchedYear, setFetchedYear] = useState(null);
  const [employeeId, setEmployeeId] = useState("");
  const [description, setDescription] = useState("");
  const [sickLeaves, setSickLeaves] = useState();
  const [privilegeLeaves, setPrivilegeLeaves] = useState();
  const [sickleaveLimitExceeded, setSickLeaveLimitExceeded] = useState(false);
  const [privilegeleaveLimitExceeded, setPrivilegeleaveLimitExceeded] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedLeaveId, setSelectedLeaveId] = useState(null); // Track the selected leave id
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedCancelLeaveId, setSelectedCancelLeaveId] = useState(null);
  const [leaveData, setLeaveData] = useState([]);
  const [rows, setRows] = useState([]);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [fetchedHistory, setFetchedHistory] = useState([]);
  const [createdOn, setCreatedOn] = useState("");
  const currentYear = new Date().getFullYear();
  const [changeYear, setChangeYear] = useState(currentYear);
  const [role, setRole] = useState("")

  const handleOpen = (description) => {
    setDescription(description);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false)
    setHistoryOpen(false)
  };

  // Add a function to handle leave deletion confirmation
  const handleDeleteConfirmation = (id) => {
    setSelectedLeaveId(id); // Set the selected leave id
    setDeleteConfirmationOpen(true); // Open the confirmation dialog
  };

  const handleConfirmDelete = () => {
    // Perform deletion action using the deleteId
    handleDelete(selectedLeaveId);
    setDeleteConfirmationOpen(false); // Close the confirmation dialog
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmationOpen(false); // Close the confirmation dialog
  };

  // useEffect(() => {
  //   axios
  //     .get(process.env.REACT_APP_API_URL + "profilepage", {
  //       withCredentials: true,
  //     })
  //     .then((response) => {
  //       setUser(response.data);
  //       setEmployeeId(response.data.employeeId);

  //       // Fetch leave data based on employee ID
  //       axios
  //         .get(
  //           `${process.env.REACT_APP_API_URL}leaves/getLeaves/${response.data.employeeId}`
  //         )
  //         .then((response) => {
  //           const leaveData = response.data.leave;
  //           console.log("leaveData", leaveData);
  //           if (leaveData && leaveData.length > 0) {
  //             setUsers(leaveData);
  //             setFetchedYear(leaveData[0].year);
  //             console.log("leaveData", leaveData);
  //             setDescription(leaveData[0].description);
  //             // setSickLeaves(leaveData[0].sickLeaves);
  //             // setPrivilegeLeaves(leaveData[0].privilegeLeaves);
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching leave data:", error);
  //         });
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user data:", error);
  //     });
  // }, []);


  const fetchLeaveData = async () => {
    try {
      const userResponse = await axios.get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      });
      setUser(userResponse.data);
      setEmployeeId(userResponse.data.employeeId);
      setRole(userResponse.data.role)
      // const currentYear = new Date().getFullYear();

      // Fetch leave data for the current year
      const leaveResponse = await axios.get(`${process.env.REACT_APP_API_URL}leaves/getLeaves/${userResponse.data.employeeId}`);
      const leaveData = leaveResponse.data.leaveData;

      // Filter leave data for the current year
      const currentYearLeaves = leaveData.years.find((yearData) => yearData.year === changeYear);

      if (currentYearLeaves) {
        setUsers(currentYearLeaves.leaves);
        setSickLeaves(currentYearLeaves.sickLeaves);
        setPrivilegeLeaves(currentYearLeaves.privilegeLeaves);
        setRows(renderRows(currentYearLeaves));
      } else {
        setUsers([]);
        setSickLeaves(12);
        setPrivilegeLeaves(12);
        setRows([]);
      }
    } catch (error) {
      console.error("Error fetching leave data:", error);
    }
  };

  useEffect(() => {
    fetchLeaveData();
  }, [changeYear]);


  useEffect(() => {
    if (sickLeaves === 0) {
      setSickLeaveLimitExceeded(true);
      toast.error("Sick leave limit is over. Cannot request sick leave.");
    } else {
      setSickLeaveLimitExceeded(false);
    }
  }, [sickLeaves]); // Add sickLeaves to the dependency array

  useEffect(() => {
    if (privilegeLeaves === 0) {
      setPrivilegeleaveLimitExceeded(true);
      toast.error(
        "Privilege leave limit is over. Cannot request privilege leave."
      );
    } else {
      setPrivilegeleaveLimitExceeded(false);
    }
  }, [privilegeLeaves]); // Add privilegeLeaves to the dependency array

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}leaves/leave/${id}`)
      .then((result) => {
        toast.success("Leave record deleted successfully");
        // Remove the deleted leave from the state
        // setUsers((prevUsers) => prevUsers.filter((user) => user._id !== id));
        fetchLeaveData();
        setDeleteConfirmationOpen(false);
      })
      .catch((err) => {
        console.error(err);
        setDeleteConfirmationOpen(false); // Close the confirmation dialog even if an error occurs
      });
  };

  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    color: "black",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    width: "50%",
    height: "auto",
    wordWrap: "break-word",
    borderRadius: "20px",
  };

  const renderRows = (yearData) => {
    if (!yearData || !yearData.leaves) {
      return [];
    }

    return yearData.leaves.reverse().map((leave, leaveIndex) => ({
      id: leave._id,
      srno: `${yearData.year}.${leaveIndex + 1}`,
      leaveType: leave.leaveType,
      startDate: leave.startDate,
      endDate: leave.endDate,
      totalDays: leave.totalDays,
      description: leave.description,
      createdFrom: leave.createdFrom,
      history: leave.history,
      status: leave.status,
      year: yearData.year,
      sickLeaves: yearData.sickLeaves,
      privilegeLeaves: yearData.privilegeLeaves,
    }));
  };

  const handleHistoryOpen = async (leaveId) => {
    console.log("employeeId:", employeeId, "leaveId:", leaveId);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}leaves/leave-history/${employeeId}/${leaveId}`);
      const history = response.data.history;
      setFetchedHistory(history);
      console.log('Leave history:', history);
      if (history.length > 0) {
        setCreatedOn(history[0].createdOn);
        console.log('createdOn:', history[0].createdOn);
      }
      setHistoryOpen(true);
    } catch (error) {
      console.error('Error fetching leave history:', error);
      toast.error('Error fetching leave history');
    }
  };


  const handleCancelLeave = (id, employeeId, year) => {
    const currentYear = new Date().getFullYear();
    axios
      .put(`${process.env.REACT_APP_API_URL}leaves/cancelLeave/${id}`, { employeeId, currentYear })
      .then((response) => {
        // If the request is successful, update the UI accordingly
        console.log("Leave canceled successfully");
        toast.success("Leave canceled successfully");
        fetchLeaveData(employeeId, year);
      })
      .catch((error) => {
        console.error("Error canceling leave:", error);
        toast.error("An error occurred while canceling the leave!");
      });
  };

  const handleCancelConfirmation = (id) => {
    setSelectedCancelLeaveId(id); // Set the selected leave id
    setConfirmationOpen(true); // Open the confirmation dialog
  };

  // Function to handle canceling leave after confirmation
  const handleConfirmCancelLeave = () => {
    // Perform cancellation action using the selectedCancelLeaveId
    handleCancelLeave(selectedCancelLeaveId);
    setConfirmationOpen(false); // Close the confirmation dialog
  };

  // Function to handle canceling leave cancellation confirmation
  const handleCancelCancelLeave = () => {
    setConfirmationOpen(false); // Close the confirmation dialog
  };

  const columns = [
    {
      field: "leaveType",
      headerName: "Leave Type",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Leave Type
        </Typography>
      ),
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Start Date
        </Typography>
      ),
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          End Date
        </Typography>
      ),
    },
    {
      field: "totalDays",
      headerName: "Total Days",
      flex: 0.8,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Total Days
        </Typography>
      ),
    },
    {
      field: "message",
      headerName: "Message",
      width: 80,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Message
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => handleOpen(params.row.description)}
            style={{ color: "#04497a" }}
          >
            <RemoveRedEyeIcon style={{ fontSize: "25px" }} />
          </IconButton>
        </div>
      ),
    },
    // {
    //   field: "createdFrom",
    //   headerName: "Created From",
    //   flex: 1,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Created From
    //     </Typography>
    //   ),
    // },
    {
      field: "history",
      headerName: "History",
      width: 70,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          History
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => handleHistoryOpen(params.row.id)}
            style={{ color: "#04497a" }}
          >
            <RemoveRedEyeIcon style={{ fontSize: "25px" }} />
          </IconButton>
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 110,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Action
        </Typography>
      ),
      renderCell: (params) => {
        const leaveToCancel = users.find((leave) => leave._id === params.id);
        const startDate = new Date(leaveToCancel.startDate);
        const oneDay = 24 * 60 * 60 * 1000; // Hours * Minutes * Seconds * Milliseconds
        const oneDay_beforeStartDate = new Date(startDate.getTime() - oneDay);
        const today = new Date();

        return (
          <div>
            {params.row.status === "Approved" && today <= oneDay_beforeStartDate ? (
              <IconButton onClick={() => handleCancelConfirmation(params.id)}>
                <CloseIcon style={{ color: "#04497a", fontSize: "30px", fontWeight: "bolder" }} />
              </IconButton>
            ) : params.row.status === "Pending" || params.row.status === "Rejected" ? (
              <>
                <Link to={`/updateleaverequest/${params.id}`}>
                  <EditIcon
                    style={{
                      marginLeft: "5px",
                      marginRight: "10px",
                      color: "#04497a",
                      fontSize: "25px",
                    }}
                  />
                </Link>
                <DeleteIcon
                  style={{ cursor: "pointer", color: "#04497a", fontSize: "25px" }}
                  onClick={() => handleDeleteConfirmation(params.id)}
                />
              </>
            ) : null}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Status
        </Typography>
      ),
      renderCell: (params) => (
        <div
          style={{
            color:
              params.value === "Approved"
                ? "#4caf50"
                : params.value === "Pending"
                  ? "#ff9800"
                  : params.value === "Rejected" // Add styling for "Rejected" status
                    ? "#f44336" // Red color
                    : params.value === "Canceled" // Add styling for "Canceled" status
                      ? "#8b0000" // Dark red color
                      : "inherit",
            background:
              params.value === "Approved"
                ? "#c8e6c9"
                : params.value === "Pending"
                  ? "#ffe0b2"
                  : params.value === "Rejected" // Add background for "Rejected" status
                    ? "#ffcdd2" // Light red background
                    : params.value === "Canceled" // Add background for "Canceled" status
                      ? "#ffb2b2" // Light red background
                      : "inherit",
            borderRadius: "4px",
            padding: "8px",
            display: "inline-block",
            width: "85px",
            textAlign: "center",
          }}
        >
          {params.value}
        </div>
      ),
    },
  ];

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const years = Array.from({ length: 100 }, (_, index) => 2000 + index);

  const handleYearChange = (e) => {
    const selectedYear = e.target.value;
    setChangeYear(selectedYear);
    // Store the selected year in localStorage
    localStorage.setItem("selectedYear", selectedYear);
  };

  return (
    <>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "crimson",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Message
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, postion: "absolute" }}
          >
            {description}
          </Typography>
        </Box>
      </Modal>


      <Modal
        open={historyOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "crimson",
            }}
          >
            <CloseIcon />
          </IconButton>
          <div>
            <Typography variant="h6" component="h2">
              History
            </Typography>
            {fetchedHistory.map((historyItem, index) => (
              <div key={index}>
                <Typography>Created On: {historyItem.createdOn}</Typography>
                <Typography>Updated On: {historyItem.updatedOn}</Typography>
                <Typography>Updated By: {historyItem.updatedBy}</Typography>
              </div>
            ))}
          </div>
        </Box>
      </Modal>

      {/* <Box m="2rem 2.5rem"> */}
      <Box m={role === "employee" || role === "consultant" ? "2rem 2.5rem" : "0"}>
        <FlexBetween sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box>
            <Button
            variant="contained"
              onClick={() => {
                if (sickLeaves === 0 && privilegeLeaves === 0) {
                  toast.error(
                    "Leave limit exceeded. You Cannot request a leave, Please contact HR"
                  );
                } else {
                  navigate("/addleaverequest");
                }
              }}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bolder",
                padding: "10px 20px",
              }}
            >
              <PersonAddAltIcon sx={{ mr: "10px" }} />
              Request Leave
            </Button>
            
            {/*  <ThemeProvider theme={lightTheme}>
            <FormControl
              variant="standard"
              sx={{minWidth: 120, marginLeft: "10px" }}
            >
              <InputLabel id="year-select-label">Year</InputLabel>
              <Select
                labelId="year-select-label"
                id="year-select"
                label="Year"
                value={changeYear}
                onChange={handleYearChange}
                MenuProps={{
                  style: {
                    maxHeight: 600, // Set a maximum height for the dropdown
                  },
                }}
              >
                <MenuItem value="">Choose Year</MenuItem>
                {years.map((year) => (
                  <MenuItem sx={{ fontSize: "18px" }} key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </ThemeProvider> */}
          </Box>
        </FlexBetween>
        <FlexBetween sx={{ justifyContent: "flex-start" }}>
          <Typography variant="h4" sx={{ color: "black", margin: "10px" }}>
            Sick Leaves Remaining:{" "}
            <span style={{ color: "#04497a" }}>{sickLeaves}</span>
          </Typography>
          <Typography variant="h4" sx={{ color: "black", margin: "10px" }}>
            Privilege Leaves Remaining:{" "}
            <span style={{ color: "#04497a" }}>{privilegeLeaves}</span>
          </Typography>
        </FlexBetween>
        <ThemeProvider theme={lightTheme}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 20]}
            pageSizeOptions={[5, 10]}
            sx={dashboardStyle(theme, "485px")}
          />
        </ThemeProvider>
        <ToastContainer />
      </Box>
      <ConfirmationDialog
        open={deleteConfirmationOpen}
        handleClose={handleDeleteCancel}
        handleConfirm={handleConfirmDelete} // Pass handleDelete as the confirm action
        message="Are you sure you want to delete this leave record?" // Customize the message
      />
      <ConfirmationDialog
        open={confirmationOpen}
        handleClose={handleCancelCancelLeave}
        handleConfirm={handleConfirmCancelLeave} // Pass handleConfirmCancelLeave as the confirm action
        message="Are you sure you want to cancel this leave request?" // Confirmation message
      />
    </>
  );
}

export default EmployeeLeaves;
