import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./login.css";

function VerifyOtp() {
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(120);
  const [resetTimer, setResetTimer] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer <= 1) {
          clearInterval(countdown);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [resetTimer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(process.env.REACT_APP_API_URL + "verify-forgot-otp", { email, otp })
    .then(res => {
      if (res.data.Status === "Success") {
        toast.success("OTP verified");
        navigate("/reset-password", { state: { email } });
      } else {
        // This branch might not get hit if a non-2xx status is returned
        toast.error("Invalid OTP");
      }
    })
    .catch(err => {
      // Check if the error response status is 401 and use its message
      if (err.response && err.response.status === 401) {
        // You can display the message from the server or a custom one
        toast.error("Invalid OTP");
      } else {
        toast.error('There was an error. Please try again later.');
      }
      console.error(err);
    });
  
  };

  const handleResend = () => {
    setTimer(120);
    axios.post(process.env.REACT_APP_API_URL + "resend-otp", { email })
      .then(res => {
        if (res.data.Status === "Success") {
          setResetTimer(true)
          toast.success("OTP resent to your email");
        } else {
          toast.error("Error resending OTP");
        }
      })
      .catch(err => {
        toast.error('There was an error. Please try again later.');
        console.error(err);
      });
  };

  return (
    <div className="centered-container">
      <form className="login_form_container" onSubmit={handleSubmit}>
        <h3 className="title" style={{ fontSize: "18px" }}>Verify OTP</h3>
        <div className="input_container" style={{ width: "85%" }}>
          {/* <label className="input_label" htmlFor="otp_field" style={{ color: "black", fontSize: "16px" }}>OTP</label> */}
          <input placeholder="Enter OTP" title="OTP" name="otp" type="text" className="input_field" id="otp_field" value={otp} onChange={(e) => setOtp(e.target.value)} />
        </div>
        <button title="Verify OTP" type="submit" className="sign-in_btn" style={{ width: "85%", fontSize: "14px" }}>
          <span>Verify</span>
        </button>
        <div className="timer_container">
          <span>{`Time remaining: ${Math.floor(timer / 60)}:${timer % 60 < 10 ? `0${timer % 60}` : timer % 60}`}</span>
        </div>
        {timer === 0 && (
          <button title="Resend OTP" type="button" className="sign-in_btn" onClick={handleResend} style={{ width: "85%", marginBottom: "10px", fontSize: "14px" }}>
            <span>Resend OTP</span>
          </button>
        )}
      </form>
      <ToastContainer />
    </div>
  );
}

export default VerifyOtp;
