import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import useAuth from './shared/useAuth';
import Layout from "./shared/layout";

const PrivateRoute = ({ isProtected }) => {
  const { isAuthenticated, userRole, loading } = useAuth();

  if (loading) {
    return <CircularProgress />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (isProtected) {
    const isAdminOrHR = ['admin', 'hr', 'manager', 'superadmin'].includes(userRole);
    if (!isAdminOrHR) {
      return <Navigate to="/dashboard" />;
    }
  }

  return <Outlet />;
};

export default PrivateRoute;