import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Button,
  Tab,
  Tabs,
  Modal,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast, ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import { dashboardStyle, tabStyles } from "shared/utils";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationDialog from "shared/ConfirmationDialog";
import axios from "axios";
import Spinner from "shared/spinner/Spinner";
import CloseIcon from "@mui/icons-material/Close";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";

const CommentModal = ({
  open,
  handleClose,
  comment,
  onSave,
  readOnly,
  isLoading,
}) => {
  const [newComment, setNewComment] = useState("");
  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });
  // Reset the comment when the modal opens or comment changes
  useEffect(() => {
    setNewComment(comment || "");
  }, [comment, open]);

  const handleSave = () => {
    onSave(newComment);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="comment-modal"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ThemeProvider theme={lightTheme}>
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            width: "400px",
            maxWidth: "90%",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#04497a" }}
            >
              {readOnly ? "View Comment" : "Add/Edit Comment"}
            </Typography>
            <IconButton onClick={handleClose} disabled={isLoading}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            disabled={readOnly || isLoading}
            placeholder="Enter your comment here..."
            sx={{ mb: 2 }}
          />
          {!readOnly && (
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isLoading}
              sx={{
                backgroundColor: "#04497a",
                "&:hover": { backgroundColor: "#04497a" },
              }}
            >
              {isLoading ? "Saving..." : "Save"}
            </Button>
          )}
        </Box>
      </ThemeProvider>
    </Modal>
  );
};

function EditTimesheetUS() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { yearFromUrl, id, monthFromUrl } = useParams(); // timesheet ID from URL
  const [isLoading, setIsLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [timesheetEntries, setTimesheetEntries] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteEntryId, setDeleteEntryId] = useState(null);

  // Form states
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedWorkType, setSelectedWorkType] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [workingHours, setWorkingHours] = useState();
  const [editMode, setEditMode] = useState(false);
  const [editEntryId, setEditEntryId] = useState(null);
  const [user, setUser] = useState({});
  const [approveConfirmationOpen, setApproveConfirmationOpen] = useState(false);
  const [editingRows, setEditingRows] = useState({});
  const [timesheetStatus, setTimesheetStatus] = useState("");

  //comment modal states
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [isViewMode, setIsViewMode] = useState(false);
  const [modifiedRowIds, setModifiedRowIds] = useState(new Set());

// Helper function to mark a row as modified
const markRowAsModified = (rowId) => {
  setModifiedRowIds(prev => {
    const updated = new Set(prev);
    updated.add(rowId);
    return updated;
  });
};

  // Add this function to generate dates between a range
  // Add this function to generate dates between a range
  const getDatesInRange = (startDate, endDate) => {
    const dates = [];
    const currentDate = new Date(startDate);
    const lastDate = new Date(endDate);

    while (currentDate <= lastDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  // Modify the generateInitialEntries functionFixed EditTimesheetUS Component

  // Modify the generateInitialEntries function to include temporary IDs
  // Modify the generateInitialEntries function
  const generateInitialEntries = (periodValue, year) => {
    const period = fortNightlyPeriods.find((p) => p.value === periodValue);
    if (!period) return [];
  
    let endYear = year;
    if (parseInt(period.endDate) < parseInt(period.startDate)) {
      endYear = parseInt(period.startMonth) === 12 ? year + 1 : year;
    }
  
    const startDateStr = `${year}-${period.startMonth.padStart(2, "0")}-${period.startDate.padStart(2, "0")}`;
    const endMonth = parseInt(period.endDate) < parseInt(period.startDate)
      ? String(parseInt(period.startMonth) + 1).padStart(2, "0")
      : period.startMonth;
    const endDateStr = `${endYear}-${endMonth}-${period.endDate.padStart(2, "0")}`;
  
    const dateRange = getDatesInRange(new Date(startDateStr), new Date(endDateStr));
  
    return dateRange.map((date) => {
      const dayOfWeek = date.getDay();
      const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
  
      return {
        date: date.toISOString().split("T")[0],
        workType: isWeekend ? "Weekend off" : "Work from Office",
        workingHours: isWeekend ? 0 : 0,
        overtimeHours: 0,
        comments: ""
      };
    });
  };

  // Modify useEffect to initialize entries if none exist
  // First, let's fix the issues in the initialization logic
  const initializeTimesheet = async () => {
    if (!id || !employeeId || !monthFromUrl) return;
  
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}timesheetUS/getTimesheetEntries`,
        {
          params: {
            employeeId,
            timesheetId: id,
            month: monthFromUrl,
            year: yearFromUrl,
          },
          withCredentials: true,
        }
      );
  
      let entries = response.data.entries || [];
  
      if (entries.length === 0) {
        const initialEntries = generateInitialEntries(monthFromUrl, yearFromUrl);
        
        const saveResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}timesheetUS/addBulkTimesheetEntries`,
          {
            employeeId,
            timesheetId: id,
            month: monthFromUrl,
            year: yearFromUrl,
            entries: initialEntries,
          },
          { withCredentials: true }
        );
  
        if (saveResponse.data.entries) {
          entries = saveResponse.data.entries;
        } else {
          entries = initialEntries;
        }
        entries = saveResponse.data.entries || initialEntries;

        // Make sure each entry has _id for proper identification
        entries = entries.map((entry, index) => ({
          ...entry,
          _id: entry._id || initialEntries[index]._id || `temp-${index}`,
        }));
      }

      setTimesheetEntries(entries);
      setTimesheetStatus(response.data.status);  
      // Process entries to ensure all required field
    } catch (error) {
      console.error("Error initializing timesheet:", error);
      toast.error("Error initializing timesheet");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      try {
        // await fetchEmployeeId();
        if (id && employeeId && monthFromUrl) {
          await initializeTimesheet();
        }
      } catch (error) {
        console.error("Error initializing data:", error);
      }
    };

    initializeData();
  }, [id, employeeId, monthFromUrl]);

  const fortNightlyPeriods = [
    {
      value: "jan1",
      label: "9 Jan - 24 Jan",
      startDate: "09",
      endDate: "24",
      startMonth: "01",
    },
    {
      value: "jan2",
      label: "25 Jan - 8 Feb",
      startDate: "25",
      endDate: "08",
      startMonth: "01",
    },
    {
      value: "feb1",
      label: "9 Feb - 24 Feb",
      startDate: "09",
      endDate: "24",
      startMonth: "02",
    },
    {
      value: "feb2",
      label: "25 Feb - 8 Mar",
      startDate: "25",
      endDate: "08",
      startMonth: "02",
    },
    {
      value: "mar1",
      label: "9 Mar - 24 Mar",
      startDate: "09",
      endDate: "24",
      startMonth: "03",
    },
    {
      value: "mar2",
      label: "25 Mar - 8 Apr",
      startDate: "25",
      endDate: "08",
      startMonth: "03",
    },
    {
      value: "apr1",
      label: "9 Apr - 24 Apr",
      startDate: "09",
      endDate: "24",
      startMonth: "04",
    },
    {
      value: "apr2",
      label: "25 Apr - 8 May",
      startDate: "25",
      endDate: "08",
      startMonth: "04",
    },
    {
      value: "may1",
      label: "9 May - 24 May",
      startDate: "09",
      endDate: "24",
      startMonth: "05",
    },
    {
      value: "may2",
      label: "25 May - 8 Jun",
      startDate: "25",
      endDate: "08",
      startMonth: "05",
    },
    {
      value: "jun1",
      label: "9 Jun - 24 Jun",
      startDate: "09",
      endDate: "24",
      startMonth: "06",
    },
    {
      value: "jun2",
      label: "25 Jun - 8 Jul",
      startDate: "25",
      endDate: "08",
      startMonth: "06",
    },
    {
      value: "jul1",
      label: "9 Jul - 24 Jul",
      startDate: "09",
      endDate: "24",
      startMonth: "07",
    },
    {
      value: "jul2",
      label: "25 Jul - 8 Aug",
      startDate: "25",
      endDate: "08",
      startMonth: "07",
    },
    {
      value: "aug1",
      label: "9 Aug - 24 Aug",
      startDate: "09",
      endDate: "24",
      startMonth: "08",
    },
    {
      value: "aug2",
      label: "25 Aug - 8 Sep",
      startDate: "25",
      endDate: "08",
      startMonth: "08",
    },
    {
      value: "sep1",
      label: "9 Sep - 24 Sep",
      startDate: "09",
      endDate: "24",
      startMonth: "09",
    },
    {
      value: "sep2",
      label: "25 Sep - 8 Oct",
      startDate: "25",
      endDate: "08",
      startMonth: "09",
    },
    {
      value: "oct1",
      label: "9 Oct - 24 Oct",
      startDate: "09",
      endDate: "24",
      startMonth: "10",
    },
    {
      value: "oct2",
      label: "25 Oct - 8 Nov",
      startDate: "25",
      endDate: "08",
      startMonth: "10",
    },
    {
      value: "nov1",
      label: "9 Nov - 24 Nov",
      startDate: "09",
      endDate: "24",
      startMonth: "11",
    },
    {
      value: "nov2",
      label: "25 Nov - 8 Dec",
      startDate: "25",
      endDate: "08",
      startMonth: "11",
    },
    {
      value: "dec1",
      label: "9 Dec - 24 Dec",
      startDate: "09",
      endDate: "24",
      startMonth: "12",
    },
    {
      value: "dec2",
      label: "25 Dec - 8 Jan",
      startDate: "25",
      endDate: "08",
      startMonth: "12",
    },
  ];

  useEffect(() => {
    fetchEmployeeId();
    if (id && employeeId && monthFromUrl) {
      fetchTimesheetEntries();
    }
  }, [id, employeeId, monthFromUrl]);

  const fetchEmployeeId = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}profilepage`,
        { withCredentials: true }
      );
      setUser(response.data);
      setEmployeeId(response.data.employeeId);
    } catch (error) {
      console.error("Error fetching employee ID:", error);
      toast.error("Error fetching employee data");
    }
  };

  // When fetching timesheet entries:
  const fetchTimesheetEntries = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}timesheetUS/getTimesheetEntries`,
        {
          params: {
            employeeId,
            timesheetId: id,
            month: monthFromUrl,
            year: yearFromUrl,
          },
          withCredentials: true,
        }
      );

      // Ensure each entry has an _id
      const entries = response.data.entries.map((entry, index) => ({
        ...entry,
        _id: entry._id || `temp-${index}`, // Ensure each entry has a unique ID
        id: entry._id || `temp-${index}`, // Set both id and _id to the same value
      }));
      console.log("timesheet-data", response.data);
      setTimesheetEntries(entries);
      setTimesheetStatus(response.data.status);
    } catch (error) {
      console.error("Error fetching timesheet entries:", error);
      // toast.error("Error fetching timesheet entries");
    } finally {
      setIsLoading(false);
    }
  };

  const saveAllChanges = async () => {
    try {
      setIsLoading(true);
      
      // Here we're still sending all entries, but only modified ones have updated timestamps
      await axios.post(
        `${process.env.REACT_APP_API_URL}timesheetUS/addBulkTimesheetEntries`,
        {
          employeeId,
          timesheetId: id,
          month: monthFromUrl,
          year: yearFromUrl,
          entries: timesheetEntries,
        },
        { withCredentials: true }
      );
      
      // Clear modified rows tracking after successful save
      setModifiedRowIds(new Set());
      toast.success("All changes saved successfully");
    } catch (error) {
      console.error("Error saving changes:", error);
      toast.error("Error saving changes");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_API_URL}timesheetUS/deleteTimesheetEntry`,
        {
          params: {
            employeeId,
            timesheetId: id,
            entryId: deleteEntryId,
            month: monthFromUrl,
            year: yearFromUrl,
          },
          withCredentials: true,
        }
      );
      toast.success("Entry deleted successfully");
      fetchTimesheetEntries();
    } catch (error) {
      console.error("Error deleting entry:", error);
      toast.error("Error deleting entry");
    } finally {
      setIsLoading(false);
      setConfirmationOpen(false);
    }
  };

  // Add a submit button in the UI
  const handleSubmitTimesheet = async () => {
    try {
      const timesheetStatus =
        user.role === "admin" ||
        user.role === "manager" ||
        user.role === "hr" ||
        user.role === "superadmin"
          ? "Approved"
          : "Submitted";

      setIsLoading(true);

      // First update all entries
      await axios.post(
        `${process.env.REACT_APP_API_URL}timesheetUS/addBulkTimesheetEntries`,
        {
          employeeId,
          timesheetId: id,
          month: monthFromUrl,
          year: yearFromUrl,
          entries: timesheetEntries,
        },
        { withCredentials: true }
      );

      // Then update the status
      await axios.put(
        `${process.env.REACT_APP_API_URL}timesheetUS/submitTimesheetStatus`,
        {
          employeeId,
          timesheetId: id,
          month: monthFromUrl,
          year: yearFromUrl,
          status: timesheetStatus,
        },
        { withCredentials: true }
      );

      toast.success("Timesheet submitted successfully");
      setApproveConfirmationOpen(false);
      navigate(-1);
    } catch (error) {
      console.error("Error submitting timesheet:", error);
      toast.error("Error submitting timesheet");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCommentIconClick = (entry, viewMode) => {
    console.log("Selected entry for comment:", entry); // Add this for debugging
    setSelectedEntry({
      ...entry,
      id: entry._id || entry.id, // Ensure we have the correct ID
    });
    setIsViewMode(viewMode);
    setCommentModalOpen(true);
  };

  const handleSaveComment = async (newComment) => {
    try {
      setIsLoading(true);

      // First update the comment
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}timesheetUS/updateComment`,
        {
          employeeId,
          timesheetId: id,
          entryId: selectedEntry.id,
          comment: newComment,
        },
        { withCredentials: true }
      );

      if (response.data.success) {
        // Mark this row as modified
        markRowAsModified(selectedEntry.id);
        
        // Update local state
        setTimesheetEntries(prev =>
          prev.map(entry =>
            entry._id === selectedEntry.id || entry.id === selectedEntry.id
              ? { 
                  ...entry, 
                  comments: newComment,
                  updatedAt: new Date().toISOString() 
                }
              : entry
          )
        );

        toast.success("Comment saved successfully");
        setCommentModalOpen(false);
      } else {
        throw new Error(response.data.message || "Error saving comment");
      }
    } catch (error) {
      console.error("Error saving comment:", error);
      toast.error(error.message || "Error saving comment");
    } finally {
      setIsLoading(false);
    }
  };

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  useEffect(() => {
    if (
      selectedWorkType === "Privilege Leave" ||
      selectedWorkType === "Sick Leave" ||
      selectedWorkType === "Weekend off" ||
      selectedWorkType === "Public Holiday" ||
      selectedWorkType === "Paid time off"
    ) {
      setWorkingHours("0");
    }
  }, [selectedWorkType]);

  const handleStartEdit = (rowId) => {
    setEditingRows((prev) => ({
      ...prev,
      [rowId]: true,
    }));
  };

  const handleCancelEdit = (rowId) => {
    setEditingRows((prev) => ({
      ...prev,
      [rowId]: false,
    }));

    // Revert changes if needed
    fetchTimesheetEntries();
  };

  const handleSaveEdit = async (rowId) => {
    try {
      setIsLoading(true);
      const entryToUpdate = timesheetEntries.find(
        (entry) => entry._id === rowId
      );

      await axios.put(
        `${process.env.REACT_APP_API_URL}timesheetUS/updateTimesheetEntry`,
        {
          employeeId,
          timesheetId: id,
          entryId: rowId,
          month: monthFromUrl,
          year: yearFromUrl,
          entry: entryToUpdate,
        },
        { withCredentials: true }
      );

      // Exit edit mode
      setEditingRows((prev) => ({
        ...prev,
        [rowId]: false,
      }));

      toast.success("Entry updated successfully");
    } catch (error) {
      console.error("Error updating entry:", error);
      toast.error("Error updating entry");
    } finally {
      setIsLoading(false);
    }
  };

  // Updated handleCellEdit function
  const handleCellEdit = async (params) => {
    try {
      setIsLoading(true);

      // Find the existing entry from timesheetEntries
      const existingEntry = timesheetEntries.find(
        (entry) => entry._id === params.id
      );

      // check this issue
      // if (!existingEntry) {
      //   throw new Error('Entry not found');
      // }

      // Create updated entry maintaining all existing fields
      const updatedEntry = {
        ...existingEntry,
        date: existingEntry.date,
        workType:
          params.field === "workType" ? params.value : existingEntry.workType,
        workingHours:
          params.field === "workingHours"
            ? Number(params.value) || 0
            : existingEntry.workingHours,
        overtimeHours:
          params.field === "overtime" // assuming field name is 'overtime'
            ? Number(params.value) || 0
            : existingEntry.overtimeHours,
      };

      // If work type is leave, set working hours to 0
      if (
        params.field === "workType" &&
        (params.value === "Privilege Leave" ||
          params.value === "Sick Leave" ||
          params.value === "Weekend off" ||
          params.value === "Public Holiday" ||
          params.value === "Paid time off")
      ) {
        updatedEntry.workingHours = 0;
        updatedEntry.overtimeHours = 0; // also reset overtime hours to 0 if worktype is leave
      }

      await axios.put(
        `${process.env.REACT_APP_API_URL}timesheetUS/updateTimesheetEntry`,
        {
          employeeId,
          timesheetId: id,
          entryId: params.id,
          month: monthFromUrl,
          year: yearFromUrl,
          entry: updatedEntry, // Send the complete entry
        },
        { withCredentials: true }
      );

      // Update local state using functional update and creating a new array with map
      setTimesheetEntries((prevEntries) => {
        return prevEntries.map((entry) =>
          entry._id === params.id ? updatedEntry : entry
        );
      });

      toast.success("Entry updated successfully");
    } catch (error) {
      console.error("Error updating entry:", error);
      toast.error("Error updating entry");
    } finally {
      setIsLoading(false);
    }
  };
  const formatAndSortRows = (entries) => {
    return entries
      .map((entry, index) => ({
        ...entry,
        id: entry._id || `temp-${index}`, // Ensure unique ID
        index: index + 1,
        date: entry.date
          ? new Date(entry.date).toISOString().split("T")[0]
          : "",
        day: entry.date
          ? new Date(entry.date).toLocaleDateString("en-US", {
              weekday: "long",
            })
          : "",
        workType: entry.workType || "Work from Office",
        workingHours: entry.workingHours || 0,
        overtimeHours: entry.overtimeHours || 0,
        status: entry.status || "Pending",
        comments: entry.comments || "",
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  };
  // Modify columns array to remove input cell renderers
  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 40,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "day",
      headerName: "Day",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "workType",
      headerName: "Work Type",
      flex: 1.2,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (timesheetStatus === "Pending" || timesheetStatus === "Rejected") {
          return (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Select
                value={params.row.workType}
                fullWidth
                onChange={(e) => {
                  const newValue = e.target.value;
                  const rowId = params.row.id || params.row._id;
                  
                  // Mark this row as modified
                  markRowAsModified(rowId);
                  
                  const updatedEntries = timesheetEntries.map((entry) => {
                    const entryId = entry.id || entry._id;
                    if (entryId === rowId) {
                      return {
                        ...entry,
                        workType: newValue,
                        workingHours: [
                          "Privilege leave",
                          "Sick leave",
                          "Weekend off",
                          "Public Holiday",
                          "Paid time off",
                        ].includes(newValue)
                          ? 0
                          : entry.workingHours,
                        updatedAt: new Date().toISOString() // Update timestamp
                      };
                    }
                    return entry;
                  });
                  setTimesheetEntries(updatedEntries);
                }}
                sx={{ width: "100%", fontSize: "12px" }}
              >
           <MenuItem value="Work from Office">Work from Office</MenuItem>
            <MenuItem value="Work from Home">Work from Home</MenuItem>
            <MenuItem value="Weekend off">Weekend off</MenuItem>
            <MenuItem value="Public Holiday">Public Holiday</MenuItem>
            <MenuItem value="Paid time off">Paid time off</MenuItem>
              </Select>
            </Box>
          );
        }
        return (
          <Typography
            fontSize="13px"
            sx={{ width: "100%", textAlign: "center" }}
          >
            {params.row.workType}
          </Typography>
        );
      },
    },
    {
      field: "workingHours",
      headerName: "Regular hrs",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (timesheetStatus === "Pending" || timesheetStatus === "Rejected") {
          return (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <TextField
                type="number"
                value={params.row.workingHours}
                onChange={(e) => {
                  const newValue = Number(e.target.value) || 0;
                  const rowId = params.row.id || params.row._id;
                  
                  // Mark this row as modified
                  markRowAsModified(rowId);
                  
                  const updatedEntries = timesheetEntries.map((entry) => {
                    const entryId = entry.id || entry._id;
                    if (entryId === rowId) {
                      return {
                        ...entry,
                        workingHours: newValue,
                        updatedAt: new Date().toISOString() // Update timestamp
                      };
                    }
                    return entry;
                  });
                  setTimesheetEntries(updatedEntries);
                }}
                disabled={[
                  "Privilege leave",
                  "Sick leave",
                  "Weekend off",
                  "Public Holiday",
                  "Paid time off",
                ].includes(params.row.workType)}
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 24,
                    style: { textAlign: "center" },
                  },
                  style: { fontSize: "13px" },
                }}
                size="small"
              />
            </Box>
          );
        }
        return (
          <Typography
            fontSize="13px"
            sx={{ width: "100%", textAlign: "center" }}
          >
            {params.row.workingHours}
          </Typography>
        );
      },
    },
    {
      field: "overtimeHours",
      headerName: "Overtime hrs",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (timesheetStatus === "Pending" || timesheetStatus === "Rejected") {
          return (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <TextField
                type="number"
                value={params.row.overtimeHours}
                onChange={(e) => {
                  const newValue = Number(e.target.value) || 0;
                  const rowId = params.row.id || params.row._id;
                  
                  // Mark this row as modified
                  markRowAsModified(rowId);
                  
                  const updatedEntries = timesheetEntries.map(entry => {
                    const entryId = entry.id || entry._id;
                    if (entryId === rowId) {
                      return {
                        ...entry,
                        overtimeHours: newValue,
                        updatedAt: new Date().toISOString() // Update timestamp
                      };
                    }
                    return entry;
                  });
                  setTimesheetEntries(updatedEntries);
                }}
                disabled={[
                  "Privilege leave",
                  "Sick leave",
                  "Weekend off",
                  "Public Holiday",
                  "Paid time off",
                ].includes(params.row.workType)}
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 24,
                    style: { textAlign: "center" },
                  },
                  style: { fontSize: "13px" },
                }}
                size="small"
              />
            </Box>
          );
        }
        return (
          <Typography
            fontSize="13px"
            sx={{ width: "100%", textAlign: "center" }}
          >
            {params.row.overtimeHours}
          </Typography>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated On",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography 
          fontSize="13px" 
          sx={{ 
            width: "100%", 
            textAlign: "center",
            // Highlight recently modified rows
            fontWeight: modifiedRowIds.has(params.row.id) ? 'bold' : 'normal',
            color: modifiedRowIds.has(params.row.id) ? '#04497a' : 'inherit'
          }}
        >
          {new Date(params.value).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          })}
        </Typography>
      ),
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 0.8,
      headerAlign: "center",
      renderCell: (params) => (
        <Box sx={{ width: "100%", gap: 1 }}>
          <IconButton
            sx={{ color: "#04497a" }}
            onClick={() => handleCommentIconClick(params.row, false)}
            disabled={
              timesheetStatus === "Approved" || timesheetStatus === "Submitted"
            }
          >
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{ color: "#04497a" }}
            onClick={() => handleCommentIconClick(params.row, true)}
            disabled={!params.row.comments?.length}
          >
            <InsertCommentOutlinedIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  // Then in your component:
  const allRows = formatAndSortRows(timesheetEntries);

  const currentDate = new Date();
  const nextDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );

  const subtitle = `${currentDate.toLocaleString("default", {
    month: "short",
  })} - ${nextDate.toLocaleString("default", { month: "short" })}`;
  return (
    <>
      <div>
        <FlexBetween sx={{ position: "fixed" }}>
          <IconButton
            onClick={() => navigate(-1)}
            style={{ color: "#04497a", marginTop: "18px", marginLeft: "10px" }}
          >
            <ArrowBackIcon />
          </IconButton>
        </FlexBetween>
      </div>
      <Box m="1.5rem 2.5rem" height="calc(100vh - 275px)">
        <Box>
          <FlexBetween sx={{ marginBottom: "0.5rem", justifyContent: "start" }}>
            <Header
              title="Timesheet"
              // subtitle={timesheetStatus}
              sx={{ alignSelf: "flex-start" }}
            />
            <Box sx={{ marginLeft: "auto" }}>
              <Button
                variant="contained"
                onClick={saveAllChanges}
                sx={{
                  backgroundColor: "#04497a",
                  color: theme.palette.background.alt,
                  fontSize: "14px",
                  fontWeight: "bolder",
                  padding: "10px 20px",
                  marginRight: "10px",
                  "&.Mui-disabled": {
                    backgroundColor: "grey",
                    color: "white",
                    opacity: 0.7,
                  },
                }}
                disabled={
                  timesheetStatus === "Approved" ||
                  timesheetStatus === "Submitted"
                }
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={() => setApproveConfirmationOpen(true)}
                sx={{
                  backgroundColor:
                    timesheetStatus === "Approved" ||
                    timesheetStatus === "Submitted"
                      ? "grey"
                      : "#04497a",
                  color: theme.palette.background.alt,
                  fontSize: "14px",
                  fontWeight: "bolder",
                  padding: "10px 20px",
                  "&.Mui-disabled": {
                    backgroundColor: "grey",
                    color: "white",
                    opacity: 0.7,
                  },
                }}
                disabled={
                  timesheetStatus === "Approved" ||
                  timesheetStatus === "Submitted"
                }
              >
                {user.role === "admin" ||
                user.role === "manager" ||
                user.role === "hr" ||
                user.role === "superadmin"
                  ? "Approve"
                  : "Submit"}
              </Button>
            </Box>
          </FlexBetween>
          <ThemeProvider theme={lightTheme}>
            <DataGrid
              rows={formatAndSortRows(timesheetEntries)}
              columns={columns}
              pageSize={16}
              disableSelectionOnClick
              disableColumnMenu
              disableRowSelectionOnClick
              pageSizeOptions={[16]}
              getRowId={(row) => row.id || row._id} // Add this line
              sx={{
                ...dashboardStyle(theme, "480px"),
                // height: "480px",
                "& .MuiDataGrid-row": {
                  maxHeight: "40px !important",
                  minHeight: "40px !important",
                },
                "& .MuiDataGrid-cell": {
                  maxHeight: "40px !important",
                  minHeight: "40px !important",
                  cursor: "default !important",
                  "&:focus": {
                    outline: "none",
                  },
                  "&:focus-within": {
                    outline: "none",
                  },
                },
                "& .MuiDataGrid-cell--editing": {
                  backgroundColor: "transparent !important",
                  outline: "none !important",
                },
                "& .MuiDataGrid-virtualScroller": {
                  minHeight: "auto !important",
                  overflowY: "auto !important",
                },
                "& .MuiDataGrid-virtualScrollerContent": {
                  minHeight: "auto !important",
                },
                "& .MuiDataGrid-virtualScrollerRenderZone": {
                  position: "relative",
                  minHeight: "auto !important",
                },
              }}
            />
          </ThemeProvider>
          {/* Add this before the closing tag of your component */}
          <CommentModal
            open={commentModalOpen}
            handleClose={() => setCommentModalOpen(false)}
            comment={selectedEntry?.comments} // Make sure you're passing comments, not comment
            onSave={handleSaveComment}
            readOnly={isViewMode}
            isLoading={isLoading}
          />
          <ConfirmationDialog
            open={confirmationOpen}
            handleClose={() => setConfirmationOpen(false)}
            handleConfirm={handleConfirmDelete}
            message="Are you sure you want to delete this entry?"
          />
          <ConfirmationDialog
            open={approveConfirmationOpen}
            handleClose={() => setApproveConfirmationOpen(false)}
            handleConfirm={handleSubmitTimesheet}
            message="Are you sure you want to submit this timesheet?"
          />
          {isLoading && <Spinner />}
          <ToastContainer />
        </Box>
      </Box>
    </>
  );
}

export default EditTimesheetUS;
