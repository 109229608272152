import React, { useEffect, useState, useRef } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  ArrowDropDownOutlined,
} from "@mui/icons-material";
import FlexBetween from "shared/FlexBetween";
import { useDispatch } from "react-redux";
import { setMode } from "state";
// import profileImage from "assets/images/profile.jpeg";
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  useTheme,
  Avatar,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { getCalendarPdfUrl } from "shared/utils";
import useAuth from "./useAuth";
import useAWSDetails from "./useAWSDetails";

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  // const [userRole, setUserRole] = useState("");
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [loggedUser, setLoggedUser] = useState([]);
  const [id, setId] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  // const [fetchedBucketName, setFetchedBucketName] = useState("");
  // const [fetchedRegion, setFetchedRegion] = useState("");
  const { userRole, loading } = useAuth();
  const apiCalledRef = useRef(false);
  // const apiAWSCalledRef = useRef(false);
  const { fetchedBucketName, fetchedRegion } = useAWSDetails();

  // useEffect(() => {
  //   if(apiAWSCalledRef.current) {
  //     return
  //   }
  //   apiAWSCalledRef.current = true
  // async function fetchAWSDetails() {

  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}getAWSDetails`
  //     );
  //     const data = response.data;
  //     const { bucketName, region } = data;

  //     setFetchedBucketName(bucketName);
  //     setFetchedRegion(region);
  //     // Now you can use the bucketName and region in your frontend code
  //     console.log("Bucket Name:", bucketName);
  //     console.log("Region:", region);

  //     // Example usage
  //     // initializeS3Client(bucketName, region);
  //   } catch (error) {
  //     console.error("Error fetching AWS details:", error.message);
  //   }
  // }

  //   fetchAWSDetails();
  // }, []);

  const handleLogout = () => {
    fetch(process.env.REACT_APP_API_URL + "logout", {
      method: "GET",
      credentials: "include", // Include cookies in the request
    })
      .then((response) => {
        if (response.ok) {
          navigate("/login");
          window.location.reload();
        } else {
          throw new Error("Failed to logout");
        }
      })
      .catch((error) => {
        console.error("Logout error:", error);
        // Handle error, display message to the user, etc.
      });
  };

  useEffect(() => {
    if (apiCalledRef.current) return;
    apiCalledRef.current = true;

    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.reload) {
          document.cookie =
            "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          window.location.reload();
        } else {
          setLoggedUser(response.data);
          setId(response.data._id);
          setEmployeeId(response.data.employeeId);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        // Handle error...
      });
  }, []);

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       process.env.REACT_APP_API_URL + "dashboard",
  //       {
  //         withCredentials: true, // Include credentials (cookies) in the request
  //       }
  //     );

  //     setUserRole(response.data.role);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     // Handle the error (e.g., show an error message)
  //     if (
  //       error.response &&
  //       error.response.data &&
  //       error.response.data.message === "Unauthorized"
  //     ) {
  //       // If unauthorized, navigate the user to the login screen
  //       navigate("/login");
  //     }
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  let profilePic;
  if (loggedUser.profilepic && employeeId) {
    profilePic = `https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/employees/${employeeId}/profile-picture/${loggedUser.profilepic
      }`;
  }
  return (
    <AppBar
      sx={{
        position: "sticky",
        background: "none",
        boxShadow: "none",
        backgroundColor: `#f0f2f5`,
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon sx={{ color: theme.palette.background.text }} />
          </IconButton>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          {/* <IconButton onClick={() => dispatch(setMode())}>
            {theme.palette.mode === "light" ? (
              <DarkModeOutlined sx={{ fontSize: "25px", color: "white" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px", color: "black" }} />
            )}
          </IconButton> */}

          <FlexBetween>
            <Button
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
              }}
            >
              {/* <Avatar src={`${process.env.REACT_APP_API_URL}public/employees/${loggedUser.employeeId}/profile-picture/${loggedUser.profilepic}`} sx={{ width: "32px", height: "32px" }} /> */}
              {/*     src={`https://ivtree-connect.s3.ap-south-1.amazonaws.com/${getCalendarPdfUrl()}/employees/${user.employeeId}/profile-picture/${user.profilepic}`} */}
              <Avatar src={profilePic} sx={{ width: "32px", height: "32px" }} />
              <div>
                <h3
                  style={{
                    color: "black",
                    margin: "0px",
                    height: "20px",
                    padding: "0px 5px",
                  }}
                >{`${loggedUser.name}`}</h3>
                <h4
                  style={{
                    color: "black",
                    margin: "0px",
                    height: "20px",
                    padding: "0px 5px",
                  }}
                >
                  {loggedUser.role
                    ? `(${capitalizeFirstLetter(loggedUser.role)})`
                    : ""}
                </h4>
              </div>
              <ArrowDropDownOutlined
                sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Link
                to="/profilepage"
                onClick={handleClose}
                style={{
                  textDecoration: "none",
                  color: theme.palette.mode === "light" ? "black" : "white",
                }}
              >
                <MenuItem>My Profile</MenuItem>
              </Link>
              <MenuItem>
                <Link
                  to="/resetpassword"
                  onClick={handleClose}
                  style={{
                    textDecoration: "none",
                    color: theme.palette.mode === "light" ? "black" : "white",
                  }}
                >
                  Reset Password
                </Link>
              </MenuItem>
              <Link
                to="#"
                onClick={handleLogout}
                style={{
                  textDecoration: "none",
                  background: "transparent",
                  width: "100%",
                  color: theme.palette.mode === "light" ? "black" : "white",
                }}
              >
                <MenuItem>Logout</MenuItem>
              </Link>

              {userRole === "admin" || userRole === "superadmin" ? (
                <MenuItem>
                  <Link
                    to="databasesettings"
                    onClick={handleClose}
                    style={{
                      textDecoration: "none",
                      background: "transparent",
                      width: "100%",
                      color: theme.palette.mode === "light" ? "black" : "white",
                    }}
                  >
                    Admin Panel
                  </Link>
                </MenuItem>
              ) : null}
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
