import React from "react";
import "./spinner.css";
import { Box } from "@mui/material";

function Spinner() {
  return (
    <div>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999,
        }}
      >
        <div class="spinner"></div>
      </Box>
    </div>
  );
}

export default Spinner;
