import React, { useEffect, useState, useRef } from "react";
import "./addDocument.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FlexBetween from "shared/FlexBetween";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ConfirmationDialog from "shared/ConfirmationDialog";
import { ToastContainer, toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Spinner from "shared/spinner/Spinner";

function AddDocument() {
  const navigate = useNavigate();
  const [selectedName, setSelectedName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [file, setFile] = useState(null);
  const theme = useTheme();
  const [employees, setEmployees] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [description, setDescription] = useState("");
  const [uploaderName, setUploaderName] = useState("");
  const [uploaderRole, setUploaderRole] = useState("");
  const [uploadConfirmationOpen, setUploadConfirmationOpen] = useState(false);
  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const apiCalledRef = useRef(false);

  const { id } = useParams();

  useEffect(() => {
    if (apiCalledRef.current) return;
    apiCalledRef.current = true;
    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      })
      .then((response) => {
        // setEmployeeId(response.data.employeeId);
        setUploaderName(response.data.name);
        setUploaderRole(response.data.role);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL)
      .then((response) => {
        setEmployees(response.data); // Set users to the array of users
        // setId(response.data._id); // Set users to the array of users
      })
      .catch((error) => {
        console.error("Error fetching user emails:", error);
      });
  }, []);

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    if (
      selectedEmployees.length === 0 ||
      !documentType ||
      !file ||
      !uploaderName ||
      !uploaderRole
    ) {
      toast.error("Please fill in all required fields.");
      setIsLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      const employeeIds = selectedEmployees.map((emp) => emp.employeeId);
      const names = selectedEmployees.map((emp) => emp.name);

      formData.append("employeeIds", JSON.stringify(employeeIds));
      formData.append("names", JSON.stringify(names));
      formData.append("documentType", documentType);
      formData.append("file", file);

      if (description) {
        formData.append("description", description);
      }

      formData.append("uploadedBy", `${uploaderName}(${uploaderRole})`);

      await axios.put(
        `${process.env.REACT_APP_API_URL}itAndDocuments/uploadDocs/${id}`,
        formData
      );
      setIsLoading(false);
      setUploadConfirmationOpen(false);
      navigate("/documentsadmin");
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  };

  const handleChange = (event) => {
    const selectedValues = event.target.value;
    setSelectedNames(selectedValues);

    // Find the employee objects with the selected names
    const newSelectedEmployees = employees.filter((employee) =>
      selectedValues.includes(employee.name)
    );

    // Update the selectedEmployees state with the new values
    setSelectedEmployees(newSelectedEmployees);
    console.log(selectedEmployees);
  };

  //Confirmation box
  const handleUploadConfirmationOpen = () => {
    setUploadConfirmationOpen(true);
  };

  const handleUploadCancel = () => {
    setUploadConfirmationOpen(false); // Close the confirmation dialog
  };

  return (
    <div>
      {isLoading && <Spinner />}
      <FlexBetween sx={{ position: "fixed" }}>
        <IconButton
          onClick={() => navigate(-1)}
          style={{ color: "#04497a", marginTop: "10px", marginLeft: "10px" }}
        >
          <ArrowBackIcon />
        </IconButton>
      </FlexBetween>
      <div
        className="formbold-main-wrapper"
        style={{ color: theme.palette.background.text }}
      >
        <div className="formbold-form-wrapper">
          <div className="formbold-form-title">
            <h3 className="header">Upload Documents</h3>
          </div>
          <ThemeProvider theme={lightTheme}>
            <FlexBetween
              sx={{ justifyContent: "flex-start", marginBottom: "20px" }}
            >
              <FormControl sx={{ width: "250px" }}>
                <InputLabel id="demo-simple-select-label">Name</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedNames}
                  label="Name"
                  onChange={handleChange}
                  multiple
                  renderValue={(selected) => selected.join(", ")}
                >
                  {employees.map(
                    (employee) =>
                      employee.country === "India" && (
                        <MenuItem key={employee._id} value={employee.name}>
                          <Checkbox
                            checked={selectedNames.indexOf(employee.name) > -1}
                          />
                          <ListItemText primary={employee.name} />
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            </FlexBetween>
          </ThemeProvider>

          <div className="formbold-input-flex">
            <div className="formbold-mb-3">
              <label className="formbold-form-label">Employee IDs:</label>
              {selectedEmployees.length > 0 ? (
                selectedEmployees.map((employee) => (
                  <h3 key={employee.employeeId}>{employee.employeeId}</h3>
                ))
              ) : (
                <p>No employee selected</p>
              )}
            </div>
            <div className="formbold-mb-3">
              <label className="formbold-form-label">Full names:</label>
              {selectedEmployees.length > 0 ? (
                selectedEmployees.map((employee) => (
                  <h3 key={employee.employeeId}>{employee.name}</h3>
                ))
              ) : (
                <p>No employee selected</p>
              )}
            </div>
          </div>
          {status === "uploaded" && (
            <div
              className="formbold-mb-3"
              style={{
                backgroundColor: "#c8e6c9",
                color: "#4caf50",
                borderRadius: "5px",
                border: "4px solid rgba(76, 175, 80, 0.5)",
                padding: "10px 10px",
                display: "inline-block",
                width: "100%",
                textAlign: "center",
                height: "auto",
                fontSize: "20px",
                fontWeight: "bolder",
              }}
            >
              {statusMessage}
            </div>
          )}
          <form>
            <div className="formbold-input-flex">
              <div className="formbold-mb-3">
                <label className="formbold-form-label">
                  Document Type
                  <span
                    style={{
                      color: "#c44545",
                      position: "relative",
                      top: "-4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="input"
                  name="documentType"
                  id="documentType"
                  className="formbold-form-input"
                  value={documentType}
                  onChange={(e) => setDocumentType(e.target.value)}
                />
              </div>
              <div className="formbold-mb-3">
                <label className="formbold-form-label">
                  Upload Document{" "}
                  <span
                    style={{
                      color: "#c44545",
                      position: "relative",
                      top: "-4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="file"
                  name="file"
                  id="document"
                  accept=".pdf, .doc, .docx, .xls, .xlsx"
                  className="formbold-form-input"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
            </div>
            <div>
              <label htmlFor="message" className="formbold-form-label">
                {" "}
                Description{" "}
              </label>
              <textarea
                rows="6"
                name="message"
                id="message"
                placeholder="Type your message"
                className="formbold-form-input"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <button
              type="button"
              onClick={handleUploadConfirmationOpen}
              className="formbold-btn"
            >
              Upload
            </button>
          </form>
        </div>
      </div>
      <ConfirmationDialog
        open={uploadConfirmationOpen}
        handleClose={handleUploadCancel}
        handleConfirm={handleSubmit}
        message="Are you sure you want to upload this document?"
      />
      <ToastContainer />
    </div>
  );
}

export default AddDocument;
