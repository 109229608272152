import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import {
  Box,
  useTheme,
  Button,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import PublishIcon from "@mui/icons-material/Publish";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import DownloadIcon from '@mui/icons-material/Download';
import { getCalendarPdfUrl, dashboardStyle } from 'shared/utils';
import { Packer, Document, Paragraph } from "docx"; // Import docx library
import * as XLSX from "xlsx";
import useAWSDetails from "shared/useAWSDetails";
import Spinner from "shared/spinner/Spinner";

function DocumentsEmployee() {

  const theme = useTheme();
  const navigate = useNavigate();
  const [employeeId, setEmployeeId] = useState("");
  const [employees, setEmployees] = useState({});
  const [open, setOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [openDescription, setOpenDescription] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [wordUrl, setWordUrl] = useState("");
  const [excelData, setExcelData] = useState([]);
  // const [fetchedBucketName, setFetchedBucketName] = useState("");
  // const [fetchedRegion, setFetchedRegion] = useState("");
  const { fetchedBucketName, fetchedRegion } = useAWSDetails();

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + "profilepage", { withCredentials: true })
      .then(response => {
        setEmployees(response.data);
        console.log("Employees: " + employees)
        setEmployeeId(response.data.employeeId);

        axios
          .get(process.env.REACT_APP_API_URL + `itAndDocuments/fetchDataDocuments/${employeeId}`)
          .then(response => {
            setDocuments(response.data.documents);
          })
          .catch(error => {
            console.error('Error fetching documents:', error);
          });

      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        if (error.response && error.response.status === 401) {
          toast.warning("Your account has been disabled. If you think this is an error contact HR. You will be logged out soon.", {
            onClose: () => {
              // Clear the token from cookies
              document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              // Reload the page after a delay
              setTimeout(() => {
                window.location.reload();
              }, 3000); // 5 seconds delay
            }
          });
        }
      });
  }, [employeeId]);

  // Assuming you're using fetch to make API calls
  //   async function fetchAWSDetails() {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API_URL}getAWSDetails`);
  //       const data = response.data;
  //       const { bucketName, region } = data;

  //       setFetchedBucketName(bucketName);
  //       setFetchedRegion(region);
  //       // Now you can use the bucketName and region in your frontend code
  //       console.log('Bucket Name:', bucketName);
  //       console.log('Region:', region);

  //       // Example usage
  //       // initializeS3Client(bucketName, region);
  //     } catch (error) {
  //       console.error('Error fetching AWS details:', error.message);
  //     }
  //   }

  // useEffect(() => {
  //   fetchAWSDetails();
  // }, [])

  const rows = documents
  .map((document) => ({
    id: document._id,
    uploadedBy: document.uploadedBy,
    documentType: document.documentType,
    document: document.document,
    description: document.description,
    createdOn: new Date(document.createdOn),  // Convert to Date object
    updatedOn: new Date(document.updatedOn),  // Convert to Date object
  }))
  .sort((a, b) => b.createdOn - a.createdOn)  // Sort in descending order
  .map(row => ({
    ...row,
    createdOn: row.createdOn.toLocaleString(),  // Format for display
    updatedOn: row.updatedOn.toLocaleString(),  // Format for display
  }));

  const handleConfirmDelete = async (documentId) => {
    setSelectedDocumentId(documentId);
    console.log(documentId);
    setConfirmationOpen(true);
    console.log(selectedDocumentId);
  };

  const handleDeleteDocument = async () => {
    try {
      // Send DELETE request to delete the document
      await axios.delete(`${process.env.REACT_APP_API_URL}itAndDocuments/deletedocument/${selectedDocumentId}`);

      // Update state to remove the deleted document
      setDocuments(documents.filter(doc => doc._id !== selectedDocumentId));

      // Display success toast notification
      toast.success("Document deleted successfully");
    } catch (error) {
      // Handle error and display error toast notification
      console.error("Error deleting document:", error);
      toast.error("Error deleting document");
    }

    // Close the confirmation dialog
    setConfirmationOpen(false);
  };


  const handleDeleteCancel = () => {
    setConfirmationOpen(false); // Close the confirmation dialog
  };

  const handleDownloadDocument = async (documentUrl, documentType, employeeId) => {
    try {
      const response = await axios.get(documentUrl, {
        responseType: "blob", // Binary data response
      });
  
      // Get the file extension from the URL
      const fileExtension = getFileExtension(documentUrl);
      
      // Create the appropriate MIME type based on file extension
      let mimeType;
      switch (fileExtension) {
        case 'pdf':
          mimeType = 'application/pdf';
          break;
        case 'xlsx':
          mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
        case 'xls':
          mimeType = 'application/vnd.ms-excel';
          break;
        case 'docx':
          mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case 'doc':
          mimeType = 'application/msword';
          break;
        default:
          mimeType = 'application/octet-stream'; // Default binary data
      }
  
      const blob = new Blob([response.data], { type: mimeType });
      const fileUrl = URL.createObjectURL(blob);
  
      // Use the correct file extension in the filename
      const fileName = `${documentType}.${fileExtension}`;
  
      // Create a temporary anchor element
      const anchor = document.createElement("a");
      anchor.href = fileUrl;
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      
      // Clean up the object URL
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error("Error downloading document:", error);
      toast.error("Error downloading document");
    }
  };

  const getFileExtension = (filename) =>
    filename.split(".").pop().toLowerCase();
  const handleOpen = (url) => {
    const fileExtension = getFileExtension(url);
    if (fileExtension === "pdf") {
      setPdfUrl(url);
      setOpen(true);
    } else if (fileExtension === "xlsx") {
      fetch(url)
        .then(response => response.arrayBuffer())
        .then(buffer => {
          const workbook = XLSX.read(buffer, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          setExcelData(data); // Assuming you have a component to display Excel data in a modal
          setPdfUrl(null);
          setOpen(true);
        })
        .catch(error => {
          console.error("Error reading Excel file:", error);
        });
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      setWordUrl(url); // Assuming you have a component to display Word documents in a modal
      setPdfUrl(null);
      setOpen(true);
    } else {
      setOpen(false);
      console.log("Unsupported file type:", fileExtension);
    }
  };

  const handleOpenDescription = (description) => {
    setDescription(description);
    setOpenDescription(true);
  };

  const handleClose = () => {
    setOpen(false)
    setOpenDescription(false)
  };

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const columns = [
    {
      field: "uploadedBy", headerName: "Uploaded by", flex: 1.5,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Uploaded by
        </Typography>
      ),
    },
    {
      field: "documentType", headerName: "Document Type", flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Document Type
        </Typography>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Description
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => handleOpenDescription(params.row.description)}
            sx={{ color: "#04497a" }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: "view",
      headerName: "Document",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Document
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() =>
              handleOpen(
                `https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/employees/${employeeId}/it-and-documents/${params.row.document}`,
              )
            }
            sx={{ color: "#04497a" }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              handleDownloadDocument(
                `https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/employees/${employeeId
                }/it-and-documents/${params.row.document}`, params.row.documentType, params.row.employeeId
              )
            }
            sx={{ color: "#04497a" }}
          >
            <DownloadIcon />
          </IconButton>
        </div>
      ),
    },
    // {
    //   field: "createdOn", headerName: "Created On", flex: 1,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Created On
    //     </Typography>
    //   ),
    // },
    {
      field: "updatedOn", headerName: "Created On", flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Created On
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Action
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <DeleteIcon
            style={{ cursor: "pointer", color: "#04497a", fontSize: "25px" }}
            onClick={() => handleConfirmDelete(params.row.id)}
          />
        </div>
      ),
    },

    // Add more columns as needed
  ];


  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    color: "black",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    width: "50%",
    height: "auto",
    wordWrap: "break-word",
    borderRadius: "20px",
  };

  const styleNew = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    color: "black",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    width: "90%", // Adjust width for PDFs and Word documents
    height: "90%", // Adjust height for PDFs and Word documents
    wordWrap: "break-word",
    borderRadius: "20px",
  };


  return (
    <div>
      <Box m="2rem 2.5rem" height="calc(100vh - 275px)">
        <ThemeProvider theme={lightTheme}>
          <Modal
            open={openDescription}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  color: "crimson",
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Message
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, postion: "absolute" }}
              >
                {description}
              </Typography>
            </Box>
          </Modal>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleNew}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  color: "crimson",
                }}
              >
                <CloseIcon />
              </IconButton>
              {pdfUrl ? (
                <object
                  data={pdfUrl}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                  style={{
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                >
                  <p>Your browser doesn't support PDF rendering.</p>
                </object>
              ) : excelData.length > 0 ? (
                <table className="excel-table">
                  <thead>
                    <tr>
                      {excelData[0].map((col, index) => (
                        <th key={index}>{col}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {excelData.slice(1).map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : wordUrl ? (
                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${wordUrl}`}
                  width="95%"
                  height="95%"
                  title="Word viewer"
                  style={{
                    margin: "auto",
                    display: "block",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                />
              ) : (
                <Typography variant="h6" align="center">
                  No file to display
                </Typography>
              )}
            </Box>
          </Modal>
        </ThemeProvider>
        <FlexBetween mb={"10px"}>
          <Header
            title="IT & Documents"
            subtitle="Manage Documents"
          />
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/employeeadddocuments");
              }}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bolder",
                padding: "10px 20px",
              }}
            >
              <PublishIcon sx={{ mr: "10px" }} /> Upload Document
            </Button>
          </Box>
        </FlexBetween>

        <ThemeProvider theme={lightTheme}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            // checkboxSelection
            rowsPerPageOptions={[5, 10, 20]}
            pageSizeOptions={[5, 10]}
            sx={dashboardStyle(theme, "480px")}
          />
        </ThemeProvider>
      </Box>
      <ConfirmationDialog
        open={confirmationOpen}
        handleClose={handleDeleteCancel}
        handleConfirm={handleDeleteDocument} // Call handleDeleteDocument on confirmation
        message="Are you sure you want to delete this Document"
      />
      <ToastContainer />
    </div>
  );
}

export default DocumentsEmployee;
