import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase, faUndo } from "@fortawesome/free-solid-svg-icons";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Header from "shared/Header";
import Typography from "@mui/material/Typography";
import FlexBetween from "shared/FlexBetween";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import "./databaseSettings.css"; // Import the CSS file
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "@mui/material";
import { indigo } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ConfirmationDialog from "../ConfirmationDialog";
import StorageIcon from "@mui/icons-material/Storage";

const theme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: "#7986cb",
    },
  },
});

const DatabaseSettings = () => {
  const muiTheme = useTheme();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [backups, setBackups] = useState([]);
  const [selectBackup, setSelectBackup] = useState("");
  const [backupConfirmationOpen, setBackupConfirmationOpen] = useState(false);
  const [restoreConfirmationOpen, setRestoreConfirmationOpen] = useState(false);
  const [restoring, setRestoring] = useState(false); // State variable for restoration process

  // const handleBackup = () => {
  //   axios
  //     .get(process.env.REACT_APP_API_URL + "backupdb/backup")
  //     .then((response) => {
  //       toast.success("Database backed up successfully");
  //       console.log("Backup initiated");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   // Fetch backup file names from the backend API
  //   axios
  //     .get(process.env.REACT_APP_API_URL + "restoredb/getbackupfiles")
  //     .then((response) => {
  //       setBackups(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching backup file names:", error);
  //     });
  // }, []);

  // const handleRestore = () => {
  //   setRestoring(true); // Set restoring to true when restoration begins
  //   axios
  //     .get(process.env.REACT_APP_API_URL + "restoredb/restore", {
  //       params: { backupName: selectBackup }, // Send the selected backup name as a query parameter
  //     })
  //     .then((response) => {
  //       toast.success("Database restored successfully");
  //       setSelectBackup("");
  //       console.log("Restore initiated");
  //       setModalOpen(false);
  //     })
  //     .catch((error) => {
  //       toast.error("There was problem restoring data. Please try again.");
  //       console.log(error);
  //     })
  //     .finally(() => {
  //       setRestoring(false); // Set restoring to false when restoration completes
  //     });
  // };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const style2 = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    color: "black",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    width: "40%",
    height: "45%",
    wordWrap: "break-word",
    borderRadius: "20px",
    display: "flex", // Add display: flex
    flexDirection: "column", // Align items vertically
    alignItems: "center", // Center items horizontally
    justifyContent: "center", // Center items vertically
  };

  // const handleCancel = () => {
  //   setConfirmationOpen(false); // Close the confirmation dialog
  // };

  const handleBackupConfirmation = () => {
    setBackupConfirmationOpen(true);
  };
  const handleConfirmBackup = () => {
    handleBackup();
    setBackupConfirmationOpen(false);
  };

  const handleRestoreConfirmation = (e) => {
    e.preventDefault();
    setRestoreConfirmationOpen(true);
  };


  const handleConfirmRestore = () => {
    handleRestore();
    setRestoreConfirmationOpen(false);
  };

  return (
    <Box m="2rem 2.5rem" height="60%">
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "crimson",
            }}
          >
            <CloseIcon />
          </IconButton>

          {restoring ? (
            <div className="loader">
              <span className="loader-text">Restoring...</span>
              <span className="load"></span>
            </div>
          ) : (
            <form>
              <FlexBetween>
                <ThemeProvider theme={lightTheme}>
                  <FormControl sx={{ width: "350px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Select Backup file
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectBackup}
                      label="Select Backup file"
                      onChange={(e) => setSelectBackup(e.target.value)}
                    >
                      {backups.map((backup) => (
                        <MenuItem key={backup} value={backup}>
                          {backup}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ThemeProvider>
              </FlexBetween>

              <button
                onClick={handleRestoreConfirmation}
                className="formbold-btn"
              >
                Restore
              </button>
            </form>
          )}
        </Box>
      </Modal>
      <FlexBetween>
        <Header
          title={"Database Settings"}
          subtitle={"Backup or Restore database"}
          style={{ marginBottom: "1rem" }} // Adjust the margin here
        />
      </FlexBetween>
      <ThemeProvider theme={theme}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",

          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: muiTheme.spacing(4),
            }}
          >
            <Card
              className="card"
              style={{
                backgroundColor: "#04497a",
                borderRadius: muiTheme.spacing(1),
                boxShadow: muiTheme.shadows[2],
                padding: muiTheme.spacing(3),
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("/backupandrestore")}
            // onClick={handleBackupConfirmation}
            >
              <CardContent>
                <IconButton disableRipple>
                  <FontAwesomeIcon
                    icon={faDatabase}
                    style={{
                      fontSize: "3rem",
                      color: "white",
                      marginBottom: muiTheme.spacing(1),
                    }}
                  />
                </IconButton>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    fontSize: "1.5rem",
                    marginBottom: muiTheme.spacing(1),
                    color: "white",
                  }}
                >
                  Backup files
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  style={{ color: "white" }}
                >
                  View Backup files
                </Typography>
              </CardContent>
            </Card>
            {/* <Card
              className="card"
              style={{
                backgroundColor: "#04497a",
                borderRadius: muiTheme.spacing(1),
                boxShadow: muiTheme.shadows[2],
                padding: muiTheme.spacing(3),
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={handleModalOpen}
            >
              <CardContent>
                <IconButton disableRipple>
                  <FontAwesomeIcon
                    icon={faUndo}
                    style={{
                      fontSize: "3rem",
                      color: "white",
                      marginBottom: muiTheme.spacing(1),
                    }}
                  />
                </IconButton>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    fontSize: "1.5rem",
                    marginBottom: muiTheme.spacing(1),
                    color: "white",
                  }}
                >
                  Restore
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  style={{ color: "white" }}
                >
                  Restore your data from a backup.
                </Typography>
              </CardContent>
            </Card> */}
            <Card
              className="card"
              style={{
                backgroundColor: "#04497a",
                borderRadius: muiTheme.spacing(1),
                boxShadow: muiTheme.shadows[2],
                padding: muiTheme.spacing(3),
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("/logstab")} // Define a handler for Logs, if necessary
            >
              <CardContent>
                <IconButton disableRipple>
                  <StorageIcon
                    style={{
                      fontSize: "3rem",
                      color: "white",
                      marginBottom: muiTheme.spacing(1),
                    }}
                  />
                </IconButton>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{
                    fontSize: "1.5rem",
                    marginBottom: muiTheme.spacing(1),
                    color: "white",
                  }}
                >
                  Logs
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  style={{ color: "white" }}
                >
                  View database logs.
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </ThemeProvider>
      <ToastContainer />
      <ConfirmationDialog
        open={backupConfirmationOpen}
        handleClose={() => setBackupConfirmationOpen(false)}
        handleConfirm={handleConfirmBackup}
        message="Are you sure you want to create database backup?"
      />
      <ConfirmationDialog
        open={restoreConfirmationOpen}
        handleClose={() => setRestoreConfirmationOpen(false)}
        handleConfirm={handleConfirmRestore}
        message="Are you sure you want to restore backup?"
      />
    </Box>
  );
};

export default DatabaseSettings;
