import React, { useEffect, useState } from "react";
import "./leaveRequest.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import FlexBetween from "shared/FlexBetween";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ConfirmationDialog from "shared/ConfirmationDialog";

function LeaveRequest() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [employeeId, setEmployeeId] = useState("");
  const [user, setUser] = useState({});
  const [name, setName] = useState("");
  const [leaveType, setLeaveType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [description, setDescription] = useState("");
  const [sickLeaves, setSickLeaves] = useState(null);
  const [privilegeLeaves, setPrivilegeLeaves] = useState(null);
  const [totalDays, setTotalDays] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [minEndDate, setMinEndDate] = useState();
  const [loggedInUser, setLoggedInUser] = useState("");

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      })
      .then((response) => {
        setUser(response.data);
        setEmployeeId(response.data.employeeId);
        setLoggedInUser(response.data.name);
        // Fetch leave data based on employee ID
        axios
          .get(
            `${process.env.REACT_APP_API_URL}leaves/getLeaveLimits/${employeeId}`
          )
          .then((response) => {
            setSickLeaves(response.data.sickLeaves);
            setPrivilegeLeaves(response.data.privilegeLeaves);
          })
          .catch((error) => {
            console.error("Error fetching leave details:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [employeeId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Calculate total days
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    // Calculate the difference in days
    const differenceDays = Math.round((endDateObj - startDateObj) / oneDay);

    // Add 1 to include both start and end dates in the count
    const calculatedTotalDays = differenceDays + 1;
    setTotalDays(calculatedTotalDays);

    // Check if leave type is sick leave and total days exceed available sick leaves
    if (leaveType === "Sick Leave" && calculatedTotalDays > sickLeaves) {
      // Show toast notification for sick leave limit exceeded
      toast.error(
        "Sick Leave limit exceeded. Try another leave or reduce the number of leaves."
      );
      return;
    }

    // Check if leave type is privilege leave and total days exceed available privilege leaves
    if (
      leaveType === "Privilege Leave" &&
      calculatedTotalDays > privilegeLeaves
    ) {
      // Show toast notification for privilege leave limit exceeded
      toast.error(
        "Privilege Leave limit exceeded. Try another leave or reduce the number of leaves."
      );
      return;
    }
    axios
      .post(process.env.REACT_APP_API_URL + "leaves", {
        employeeId: employeeId,
        name: name, // Include the name value
        leaveType,
        startDate,
        endDate,
        totalDays: calculatedTotalDays,
        description,
        status: "Pending",
        updatedBy: loggedInUser
      })
      .then((response) => {
        console.log(response.data);
        // Redirect or handle success as needed
        setOpenConfirmation(false);
        navigate(-1); // Redirect to a success page, for example
      })
      .catch((error) => {
        console.error("Error creating time sheet:", error);
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.error || "Bad Request");
        } else {
          toast.error("An error occurred while creating the leave request.");
        }
      });
  };

  useEffect(() => { }, [totalDays, sickLeaves, privilegeLeaves, leaveType]);

  //Confirmation box
  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  useEffect(() => {
    setMinEndDate(startDate);
  }, [startDate]);

  return (
    <div className="page-container">
      <IconButton
        onClick={() => navigate(-1)}
        style={{ position: 'absolute', top: '20px', left: '20px', color: "#04497a" }}
      >
        <ArrowBackIcon />
      </IconButton>
  
      <div className="form-wrapper">
        <div className="header-section">
          <h2 className="page-title">Create Leave Request</h2>
          <div className="leave-info">
            <div className="employee-id">
              ID: <span>{employeeId}</span>
            </div>
            <div className="leave-balance">
              <div className="balance-item">
                <label>Privilege Leave:</label>
                <span>{privilegeLeaves}</span>
              </div>
              <div className="balance-item">
                <label>Sick Leave:</label>
                <span>{sickLeaves}</span>
              </div>
            </div>
          </div>
        </div>
  
        <form onSubmit={handleSubmit}>
          <div className="form-content">
            <div className="form-row">
              <div className="input-group">
                <label>Leave Type<span className="required">*</span></label>
                <select
                  value={leaveType}
                  onChange={(e) => setLeaveType(e.target.value)}
                  className="form-input"
                >
                  <option value="">Select Leave Type</option>
                  <option value="Privilege Leave">Privilege Leave</option>
                  <option value="Sick Leave">Sick Leave</option>
                </select>
              </div>
            </div>
  
            <div className="form-row date-section">
              <div className="input-group">
                <label>From<span className="required">*</span></label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="form-input"
                />
              </div>
              <div className="input-group">
                <label>To<span className="required">*</span></label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  disabled={!startDate}
                  min={minEndDate}
                  className="form-input"
                />
              </div>
            </div>
  
            <div className="form-row">
              <div className="input-group full-width">
                <label>Description<span className="required">*</span></label>
                <textarea
                  rows="4"
                  placeholder="Please provide details about your leave request..."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="form-input"
                ></textarea>
              </div>
            </div>
  
            <div className="button-section">
              <button
                type="button"
                onClick={handleOpenConfirmation}
                className="submit-button"
              >
                Submit Request
              </button>
            </div>
          </div>
        </form>
      </div>
  
      <ConfirmationDialog
        open={openConfirmation}
        handleClose={handleCloseConfirmation}
        handleConfirm={handleSubmit}
        message="Are you sure you want to submit this leave request?"
      />
      <ToastContainer />
    </div>
  );
}

export default LeaveRequest;
