import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import { Box, Button, useTheme, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import ConfirmationDialog from "shared/ConfirmationDialog";
import { useParams, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

//Fetch APIs
import axios from "axios";

//Toast Notifications
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dashboardStyle } from "shared/utils";

function ManageAttendance() {
	const theme = useTheme();
	const navigate = useNavigate();
	const [employeeId, setEmployeeId] = useState("");
	const [timesheets, setTimesheets] = useState([]);
	const [selectedMonth, setSelectedMonth] = useState("");
	const [selectedYear, setSelectedYear] = useState(2024);
	const [originalTimesheets, setOriginalTimesheets] = useState([]);
	const [selectedRowIds, setSelectedRowIds] = useState([]);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [downloadTimesheetOpen, setDownloadTimesheetOpen] = useState(false);
	const { fetchedEmployeeId, year, month } = useParams();
	const [attendanceData, setAttendanceData] = useState([]);
	const [uploadConfirmationOpen, setUploadConfirmationOpen] = useState(false);

	const updateStatus = () => {
		axios
			.get(
				process.env.REACT_APP_API_URL + "attendance/getTimesheet/" + employeeId
			)
			.then((response) => {
				const attendanceData = response.data.attendance;
				if (!attendanceData || attendanceData.length === 0) {
					setTimesheets([]);
					setOriginalTimesheets([]);
				} else {
					const sortedAttendance = attendanceData.sort(
						(a, b) => new Date(b.date) - new Date(a.date)
					);
					setTimesheets(sortedAttendance);
					setOriginalTimesheets(sortedAttendance);
				}
			})
			.catch((error) => {
				if (error.response && error.response.status === 404) {
					setTimesheets([]);
					setOriginalTimesheets([]);
				} else {
					console.error("Error fetching attendance data:", error);
				}
			});
	};

	useEffect(() => {
		updateStatus();
	}, [employeeId]);

	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const handleSubmitStatus = async () => {
		try {
			// Assuming selectedRowIds contain the IDs of the timesheets you want to submit
			for (const timesheetId of selectedRowIds) {
				await axios.put(
					`${process.env.REACT_APP_API_URL}attendance/api/attendance/updateStatus/${timesheetId}`,
					{ status: "Approved", employeeId: fetchedEmployeeId, year, month }
				);
			}
			toast.success("Timesheet(s) submitted successfully");
			setConfirmationOpen(false);
			fetchTimesheets();
			// Optionally, you can refresh the data after submission
			// For example, you can refetch the attendance data using useEffect
		} catch (error) {
			console.error("Error submitting timesheet:", error);
			toast.error("Failed to submit timesheet(s)");
		}
	};


	const rows = attendanceData
		.map((timesheet) => ({
			id: timesheet._id,
			date: timesheet.date,
			workType: timesheet.workType,
			startTime: timesheet.startTime,
			endTime: timesheet.endTime,
			workingHours: timesheet.workingHours,
			status: timesheet.status,
			createdOn: timesheet.createdOn,
			updatedOn: timesheet.updatedOn,
		}))
		.sort((a, b) => new Date(a.date) - new Date(b.date));



	const fetchTimesheets = () => {
		const monthNumber = parseInt(month);
		// if (employeeId && selectedYear && selectedMonth) {
		axios
			.get(
				`${process.env.REACT_APP_API_URL}attendance/api/attendance/${fetchedEmployeeId}/${year}/${monthNumber}`
			)
			.then((response) => {
				setAttendanceData(response.data.attendance);
			})
			.catch((error) => {
				console.error("Error fetching attendance data:", error);
			});
	}

	useEffect(() => {
		fetchTimesheets();
		// }
	}, []);

	const handleHeaderCheckboxChange = (event) => {
		if (event.target.checked) {
			const allRowIds = rows.map((row) => row.id);
			setSelectedRowIds(allRowIds);
		} else {
			setSelectedRowIds([]);
		}
	};

	const columns = [
		{
			field: "checkbox",
			headerName: (
				<Checkbox
					checked={selectedRowIds.length === rows.length}
					indeterminate={
						selectedRowIds.length > 0 && selectedRowIds.length < rows.length
					}
					onChange={handleHeaderCheckboxChange}
				/>
			),
			flex: 1,
			renderCell: (params) => (
				<Checkbox
					checked={
						selectedRowIds.includes(params.row.id) ||
						params.row.status === "Approved"
					}
					onChange={() => handleRowCheckboxChange(params.row.id)}
					disabled={params.row.status === "Approved"}
				/>
			),
		},
		{
			field: "date",
			headerName: "Date",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Date
				</Typography>
			),
		},
		{
			field: "workType",
			headerName: "Work Type",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Work Type
				</Typography>
			),
		},
		{
			field: "startTime",
			headerName: "Start Time",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Start Time
				</Typography>
			),
		},
		{
			field: "endTime",
			headerName: "End Time",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					End Time
				</Typography>
			),
		},
		{
			field: "workingHours",
			headerName: "Total Working Hours",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Total Hours
				</Typography>
			),
		},
		{
			field: "createdOn",
			headerName: "Created On",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Created On
				</Typography>
			),
		},
		{
			field: "updatedOn",
			headerName: "Updated On",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Updated On
				</Typography>
			),
		},
		{
			field: "status",
			headerName: "Status",
			flex: 1,
			renderHeader: () => (
				<Typography fontSize="14px" fontWeight="bold">
					Status
				</Typography>
			),
			renderCell: (params) => (
				<div
					style={{
						color:
							params.value === "Approved"
								? "#4caf50"
								: params.value === "Pending"
									? "#ff9800"
									: params.value === "Submitted"
										? "#007bff"
										: "inherit",
						background:
							params.value === "Approved"
								? "#c8e6c9"
								: params.value === "Pending"
									? "#ffe0b2"
									: params.value === "Submitted"
										? "#cce5ff"
										: "inherit",
						borderRadius: "4px",
						padding: "8px",
						display: "inline-block",
						width: "90px",
						textAlign: "center",
					}}
				>
					{params.value}
				</div>
			),
		},
	];

	const lightTheme = createTheme({
		palette: {
			primary: indigo,
		},
	});

	const handleRowCheckboxChange = (rowId) => {
		console.log("row");
		const selectedIndex = selectedRowIds.indexOf(rowId);
		let newSelectedRowIds = [];

		if (selectedIndex === -1) {
			newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds, rowId);
		} else {
			newSelectedRowIds = selectedRowIds.filter((id) => id !== rowId);
		}

		setSelectedRowIds(newSelectedRowIds);
	};

	const handleOpenConfirmation = () => {
		setConfirmationOpen(true);
	};

	// Function to handle closing the confirmation dialog
	const handleCloseConfirmation = () => {
		setConfirmationOpen(false);
		setDownloadTimesheetOpen(false);
	};

	// const handleOpenDownloadConfirmation = () => {
	// setDownloadTimesheetOpen(true);
	// };

	return (
		<>
			{/* <FlexBetween /> */}
			<div>
				<FlexBetween sx={{ position: "fixed" }}>
					<IconButton
						onClick={() => navigate(-1)}
						style={{ color: "#04497a", marginTop: "25px", marginLeft: "10px" }}
					>
						<ArrowBackIcon />
					</IconButton>
				</FlexBetween>
			</div>
			<Box m="2rem 2.5rem" height="550px" >
				<FlexBetween sx={{ marginBottom: "20px" }}>
					<Header
						title={`${year} ${months[parseInt(month, 10) - 1]} Timesheet`}
						subtitle={`Employee Id: ${fetchedEmployeeId || "N/A"}`}
					/>

					{/* )} */}
					<FlexBetween
						sx={{ justifyContent: "flex-end", width: "36%" }}
					>

						<ThemeProvider theme={lightTheme}>

							<FlexBetween sx={{ justifyContent: "flex-end", width: "100%" }}>
								<Button
									variant="contained"
									color="primary"
									sx={{
										backgroundColor: theme.palette.secondary.light,
										color: theme.palette.background.alt,
										fontSize: "14px",
										fontWeight: "bolder",
										padding: "10px 10px",
									}}
									onClick={handleOpenConfirmation} // Open confirmation dialog on click
									disabled={selectedRowIds.length === 0}
								>
									Approve
								</Button>
							</FlexBetween>
						</ThemeProvider>
					</FlexBetween>
				</FlexBetween>

				<ThemeProvider theme={lightTheme}>
					<DataGrid
						rows={rows}
						columns={columns}
						pageSize={31}
						rowsPerPageOptions={[5, 10, 20]}
						pageSizeOptions={[5, 10]}
						sx={{
							color: theme.palette.background.text,
							// color: "theme.palette.background.text", // remove this line
							backgroundColor: theme.palette.primary.main,
							borderRadius: "15px",
							boxShadow: "13px 22px 19px 0px rgba(0,0,0,0.2)",
							border: "1px solid #DBE2EF",
							fontWeight: "light",
							fontSize: "13px",
							'& .MuiTablePagination-root': {
								color: theme.palette.background.text,
							},
							height: "460px",
						}}
					/>
					{/* 
						<FlexBetween
							sx={{
								marginTop: "10px",
								justifyContent: "flex-end",
								marginBottom: "20px",
							}}
						></FlexBetween> */}
				</ThemeProvider>
				<ToastContainer />
				<ConfirmationDialog
					open={downloadTimesheetOpen} // Pass state to control dialog visibility
					handleClose={handleCloseConfirmation} // Function to close the dialog
					// handleConfirm={exportToExcel} // Function to handle confirmation action (download)
					message="Are you sure you want to download the timesheet?" // Confirmation message
				/>
				<ConfirmationDialog
					open={confirmationOpen} // Pass state to control dialog visibility
					handleClose={handleCloseConfirmation} // Function to close the dialog
					handleConfirm={handleSubmitStatus} // Function to handle confirmation action (approve)
					message="Are you sure you want to approve the timesheets?" // Confirmation message
				/>
				<ConfirmationDialog
					open={uploadConfirmationOpen} // Pass state to control dialog visibility
					handleClose={handleCloseConfirmation} // Function to close the dialog
					handleConfirm={handleSubmitStatus} // Function to handle confirmation action (approve)
					message="Are you sure you want to approve the timesheets?" // Confirmation message
				/>
			</Box>
		</>
	);
}

export default ManageAttendance;
