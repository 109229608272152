import React, { useEffect, useState } from "react";
import "./payslips.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FlexBetween from "shared/FlexBetween";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ToastContainer, toast } from "react-toastify";
import ConfirmationDialog from "shared/ConfirmationDialog";
import Spinner from "shared/spinner/Spinner";

function UploadPayslip() {
  const theme = useTheme();

  const [employeeId, setEmployeeId] = useState("");
  // const [userName, setUserName] = useState("");
  const [name, setName] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState();
  const [file, setFile] = useState();
  const [status, setStatus] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [employees, setEmployees] = useState([]);
  const [sendConfirmationOpen, setSendConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");

// Modify the file onChange handler
const handleFileChange = (e) => {
  const selectedFile = e.target.files[0];
  setFile(selectedFile);
  setSelectedFileName(selectedFile ? selectedFile.name : "");
};


  const navigate = useNavigate();
  // const { id } = useParams();

  useEffect(() => {
    if (id) {
      axios
        .get(process.env.REACT_APP_API_URL + "getUser/" + id)
        .then((result) => {
          console.log(result);
          setEmployeeId(result.data.employeeId);
          setUserName(result.data.userName);
          setName(result.data.name);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL)
      .then((response) => {
        setEmployees(response.data); // Set users to the array of users
      })
      .catch((error) => {
        console.error("Error fetching user emails:", error);
      });
  }, []);

  useEffect(() => {
    // Check if month already exists in database
    if (selectedMonth && selectedYear) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
          `payslips/check/${employeeId}/${selectedYear}/${selectedMonth}`
        )
        .then((result) => {
          if (result.data.status === "uploaded") {
            setStatus("uploaded");
            setStatusMessage(`Payslip is already uploaded for ${selectedMonth}`);
            toast.error(`Payslip is already uploaded for ${selectedMonth}`)
          } else {
            setStatus("");
            setStatusMessage("");
          }
        })
        .catch((err) => console.log(err));
    }
  }, [selectedMonth, selectedYear]);

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const validateFileName = (fileName) => {
    // Check if filename matches expected format: something_employeeID_month_year.pdf
    const fileFormat = /^[^_]+_[^_]+_(?:January|February|March|April|May|June|July|August|September|October|November|December)_\d{4}\.pdf$/;
    
    if (!fileFormat.test(fileName)) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (!employeeId || !name || !selectedYear || !selectedMonth || !file) {
        toast.error("Please fill in all required fields.");
        setIsLoading(false);
        return;
      }

      // Validate filename format first
      if (!validateFileName(file.name)) {
        toast.error("Invalid file name format. Expected format: prefix_employeeID_month_year.pdf");
        setIsLoading(false);
        return;
      }

      const fileNameParts = file.name.split("_");
      
      // Additional safety check in case split doesn't produce expected parts
      if (fileNameParts.length !== 4) {
        toast.error("Invalid file name format. Expected format: prefix_employeeID_month_year.pdf");
        setIsLoading(false);
        return;
      }

      const extractedEmployeeId = fileNameParts[1];
      const extractedMonthName = fileNameParts[2];
      const extractedYear = parseInt(fileNameParts[3].replace(".pdf", ""));

      if (extractedEmployeeId !== employeeId) {
        toast.error("Employee ID in the file does not match the selected ID.");
        setIsLoading(false);
        return;
      }

      if (extractedMonthName !== selectedMonth) {
        toast.error("Month in the file does not match the selected month.");
        setIsLoading(false);
        return;
      }

      if (extractedYear != selectedYear) {
        toast.error("Year in the file does not match the selected year.");
        setIsLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append("employeeId", employeeId);
      formData.append("name", name);
      formData.append("year", selectedYear);
      formData.append("month", extractedMonthName);
      formData.append("file", file);

      await axios.post(process.env.REACT_APP_API_URL + "payslips/uploadpayslip", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      
      setSendConfirmationOpen(false);
      navigate("/managepayslips");
    } catch (error) {
      console.error("Error uploading payslip:", error);
      toast.error("Failed to upload the payslip.");
    } finally {
      setIsLoading(false);
    }
  };

  const years = Array.from({ length: 11 }, (_, index) => 2024 + index);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //Setting the selected name and employee ID
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedName(selectedValue);
  
    // Find the employee object with the selected name
    const selectedEmployee = employees.find((employee) => employee.name === selectedValue);
  
    // If employee is found, set the employee ID and name
    if (selectedEmployee) {
      setEmployeeId(selectedEmployee.employeeId);
      setName(selectedEmployee.name);
  
      // Fetch additional user data if needed
      axios
        .get(process.env.REACT_APP_API_URL + "getUser/" + selectedEmployee._id)
        .then((result) => {
          // Update state with additional user data if needed
        })
        .catch((err) => console.log(err));
    }
  };


  useEffect(() => {

  }, [selectedName]);

  //Confirmation dialog box
  const handleSendConfirmationOpen = () => {
    setSendConfirmationOpen(true);
  };

  // Function to cancel the send payslip action
  const handleCancelSend = () => {
    // Close the confirmation dialog without sending the payslip
    setSendConfirmationOpen(false);
  };

  return (
    <div className="main-wrapper">
      <IconButton 
        onClick={() => navigate(-1)} 
        style={{ 
          color: "#04497a", 
          position: 'absolute', 
          top: '20px', 
          left: '20px' 
        }}
      >
        <ArrowBackIcon />
      </IconButton>
  
      {isLoading && <Spinner />}
  
      <div className="form-wrapper">
        <h3 className="header">Payslips</h3>
        
        <div className="form-grid">
          {/* Left Panel */}
          <div className="left-panel">
            <div className="employee-selection">
              <ThemeProvider theme={lightTheme}>
                <FormControl>
                  <InputLabel>Select Employee<span style={{ color: "#c44545", position: "relative", top: "-4px" }}>*</span></InputLabel>
                  <Select
                    value={selectedName}
                    label="Select Employee"
                    onChange={handleChange}
                  >
                    <MenuItem value="">Show All</MenuItem>
                    {employees.map((employee) => (
                      <MenuItem key={employee._id} value={employee.name}>
                        {employee.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ThemeProvider>
            </div>
  
            <div className="employee-info">
              <div className="info-field">
                <div className="info-label">Employee ID</div>
                <div className="info-value">{employeeId || "Not Selected"}</div>
              </div>
              <div className="info-field">
                <div className="info-label">Full Name</div>
                <div className="info-value">{name || "Not Selected"}</div>
              </div>
            </div>
          </div>
  
          {/* Right Panel */}
          <div className="right-panel">
            <form>
              <div className="date-selection-grid">
                <div className="form-control">
                  <label className="form-label">
                    Year
                    <span style={{ color: "#c44545", position: "relative", top: "-4px" }}>*</span>
                  </label>
                  <select
                    className="form-input"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                  >
                    <option value="">Select Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
  
                <div className="form-control">
                  <label className="form-label">
                    Month
                    <span style={{ color: "#c44545", position: "relative", top: "-4px" }}>*</span>
                  </label>
                  <select
                    className="form-input"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                  >
                    <option value="">Select Month</option>
                    {months.map((month, index) => (
                      <option key={index} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
  
              <div className="formbold-mb-3">
              <label className="formbold-form-label">Upload Payslip<span style={{ color: "#c44545", position: "relative", top: "-4px" }}>*</span></label>
              <input
                type="file"
                name="file"
                id="payslip"
                accept="application/pdf"
                className="formbold-form-input"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>
  
              {status === "uploaded" && (
                <div style={{ color: "#c44545", marginBottom: "15px" }}>
                  {statusMessage}
                </div>
              )}
  
              <button 
                type="button" 
                onClick={handleSendConfirmationOpen} 
                className="submit-button"
                disabled={status === "uploaded"}
              >
                Send Payslip
              </button>
            </form>
          </div>
        </div>
      </div>
  
      <ConfirmationDialog
        open={sendConfirmationOpen}
        handleClose={handleCancelSend}
        handleConfirm={handleSubmit}
        message="Are you sure you want to send this payslip?"
      />
      
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default UploadPayslip;
