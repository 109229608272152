import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ReceiptLongOutlined,
} from "@mui/icons-material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import EventIcon from "@mui/icons-material/Event";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import axios from "axios";
import ivtreeLogo from "../assets/images/ivtreeLogo.jpg";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import useAuth from "./useAuth";

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen, isNonMobile }) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const { userRole, loading } = useAuth();
  const [country, setCountry] = useState("");
  const [openSubMenu, setOpenSubMenu] = useState("");

  const drawerWidth = 240;

  const fetchData = async () => {
    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      })
      .then((response) => {
        setCountry(response.data.country);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setActive(pathname.substring(1));
    // Check if current path is admin/timesheets or has timesheets in it, open the submenu
    if (pathname.includes('timesheets') || pathname.includes('timesheetsadmin')) {
      setOpenSubMenu("Timesheets");
    }
  }, [pathname]);

const navItems = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
    link: "dashboard",
  },
  {
    text: "User Management",
    icon: <ManageAccountsOutlinedIcon />,
    link: "employee",
  },
  {
    text: "Employee Details",
    icon: <BadgeOutlinedIcon />,
    link: "employeedetails",
  },
  {
    text: "Timesheets",
    icon: <PermContactCalendarOutlinedIcon />,
    link: "admin/timesheets",
    subItems: [
      {
        text: "Timesheet-IND",
        link: "admin/timesheets",
      },
      {
        text: "Timesheet-US",
        link: "us/timesheetsadmin",
      }
    ]
  },
  {
    text: "Leaves",
    icon: <EventIcon />,
    link: "manageleaves",
  },
  {
    text: "Pay Slips",
    icon: <ReceiptLongOutlined />,
    link: "managepayslips",
  },
  {
    text: "Holiday Calendar",
    icon: <CalendarMonthIcon />,
    link: "holidaycalendar",
  },
  ...(country !== "US" ? [
    {
      text: "IT / Documents ",
      icon: <UploadFileOutlinedIcon />,
      link: "documentsadmin",
    },
    {
      text: "Link to EPF Portal",
      icon: <InsertLinkIcon />,
      link: "linktoepfportal",
    },
  ] : []),
  {
    text: "Current Openings",
    icon: <WorkOutlineOutlinedIcon />,
    link: "manageopenings",
  },
];

const userNavItems = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
    link: "dashboard",
  },
  {
    text: "Timesheets",
    icon: <PermContactCalendarOutlinedIcon />,
    link: country === "US" ? "us/timesheets" : "timesheetmonthstable",
    ...(userRole === "admin" || userRole === "hr" || userRole === "superadmin" ? {
      subItems: [
        {
          text: "Timesheet-IND",
          link: "timesheetmonthstable",
        },
        {
          text: "Timesheet-US",
          link: "us/timesheets",
        }
      ]
    } : {})
  },
  {
    text: "Leave",
    icon: <EventIcon />,
    link: userRole === "manager" ? "manageleaves" : "employeeLeaves",
  },
  {
    text: "Pay Slips",
    icon: <ReceiptLongOutlined />,
    link: "paysliptable",
  },
  {
    text: "Holiday Calendar",
    icon: <CalendarMonthIcon />,
    link: "employeeholidayCalendar",
  },
  ...(country !== "US" ? [
    {
      text: "IT / Documents ",
      icon: <ArticleOutlinedIcon />,
      link: "documentsemployee",
    },
    {
      text: "Link to EPF Portal",
      icon: <InsertLinkIcon />,
      link: "linktoepfportal",
    },
  ] : []),
  {
    text: "Current Openings",
    icon: <WorkOutlineOutlinedIcon />,
    link: "employeeopenings",
  },
];

// ... (previous code remains the same until handleItemClick function)

const handleItemClick = (text, link, hasSubItems) => {
  if (text === "Link to EPF Portal") {
    window.open("https://www.epfindia.gov.in/", "_blank");
    return;
  }
  
  // Close submenu if clicking on a different tab
  if (text !== "Timesheets" && openSubMenu === "Timesheets") {
    setOpenSubMenu("");
  }
  
  if (hasSubItems) {
    if (text === "Timesheets") {
      setOpenSubMenu(openSubMenu === text ? "" : text);
      if (openSubMenu !== text) {
        // If opening the menu, navigate to the first subtab (IND) by default
        const firstSubItem = navItems.find(item => item.text === "Timesheets")?.subItems[0];
        if (firstSubItem) {
          setActive(firstSubItem.link);
          navigate(`/${firstSubItem.link}`);
          return;
        }
      }
    } else {
      setOpenSubMenu(openSubMenu === text ? "" : text);
    }
  }
  
  setActive(link);
  navigate(`/${link}`);
};

// ... (rest of the code remains the same)
  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              position: "fixed",
              top: "15px",
              left: "15px",
              color: theme.palette.secondary[200],
              backgroundColor: "white",
              boxShadow: "13px 22px 19px 0px rgba(0,0,0,0.1)",
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
              transition: "width 0.3s ease",
              height: "95%",
              borderRadius: "15px",
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 1.5rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <img
                    src={ivtreeLogo}
                    alt="IVTREE Logo"
                    onClick={() => navigate("/")}
                    style={{ width: "auto", height: "2rem", cursor: "pointer" }}
                  />
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft sx={{ color: "black" }} />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>

            <List>
              {(userRole === "admin" ||
                userRole === "hr" ||
                userRole === "superadmin"
                ? navItems
                : userNavItems
              ).map(({ text, icon, link, subItems }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ mx: "1rem" }}>
                      {text}
                    </Typography>
                  );
                }

                if (subItems) {
                  return (
                    <Box key={text}>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => handleItemClick(text, link, true)}
                          sx={{
                            backgroundColor:
                              active === link || openSubMenu === text
                                ? "#8BC34A"
                                : "transparent",
                            color:
                              active === link || openSubMenu === text
                                ? "white"
                                : theme.palette.secondary[100],
                            borderRadius: "0px",
                            width: "100%",
                            padding: "8px 16px",
                            "&:hover": {
                              backgroundColor: "#8BC34A",
                              color: "white",
                              "& .MuiListItemIcon-root": {
                                color: "white",
                              },
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              padding: "0px 0px",
                              minWidth: "40px",
                              color:
                                active === link || openSubMenu === text
                                  ? "white"
                                  : theme.palette.secondary[200],
                              "&:hover": {
                                color: "white",
                              },
                            }}
                          >
                            {icon}
                          </ListItemIcon>
                          <ListItemText primary={text} />
                          {(openSubMenu === text || active === link) && (
                            <ChevronRightOutlined
                              sx={{
                                ml: "auto",
                                color: "white",
                                transform: openSubMenu === text ? 'rotate(90deg)' : 'none',
                                transition: 'transform 0.3s',
                              }}
                            />
                          )}
                        </ListItemButton>
                      </ListItem>
                      {openSubMenu === text && subItems.map((subItem, index) => (
                        <ListItem key={subItem.text} disablePadding>
                          <ListItemButton
                            onClick={() => handleItemClick(subItem.text, subItem.link)}
                            sx={{
                              backgroundColor:
                                active === subItem.link
                                  ? theme.palette.secondary[100]
                                  : "transparent",
                              color:
                                active === subItem.link
                                  ? "white"
                                  : theme.palette.secondary[100],
                              borderRadius: "0px",
                              width: "100%",
                              padding: "8px 16px 8px 56px",
                              "&:hover": {
                                backgroundColor: "#8BC34A",
                                color: "white",
                              },
                            }}
                          >
                            <ListItemText primary={subItem.text} />
                            {active === subItem.link && (
                              <ChevronRightOutlined
                                sx={{ ml: "auto", color: "white" }}
                              />
                            )}
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </Box>
                  );
                }

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => handleItemClick(text, link)}
                      sx={{
                        backgroundColor:
                          active === link
                            ? "#8BC34A"
                            : "transparent",
                        color:
                          active === link
                            ? "white"
                            : theme.palette.secondary[100],
                        borderRadius: "0px",
                        width: "100%",
                        padding: "8px 16px",
                        "&:hover": {
                          backgroundColor: "#8BC34A",
                          color: "white",
                          "& .MuiListItemIcon-root": {
                            color: "white",
                          },
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "0px 0px",
                          minWidth: "40px",
                          color:
                            active === link
                              ? "white"
                              : theme.palette.secondary[200],
                          "&:hover": {
                            color: "white",
                          },
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === link && (
                        <ChevronRightOutlined
                          sx={{ ml: "auto", color: "white" }}
                        />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;