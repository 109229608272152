import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
// import ivtreeLogo from "../../assets/images/ivtree.png";
import ivtreeLogo from "../../assets/images/ivtree-removebg-preview.png";
import { Link } from "react-router-dom";
// import { setupTokenExpirationHandler } from "shared/tokenManager"; 

// Email regex pattern
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpPhase, setIsOtpPhase] = useState(false);
  const [userId, setUserId] = useState(null);
  const [timer, setTimer] = useState(120);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const navigate = useNavigate();

  axios.defaults.withCredentials = true;

  useEffect(() => {
    let interval;
    if (isOtpPhase && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendEnabled(true);
    }
    return () => clearInterval(interval);
  }, [isOtpPhase, timer]);

    const validateEmail = (email) => {
    return emailRegex.test(email.trim().toLowerCase());
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const trimmedEmail = email.trim().toLowerCase();
    
    if (!validateEmail(trimmedEmail)) {
      toast.error("Invalid email format");
      return;
    }

    setIsLoading(true);
    axios
      .post(process.env.REACT_APP_API_URL + "login", { email: trimmedEmail, password })
      .then((res) => {
        if (res.data.Status === "Success") {
          setUserId(res.data.userId);
          setIsOtpPhase(true);
          setTimer(120);
          setResendEnabled(false);
          toast.info(`OTP sent to ${trimmedEmail}`);
        } else {
          toast.error(res.data.message || "An unknown error occurred");
        }
      })
      .catch((err) => {
        setIsOtpSent(false);  // Hide OTP field if there was an error
        if (err.response) {
          switch (err.response.status) {
            case 401:
              toast.error(err.response.data.message);
              break;
            case 404:
              toast.error("Email not found. Please check your email address.");
              break;
            case 500:
              navigate("/500");
              break;
            default:
              toast.error("There was an error. Please try again later.");
          }
        } else {
          toast.error("There was an error. Please try again later.");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // const handleOtpSubmit = (e) => {
  //   e.preventDefault();
  //   axios
  //     .post(process.env.REACT_APP_API_URL + "verify-otp", { userId, otp })
  //     .then((res) => {
  //       if (res.data.Status === "Success") {
  //         toast.success("Logged in Successfully");
  //         navigate("/dashboard");
  //       } else {
  //         toast.error("Invalid OTP");
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.response && err.response.status === 401) {
  //         toast.error("Invalid OTP");
  //       } else {
  //         toast.error("There was an error. Please try again later.");
  //       }
  //     });
  // };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(process.env.REACT_APP_API_URL + "verify-otp", { userId, otp })
      .then((res) => {
        if (res.data.Status === "Success") {
          toast.success("Logged in Successfully");
          navigate("/dashboard", { replace: true });
          // Session management will be automatically initialized by SessionHandler component
        } else {
          toast.error("Invalid OTP");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          toast.error("Invalid OTP");
        } else {
          toast.error("There was an error. Please try again later.");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  
    const handleResendOtp = () => {
      setIsLoading(true);
      axios
        .post(process.env.REACT_APP_API_URL + "resendOtp/" + userId)
        .then((res) => {
          if (res.data.Status === "Success") {
            setTimer(120);
            setResendEnabled(false);
            toast.info(`New OTP sent to ${email}`);
          } else {
            toast.error("Error resending OTP. Please try again later.");
          }
        })
        .catch((err) => {
          toast.error("There was an error. Please try again later.");
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

  return (
    <div className="centered-container">
      {!isOtpPhase ? (
        <form className="login_form_container" onSubmit={handleLoginSubmit}>
          <div className="login_logo_container">
            <img src={ivtreeLogo} alt="IVTREE Logo" className="logo_style" />
          </div>
          <div className="title_container">
            <p className="title" style={{ fontSize: "18px" }}>Welcome to IVTREE Connect</p>
          </div>
          <br />
          <div className="input_container" style={{ width: "85%" }}>
            <label
              className="input_label"
              htmlFor="email_field"
              style={{ color: "black", fontSize: "14px" }}
            >
              Email
            </label>
            <input
              placeholder=""
              title="Email Address"
              name="email"
              type="text"
              className="input_field"
              id="email_field"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input_container" style={{ width: "85%" }}>
            <label
              className="input_label"
              htmlFor="password_field"
              style={{ color: "black", fontSize: "14px" }}
            >
              Password
            </label>
            <input
              placeholder=""
              title="Password"
              name="password"
              type="password"
              className="input_field"
              id="password_field"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button title="Sign In" type="submit" className="sign-in_btn" style={{ width: "85%", fontSize: "14px" }}>
            <span>Sign In</span>
          </button>
          <Link to="/forgot-password" style={{ textDecoration: "none", fontWeight: "bold", marginTop: "10px", fontSize: "15px" }} className="forgot-password-link">
            Forgot Password?
          </Link>
        </form>
      ) : (
        <form className="login_form_container" onSubmit={handleOtpSubmit}>
          <div className="login_logo_container">
            <img src={ivtreeLogo} alt="IVTREE Logo" className="logo_style" />
          </div>
          <div className="title_container">
            <p className="title" style={{ fontSize: "18px" }}>Welcome to IVTREE Connect</p>
          </div>
          <br />
          <div className="input_container">
            <input
              placeholder=""
              title="OTP"
              name="otp"
              type="text"
              className="otp_input_field"
              id="otp_field"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          <button
            style={{  width: "50%", fontSize: "14px" }}
            title="Verify OTP"
            type="submit"
            className="sign-in_btn"
          >
            <span>Verify OTP</span>
          </button>
          <div className="timer_container">
            <p style={{ color: "black" }}>{`Resend OTP: ${Math.floor(
              timer / 60
            )}:${("0" + (timer % 60)).slice(-2)}`}</p>
          </div>
          {resendEnabled ? (
            <button
              style={{ marginTop: "10px",  width: "50%" }}
              title="Resend OTP"
              type="button"
              className="sign-in_btn"
              onClick={handleResendOtp}
              disabled={!resendEnabled}
            >
              <span>Resend OTP</span>
            </button>
          ) : null}
        </form>
      )}
      <ToastContainer />
    </div>
  );
}

export default Login;
