import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo, red } from "@mui/material/colors";

function ConfirmationDialog({ open, handleClose, handleConfirm, message }) {
    const theme = useTheme();

    const lightTheme = createTheme({
        palette: {
            primary: indigo,
            error: red, // Adding error color for the "Confirm" button
        },
    });

    return (
        <ThemeProvider theme={lightTheme}>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        backgroundColor: theme.palette.primary.main, // Using primary color for dialog background
                        color: theme.palette.text.primary, // Using primary text color
                    },
                }}
                sx={{ borderRadius: "25px" }}
            >
                <DialogTitle style={{ color: "black" }}>Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ fontWeight: "bolder" }}>{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} color="error" autoFocus> {/* Setting color to "error" for the "Confirm" button */}
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export default ConfirmationDialog;
