// useAuth.js
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [country, setCountry] = useState("")
  const [loading, setLoading] = useState(true);

  const fetchAuthStatus = useCallback(async () => {
    try {
      // Check if we have cached data
      const cachedData = sessionStorage.getItem('authData');
      if (cachedData) {
        const { isAuthenticated, userRole } = JSON.parse(cachedData);
        setIsAuthenticated(isAuthenticated);
        setUserRole(userRole);
        setCountry(country);
        setLoading(false);
        return;
      }

      const response = await axios.get(process.env.REACT_APP_API_URL + 'dashboard', {
        withCredentials: true,
      });
      
      setIsAuthenticated(true);
      setUserRole(response.data.role);
      setCountry(response.data.country);
      
      // Cache the data
      sessionStorage.setItem('authData', JSON.stringify({ isAuthenticated: true, userRole: response.data.role, country: response.data.country }));
    } catch (error) {
      console.error('Error fetching user role:', error);
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAuthStatus();
  }, [fetchAuthStatus]);

  return { isAuthenticated, userRole, loading, country };
};

export default useAuth;