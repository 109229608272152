import React, { useEffect, useState, useRef } from "react";
import "./home.css";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import axios from "axios";

import { Box, useTheme, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../../shared/ConfirmationDialog";
import { ToastContainer, toast } from "react-toastify";
import { getCalendarPdfUrl, dashboardStyle } from 'shared/utils';
import useAWSDetails from "shared/useAWSDetails";

import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDropzone } from "react-dropzone"; // You'll need to install react-dropzone
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

// Add this new component
const UploadModal = ({ open, handleClose, handleUpload, setImageUrl, setCaption }) => {
  const [preview, setPreview] = useState(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png']
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setImageUrl(file);
      
      // Create preview URL for the image
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);
    }
  });

  // Cleanup preview URL when modal closes
  useEffect(() => {
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]);

  // Reset preview when modal closes
  useEffect(() => {
    if (!open) {
      setPreview(null);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="upload-modal"
      aria-describedby="upload-image-form"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: '#ffffff',
        borderRadius: '12px',
        boxShadow: 24,
        p: 3,
      }}>
        <IconButton
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color:"crimson"
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        
        <form>
          <h2 style={{ marginBottom: '18px', fontWeight: 'bold', color: '#04497a' }}>Upload Image</h2>
          
          <div {...getRootProps()} style={{
            border: '2px dashed #2196f3',
            borderRadius: '8px',
            padding: '40px 20px',
            textAlign: 'center',
            cursor: 'pointer',
            marginBottom: '24px',
            backgroundColor: isDragActive ? '#e3f2fd' : '#f8f8f8',
            transition: 'all 0.2s ease',
            minHeight: '200px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative'
          }}>
            <input {...getInputProps()} />
            
            {preview ? (
              <div style={{ 
                width: '100%', 
                height: '100%', 
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px'
              }}>
                <img 
                  src={preview} 
                  alt="Preview" 
                  style={{ 
                    maxWidth: '100%',
                    maxHeight: '180px',
                    objectFit: 'contain'
                  }}
                />
              </div>
            ) : (
              <>
                <div style={{ marginBottom: '12px' }}>
                  <CloudUploadIcon style={{ fontSize: '48px', color: '#2196f3', marginBottom: '12px' }} />
                </div>
                {isDragActive ? (
                  <p style={{ color: '#2196f3', fontSize: '16px', margin: 0 }}>Drop the image here...</p>
                ) : (
                  <>
                    <p style={{ color: '#333', fontSize: '16px', margin: '0 0 8px 0' }}>
                      Drag and drop an image here
                    </p>
                    <p style={{ color: '#666', fontSize: '14px', margin: 0 }}>
                      or click to select a file
                    </p>
                  </>
                )}
              </>
            )}
          </div>

          <div style={{ marginBottom: '24px' }}>
            <label 
              htmlFor="caption" 
              style={{ 
                color: "#333", 
                display: 'block', 
                marginBottom: '8px',
                fontSize: '14px',
                fontWeight: '500'
              }}
            >
              Caption
            </label>
            <textarea
              id="caption"
              rows="4"
              placeholder="Type your caption here"
              onChange={(e) => setCaption(e.target.value)}
              style={{
                width: '100%',
                padding: '12px',
                borderRadius: '8px',
                border: '1px solid #ddd',
                resize: 'vertical',
                fontSize: '14px',
                fontFamily: 'inherit'
              }}
            />
          </div>

          <Button
            variant="contained"
            onClick={handleUpload}
            sx={{
              backgroundColor: '#04497a',
              color: '#fff',
              fontSize: '16px',
              fontWeight: '500',
              padding: '10px',
            }}
          >
            Upload
          </Button>
        </form>
      </Box>
    </Modal>
  );
};
function Home() {
  const theme = useTheme();
  const [user, setUser] = useState({});
  const [employeeId, setEmployeeId] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [uploadVisible, setUploadVisible] = useState(false);
  const [images, setImages] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [caption, setCaption] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationUploadOpen, setConfirmationUploadOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  // const [fetchedBucketName, setFetchedBucketName] = useState("");
  // const [fetchedRegion, setFetchedRegion] = useState("");
  const { fetchedBucketName, fetchedRegion } = useAWSDetails();
  const apiCalledRef = useRef(false);
  const [modalOpen, setModalOpen] = useState(false);


  //   async function fetchAWSDetails() {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API_URL}getAWSDetails`);
  //       const data = response.data;
  //       const { bucketName, region } = data;

  //       setFetchedBucketName(bucketName);
  //       setFetchedRegion(region);
  //       // Now you can use the bucketName and region in your frontend code
  //       console.log('Bucket Name:', bucketName);
  //       console.log('Region:', region);

  //       // Example usage
  //       // initializeS3Client(bucketName, region);
  //     } catch (error) {
  //       console.error('Error fetching AWS details:', error.message);
  //     }
  //   }

  // useEffect(() => {
  //   fetchAWSDetails();
  // }, []) 

  const fetchImages = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "dasboardImages/images"
      );
      setImages(response.data);
      console.log(response.data.imageUrl);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleUpload = async () => {
    if (!imageUrl) {
      toast.error("Please select an image.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("image", imageUrl);
      formData.append("caption", caption);

      await axios.post(
        process.env.REACT_APP_API_URL + "dasboardImages/upload",
        formData
      );

      setModalOpen(false);
      fetchImages();
      setCaption("");
      setImageUrl("");
      toast.success("Image uploaded successfully!");
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Failed to upload image");
    }
  };


  // useEffect(() => {
  //   fetchImages();
  // }, []);

  useEffect(() => {
    if (apiCalledRef.current) return;
    apiCalledRef.current = true;
    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      })
      .then((response) => {
        setUser(response.data);
        setEmployeeId(response.data.employeeId);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        if (error.response && error.response.status === 401) {
          toast.warning("Your account has been disabled. If you think this is an error contact HR. You will be logged out soon.", {
            onClose: () => {
              // Clear the token from cookies
              document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              // Reload the page after a delay
              setTimeout(() => {
                window.location.reload();
              }, 3000); // 5 seconds delay
            }
          });
        }
      });
  }, []);

  useEffect(() => {
    const slides = document.querySelectorAll(".slide");
    const nextSlide = document.querySelector(".btn-next");
    const prevSlide = document.querySelector(".btn-prev");

    // Check if elements are available before adding event listeners
    if (slides.length > 0 && nextSlide && prevSlide) {
      let curSlide = 0;
      let maxSlide = slides.length - 1;

      nextSlide.addEventListener("click", function () {
        if (curSlide === maxSlide) {
          curSlide = 0;
        } else {
          curSlide++;
        }

        slides.forEach((slide, indx) => {
          slide.style.transform = `translateX(${100 * (indx - curSlide)}%)`;
        });
      });

      prevSlide.addEventListener("click", function () {
        if (curSlide === 0) {
          curSlide = maxSlide;
        } else {
          curSlide--;
        }

        slides.forEach((slide, indx) => {
          slide.style.transform = `translateX(${100 * (indx - curSlide)}%)`;
        });
      });
    }

    const intervalId = setInterval(changeSlide, 5000); // Change slides every 5 seconds (5000 milliseconds)
    setIntervalId(intervalId);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);

  }, [images]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Inside the Home.js component

  const handleConfirmDelete = () => {
    // Perform deletion action using the deleteId
    handleDelete(selectedImageId);
    setConfirmationOpen(false); // Close the confirmation dialog
  };

  const handleDeleteConfirmation = (imageId) => {
    setSelectedImageId(imageId); // Set the selected image id
    setConfirmationOpen(true); // Open the confirmation dialog
  };

  // Inside the Home.js component

  const handleDelete = async (imageId) => {
    console.log("imageid: ", imageId);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}dasboardImages/images/${imageId}`
      );
      setConfirmationOpen(false); // Close the confirmation dialog after deletion
      fetchImages(); // Fetch images again to update the carousel
    } catch (error) {
      console.error("Error deleting image:", error);
      // Handle error
    }
  };

  // Upload
  const handleConfirmUpload = () => {
    handleUpload(); // Perform upload action
    setConfirmationUploadOpen(false); // Close the confirmation dialog
  };

  const handleUploadConfirmation = () => {
    setConfirmationUploadOpen(true); // Open the confirmation dialog
  };

  const changeSlide = () => {
    const slides = document.querySelectorAll(".slide");
    const nextSlide = document.querySelector(".btn-next");

    // Check if elements are available before simulating the click
    if (slides.length > 0 && nextSlide) {
      nextSlide.click();
    }
  };

  return (
    // <!-- Slider container -->
    <div>
      <div className="container">
        <div className="slider">
          {images?.map((image) => (
            <div className="slide" key={image._id}>
              <img
                src={`https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/dashboard/${image.imageUrl}`}
                alt={images?.imageUrl}
                style={{ width: "100%", height: "100%" }}
              />
              {(user.role === "admin" ||
                user.role === "hr" ||
                user.role === "superadmin") && (
                  <DeleteIcon
                    className="icon-container"
                    onClick={() => handleDeleteConfirmation(image._id)}
                  />
                )}
              <div className="caption">{image.caption}</div>{" "}
              {/* Move caption here */}
            </div>
          ))}

          {/* Control buttons */}
          <button className="btn btn-next">
            {" "}
            <ArrowForwardIosRoundedIcon />{" "}
          </button>
          <button className="btn btn-prev">
            {" "}
            <ArrowBackIosRoundedIcon />{" "}
          </button>
        </div>

        {(user.role === "admin" ||
        user.role === "hr" ||
        user.role === "superadmin") && (
        <Button
          variant="contained"
          onClick={() => setModalOpen(true)}
          sx={{
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bolder",
            padding: "10px 20px",
            margin: "15px 0px",
          }}
        >
          Upload Image
        </Button>
      )}

      <UploadModal 
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleUpload={handleUploadConfirmation}
        setImageUrl={setImageUrl}
        setCaption={setCaption}
      />
      </div>
      <ConfirmationDialog
        open={confirmationOpen} // Pass confirmationOpen as the open prop
        handleClose={() => setConfirmationOpen(false)} // Close the dialog
        handleConfirm={() => handleConfirmDelete()} // Confirm the deletion
        message="Are you sure you want to delete this image?" // Confirmation message
      />
      {/* Upload */}
      <ConfirmationDialog
        open={confirmationUploadOpen} // Pass confirmationUploadOpen as the open prop
        handleClose={() => setConfirmationUploadOpen(false)} // Close the dialog
        handleConfirm={() => handleConfirmUpload()} // Confirm the upload
        message="Are you sure you want to upload the image?" // Confirmation message
      />
      <ToastContainer />
    </div>
  );
}

export default Home;
