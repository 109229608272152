import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import {
  Box,
  useTheme,
  Button,
  Typography,
  Modal,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import PublishIcon from "@mui/icons-material/Publish";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import { getCalendarPdfUrl, dashboardStyle, tabStyles } from "shared/utils";
import PayslipTable from "../employee-payslips/PayslipTable";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import Spinner from "shared/spinner/Spinner";
import useAWSDetails from "shared/useAWSDetails";

function ManagePayslips() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [employeeId, setEmployeeId] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [employees, setEmployees] = useState([]);
  const [payslips, setPayslips] = useState([]);
  const [selectedYear, setSelectedYear] = useState(2024);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedPayslips, setSelectedPayslips] = useState([]);
  const [open, setOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedPayslipId, setSelectedPayslipId] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  // const [fetchedBucketName, setFetchedBucketName] = useState("");
  // const [fetchedRegion, setFetchedRegion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { fetchedBucketName, fetchedRegion} = useAWSDetails();

  // async function fetchAWSDetails() {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}getAWSDetails`
  //     );
  //     const data = response.data;
  //     const { bucketName, region } = data;

  //     setFetchedBucketName(bucketName);
  //     setFetchedRegion(region);
  //     // Now you can use the bucketName and region in your frontend code
  //     console.log("Bucket Name:", bucketName);
  //     console.log("Region:", region);

  //     // Example usage
  //     // initializeS3Client(bucketName, region);
  //   } catch (error) {
  //     console.error("Error fetching AWS details:", error.message);
  //   }
  // }

  // useEffect(() => {
  //   fetchAWSDetails();
  // }, []);

  const fetchPayslips = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}payslips/getpayslips`)
      .then((response) => {
        setPayslips(response.data);
        setEmployeeId(response.data[0].employeeId);
      })
      .catch((error) => console.error("Error fetching payslips:", error));
  };

  useEffect(() => {
    fetchPayslips();
  }, []);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL) // Update this endpoint to the correct one
      .then((result) => setUsers(result.data))
      .catch((err) => console.log(err));
  }, []);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedName(selectedValue === "" ? "" : selectedValue);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL)
      .then((response) => {
        setEmployees(response.data); // Set users to the array of users
      })
      .catch((error) => {
        console.error("Error fetching user emails:", error);
      });
  }, []);

  const handleConfirmDelete = () => {
    setIsLoading(true);
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}payslips/deletepayslip/${selectedPayslipId}`
      )
      .then((response) => {
        toast.success("Payslip deleted successfully:", response.data);
        // Optionally, you can refresh the payslip data after deletion
        setPayslips(
          payslips.filter((payslip) => payslip._id !== selectedPayslipId)
        );
        fetchPayslips();
      })
      .catch((error) => {
        console.error("Error deleting payslip:", error);
        setIsLoading(false);
        // Handle error
      })
      .finally(() => {
        setConfirmationOpen(false);
        setIsLoading(false);
      });
  };

  const handleDeleteConfirmation = (payslipId) => {
    setSelectedPayslipId(payslipId);
    setConfirmationOpen(true);
  };

  const handleDeleteCancel = () => {
    setConfirmationOpen(false); // Close the confirmation dialog
  };

  const payslipsRows = () => {
    const rows = payslips.reverse()
      .flatMap((payslip, index) =>
        payslip.payslips.flatMap((innerPayslip, innerIndex) => ({
          id: `${index}-${innerIndex}`,
          newId: innerPayslip._id,
          employeeId: payslip.employeeId,
          name: payslip.name,
          year: innerPayslip.year,
          month: innerPayslip.month,
          filename: innerPayslip.filename,
          createdOn: innerPayslip.createdOn,
          updatedOn: innerPayslip.updatedOn,
          status: innerPayslip.status,
        }))
      )
      .filter((payslip) => {
        let matchName = true;

        if (selectedName !== "") {
          matchName = payslip.name === selectedName;
        }

        return matchName;
      });

    setSelectedPayslips(rows);
  };

  const handleOpen = (url) => {
    setPdfUrl(url);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    payslipsRows();
  }, [selectedName, payslips]);

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const years = Array.from({ length: 11 }, (_, index) => 2024 + index);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const columns = [
    {
      field: "employeeId",
      headerName: "Employee ID",
      flex: 0.5,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Emp Id
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Name
        </Typography>
      ),
    },
    {
      field: "year",
      headerName: "Year",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Year
        </Typography>
      ),
    },
    {
      field: "month",
      headerName: "Month",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Month
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Status
        </Typography>
      ),
    },
    // {
    //   field: "createdOn",
    //   headerName: "Created On",
    //   flex:  1,
    //   renderHeader: () => (
    //     <Typography fontSize="14px" fontWeight="bold">
    //       Created On
    //     </Typography>
    //   ),
    // },
    {
      field: "updatedOn",
      headerName: "Updated On",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Updated On
        </Typography>
      ),
    },
    {
      field: "view",
      headerName: "View",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          View
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() =>
              handleOpen(
                `https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/employees/${params.row.employeeId
                }/payslips/${params.row.filename}`
              )
            }
            sx={{ color: "#04497a" }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Action
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <DeleteIcon
            style={{ cursor: "pointer", color: "#04497a", fontSize: "25px" }}
            onClick={() => handleDeleteConfirmation(params.row.newId)}
          />
        </div>
      ),
    },
  ];

  const handleFilterClick = () => {
    const filteredPayslips = payslips.flatMap((payslip) =>
      payslip.payslips
        .filter((innerPayslip) => {
          let matchYear = true;
          let matchMonth = true;

          if (selectedYear !== "") {
            matchYear = innerPayslip.year === selectedYear;
          }

          if (selectedMonth) {
            matchMonth = innerPayslip.month === selectedMonth;
          }

          return matchYear && matchMonth;
        })
        .map((innerPayslip, innerIndex) => ({
          id: `${payslip.employeeId}-${innerIndex}`,
          employeeId: payslip.employeeId,
          name: payslip.name,
          year: innerPayslip.year,
          month: innerPayslip.month,
          status: innerPayslip.status,
        }))
    );

    setSelectedPayslips(filteredPayslips);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const styles = tabStyles(theme, selectedTab);

  return (
    <div>
      {isLoading && <Spinner />}
      <Box m="1.5rem 2.5rem" height="calc(100vh - 275px)">
        <ThemeProvider theme={lightTheme}>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "white",
                boxShadow: 24,
                padding: "20px", // Adjust padding for inner content spacing
                borderRadius: "8px",
                width: "80%", // Adjust width for the modal
                height: "90%", // Adjust height for the modal
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography id="modal-title" variant="h6" component="h2">
                View Payslip
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "black",
                }}
              >
                <CloseIcon />
              </IconButton>
              <iframe
                src={pdfUrl}
                width="95%"
                height="90%"
                title="PDF viewer"
                style={{ margin: "auto" }}
              />
            </Box>
          </Modal>

          <Box>
            <ThemeProvider theme={lightTheme}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="fullWidth"
                sx={{
                  marginBottom: "20px",
                  height: "60px",
                  color: "white",
                  borderBottom: "1px solid #e5e5e5",
                }}
              >
                <Tab
                  label=" Manage Employee Payslips"
                  icon={
                    <ManageAccountsOutlinedIcon sx={{ marginRight: "4px" }} />
                  }
                  sx={styles}
                />
                <Tab
                  label=" Manage Personal Payslips"
                  icon={<GroupsOutlinedIcon sx={{ marginRight: "4px" }} />}
                  sx={styles}
                />
                {/* Add more tabs as needed */}
              </Tabs>
            </ThemeProvider>
          </Box>
        </ThemeProvider>

        {selectedTab === 0 && (
          <Box>
            <FlexBetween>
              <Header title="Manage Payslips" />
              <Box>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/uploadpayslips");
                  }}
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontSize: "14px",
                    fontWeight: "bolder",
                    padding: "10px 20px",
                  }}
                >
                  <PublishIcon sx={{ mr: "10px" }} /> Upload Payslip
                </Button>
              </Box>
            </FlexBetween>
            <ThemeProvider theme={lightTheme}>
              <FlexBetween sx={{ justifyContent: "flex-start" }}>
                <FormControl sx={{ maxWidth: "150px" }}>
                  <InputLabel id="demo-simple-select-label">Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedName}
                    label="Name"
                    onChange={handleChange}
                  >
                    <MenuItem value="">Show All</MenuItem>
                    {employees.map((employee) => (
                      <MenuItem key={employee._id} value={employee.name}>
                        {employee.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* </FlexBetween>
          <FlexBetween sx={{ justifyContent: "flex-start" }}>  */}
                <FormControl
                  variant="standard"
                  sx={{ maxWidth: "150px", mb: "20px" , ml: "10px"}}
                >
                  <InputLabel id="year-select-label">Year</InputLabel>
                  <Select
                    labelId="year-select-label"
                    id="year-select"
                    label="Year"
                    value={selectedYear}
                    onChange={handleYearChange}
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ maxWidth: "150px", mb: "20px" , ml: "10px"}}>
                  <InputLabel id="month-select-label">Month</InputLabel>
                  <Select
                    labelId="month-select-label"
                    id="month-select"
                    label="Month"
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    sx={{ color: theme.palette.background.text }}
                  >
                    <MenuItem value="">
                      <em>Show All</em>
                    </MenuItem>
                    {months.map((month, index) => (
                      <MenuItem key={index + 1} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Button
                  variant="contained"
                  onClick={handleFilterClick}
                  sx={{
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.background.alt,
                    fontSize: "14px",
                    fontWeight: "bolder",
                    padding: "10px 20px",
                    ml: "10px",
                    mb: "10px",

                  }}
                >
                  Filter
                </Button>
              </FlexBetween>
            </ThemeProvider>
            <ThemeProvider theme={lightTheme}>
              <DataGrid
                rows={selectedPayslips}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                pageSizeOptions={[5, 10]}
                sx={dashboardStyle(theme, "350px")}
              />
            </ThemeProvider>
            <ConfirmationDialog
              open={confirmationOpen}
              handleClose={handleDeleteCancel}
              handleConfirm={handleConfirmDelete} // Pass handleDelete as the confirm action
              message="Are you sure you want to delete this payslip record?" // Customize the message
            />
            <ToastContainer />
          </Box>
        )}
        {selectedTab === 1 && (
          <div>
            {/* Content for Tab 2 */}
            <PayslipTable />
          </div>
        )}
      </Box>
    </div>
  );
}

export default ManagePayslips;
