import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, useTheme, Button, Modal, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { dashboardStyle } from "shared/utils";
import { indigo } from "@mui/material/colors";
import axios from "axios";
// import { fetchedBucketName, fetchedRegion } from "shared/utils";
// import { users } from "shared/utils";

function ViewHolidayCalendar({ selectedCalendarName, selectedYear, selectedCalendarId }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [calendarId, setCalendarId] = useState("");
const [fetchedHolidays, setFetchedHolidays] = useState([]);

  useEffect(() => {
    if(selectedCalendarId){
    fetchCalendar();
    }
  }, [selectedCalendarId]);

  const fetchCalendar = async () => {
    console.log("selectedCalendarId", selectedCalendarId);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}holidayCalendars/fetchCalendar/${selectedCalendarId}`
      );
      setFetchedHolidays(response.data.holidays);
      console.log("fetchedHolidays", response.data.holidays);
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    }
  };

  //create dummy data for rows, date and reason for holiday
  // const rows = fetchedHolidays.map((holiday, index) => ({
  //   id: index + 1,
  //   date: holiday.date,
  //   holiday: holiday.holiday,
  // }));

  const rows = fetchedHolidays.map((holiday, index) => {
    const holidayDate = new Date(holiday.date);
    return {
      id: index + 1,
      date: holiday.date,
      holiday: holiday.holiday,
      day: holidayDate.toLocaleString('en-US', { weekday: 'long' })
    };
    });

  // Columns configuration for the DataGrid
  const columns = [
  {
    field: "id",
    headerName: "Sr no.",
    flex: 0.2,
    align: "center",
    renderHeader: () => (
    <Typography fontSize="14px" fontWeight="bold">
      Sr no.
    </Typography>
    ),
  },
  {
    field: "date",
    headerName: "Date",
    flex: 0.5,
    renderHeader: () => (
    <Typography fontSize="14px" fontWeight="bold">
      Date
    </Typography>
    ),
    valueFormatter: (params) => {
    const date = new Date(params.value);
    return date.toLocaleString('en-US', { month: 'short', day: '2-digit' });
    }
  },
  {
    field: "day",
    headerName: "Day",
    flex: 0.6,
    renderHeader: () => (
    <Typography fontSize="14px" fontWeight="bold">
      Day
    </Typography>
    )
  },
  {
    field: "holiday",
    headerName: "Holiday",
    flex: 1,
    renderHeader: () => (
    <Typography fontSize="14px" fontWeight="bold">
      Holiday
    </Typography>
    ),
  },
  ];

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  return (
    <ThemeProvider theme={lightTheme}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        // checkboxSelection
        // rowsPerPageOptions={[5, 10, 20]}
        pageSizeOptions={[5, 10]}
        sx={dashboardStyle(theme, "480px")}
      />
    </ThemeProvider>
  );
}

export default ViewHolidayCalendar;
