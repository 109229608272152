import React, { useEffect, useState } from "react";
import axios from "axios";

import FlexBetween from "shared/FlexBetween";
import { Box, useTheme, Button, Modal, Typography } from "@mui/material";
import Header from "shared/Header";
import { DataGrid } from "@mui/x-data-grid";
import { indigo } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import { getCalendarPdfUrl, dashboardStyle } from "shared/utils";
import Spinner from "shared/spinner/Spinner";
import useAWSDetails from "shared/useAWSDetails";
import { useNavigate } from "react-router-dom";
import ViewHolidayCalendar from "./ViewHolidayCalendar";
// import {dashboardStyle}  from "shared/utils";

function HolidayCalendar() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [calendarPDF, setCalendarPDF] = useState();
  const [calendarName, setCalendarName] = useState("");
  const [selectedYear, setSelectedYear] = useState(2024);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [uploadConfirmationOpen, setUploadConfirmationOpen] = useState(false);
  // const [fetchedBucketName, setFetchedBucketName] = useState("");
  // const [fetchedRegion, setFetchedRegion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { fetchedBucketName, fetchedRegion } = useAWSDetails();
  const [company, setCompany] = useState(null);
  const [selectedRowYear, setSelectedRowYear] = useState(null);
  const [selectedCalendarName, setSelectedCalendarName] = useState(null);
  const [selectedCalendarId, setSelectedCalendarId] = useState(null);


  // async function fetchAWSDetails() {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}getAWSDetails`
  //     );
  //     const data = response.data;
  //     const { bucketName, region } = data;

  //     setFetchedBucketName(bucketName);
  //     setFetchedRegion(region);
  //     // Now you can use the bucketName and region in your frontend code
  //     console.log("Bucket Name:", bucketName);
  //     console.log("Region:", region);

  //     // Example usage
  //     // initializeS3Client(bucketName, region);
  //   } catch (error) {
  //     console.error("Error fetching AWS details:", error.message);
  //   }
  // }

  // useEffect(() => {
  //   fetchAWSDetails();
  // }, []);

  // Function to handle opening the confirmation dialog
  const handleDeleteConfirmation = (id) => {
    setDeleteId(id);
    setConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    // Perform deletion action using the deleteId
    handleDelete(deleteId);
    setConfirmationOpen(false); // Close the confirmation dialog
  };

  // Function to handle canceling the deletion
  const handleDeleteCancel = () => {
    setConfirmationOpen(false); // Close the confirmation dialog
  };

  // Function to handle opening the confirmation dialog for upload
  const handleUploadConfirmationOpen = () => {
    setUploadConfirmationOpen(true);
  };

  // Function to handle canceling the upload action
  const handleUploadCancel = () => {
    setUploadConfirmationOpen(false); // Close the confirmation dialog
  };
  const handleOpen = (year, calendarName, calendarId) => {
    setSelectedRowYear(year);
    setSelectedCalendarName(calendarName);
    setSelectedCalendarId(calendarId);
    setOpen(true);
  };
  const handleOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleClose = () => setOpen(false);
  const handleCloseUpload = () => setOpenUpload(false);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      })
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        if (error.response && error.response.status === 401) {
          toast.warning(
            "Your account has been disabled. If you think this is an error contact HR. You will be logged out soon.",
            {
              onClose: () => {
                // Clear the token from cookies
                document.cookie =
                  "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                // Reload the page after a delay
                setTimeout(() => {
                  window.location.reload();
                }, 3000); // 5 seconds delay
              },
            }
          );
        }
      });
  }, []);

  const fetchPdf = async (year) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}holidayCalendars/pdf/${year}`,
        { responseType: "blob" }
      );
      console.log(response);
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfUrl);
      console.log(pdfUrl);
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  useEffect(() => {
    fetchPdf(selectedYear);
  }, [selectedYear]);

  const currentYear = new Date().getFullYear(); // Get current year

  // Your existing handleUpload function with the loading state management
  const handleUpload = async () => {
    if (!selectedYear || !calendarName) {
      toast.error("Please fill in all required fields.");
      setUploadConfirmationOpen(false);
      return;
    }

    setIsLoading(true); // Start loading
    setUploadConfirmationOpen(false); // Close the confirmation dialog

    try {
      const formData = new FormData();
      formData.append("year", selectedYear);
      //   formData.append("company", company);
      formData.append("calendarName", calendarName);

      await axios.post(
        process.env.REACT_APP_API_URL + "holidayCalendars/upload",
        formData,
        {
          withCredentials: true,
        }
      );

      setOpenUpload(false);

      axios
        .get(process.env.REACT_APP_API_URL + "holidayCalendars/calendar")
        .then((response) => {
          setUsers(response.data);
          toast.success("Calendar uploaded successfully");
        })
        .catch((error) => {
          console.error("Error fetching updated calendar data:", error);
          toast.error("Error fetching updated calendar data");
        });
    } catch (error) {
      console.error("Error uploading PDF:", error);
      toast.error("Error uploading PDF");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchCalendar();
  }, []);

  const fetchCalendar = async () => {
    axios
      .get(process.env.REACT_APP_API_URL + "holidayCalendars/calendar")
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching calendar data:", error);
      });
  };

  // Transforming fetched data into rows for the DataGrid
  const rows = users
    .map((user) => ({
      id: user._id,
      year: user.year,
      // company: user.company,
      createdOn: new Date(user.createdOn), // Keep as Date object for sorting
      updatedOn: new Date(user.updatedOn), // Keep as Date object for sorting
      //   calendarPDF: user.calendarPDF,
      calendarName: user.calendarName,
    }))
    .sort((a, b) => b.createdOn - a.createdOn) // Sort in descending order
    .map((row) => ({
      ...row,
      createdOn: row.createdOn.toLocaleString(), // Format for display
      updatedOn: row.updatedOn.toLocaleString(), // Format for display
    }));

  // Columns configuration for the DataGrid
  const columns = [
    {
      field: "year",
      headerName: "Year",
      flex: 0.5,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Year
        </Typography>
      ),
    },
    {
      field: "calendarName",
      headerName: "Name",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Name
        </Typography>
      ),
    },
    {
      field: "view",
      headerName: "View",
      width: 150,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          View
        </Typography>
      ),
      renderCell: (params) => (
        <IconButton
          onClick={() => handleOpen(params.row.calendarName, params.row.year, params.row.id)}
          sx={{ color: "#04497a" }}
        >
          <RemoveRedEyeIcon />
        </IconButton>
      ),
    },
    {
      field: "createdOn",
      headerName: "Created On",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Created On
        </Typography>
      ),
    },
    {
      field: "updatedOn",
      headerName: "Updated On",
      flex: 1,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Updated On
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderHeader: () => (
        <Typography fontSize="14px" fontWeight="bold">
          Action
        </Typography>
      ),
      renderCell: (params) => (
        <>
          <IconButton
            style={{ cursor: "pointer", color: "#04497a", fontSize: "25px" }}
            onClick={() => navigate(`/createholidaycalendar/${params.row.id}`)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            style={{ cursor: "pointer", color: "#04497a", fontSize: "25px" }}
            onClick={() => handleDeleteConfirmation(params.row.id)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  // Adjust the height based on the number of rows // const tableHeight = users.length > 10 ? 53.5 * 10 : 55 * users.length + 100;

  const years = Array.from({ length: 11 }, (_, index) => 2024 + index);

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}holidayCalendars/calendar/${id}`)
      .then((response) => {
        toast.success("Calendar deleted successfully");
        // Filter out the deleted calendar entry from the users state
        setUsers((prevUsers) => prevUsers.filter((user) => user._id !== id));
      })
      .catch((error) => {
        console.error("Error deleting calendar entry:", error);
        toast.error("Error deleting calendar entry");
      });
  };

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "8px",
    width: "70%", // Adjust width for the modal
    height: "70%", // Adjust height for the modal
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
              color: "crimson",
              zIndex: 9999,
            }}
          >
            <CloseIcon />
          </IconButton>
          <ViewHolidayCalendar calendarName={selectedCalendarName} year={selectedRowYear} selectedCalendarId={selectedCalendarId} />
        </Box>
      </Modal>

      <Modal
        open={openUpload}
        onClose={handleCloseUpload}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#e0e0e0",
            boxShadow: 24,
            p: 4,
            width: "80%",
            maxWidth: "500px",
            outline: "none",
            borderRadius: "10px",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseUpload}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "crimson",
            }}
          >
            <CloseIcon />
          </IconButton>

          <div className="upload-section">
            <form>
              <div className="formbold-mb-3">
                <label
                  className="formbold-form-label"
                  style={{ color: "black" }}
                >
                  Year
                  <span
                    style={{
                      color: "#c44545",
                      position: "relative",
                      top: "-4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <select
                  className="formbold-form-input"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>

              {/* <div className="formbold-mb-3">
                <label
                  className="formbold-form-label"
                  style={{ color: "black" }}
                >
                  Company
                  <span
                    style={{
                      color: "#c44545",
                      position: "relative",
                      top: "-4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <select
                  className="formbold-form-input"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                >
                  <option value="" disabled selected>
                    Choose Company
                  </option>
                  <option value="IVTREE">IVTREE</option>
                  <option value="Company 1">Company 1</option>
                  <option value="Company 2">Company 2</option>
                  <option value="Company 3">Company 3</option>
                </select>
              </div> */}

              <div className="formbold-mb-3">
                <label
                  htmlFor="address"
                  className="formbold-form-label"
                  style={{ color: "black" }}
                >
                  Name
                  <span
                    style={{
                      color: "#c44545",
                      position: "relative",
                      top: "-4px",
                    }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="calendar"
                  id="calendar"
                  placeholder="Enter Calendar Name"
                  className="formbold-form-input"
                  onChange={(e) => setCalendarName(e.target.value)}
                />
              </div>
              <Button
                variant="contained"
                type="button"
                onClick={handleUploadConfirmationOpen}
                sx={{
                  backgroundColor: theme.palette.secondary.light,
                  color: theme.palette.background.alt,
                  fontSize: "14px",
                  fontWeight: "bolder",
                  padding: "10px 20px",
                  margin: "15px 0px",
                }}
              >
                Create
              </Button>
            </form>
          </div>
        </Box>
      </Modal>

      {/* Loading Overlay */}
      {isLoading && <Spinner />}

      <Box m="1.5rem 2.5rem" height="calc(100vh - 275px)">
        <FlexBetween sx={{ marginBottom: "10px" }}>
          <Header title={"Holiday Calendar of " + currentYear} />
          <Box>
            <Button
              variant="contained"
              onClick={() => handleOpenUpload()}
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.background.alt,
                fontSize: "14px",
                fontWeight: "bolder",
                padding: "10px 20px",
              }}
            >
              Create Holiday Calendar
            </Button>
          </Box>
        </FlexBetween>
        <ThemeProvider theme={lightTheme}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            // checkboxSelection
            // rowsPerPageOptions={[5, 10, 20]}
            pageSizeOptions={[5, 10]}
            sx={dashboardStyle(theme, "480px")}
          />
        </ThemeProvider>
      </Box>
      <ToastContainer />
      <ConfirmationDialog
        open={confirmationOpen}
        handleClose={handleDeleteCancel}
        handleConfirm={handleConfirmDelete} // Pass handleConfirmDelete here
        message="Are you sure you want to delete this calendar entry?"
      />
      {/* // Render the confirmation dialog component */}
      <ConfirmationDialog
        open={uploadConfirmationOpen}
        handleClose={handleUploadCancel}
        handleConfirm={handleUpload}
        message="Are you sure you want to upload this holiday calendar?"
      />
    </div>
  );
}

export default HolidayCalendar;
