import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./login.css";

function NewPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    axios.post(process.env.REACT_APP_API_URL + "reset-password", { email, newPassword })
      .then(res => {
        if (res.data.Status === "Success") {
          // toast.success("Password reset successfully");

          // Display a toast notification and navigate after 5 seconds
          toast.info("Your password has been changed. You will be redirected to the login page in 5 seconds.", {
            autoClose: 5000,
            onClose: () => navigate("/login")
          });
        } else {
          toast.error("Error resetting password");
        }
      })
      .catch(err => {
        toast.error('There was an error. Please try again later.');
        console.error(err);
      });
  };

  return (
    <div className="centered-container">
      <form className="login_form_container" onSubmit={handleSubmit}>
        <h2 className="title" style={{ fontSize: "18px" }}>Reset Password</h2>
        <div className="input_container" style={{ width: "85%" }}>
          <label className="input_label" htmlFor="new_password_field" style={{ color: "black", fontSize: "14px" }}>New Password</label>
          <input placeholder="Enter new password" title="New Password" name="newPassword" type="password" className="input_field" id="new_password_field" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
        </div>
        <div className="input_container" style={{ width: "85%" }}>
          <label className="input_label" htmlFor="confirm_password_field" style={{ color: "black", fontSize: "14px" }}>Confirm Password</label>
          <input placeholder="Confirm new password" title="Confirm Password" name="confirmPassword" type="password" className="input_field" id="confirm_password_field" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </div>
        <button title="Reset Password" type="submit" className="sign-in_btn" style={{ width: "85%", marginBottom: "10px", fontSize: "14px" }}>
          <span>Reset Password</span>
        </button>
      </form>
      <ToastContainer />
    </div>
  );
}

export default NewPassword;