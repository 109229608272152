import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import FlexBetween from "shared/FlexBetween";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationDialog from "shared/ConfirmationDialog";
import Spinner from "shared/spinner/Spinner";
import "./createUser.css";

function CreateUser() {
  const theme = useTheme();

  const [employeeId, setEmployeeId] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState(0);
  const [birthDate, setBirthDate] = useState();
  const [gender, setGender] = useState();
  const [address, setAddress] = useState();
  const [file, setFile] = useState();

  const [department, setDepartment] = useState();
  const [workLocation, setWorkLocation] = useState();
  const [role, setRole] = useState();
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [uploadConfirmationOpen, setUploadConfirmationOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [hiringManager, setHiringManager] = useState();
  const [mailDistributionList, setMailDistributionList] = useState();
  // const [changeYear, setChangeYear] = useState(currentYear);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "groups/getgroups")
      .then((result) => {
        setGroups(result.data);
      });
  }, []);

  useEffect(() => {
    fetchCalendars();
  }, []);

  const fetchCalendars = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}holidayCalendars/calendar`
      );
      setCalendars(response.data);
      console.log("calendars", response.data);
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  function generateRandomPassword(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters[randomIndex];
    }
    return password;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const randomPassword = generateRandomPassword(12);
    const activeStatus = true;
    let newErrors = {};
    const currentYear = new Date().getFullYear();

    const emailArray = mailDistributionList
      ? mailDistributionList.split(",").map((email) => email.trim())
      : [];
    setIsLoading(true);

    // Validate employeeId
    if (!employeeId) {
      newErrors.employeeId = "Employee ID is required";
    }

    // Validate name
    if (!name) {
      newErrors.name = "Name is required";
    }

    // Validate email
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Invalid email address";
    }

    // Validate number
    if (!number) {
      newErrors.number = "Phone number is required";
    }

    if (number < 1000000000 || number > 9999999999) {
      newErrors.number = "Invalid phone number";
    }

    // Validate birthDate
    if (!birthDate) {
      newErrors.birthDate = "Birth date is required";
    }

    // Validate gender
    if (!gender) {
      newErrors.gender = "Gender is required";
    }

    // Validate address
    if (!address) {
      newErrors.address = "Address is required";
    }

    // Validate file
    if (!file) {
      newErrors.file = "Profile picture is required";
    }

    // Validate department
    if (!department) {
      newErrors.department = "Department is required";
    }

    // Validate workLocation
    if (!workLocation) {
      newErrors.workLocation = "Work location is required";
    }

    // Validate role
    if (!role) {
      newErrors.role = "Role is required";
    }

    // Check if any error exists
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      // Display errors
      for (const [key, value] of Object.entries(newErrors)) {
        // toast.error(value);
      }
      setIsLoading(false);
      setUploadConfirmationOpen(false);
      return;
    }

    // Create FormData object
    const formData = new FormData();
    formData.append("employeeId", employeeId);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("number", number);
    formData.append("birthDate", birthDate);
    formData.append("gender", gender);
    formData.append("address", address);
    formData.append("department", department);
    formData.append("workLocation", workLocation);
    formData.append("role", role);
    formData.append("password", randomPassword);
    formData.append("calendarName", selectedCalendar);
    formData.append("country", selectedCountry);
    formData.append("hiringManager", hiringManager);
    formData.append("mailDistributionList", JSON.stringify(emailArray));

    // Append groupname only if it's not empty
    if (selectedGroup) {
      formData.append("groupname", selectedGroup);
    }

    formData.append("file", file);
    formData.append("active", activeStatus);

    // Proceed with the post request
    axios
      .post(process.env.REACT_APP_API_URL + "createUser", formData)
      .then((result) => {
        console.log(result);
        toast.info(
          "User created successfully. You will be redirected to the employee page in 5 seconds.",
          {
            autoClose: 5000,
            onClose: () => navigate("/employee"),
          }
        );
        setUploadConfirmationOpen(false);
        // Make a PUT request to your backend API to update leave limits
        axios
          .post(process.env.REACT_APP_API_URL + "leaves/updateLeaveLimit", {
            employeeId: employeeId,
            name: name,
            sickLeaves: "12",
            privilegeLeaves: "12",
            year: currentYear,
          })
          .then((result) => {
            // Show success message
            // toast.success("Leave limits updated successfully!");
            // setLeaveModalOpen(false);
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              const errorMessage = error.response.data.error;
              setIsLoading(false);
              setUploadConfirmationOpen(false);
              toast.error(errorMessage);
            }
          });
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setIsLoading(false);
          const errorMessage = error.response.data.error;
          // Display toast notification for the error message
          setUploadConfirmationOpen(false);
          toast.error(errorMessage);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleConfirmationSubmit = () => {
    setUploadConfirmationOpen(true);
  };

  const handleUploadCancel = () => {
    // Close the confirmation dialog
    setUploadConfirmationOpen(false);
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {" "}
      {/* Add this wrapper */}
      <FlexBetween sx={{ position: "fixed" }}>
        <IconButton
          onClick={() => navigate(-1)}
          style={{ color: "#04497a", marginTop: "10px", marginLeft: "10px" }}
        >
          <ArrowBackIcon />
        </IconButton>
      </FlexBetween>
      {isLoading && <Spinner />}
      <div
        className="main-wrapper"
        style={{
          color: theme.palette.background.text,
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <div className="form-wrapper">
          <form>
            <div className="formbold-form-title">
              <h3 className="header">Create User</h3>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
              }}
            >
              {/* Personal Information Section */}
              <div className="form-section">
                <h4>Personal Information</h4>
                <div className="formbold-mb-3">
                  <label htmlFor="employeeId" className="formbold-form-label">
                    Employee ID
                  </label>
                  <input
                    type="number"
                    name="employeeId"
                    id="employeeId"
                    className="formbold-form-input"
                    value={employeeId}
                    onChange={(e) => {
                      setEmployeeId(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        employeeId: "",
                      }));
                    }}
                  />
                  {errors.employeeId && (
                    <span style={{ color: "#cc0000" }}>
                      {errors.employeeId}
                    </span>
                  )}
                </div>

                <div className="formbold-mb-3">
                  <label htmlFor="name" className="formbold-form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="formbold-form-input"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
                    }}
                  />
                  {errors.name && (
                    <span style={{ color: "#cc0000" }}>{errors.name}</span>
                  )}
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "10px",
                  }}
                >
                  <div className="formbold-mb-3">
                    <label htmlFor="birthDate" className="formbold-form-label">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      name="birthDate"
                      id="birthDate"
                      className="formbold-form-input"
                      onChange={(e) => {
                        setBirthDate(e.target.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          birthDate: "",
                        }));
                      }}
                    />
                    {errors.birthDate && (
                      <span style={{ color: "#cc0000" }}>
                        {errors.birthDate}
                      </span>
                    )}
                  </div>

                  <div className="formbold-mb-3">
                    <label className="formbold-form-label">Gender</label>
                    <select
                      className="formbold-form-input"
                      name="gender"
                      onChange={(e) => {
                        setGender(e.target.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          gender: "",
                        }));
                      }}
                    >
                      <option value="">Choose...</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="others">Others</option>
                    </select>
                    {errors.gender && (
                      <span style={{ color: "#cc0000" }}>{errors.gender}</span>
                    )}
                  </div>
                </div>

                <div className="formbold-mb-3">
                  <label htmlFor="address" className="formbold-form-label">
                    Address
                  </label>
                  <textarea
                    rows="2"
                    name="address"
                    id="address"
                    className="formbold-form-input"
                    onChange={(e) => {
                      setAddress(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        address: "",
                      }));
                    }}
                  />
                  {errors.address && (
                    <span style={{ color: "#cc0000" }}>{errors.address}</span>
                  )}
                </div>
              </div>

              {/* Contact Information Section */}
              <div className="form-section">
                <h4>Contact Information</h4>
                <div className="formbold-mb-3">
                  <label htmlFor="email" className="formbold-form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="formbold-form-input"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
                    }}
                  />
                  {errors.email && (
                    <span style={{ color: "#cc0000" }}>{errors.email}</span>
                  )}
                </div>

                <div className="formbold-mb-3">
                  <label htmlFor="phone" className="formbold-form-label">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    name="phone"
                    id="phone"
                    className="formbold-form-input"
                    onChange={(e) => {
                      setNumber(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        number: "",
                      }));
                    }}
                  />
                  {errors.number && (
                    <span style={{ color: "#cc0000" }}>{errors.number}</span>
                  )}
                </div>

                <div className="formbold-mb-3">
                  <label htmlFor="profilepic" className="formbold-form-label">
                    Profile Picture
                  </label>
                  <input
                    type="file"
                    name="profilepic"
                    id="profilepic"
                    className="formbold-form-input"
                    accept="image/*"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                      setErrors((prevErrors) => ({ ...prevErrors, file: "" }));
                    }}
                  />
                  {errors.file && (
                    <span style={{ color: "#cc0000" }}>{errors.file}</span>
                  )}
                </div>
              </div>
            </div>

            {/* Professional Information Section */}
            <div style={{ marginTop: "20px" }}>
              <h4 className="professional">Professional Information</h4>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "20px",
                }}
              >
                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Department</label>
                  <select
                    className="formbold-form-input"
                    name="department"
                    onChange={(e) => {
                      setDepartment(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        department: "",
                      }));
                    }}
                  >
                    <option value="">Choose...</option>
                    <option value="Engineering">Engineering</option>
                    <option value="HRBP/Admin">HRBP/Admin</option>
                    <option value="Human Resource">Human Resource</option>
                    <option value="Business">Business</option>
                  </select>
                  {errors.department && (
                    <span style={{ color: "#cc0000" }}>
                      {errors.department}
                    </span>
                  )}
                </div>

                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Country</label>
                  <select
                    className="formbold-form-input"
                    name="country"
                    onChange={(e) => {
                      setSelectedCountry(e.target.value);
                    }}
                  >
                    <option value="">Choose...</option>
                    <option value="India">India</option>
                    <option value="US">US</option>
                  </select>
                </div>

                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Work Location</label>
                  {selectedCountry === "India" ? (
                  <select
                    className="formbold-form-input"
                    name="workLocation"
                    onChange={(e) => {
                      setWorkLocation(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        workLocation: "",
                      }));
                    }}
                    disabled={!selectedCountry}
                  >
                    <option value="">Choose...</option>
                    <option value="Banglore">Bangalore</option>
                    <option value="Pune">Pune</option>
                    <option value="Kolkata">Kolkata</option>
                    <option value="Remote">Remote</option>
                  </select>) : (
                    <input
                    type="text"
                    name="workLocation"
                    id="workLocation"
                    className="formbold-form-input"
                    value={name}
                    onChange={(e) => {
                      setWorkLocation(e.target.value);
                      setErrors((prevErrors) => ({ ...prevErrors, workLocation: "" }));
                    }}
                    disabled={!selectedCountry}
                  />
                  )}
                  {errors.workLocation && (
                    <span style={{ color: "#cc0000" }}>
                      {errors.workLocation}
                    </span>
                  )}
                </div>
              </div>

              {/* Role Selection */}
              <div className="formbold-mb-3">
                <label className="formbold-form-label">Role</label>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                    gap: "15px",
                    marginTop: "10px",
                  }}
                >
                  {[
                    { value: "admin", label: "Admin" },
                    { value: "hr", label: "HR" },
                    { value: "manager", label: "Manager" },
                    { value: "employee", label: "Employee" },
                    { value: "consultant", label: "Consultant" },
                  ].map((roleOption) => (
                    <label
                      key={roleOption.value}
                      className={`role-radio-label ${
                        role === roleOption.value ? "selected" : ""
                      }`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "12px 20px",
                        border: `1px solid ${
                          role === roleOption.value ? "#04497a" : "#dde3ec"
                        }`,
                        borderRadius: "8px",
                        backgroundColor:
                          role === roleOption.value ? "#04497a" : "#ffffff",
                        color:
                          role === roleOption.value ? "#ffffff" : "#536387",
                        transition: "all 0.3s ease",
                      }}
                    >
                      <input
                        type="radio"
                        name="role"
                        value={roleOption.value}
                        checked={role === roleOption.value}
                        onChange={(e) => setRole(e.target.value)}
                        style={{ marginRight: "10px" }}
                      />
                      {roleOption.label}
                    </label>
                  ))}
                </div>
                  {errors.role && (
                    <span style={{ color: "#cc0000" }}>{errors.role}</span>
                  )}
              </div>

              {/* Additional Settings */}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Group</label>
                  <select
                    className="formbold-form-input"
                    name="group"
                    onChange={(e) => setSelectedGroup(e.target.value)}
                  >
                    <option value="">Choose...</option>
                    {groups.map((group) => (
                      <option key={group._id} value={group.groupname}>
                        {group.groupname}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="formbold-mb-3">
                  <label className="formbold-form-label">Calendar</label>
                  <select
                    className="formbold-form-input"
                    name="calendar"
                    onChange={(e) => setSelectedCalendar(e.target.value)}
                  >
                    <option value="">Choose...</option>
                    {calendars.map((calendar) => (
                      <option key={calendar._id} value={calendar.calendarName}>
                        {calendar.calendarName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="formbold-mb-3">
                  <label className="formbold-form-label">
                    Hiring Manager Email
                  </label>
                  <input
                    type="email"
                    name="hiringManager"
                    className="formbold-form-input"
                    onChange={(e) => setHiringManager(e.target.value)}
                  />
                </div>

                <div className="formbold-mb-3">
                  <label className="formbold-form-label">
                    Mail Distribution List
                    <span
                      style={{
                        fontSize: "10px",
                        marginLeft: "5px",
                        color: "gray",
                      }}
                    >
                      (Enter emails separated by comma for timesheet)
                    </span>
                  </label>
                  <input
                    type="text"
                    name="mailDistributionList"
                    className="formbold-form-input"
                    onChange={(e) => setMailDistributionList(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div style={{ marginTop: "30px", textAlign: "center" }}>
              <button
                type="button"
                onClick={handleConfirmationSubmit}
                className="formbold-btn"
              >
                Create User
              </button>
            </div>
          </form>
        </div>

        <ConfirmationDialog
          open={uploadConfirmationOpen}
          handleClose={handleUploadCancel}
          handleConfirm={handleSubmit}
          message="Are you sure you want to create this user?"
        />
        <ToastContainer />
      </div>
    </div>
  );
}

export default CreateUser;
