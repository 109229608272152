import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Paper,
  Card,
  CardContent,
  Grid,
  TextField,
  Tooltip,
  useTheme,
  Avatar,
} from "@mui/material";
import {
  Person,
  Folder,
  InsertDriveFile,
  CloudUpload,
  Close,
  ArrowBack,
  Visibility,
  Delete,
  Download,
} from "@mui/icons-material";
import { indigo } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { use } from "react";
import { toast, ToastContainer } from "react-toastify";
import useAWSDetails from "shared/useAWSDetails";
import { dashboardStyle, getCalendarPdfUrl } from "shared/utils";
import ConfirmationDialog from "shared/ConfirmationDialog";
import Spinner from "shared/spinner/Spinner";

const lightTheme = createTheme({
  palette: {
    primary: indigo,
  },
});

const EmployeeDocuments = () => {
  const theme = useTheme();
  const [users, setUsers] = useState([]); // Change to array
  const [documents, setDocuments] = useState({}); // Store documents separately
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { fetchedBucketName, fetchedRegion } = useAWSDetails();
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [uploadConfirmationOpen, setUploadConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL);
      setUsers(response.data);
      // After getting users, fetch their documents
      fetchDocuments();
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.employeeId.toString().includes(searchQuery)
  );

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}employeeDetails/employeeDocuments`
      );
      // Transform documents into folder structure
      const documentsById = response.data.reduce((acc, doc) => {
        if (!acc[doc.employeeId]) {
          acc[doc.employeeId] = {
            Personal: [],
            Experience: [],
            Agreements: [],
            Others: [],
          };
        }
        acc[doc.employeeId][doc.folderType].push(doc);
        return acc;
      }, {});
      setDocuments(documentsById);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {

    setIsLoading(true);

    if (
      !selectedFile ||
      !documentName ||
      !selectedEmployee ||
      !selectedFolder
    ) {
      toast.error("Please fill all the details");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("documentName", documentName);
    formData.append("folderType", selectedFolder);
    formData.append("employeeId", selectedEmployee);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}employeeDetails/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Refresh documents
      fetchUsers();
      setUploadDialogOpen(false);
      setSelectedFile(null);
      setDocumentName("");
      toast.success("Document uploaded successfully");
    } catch (error) {
      console.error("Error uploading document:", error);
      toast.error("Error uploading document");
    } finally {
      setIsLoading(false);
    }
  };

  // Add this function to get presigned URL
  const getDocumentUrl = async (documentId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}employeeDetails/documents/${documentId}/url`
      );
      return response.data.url;
    } catch (error) {
      console.error("Error getting document URL:", error);
      toast.error("Error accessing document");
      return null;
    }
  };

  // Update handleDeleteDocument
  const handleDeleteDocument = async (documentId) => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}employeeDetails/documents/${documentId}`
        );
        fetchDocuments();
        toast.success("Document deleted successfully");
      } catch (error) {
        console.error("Error deleting document:", error);
        toast.error("Error deleting document");
      }
    }
  };

  // Add functions for viewing and downloading
  // const handleViewDocument = async (documentId) => {
  //   const url = await getDocumentUrl(documentId);
  //   if (url) {
  //     window.open(url, '_blank');
  //   }
  // };

  const handleDownloadDocument = async (documentId, fileName) => {
    const url = await getDocumentUrl(documentId);
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleEmployeeSelect = (empId) => {
    setSelectedEmployee(empId);
    setSelectedFolder(null);
  };

  const handleFolderSelect = (folder) => {
    setSelectedFolder(folder);
  };

  const handleViewDocument = async (documentId, contentType) => {
    const url = await getDocumentUrl(documentId);
    if (url) {
      setSelectedDocumentUrl(url);
      setSelectedDocumentType(contentType);
      setViewModalOpen(true);
    }
  };

  // Add this utility function to format file size
  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  // Create a new FileViewerModal component
  const FileViewerModal = ({ open, onClose, fileUrl, fileType }) => {
    const renderFileContent = () => {
      if (!fileUrl) return null;

      if (fileType?.startsWith("image/")) {
        return (
          <img
            src={fileUrl}
            alt="Document preview"
            style={{ maxWidth: "100%", maxHeight: "calc(90vh - 100px)" }}
          />
        );
      } else if (fileType === "application/pdf") {
        return (
          <iframe
            src={fileUrl}
            title="PDF preview"
            width="100%"
            height="100%"
            style={{ border: "none" }}
          />
        );
      } else {
        return (
          <Box sx={{ p: 3, textAlign: "center" }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              This file type cannot be previewed directly.
            </Typography>
            <Button
              variant="contained"
              href={fileUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open in New Tab
            </Button>
          </Box>
        );
      }
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            height: "90vh",
            m: 2,
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Document Preview
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {renderFileContent()}
        </DialogContent>
      </Dialog>
    );
  };

  // Update the renderFileActions function

  const renderSidebar = () => (
    <Box
      sx={{
        width: 280,
        height: "85vh",
        borderRadius: "10px",
        border: "1px solid #e0e0e0",
        bgcolor: "white",
        m: "20px",
        overflow: "hidden",
      }}
    >
      <ThemeProvider theme={lightTheme}>
        {/* Search Box Container */}
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid #e0e0e0",
            position: "sticky",
            top: 0,
            bgcolor: "white",
            zIndex: 1,
          }}
        >
          <TextField
            fullWidth
            size="small"
            placeholder="Search by ID or name..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <Box sx={{ color: "gray", mr: 1 }}>
                  <Person fontSize="small" />
                </Box>
              ),
              sx: {
                borderRadius: "8px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#e0e0e0",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#04497a",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#04497a",
                },
              },
            }}
          />
        </Box>

        {/* List Container */}
        <List
          sx={{
            overflowY: "auto",
            maxHeight: "calc(100% - 72px)",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) => (
              <ListItemButton
                key={user.employeeId}
                onClick={() => handleEmployeeSelect(user.employeeId)}
                selected={selectedEmployee === user.employeeId}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "rgba(4, 73, 122, 0.12)",
                  },
                  borderRadius: 1,
                  mx: 1,
                  // mb: 0.5,
                  "&.Mui-selected": {
                    backgroundColor: "#e0e0e0",
                    "&:hover": {
                      backgroundColor: "rgba(4, 73, 122, 0.12)",
                    },
                  },
                }}
              >
                <ListItemIcon>
                  {/* <Person sx={{ color: "#04497a" }} /> */}
                  <Avatar
                    src={`https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/employees/${
                      user.employeeId
                    }/profile-picture/${user.profilepic}`}
                    sx={{ width: "32px", height: "32px", marginRight: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`ID: ${user.employeeId}`}
                  secondary={user.name}
                  primaryTypographyProps={{
                    sx: { color: "#04497a" },
                  }}
                />
              </ListItemButton>
            ))
          ) : (
            <Box sx={{ p: 2, textAlign: "center", color: "gray" }}>
              No employees found
            </Box>
          )}
        </List>
      </ThemeProvider>
    </Box>
  );

  // Update renderFileActions
  const renderFileActions = (file) => (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Tooltip title="View">
        <IconButton
          size="small"
          sx={{ color: "#04497a" }}
          onClick={() => handleViewDocument(file._id, file.contentType)}
        >
          <Visibility />
        </IconButton>
      </Tooltip>
      <Tooltip title="Download">
        <IconButton
          size="small"
          sx={{ color: "#04497a" }}
          onClick={() => handleDownloadDocument(file._id, file.fileName)}
        >
          <Download />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          size="small"
          sx={{ color: "#04497a" }}
          onClick={() => handleDeleteDocument(file._id)}
        >
          <Delete />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const renderContent = () => {
    if (!selectedEmployee) {
      return (
        <Box
          sx={{ p: 1, textAlign: "center", color: "#04497a", fontSize: "16px" }}
        >
          Select an employee from the sidebar to view their documents
        </Box>
      );
    }

    const selectedUser = users.find(
      (user) => user.employeeId === selectedEmployee
    );
    const userDocuments = documents[selectedEmployee] || {
      Personal: [],
      Experience: [],
      Agreements: [],
      Others: [],
    };

    if (!selectedFolder) {
      return (
        <Box sx={{ p: 3 }}>
          <ThemeProvider theme={lightTheme}>
            <Typography
              variant="h6"
              sx={{ mb: 3, color: "#04497a", fontWeight: "medium" }}
            >
              {selectedUser?.name}'s Documents
            </Typography>
            <Grid container spacing={2}>
              {Object.entries(userDocuments).map(([folder, files]) => (
                <Grid item xs={12} sm={6} key={folder}>
                  <Card
                    onClick={() => handleFolderSelect(folder)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": { bgcolor: "rgba(4, 73, 122, 0.04)" },
                      border: "1px solid #e0e0e0",
                      borderRadius: 2,
                    }}
                  >
                    <CardContent>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Folder sx={{ mr: 1, color: "#04497a" }} />
                        <Typography
                          sx={{ color: "#04497a", fontWeight: "medium" }}
                        >
                          {folder}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ mt: 1, color: "text.secondary" }}
                      >
                        {files.length} documents
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </ThemeProvider>
        </Box>
      );
    }
    const columns = [
      {
        field: "fileName",
        headerName: "Filename",
        flex: 2,
        renderCell: (params) => (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <InsertDriveFile sx={{ color: "#04497a" }} />
            <Typography sx={{ color: "#04497a" }}>{params.value}</Typography>
          </Box>
        ),
      },
      {
        field: "fileSize",
        headerName: "Size",
        flex: 1,
        valueGetter: (params) => formatFileSize(params.row.fileSize),
      },
      {
        field: "createdAt",
        headerName: "Modified Date",
        flex: 1,
        valueGetter: (params) => new Date(params.value).toLocaleDateString(),
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title="View">
              <IconButton
                size="small"
                sx={{ color: "#04497a" }}
                onClick={() =>
                  handleViewDocument(params.row._id, params.row.contentType)
                }
              >
                <Visibility />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download">
              <IconButton
                size="small"
                sx={{ color: "#04497a" }}
                onClick={() =>
                  handleDownloadDocument(params.row._id, params.row.fileName)
                }
              >
                <Download />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                size="small"
                sx={{ color: "#04497a" }}
                onClick={() => handleDeleteDocument(params.row._id)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ];

    return (
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => setSelectedFolder(null)}
              sx={{ mr: 1, color: "#04497a" }}
            >
              <ArrowBack />
            </IconButton>
            <Typography
              variant="h6"
              sx={{ color: "#04497a", fontWeight: "medium" }}
            >
              {selectedFolder}
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() => setUploadDialogOpen(true)}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bolder",
              padding: "10px 20px",
            }}
          >
            Upload Document
          </Button>
        </Box>

        <Box sx={{ width: "100%", height: 400 }}>
          <DataGrid
            rows={userDocuments[selectedFolder]}
            columns={columns}
            getRowId={(row) => row._id}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection={false}
            disableSelectionOnClick
            sx={dashboardStyle(theme, "480px")}
            components={{
              NoRowsOverlay: () => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    color: "#04497a",
                  }}
                >
                  No documents in this folder
                </Box>
              ),
            }}
          />
        </Box>
      </Box>
    );
  };

  // Rest of the component remains the same
  return (
    <Box sx={{ display: "flex", height: "90%", bgcolor: "#f5f5f5" }}>
      {renderSidebar()}
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>{renderContent()}</Box>

      <ThemeProvider theme={lightTheme}>
        <Dialog
          open={uploadDialogOpen}
          onClose={() => setUploadDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            Upload Document
            <IconButton
              onClick={() => setUploadDialogOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#04497a",
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              label="Document Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
            />
            <input
              accept="application/pdf,image/*,.doc,.docx,.xls,.xlsx"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              onChange={handleFileSelect}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="outlined"
                component="span"
                fullWidth
                sx={{ mt: 2 }}
                startIcon={<CloudUpload />}
              >
                {selectedFile ? selectedFile.name : "Choose File"}
              </Button>
            </label>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUploadDialogOpen(false)}>Cancel</Button>
            <Button
              variant="contained"
              onClick={handleUpload}
              sx={{
                bgcolor: "#04497a",
                "&:hover": { bgcolor: "#033a61" },
              }}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
        <FileViewerModal
          open={viewModalOpen}
          onClose={() => {
            setViewModalOpen(false);
            setSelectedDocumentUrl(null);
            setSelectedDocumentType(null);
          }}
          fileUrl={selectedDocumentUrl}
          fileType={selectedDocumentType}
        />
      </ThemeProvider>
      {/* <ConfirmationDialog
        open={uploadConfirmationOpen}
        handleClose={handleUploadCancel}
        handleConfirm={handleCreateHoliday}
        message="Are you sure you want to create this holiday calendar?"
      /> */}
      {isLoading && <Spinner />}
      <ToastContainer />
    </Box>
  );
};

export default EmployeeDocuments;
