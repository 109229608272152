import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Button,
  Tab,
  Tabs,
  Modal,
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast, ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import { dashboardStyle, tabStyles } from "shared/utils";
import FlexBetween from "shared/FlexBetween";
import Header from "shared/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationDialog from "shared/ConfirmationDialog";
import axios from "axios";
import Spinner from "shared/spinner/Spinner";
import CloseIcon from "@mui/icons-material/Close";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";

const CommentModal = ({
  open,
  handleClose,
  comments = [],
  onSave,
  readOnly,
  isLoading,
  currentUser,
}) => {
  const [newComment, setNewComment] = useState("");

  const handleSave = () => {
    onSave(newComment);
    setNewComment("");
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="comment-modal"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
          p: 4,
          width: "500px",
          maxWidth: "90%",
          maxHeight: "90vh",
          overflow: "auto",
          color: "#333",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
              color: "#04497a",
              fontSize: "1.25rem",
            }}
          >
            {readOnly ? "Comments History" : "Add Comment"}
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              color: "#666",
              "&:hover": {
                color: "crimson",
                backgroundColor: "rgba(220,20,60,0.1)",
              },
            }}
            disabled={isLoading}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Comments History Section */}
        <Box
          sx={{
            mb: 3,
            maxHeight: "300px",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ccc",
              borderRadius: "4px",
            },
          }}
        >
          {comments && comments.length > 0 ? (
            comments.map((comment, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,
                  animation: "fadeIn 0.3s ease-in",
                  "@keyframes fadeIn": {
                    from: { opacity: 0 },
                    to: { opacity: 1 },
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#04497a",
                      fontWeight: 600,
                    }}
                  >
                    {comment.authorName}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#666",
                      fontSize: "0.75rem",
                    }}
                  >
                    {new Date(comment.createdAt).toLocaleString()}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#333",
                    backgroundColor:
                      comment.author === currentUser ? "#e3f2fd" : "#f5f5f5",
                    padding: "12px 16px",
                    borderRadius: "12px",
                    marginLeft: comment.author === currentUser ? "auto" : "0",
                    marginRight: comment.author === currentUser ? "0" : "auto",
                    maxWidth: "80%",
                    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                    fontSize: "0.9rem",
                    lineHeight: "1.5",
                  }}
                >
                  {comment.text}
                </Typography>
                {index < comments.length - 1 && (
                  <Divider sx={{ mt: 2, backgroundColor: "#eee" }} />
                )}
              </Box>
            ))
          ) : (
            <Typography
              variant="body2"
              sx={{
                color: "#666",
                textAlign: "center",
                fontStyle: "italic",
              }}
            >
              No comments yet
            </Typography>
          )}
        </Box>

        {/* Add New Comment Section */}
        {!readOnly && (
          <>
            <Divider sx={{ mb: 3, backgroundColor: "#eee" }} />
            <TextField
              fullWidth
              multiline
              rows={3}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              disabled={isLoading}
              placeholder="Add your comment..."
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#fff",
                  "& fieldset": {
                    borderColor: "#ddd",
                  },
                  "&:hover fieldset": {
                    borderColor: "#04497a",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#04497a",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  color: "#333",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#999",
                  opacity: 1,
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isLoading || !newComment.trim()}
              sx={{
                backgroundColor: "#04497a",
                padding: "8px 24px",
                borderRadius: "8px",
                textTransform: "none",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#033c65",
                },
                "&:disabled": {
                  backgroundColor: "#ccc",
                  color: "#666",
                },
                transition: "all 0.2s ease",
              }}
            >
              {isLoading ? "Adding..." : "Add Comment"}
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

function EditTimesheetIND() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { yearFromUrl, monthFromUrl, id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [timesheetEntries, setTimesheetEntries] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteEntryId, setDeleteEntryId] = useState(null);

  // Form states
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedWorkType, setSelectedWorkType] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [workingHours, setWorkingHours] = useState();
  const [editMode, setEditMode] = useState(false);
  const [editEntryId, setEditEntryId] = useState(null);
  const [user, setUser] = useState({});
  const [approveConfirmationOpen, setApproveConfirmationOpen] = useState(false);
  const [editingRows, setEditingRows] = useState({});
  const [timesheetStatus, setTimesheetStatus] = useState("");
  const [name, setName] = useState("");

  //comment modal states
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [isViewMode, setIsViewMode] = useState(false);

  const generateInitialEntries = (month, year) => {
    const monthIndex = parseInt(month) - 1;
    const yearNum = parseInt(year);

    const daysInMonth = new Date(yearNum, monthIndex + 1, 0).getDate();

    const entries = [];

    for (let day = 2; day <= daysInMonth + 1; day++) {
      const currentDate = new Date(yearNum, monthIndex, day);
      const dayOfWeek = currentDate.getDay();
      const isWeekend = dayOfWeek === 1;

      entries.push({
        _id: `temp-${
          new Date(yearNum, monthIndex, day).toISOString().split("T")[0]
        }`, // Add temporary _id
        date: currentDate.toISOString().split("T")[0],
        workType: isWeekend ? "Weekend off" : "Work from Home",
        workingHours: isWeekend ? 0 : 9,
        overtimeHours: 0,
        comments: "",
        updatedAt: new Date().toISOString(),
      });
    }

    return entries;
  };

  const initializeTimesheet = async () => {
    if (!id || !employeeId || !monthFromUrl) return;

    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}attendance/getTimesheetEntriesIND`,
        {
          params: {
            employeeId,
            timesheetId: id,
            month: parseInt(monthFromUrl),
            year: yearFromUrl,
          },
          withCredentials: true,
        }
      );

      let entries = response.data.entries || [];

      if (entries.length === 0) {
        // Generate entries for the full month
        const initialEntries = generateInitialEntries(
          monthFromUrl,
          yearFromUrl
        );

        // Save the generated entries
        const saveResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}attendance/addBulkTimesheetEntriesIND`,
          {
            employeeId,
            timesheetId: id,
            month: parseInt(monthFromUrl),
            year: selectedYear,
            entries: initialEntries,
          },
          { withCredentials: true }
        );

        entries = saveResponse.data.entries || initialEntries;

        // Make sure each entry has _id for proper identification
        entries = entries.map((entry, index) => ({
          ...entry,
          _id: entry._id || initialEntries[index]._id || `temp-${index}`,
        }));
      }

      setTimesheetEntries(entries);
      setTimesheetStatus(response.data.status);
    } catch (error) {
      console.error("Error initializing timesheet:", error);
      toast.error("Error initializing timesheet");
    } finally {
      setIsLoading(false);
    }
  };

  // Replace the existing useEffect with this one
  useEffect(() => {
    const initializeData = async () => {
      try {
        await fetchEmployeeId();
        if (id && employeeId && monthFromUrl) {
          await initializeTimesheet();
        }
      } catch (error) {
        console.error("Error initializing data:", error);
      }
    };

    initializeData();
  }, [id, employeeId, monthFromUrl]);

  const fetchEmployeeId = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}profilepage`,
        { withCredentials: true }
      );
      setUser(response.data);
      setName(response.data.name);
      setEmployeeId(response.data.employeeId);
    } catch (error) {
      console.error("Error fetching employee ID:", error);
      toast.error("Error fetching employee data");
    }
  };

  // When fetching timesheet entries:
  const fetchTimesheetEntries = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}attendance/getTimesheetEntriesIND`,
        {
          params: {
            employeeId,
            timesheetId: id,
            month: parseInt(monthFromUrl), // monthFromUrl is already 1-based
            year: selectedYear,
          },
          withCredentials: true,
        }
      );

      const entries = response.data.entries.map((entry, index) => ({
        ...entry,
        _id: entry._id || `temp-${index}`,
      }));

      setTimesheetEntries(entries);
      setTimesheetStatus(response.data.status);
    } catch (error) {
      console.error("Error fetching timesheet entries:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveAllChanges = async () => {
    try {
      setIsLoading(true);
      await axios.post(
        `${process.env.REACT_APP_API_URL}attendance/addBulkTimesheetEntriesIND`,
        {
          employeeId,
          timesheetId: id,
          month: monthFromUrl,
          year: selectedYear,
          entries: timesheetEntries, // These already have the correct updatedAt values
        },
        { withCredentials: true }
      );
      toast.success("All changes saved successfully");
    } catch (error) {
      console.error("Error saving changes:", error);
      toast.error("Error saving changes");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteConfirmation = (entryId) => {
    setDeleteEntryId(entryId);
    setConfirmationOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_API_URL}attendance/deleteTimesheetEntryIND`,
        {
          params: {
            employeeId,
            timesheetId: id,
            entryId: deleteEntryId,
            month: monthFromUrl,
            year: selectedYear,
          },
          withCredentials: true,
        }
      );
      toast.success("Entry deleted successfully");
      fetchTimesheetEntries();
    } catch (error) {
      console.error("Error deleting entry:", error);
      toast.error("Error deleting entry");
    } finally {
      setIsLoading(false);
      setConfirmationOpen(false);
    }
  };

  // Add a submit button in the UI
  const handleSubmitTimesheet = async () => {
    try {
      const timesheetStatus =
        user.role === "admin" ||
        user.role === "manager" ||
        user.role === "hr" ||
        user.role === "superadmin"
          ? "Approved"
          : "Submitted";

      setIsLoading(true);

      // First update all entries
      await axios.post(
        `${process.env.REACT_APP_API_URL}attendance/addBulkTimesheetEntriesIND`,
        {
          employeeId,
          timesheetId: id,
          month: monthFromUrl,
          year: yearFromUrl,
          entries: timesheetEntries,
        },
        { withCredentials: true }
      );

      // Then update the status
      await axios.put(
        `${process.env.REACT_APP_API_URL}attendance/submitTimesheetStatusIND`,
        {
          employeeId,
          timesheetId: id,
          month: monthFromUrl,
          year: yearFromUrl,
          status: timesheetStatus,
        },
        { withCredentials: true }
      );

      toast.success("Timesheet submitted successfully");
      setApproveConfirmationOpen(false);
      navigate(-1);
    } catch (error) {
      console.error("Error submitting timesheet:", error);
      toast.error("Error submitting timesheet");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCommentIconClick = (entry, viewMode) => {
    setSelectedEntry({
      ...entry,
      id: entry._id || entry.id,
    });
    setIsViewMode(viewMode);
    setCommentModalOpen(true);
  };

  const handleSaveComment = async (newComment) => {
    try {
      setIsLoading(true);
      const currentTime = new Date().toISOString();

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}attendance/updateCommentIND`,
        {
          employeeId,
          timesheetId: id,
          entryId: selectedEntry.id,
          author: user.employeeId,
          authorName: name,
          comment: newComment,
          updatedAt: currentTime, // Add this line
        },
        { withCredentials: true }
      );

      if (response.data.success) {
        // Update only the specific entry's comments and updatedAt
        setTimesheetEntries((prev) =>
          prev.map((entry) =>
            entry._id === selectedEntry.id
              ? {
                  ...entry,
                  updatedAt: currentTime, // Update the timestamp
                  comments: [
                    ...(entry.comments || []),
                    {
                      text: newComment,
                      author: user.employeeId,
                      authorName: name,
                      createdAt: new Date(),
                    },
                  ],
                }
              : entry
          )
        );

        toast.success("Comment saved successfully");
        setCommentModalOpen(false);
      } else {
        throw new Error(response.data.message || "Error saving comment");
      }
    } catch (error) {
      console.error("Error saving comment:", error);
      toast.error(error.message || "Error saving comment");
    } finally {
      setIsLoading(false);
    }
  };

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  useEffect(() => {
    if (
      selectedWorkType === "Privilege leave" ||
      selectedWorkType === "Sick leave" ||
      selectedWorkType === "Weekend off" ||
      selectedWorkType === "Public Holiday" ||
      selectedWorkType === "Paid time off"
    ) {
      setWorkingHours("0");
    }
  }, [selectedWorkType]);

  const handleStartEdit = (rowId) => {
    setEditingRows((prev) => ({
      ...prev,
      [rowId]: true,
    }));
  };

  const handleCancelEdit = (rowId) => {
    setEditingRows((prev) => ({
      ...prev,
      [rowId]: false,
    }));

    // Revert changes if needed
    fetchTimesheetEntries();
  };

  const handleSaveEdit = async (rowId) => {
    try {
      setIsLoading(true);
      const entryToUpdate = timesheetEntries.find(
        (entry) => entry._id === rowId
      );

      await axios.put(
        `${process.env.REACT_APP_API_URL}timesheetUS/updateTimesheetEntry`,
        {
          employeeId,
          timesheetId: id,
          entryId: rowId,
          month: monthFromUrl,
          year: selectedYear,
          entry: entryToUpdate,
        },
        { withCredentials: true }
      );

      // Exit edit mode
      setEditingRows((prev) => ({
        ...prev,
        [rowId]: false,
      }));

      toast.success("Entry updated successfully");
    } catch (error) {
      console.error("Error updating entry:", error);
      toast.error("Error updating entry");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCellEdit = async (params) => {
    try {
      setIsLoading(true);

      // Find the existing entry from timesheetEntries
      const existingEntry = timesheetEntries.find(
        (entry) => entry._id === params.id
      );

      const currentTime = new Date().toISOString();

      // Create updated entry maintaining all existing fields
      const updatedEntry = {
        ...existingEntry,
        date: existingEntry.date,
        workType:
          params.field === "workType" ? params.value : existingEntry.workType,
        workingHours:
          params.field === "workingHours"
            ? Number(params.value) || 0
            : existingEntry.workingHours,
        updatedAt: currentTime, // Update the timestamp
      };

      // If work type is leave, set working hours to 0
      if (
        params.field === "workType" &&
        (params.value === "Privilege leave" ||
          params.value === "Sick leave" ||
          params.value === "Weekend off" ||
          params.value === "Public Holiday" ||
          params.value === "Paid time off")
      ) {
        updatedEntry.workingHours = 0;
      }

      await axios.put(
        `${process.env.REACT_APP_API_URL}timesheetUS/updateTimesheetEntry`,
        {
          employeeId,
          timesheetId: id,
          entryId: params.id,
          month: monthFromUrl,
          year: selectedYear,
          entry: updatedEntry, // Send the complete entry
        },
        { withCredentials: true }
      );

      // Update local state
      setTimesheetEntries((prev) =>
        prev.map((entry) => (entry._id === params.id ? updatedEntry : entry))
      );

      toast.success("Entry updated successfully");
    } catch (error) {
      console.error("Error updating entry:", error);
      toast.error("Error updating entry");
    } finally {
      setIsLoading(false);
    }
  };
  const formatAndSortRows = (entries) => {
    return entries
      .map((entry, index) => ({
        id: entry._id || `temp-${index}`, // Use the _id as the DataGrid id
        _id: entry._id || `temp-${index}`, // Keep original _id for reference
        ...entry,
        index: index + 1,
        date: entry.date
          ? new Date(entry.date).toISOString().split("T")[0]
          : "",
        day: entry.date
          ? new Date(entry.date).toLocaleDateString("en-US", {
              weekday: "long",
            })
          : "",
        workType: entry.workType || "Work from Home",
        workingHours: entry.workingHours || 0,
        overtimeHours: entry.overtimeHours || 0,
        status: entry.status || "Pending",
        comments: entry.comments || "",
        updatedAt: entry.updatedAt,
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  };
  // Modify columns array to remove input cell renderers
  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 40,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "day",
      headerName: "Day",
      flex: 0.6,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "workType",
      headerName: "Work Type",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (timesheetStatus === "Pending" || timesheetStatus === "Rejected") {
          return (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Select
                value={params.row.workType}
                fullWidth
                onChange={(e) => {
                  const newValue = e.target.value;
                  const currentTime = new Date().toISOString();

                  // Create a copy of the current timesheetEntries
                  const updatedEntries = [...timesheetEntries];

                  // Find the entry to update
                  const entryIndex = updatedEntries.findIndex(
                    (entry) =>
                      entry._id === params.row.id ||
                      entry._id === params.row._id
                  );

                  if (entryIndex !== -1) {
                    // Update the entry with the new workType and updatedAt timestamp
                    updatedEntries[entryIndex] = {
                      ...updatedEntries[entryIndex],
                      workType: newValue,
                      updatedAt: currentTime,
                      workingHours:
                        newValue === "Privilege leave" ||
                        newValue === "Sick leave" ||
                        newValue === "Weekend off" ||
                        newValue === "Public Holiday" ||
                        newValue === "Paid time off"
                          ? 0
                          : updatedEntries[entryIndex].workingHours,
                    };

                    // Set the state with the updated entries
                    setTimesheetEntries(updatedEntries);
                  }
                }}
                sx={{ width: "100%", fontSize: "12px" }}
              >
                <MenuItem value="Work from Office">Work from Office</MenuItem>
                <MenuItem value="Work from Home">Work from Home</MenuItem>
                <MenuItem value="Weekend off">Weekend off</MenuItem>
                <MenuItem value="Public Holiday">Public Holiday</MenuItem>
                <MenuItem value="Sick leave">Sick Leave</MenuItem>
                <MenuItem value="Privilege leave">Privilege Leave</MenuItem>
              </Select>
            </Box>
          );
        }
        return (
          <Typography
            fontSize="13px"
            sx={{ width: "100%", textAlign: "center" }}
          >
            {params.row.workType}
          </Typography>
        );
      },
    },
    {
      field: "workingHours",
      headerName: "Regular hrs",
      flex: 0.6,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (timesheetStatus === "Pending" || timesheetStatus === "Rejected") {
          return (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <TextField
                type="number"
                value={params.row.workingHours}
                onChange={(e) => {
                  const newValue = Number(e.target.value) || 0;
                  const currentTime = new Date().toISOString();

                  // Create a copy of the current timesheetEntries
                  const updatedEntries = [...timesheetEntries];

                  // Find the entry to update
                  const entryIndex = updatedEntries.findIndex(
                    (entry) =>
                      entry._id === params.row.id ||
                      entry._id === params.row._id
                  );

                  if (entryIndex !== -1) {
                    // Update the entry with the new workingHours and updatedAt timestamp
                    updatedEntries[entryIndex] = {
                      ...updatedEntries[entryIndex],
                      workingHours: newValue,
                      updatedAt: currentTime,
                    };

                    // Set the state with the updated entries
                    setTimesheetEntries(updatedEntries);
                  }
                }}
                disabled={
                  params.row.workType === "Privilege leave" ||
                  params.row.workType === "Sick leave" ||
                  params.row.workType === "Weekend off" ||
                  params.row.workType === "Public Holiday" ||
                  params.row.workType === "Paid time off"
                }
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 24,
                    style: { textAlign: "center" },
                  },
                  style: { fontSize: "13px" },
                }}
                size="small"
              />
            </Box>
          );
        }
        return (
          <Typography
            fontSize="13px"
            sx={{ width: "100%", textAlign: "center" }}
          >
            {params.row.workingHours}
          </Typography>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated On",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography fontSize="13px" sx={{ width: "100%", textAlign: "center" }}>
          {new Date(params.value).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          })}
        </Typography>
      ),
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 0.8,
      headerAlign: "center",
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 1,
            justifyContent: "center",
          }}
        >
          {/* Edit Comment Button */}
          <IconButton
            sx={{ color: "#04497a" }}
            onClick={() => handleCommentIconClick(params.row, false)}
            disabled={
              timesheetStatus === "Approved" || timesheetStatus === "Submitted"
            }
          >
            <EditIcon />
          </IconButton>

          {/* View Comments Button */}
          <IconButton
            sx={{
              color: params.row.comments?.length > 0 ? "#04497a" : "grey",
            }}
            onClick={() => handleCommentIconClick(params.row, true)}
            disabled={!params.row.comments?.length}
          >
            <InsertCommentOutlinedIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  const allRows = formatAndSortRows(timesheetEntries);

  const currentDate = new Date();
  const nextDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );
  return (
    <>
      <div>
        <FlexBetween sx={{ position: "fixed" }}>
          <IconButton
            onClick={() => navigate(-1)}
            style={{ color: "#04497a", marginTop: "18px", marginLeft: "10px" }}
          >
            <ArrowBackIcon />
          </IconButton>
        </FlexBetween>
      </div>
      <Box m="1.5rem 2.5rem" height="calc(100vh - 275px)">
        <Box>
          <FlexBetween sx={{ marginBottom: "0.5rem", justifyContent: "start" }}>
            <Header
              title="Timesheet"
              // subtitle={timesheetStatus}
              sx={{ alignSelf: "flex-start" }}
            />
            <Box sx={{ marginLeft: "auto" }}>
              <Button
                variant="contained"
                onClick={saveAllChanges}
                sx={{
                  backgroundColor: "#04497a",
                  color: theme.palette.background.alt,
                  fontSize: "14px",
                  fontWeight: "bolder",
                  padding: "10px 20px",
                  marginRight: "10px",
                  "&.Mui-disabled": {
                    backgroundColor: "grey",
                    color: "white",
                    opacity: 0.7,
                  },
                }}
                disabled={
                  timesheetStatus === "Approved" ||
                  timesheetStatus === "Submitted"
                }
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={() => setApproveConfirmationOpen(true)}
                sx={{
                  backgroundColor:
                    timesheetStatus === "Approved" ||
                    timesheetStatus === "Submitted"
                      ? "grey"
                      : "#04497a",
                  color: theme.palette.background.alt,
                  fontSize: "14px",
                  fontWeight: "bolder",
                  padding: "10px 20px",
                  "&.Mui-disabled": {
                    backgroundColor: "grey",
                    color: "white",
                    opacity: 0.7,
                  },
                }}
              >
                {user.role === "admin" ||
                user.role === "manager" ||
                user.role === "hr" ||
                user.role === "superadmin"
                  ? "Approve"
                  : "Submit"}
              </Button>
            </Box>
          </FlexBetween>
          <ThemeProvider theme={lightTheme}>
            <DataGrid
              rows={formatAndSortRows(timesheetEntries)}
              columns={columns}
              pageSize={31}
              disableSelectionOnClick
              disableColumnMenu
              disableRowSelectionOnClick
              pageSizeOptions={[31]}
              sx={{
                ...dashboardStyle(theme, "480px"),
                // height: "480px",
                "& .MuiDataGrid-row": {
                  maxHeight: "40px !important",
                  minHeight: "40px !important",
                },
                "& .MuiDataGrid-cell": {
                  maxHeight: "40px !important",
                  minHeight: "40px !important",
                  cursor: "default !important",
                  "&:focus": {
                    outline: "none",
                  },
                  "&:focus-within": {
                    outline: "none",
                  },
                },
                "& .MuiDataGrid-cell--editing": {
                  backgroundColor: "transparent !important",
                  outline: "none !important",
                },
                "& .MuiDataGrid-virtualScroller": {
                  minHeight: "auto !important",
                  overflowY: "auto !important",
                },
                "& .MuiDataGrid-virtualScrollerContent": {
                  minHeight: "auto !important",
                },
                "& .MuiDataGrid-virtualScrollerRenderZone": {
                  position: "relative",
                  minHeight: "auto !important",
                },
              }}
            />
          </ThemeProvider>
          {/* Add this before the closing tag of your component */}
          <CommentModal
            open={commentModalOpen}
            handleClose={() => setCommentModalOpen(false)}
            comments={selectedEntry?.comments || []}
            onSave={handleSaveComment}
            readOnly={isViewMode}
            isLoading={isLoading}
            currentUser={user.employeeId}
          />
          <ConfirmationDialog
            open={confirmationOpen}
            handleClose={() => setConfirmationOpen(false)}
            handleConfirm={handleConfirmDelete}
            message="Are you sure you want to delete this entry?"
          />
          <ConfirmationDialog
            open={approveConfirmationOpen}
            handleClose={() => setApproveConfirmationOpen(false)}
            handleConfirm={handleSubmitTimesheet}
            message="Are you sure you want to submit this timesheet?"
          />
          {isLoading && <Spinner />}
          <ToastContainer />
        </Box>
      </Box>
    </>
  );
}

export default EditTimesheetIND;
