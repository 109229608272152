import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Modal,
  useTheme,
} from "@mui/material";
import "./editProfile.css";
import { Link, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";
import FlexBetween from "shared/FlexBetween";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getCalendarPdfUrl } from "shared/utils";
import { Edit as EditIcon } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { ToastContainer, toast } from "react-toastify";
import useAWSDetails from "shared/useAWSDetails";

function ProfilePage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [id, setId] = useState("");
  const [openUpload, setOpenUpload] = useState(false);
  const [file, setFile] = useState();
  const [profilePic, setProfilePic] = useState();
  // const [fetchedBucketName, setFetchedBucketName] = useState("");
  // const [fetchedRegion, setFetchedRegion] = useState("");
  const { fetchedBucketName, fetchedRegion} = useAWSDetails();

  // async function fetchAWSDetails() {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}getAWSDetails`
  //     );
  //     const data = response.data;
  //     const { bucketName, region } = data;

  //     setFetchedBucketName(bucketName);
  //     setFetchedRegion(region);
  //     // Now you can use the bucketName and region in your frontend code
  //     console.log("Bucket Name:", bucketName);
  //     console.log("Region:", region);

  //     // Example usage
  //     // initializeS3Client(bucketName, region);
  //   } catch (error) {
  //     console.error("Error fetching AWS details:", error.message);
  //   }
  // }

  // useEffect(() => {
  //   fetchAWSDetails();
  // }, []);

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setProfilePic(e.target.files[0]);
  }

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.reload) {
          // If the backend indicates a reload, clear the token and reload the page
          document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          window.location.reload();
        } else {
          // setEmployee(response.data);
          setId(response.data._id);
          console.log(id);
        }

      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        if (error.response && error.response.status === 401) {
          toast.warning("Your account has been disabled. If you think this is an error contact HR. You will be logged out soon.", {
            onClose: () => {
              // Clear the token from cookies
              document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              // Reload the page after a delay
              setTimeout(() => {
                window.location.reload();
              }, 3000); // 5 seconds delay
            }
          });
        }
      });
  }, [user]);

  useEffect(() => {
    if (id) {
    axios
      .get(process.env.REACT_APP_API_URL + "getUser/" + id) // Update this endpoint to the correct one
      .then((result) => {
        setUser(result.data);
      })
      .catch((err) => console.log(err));
    }
  }, [id]);

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("profilePic", profilePic);
      formData.append("employeeId", user.employeeId);

      const response = await axios.post(
        process.env.REACT_APP_API_URL + "upload-profile-pic/" + id,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setOpenUpload(false);
      toast.success("Profile picture uploaded successfully!");
      console.log(response.data); // Log success message or handle it as needed
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      // Handle error
    }
  };

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const handleOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleCloseUpload = () => setOpenUpload(false);

  const birthDate = new Date(user.birthDate);

  const formattedBirthDate = birthDate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return (
    <div>
      <Modal
        open={openUpload}
        onClose={handleCloseUpload}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      // sx={{width:"100px"}}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#e0e0e0",
            boxShadow: 24,
            p: 4,
            width: "80%", // Adjust the width here as per your requirement
            maxWidth: "500px", // Set maximum width if needed
            outline: "none",
            borderRadius: "10px",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseUpload}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "crimson",
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="upload-section">
            <form>
              <div className="formbold-mb-3">
                <label className="picture" htmlFor="picture__input">
                  <span
                    className="picture__image"
                    style={{ cursor: "pointer" }}
                  >
                    <input
                      type="file"
                      name="profilePic"
                      id="picture__input"
                      onChange={handleChange}
                      style={{ display: "none" }}
                    />
                    {!file && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#04497a",
                        }}
                      >
                        <CloudUploadOutlinedIcon
                          sx={{ fontSize: "45px", margin: "0 10px" }}
                        />
                        <p style={{ textAlign: "center", fontSize: "18px" }}>
                          Click to select a file!
                        </p>
                      </div>
                    )}
                    {file && (
                      <img src={file} alt="Uploaded" className="picture__img" />
                    )}
                  </span>
                </label>
              </div>

              <Button
                variant="contained"
                type="button"
                onClick={handleUpload}
                sx={{
                  backgroundColor: theme.palette.secondary.light,
                  color: theme.palette.background.alt,
                  fontSize: "14px",
                  fontWeight: "bolder",
                  padding: "10px 20px",
                  margin: "15px 0px",
                }}
              >
                Upload
              </Button>
            </form>
          </div>
        </Box>
      </Modal>

      <FlexBetween sx={{ position: "fixed" }}>
        <IconButton
          onClick={() => navigate(-1)}
          style={{
            color: "#04497a",
            position: "absolute",
            marginTop: "-25px",
            marginLeft: "10px",
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      </FlexBetween>
      <Container maxWidth="md" style={{ marginTop: 40 }}>
        <ThemeProvider theme={lightTheme}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginBottom={4}
          >
            <div className="avatar-container" style={{ position: 'relative' }}>
              <Avatar
                src={`https://${fetchedBucketName}.s3.${fetchedRegion}.amazonaws.com/${getCalendarPdfUrl()}/employees/${user.employeeId
                  }/profile-picture/${user.profilepic}`}
                sx={{ width: 120, height: 120 }}
              />
              <IconButton
                onClick={handleOpenUpload}
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  backgroundColor: "white",
                  color: '#04497a',
                  padding: '8px',
                  borderRadius: '50%',
                  boxShadow: '0px 2px 4px rgba(0,0,0,0.65)',
                  transition: 'all 0.3s ease',
                }}
                sx={{
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                    transform: 'scale(1.1)',
                  }
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </div>

            <Typography variant="h4" gutterBottom color="textPrimary">
              {user.name}
            </Typography>
            {/* <Typography variant="h6" color="textSecondary" gutterBottom>
              {user.role}
            </Typography> */}
            <Typography variant="h5" color="textSecondary" gutterBottom>
              {user.department}
            </Typography>
          </Box>

          <Card
            variant="outlined"
            sx={{ maxWidth: 600, margin: "0 auto 20px" }}
          >
            <CardContent>
              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    color="textSecondary"
                  >
                    Employee Id
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ borderBottom: "1px solid #E0E0E0", paddingBottom: 1 }}
                    color="textPrimary"
                  >
                    {user.employeeId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    color="textSecondary"
                  >
                    Email
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ borderBottom: "1px solid #E0E0E0", paddingBottom: 1 }}
                    color="textPrimary"
                  >
                    {user.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    color="textSecondary"
                  >
                    Mobile
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ borderBottom: "1px solid #E0E0E0", paddingBottom: 1 }}
                    color="textPrimary"
                  >
                    {user.number}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    color="textSecondary"
                  >
                    Work Location
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ borderBottom: "1px solid #E0E0E0", paddingBottom: 1 }}
                    color="textPrimary"
                  >
                    {user.workLocation}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    color="textSecondary"
                  >
                    Birth Date
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ borderBottom: "1px solid #E0E0E0", paddingBottom: 1 }}
                    color="textPrimary"
                  >
                    {formattedBirthDate}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    color="textSecondary"
                  >
                    Address
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ borderBottom: "1px solid #E0E0E0", paddingBottom: 1 }}
                    color="textPrimary"
                  >
                    {user.address}
                    {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui voluptas incidunt maxime praesentium asperiores odit aliquam! Iusto veniam omnis optio quae necessitatibus incidunt sint. */}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Box display="flex" justifyContent="center">
            <Button
              component={Link}
              to={`/editprofile/${user._id}`}
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "#04497a", fontWeight: "bolder" }}
            >
              Edit
            </Button>
          </Box>
        </ThemeProvider>
      </Container>
      <ToastContainer />
    </div>
  );
}

export default ProfilePage;
