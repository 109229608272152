import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./login.css";

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(process.env.REACT_APP_API_URL + "forgot-password", { email })
      .then(res => {
        if (res.data.Status === "Success") {
          toast.success("OTP sent to your email");
          navigate("/verify-otp", { state: { email } });
        } else {
          toast.error("Error sending OTP");
        }
      })
      .catch(err => {
        toast.error('Invalid Otp.');
        console.error(err);
      });
  };

  return (
    <div className="centered-container">
      <form className="login_form_container" onSubmit={handleSubmit}>
        <h2 className="title" style={{ fontSize: "18px" }}>Forgot Password</h2>
        <div className="input_container" style={{ width: "85%" }}>
          <label className="input_label" htmlFor="email_field" style={{ color: "black", fontSize: "14px" }}>Email</label>
          <input placeholder="Enter your email" title="Email Address" name="email" type="text" className="input_field" id="email_field" onChange={(e) => setEmail(e.target.value)} />
        </div>
        <button title="Send OTP" type="submit" className="sign-in_btn" style={{ width: "85%", marginBottom: "10px", fontSize: "14px" }}>
          <span>Send OTP</span>
        </button>
      </form>
      <ToastContainer />
    </div>
  );
}

export default ForgotPassword;
