// client/src/shared/SessionHandler.jsx
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  useTheme,
  DialogTitle,
} from "@mui/material";
import { toast } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo, red } from "@mui/material/colors";

const SESSION_TIMEOUT = 3 * 60 * 1000; // 3 minutes in milliseconds
const WARNING_TIME = 2 * 60 * 1000; // 2 minutes in milliseconds

const SessionHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [isWarningShown, setIsWarningShown] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  const lightTheme = createTheme({
    palette: {
      primary: indigo,
      error: red,
    },
  });

  // Check if user is authenticated
  const isAuthenticated = () => {
    return document.cookie.includes('token=');
  };

  // Check if current route is public
  const isPublicRoute = () => {
    const publicRoutes = ['/login', '/forgot-password', '/verify-otp', '/reset-password'];
    return publicRoutes.includes(location.pathname);
  };

  const resetTimer = () => {
    if (!isAuthenticated() || isPublicRoute()) return;
    setLastActivity(Date.now());
    setIsWarningShown(false);
  };

  const handleLogout = () => {
    // Clear the token cookie
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // Navigate to login
    navigate("/login");
    setIsDialogOpen(false);
  };

  useEffect(() => {
    // Don't set up session management for public routes or when not authenticated
    if (!isAuthenticated() || isPublicRoute()) {
      return;
    }

    // Event listeners for user activity
    const activityEvents = [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
      "click",
    ];

    const handleUserActivity = () => {
      if (!sessionExpired && isAuthenticated()) {
        resetTimer();
      }
    };

    // Add event listeners
    activityEvents.forEach((event) => {
      document.addEventListener(event, handleUserActivity);
    });

    // Timer to check session status
    const interval = setInterval(() => {
      if (!isAuthenticated() || isPublicRoute()) {
        return;
      }

      const timeSinceLastActivity = Date.now() - lastActivity;

      // Show warning at 2 minutes
      if (
        timeSinceLastActivity >= WARNING_TIME &&
        !isWarningShown &&
        !sessionExpired
      ) {
        toast.warning("Session will expire in a minute due to inactivity", {
          position: "top-center",
          autoClose: 5000,
        });
        setIsWarningShown(true);
      }

      // Show session expired dialog at 3 minutes
      if (timeSinceLastActivity >= SESSION_TIMEOUT && !sessionExpired) {
        setSessionExpired(true);
        setIsDialogOpen(true);
      }
    }, 1000);

    // Cleanup
    return () => {
      activityEvents.forEach((event) => {
        document.removeEventListener(event, handleUserActivity);
      });
      clearInterval(interval);
    };
  }, [lastActivity, isWarningShown, sessionExpired, navigate, location.pathname]);

  // Don't render anything if not authenticated or on public route
  if (!isAuthenticated() || isPublicRoute()) {
    return null;
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog
        open={isDialogOpen}
        onClose={() => {}}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.primary,
          },
        }}
        sx={{ borderRadius: "30px", backdropFilter: "blur(4px)" }}
      >
        <DialogTitle style={{ color: "black" }}>Session Expired</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your session has expired. Please click OK to logout.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default SessionHandler;