import React, { useEffect, useState } from "react";
import "../../user-managment/create-user/createUser.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import FlexBetween from "shared/FlexBetween";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ConfirmationDialog from "shared/ConfirmationDialog";

function EditProfile() {
  const theme = useTheme();

  const [employeeId, setEmployeeId] = useState("");
  // const [userName, setUserName] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [file, setFile] = useState();

  const [department, setDepartment] = useState("");
  const [workLocation, setWorkLocation] = useState("");
  const [role, setRole] = useState("");
  const [loggedUserRole, setLoggedUserRole] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [groups, setGroups] = useState([]);
  const [uploadConfirmationOpen, setUploadConfirmationOpen] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    if (id) {
    axios
      .get(process.env.REACT_APP_API_URL + "getUser/" + id) // Update this endpoint to the correct one
      .then((result) => {
        console.log(result);
        setEmployeeId(result.data.employeeId);
        setName(result.data.name);
        setEmail(result.data.email);
        setNumber(result.data.number);
        setBirthDate(result.data.birthDate);
        setGender(result.data.gender);
        setAddress(result.data.address);
        setDepartment(result.data.department);
        setWorkLocation(result.data.workLocation);
        setRole(result.data.role);
        setSelectedGroup(result.data.groupname);
        setFile(result.data.profilepic)
      })
      .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "groups/getgroups")
      .then((result) => {
        setGroups(result.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "profilepage", {
        withCredentials: true,
      })
      .then((response) => {
        setLoggedUserRole(response.data.role);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  const Update = (e) => {
    e.preventDefault();

    // Create FormData object
    const formData = new FormData();
    formData.append("employeeId", employeeId);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("number", number);
    formData.append("birthDate", birthDate);
    formData.append("gender", gender);
    formData.append("address", address);
    formData.append("department", department);
    formData.append("workLocation", workLocation);
    formData.append("role", role);
    formData.append("groupname", selectedGroup);

    axios
      .put(process.env.REACT_APP_API_URL + "editProfile/" + id, formData)
      .then((result) => {
        console.log(result);
        navigate(-1);
      })
      .catch((err) => console.log(err));
  };

  const handleConfirmationSubmit = () => {
    setUploadConfirmationOpen(true);
  }

  const handleUploadCancel = () => {
    // Close the confirmation dialog
    setUploadConfirmationOpen(false);
  };
  return (
    <div>
      <FlexBetween sx={{ position: "fixed" }}>
        <IconButton
          onClick={() => navigate(-1)}
          style={{ color: "#04497a", marginTop: "10px", marginLeft: "10px" }}
        >
          <ArrowBackIcon />
        </IconButton>
      </FlexBetween>
      <div
        className="formbold-main-wrapper"
        style={{ color: theme.palette.background.text }}
      >

        <div className="formbold-form-wrapper">
          <form>
            <div className="formbold-form-title">
              <h3 className="header">Edit</h3>
            </div>

            <div className="formbold-mb-3">
              <label htmlFor="address" className="formbold-form-label">
                Employee Id
              </label>
              <input
                type="text"
                name="employeeId"
                id="employeeId"
                className="formbold-form-input"
                value={employeeId}
                disabled={loggedUserRole === "employee"}
                onChange={(e) => setEmployeeId(e.target.value)}
              />
            </div>

            <div className="formbold-mb-3">
              <div>
                <label htmlFor="firstname" className="formbold-form-label">
                  Full name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="formbold-form-input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            <div className="formbold-input-flex">
              <div>
                <label htmlFor="email" className="formbold-form-label">
                  {" "}
                  Email{" "}
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="formbold-form-input"
                  value={email}
                  disabled
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="phone" className="formbold-form-label">
                  {" "}
                  Phone number{" "}
                </label>
                <input
                  type="number"
                  name="phone"
                  id="phone"
                  className="formbold-form-input"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </div>
            </div>

            <div className="formbold-input-flex">
              <div className="formbold-mb-5 w-full">
                <label htmlFor="date" className="formbold-form-label">
                  {" "}
                  Date of Birth{" "}
                </label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  className="formbold-form-input"
                  value={birthDate}
                  disabled
                  onChange={(e) => setBirthDate(e.target.value)}
                />
              </div>

              <div className="formbold-mb-3">
                <label className="formbold-form-label">Gender</label>

                <select
                  className="formbold-form-input"
                  name="gender"
                  id="gener"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option selected>Choose...</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>
              </div>
            </div>

            <div className="formbold-mb-3">
              <label htmlFor="address" className="formbold-form-label">
                Address
              </label>
              <input
                type="text"
                name="address"
                id="address"
                className="formbold-form-input"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>

            <div className="formbold-mb-3">
              <label className="formbold-form-label">Department</label>

              <select
                className="formbold-form-input"
                name="department"
                id="department"
                value={department}
                disabled
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option selected disabled>Choose...</option>
                <option value="Engineering">Engineering</option>
                <option value="HRBP/Admin">HRBP/Admin</option>
                <option value="Human Resource">Human Resource</option>
                <option value="Business">Business</option>
              </select>
            </div>

            <div className="formbold-mb-3">
              <label className="formbold-form-label">Work Location</label>

              <select
                className="formbold-form-input"
                name="worklocation"
                id="work location"
                value={workLocation}
                onChange={(e) => setWorkLocation(e.target.value)}
              >
                <option selected disabled>Choose...</option>
                <option value="Banglore">Banglore</option>
                <option value="Pune">Pune</option>
                <option value="Kolkata">Kolkata</option>
                <option value="Remote">Remote</option>
              </select>
            </div>

            <button type="button" onClick={handleConfirmationSubmit} className="formbold-btn">
              Update
            </button>
          </form>
        </div>
      </div>
      <ConfirmationDialog
        open={uploadConfirmationOpen}
        handleClose={handleUploadCancel}
        handleConfirm={Update}
        message="Are you sure you want to update this user?"
      />
    </div>
  );
}

export default EditProfile;
